import animationValidate from "../../Assets/animations/failed.json";
import Lottie from "lottie-react";
import React from "react";
import {Modal} from "react-bootstrap";
import applestore from "../../Assets/applestore.png"
import playstore from "../../Assets/playstore.png"

export default function AskDownloadApp({setShowModal,showModal}) {

    return(
        <Modal show={showModal} onHide={()=>{setShowModal(false)}} animation={false}>
            <div className="modal-header">
                <div className="modal-title">Télécharger l'application</div>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col cursor-pointer justify-content-center d-flex">
                        <img onClick={() => {
                            window.open("https://apps.apple.com/fr/app/id1615187376", "_blank")
                        }} style={{height:70, margin:"10px",cursor:"pointer"}}  src={applestore}/>
                    </div>
                    <div  className="col cursor-pointer justify-content-center d-flex">
                        <img onClick={() => {
                            window.open("https://apps.apple.com/fr/app/id1615187376", "_blank")
                        }} style={{height:70, margin:"10px",cursor:"pointer"}}  src={playstore}/>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button style={{cursor:"pointer"}} onClick={()=>{
                    setShowModal(false)
                }}>Fermer</button>
            </div>
            {/*<div style={{display:"flex",alignItems:"center",justifyContent:"center"}} className="modal-dialog-centered" role="document">*/}
            {/*   */}
            {/*</div>*/}
        </Modal>
    )
}
