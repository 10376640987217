import Navbar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer/index";
import Reduc from "./../../Containers/Reduction/Reduction";
import Banner from "./../../Containers/Reduction/Banner";
const Reduction = () => {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
          <Banner/>
          <Reduc/>
      </div>
      <Footer />
    </div>
  );
};

export default Reduction;
