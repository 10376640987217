import React from "react";
import { Navbar, Footer } from "./../../Components";
import Detail from "./../../Containers/CheckOut/Detail";
import Banner from "../../Containers/Securite/Banner";
import {default as SecuriteContainer}  from "../../Containers/Securite/Securite";

function Securite() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
          <Banner/>
          <SecuriteContainer/>
      </div>
      <Footer />
    </div>
  );
}

export default Securite;
