import React,{useState} from "react";
import RadioImg from "./../../../Assets/radio.PNG";
import "./style.css";
import {useQuery} from "react-query";
import api from "../../../Services/api";
import checkedCircle from "../../../Assets/checkedCircle.png";
import checkCircle from "../../../Assets/checkCircle.png";

const BottomSec = ({priceChoosed,setPriceChoosed}) => {
  const {
    data:dataBoostModel
  } = useQuery(['repoBoostModel'], ()=> api.getBoostModel(),{ keepPreviousData : true }
  )
  console.log(dataBoostModel)
  return (
    <div className="container bottomParent mb-5">
      <section className="bottomBox">
        <div className="bottomContent mb-4">
          <h5>Booster ma bouteille :</h5>
          <p>Elle apparaitra dans le haut du catalogue et des recherches</p>
        </div>
        {dataBoostModel?.data?.map((v,i)=>{
          return(
              <section key={"boost"+i} onClick={()=>{
                setPriceChoosed({
                  index: v.id,
                  price: v.price,
                  days: v.duration,
                })
              }} className="radioBox">
                <div>
                  <label>
                    <img style={{width:25,marginRight:5}} className={priceChoosed.index == v.id?("circleChecked"):("circleUncheck")} src={priceChoosed.index == v.id
                        ? checkedCircle
                        : checkCircle}/>
                  </label>
                </div>
                <div className="d-flex prizeCont">
                  <div className="radioContent">
                    <h5>{v.duration + " jours "}</h5>
                    <p>{"( " + Math.round((v?.price / v?.duration) * 100) / 100 + " € / jour )"}</p>
                  </div>
                  <div className="mr-3">{v?.price}&#8364;</div>
                </div>
              </section>
          )
        })}
        <section onClick={()=>{
          setPriceChoosed({
            index: -1,
            price: 0,
            days: 0,
          });
        }} className="radioBox">
          <div>
            <label>
              <img style={{width:25,marginRight:5}} className={priceChoosed.index == -1?("circleChecked"):("circleUncheck")} src={priceChoosed.index == -1
                  ? checkedCircle
                  : checkCircle}/>
            </label>
          </div>
          <div className="d-flex prizeCont">
            <div className="radioContent">
              <h5>Ne pas booster ma bouteille</h5>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default BottomSec;
