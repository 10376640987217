import React,{useState,useEffect} from "react";

import "./style.css";
import "moment/locale/fr";


function Protection() {
    return (
        <div className="container">
            <div className="whiteContainer">
                <div>
                <h1>PROTECTION ACHETEUR VIGNITUS</h1>
                <div style={{whiteSpace:"pre-line"}} >

                    Notre objectif est que les acheteurs puissent effectuer leurs achats de manière sûre. Pour ce faire, nous facturons des frais de Protection acheteurs dès lors qu’ils règlent leurs commandes par le biais de la plateforme VIGNITUS. Ces frais se décomposent comme suit :
                    Un montant fixe de 1€
                    8 % du prix de l’article (cela inclut la taxe sur la valeur ajoutée (TVA) appliquée au prix de l’article)
                    La TVA s’applique également aux frais de Protection acheteurs si l’acheteur est le consommateur de l’article. Si l’acheteur est un membre Pro, la TVA ne s’applique pas, mais l’acheteur doit le signaler et payer lui-même le montant de la TVA.
                    Tu verras le montant total de la Protection acheteurs, ajouté au prix final de ton article, au moment de régler la commande.
                    Découvre tous les avantages dont tu bénéficies grâce à la PROTECTION ACHETEUR VIGNITUS :
                    Veiller à ce que tu sois protégé e lorsque tu achètes sur la plateforme VIGNITUS est l’une de nos priorités. C’est pour cela que tu es couvert e par la Protection acheteurs lorsque tu effectues des paiements sur VGNITUS. Celle-ci comprend :
                    <h2>1. La protection de tes achats</h2>
                    Tu peux acheter l’esprit tranquille. En effet, nous te remboursons si ton article :
                    <ul>
                        <li>n’est jamais livré</li>
                        <li>est livré endommagé</li>
                        <li>n’est pas du tout conforme à sa description</li>
                    </ul>
                    Appuie simplement sur J’ai un problème et préviens-nous dans les 2 jours suivant la date à laquelle le statut de la commande est passé à « Livré ».
                    Passé ce délai de 2 jours, ou si à tout moment tu appuies sur le bouton Tout est OK, la transaction sera conclue et le paiement transféré automatiquement au vendeur.
                    <h2> 2. Des mesures renforcées contre la fraude</h2>
                    Afin qu’aucun intrus n’achète en ton nom sur VIGNITUS, nous renforçons les mesures de sécurité lorsque tu achètes. Nous contrôlons les transactions de manière aléatoire en provoquant la vérification de certains achats.
                    <h2>3. Le support de notre équipe</h2>
                    Nous sommes joignables à tout moment si tu as des questions relatives à ta commande. Nous sommes là pour veiller à ce que ton expérience sur VIGNITUS soit aussi simple que satisfaisante.
                    <h2>Bon à savoir</h2>
                    Les Frais de Protection acheteur sont obligatoires pour toute commande passée sur VIGNITUS et sont appliqués automatiquement lors du paiement.



                </div>
                </div>
            </div>
        </div>)
}
export default Protection;
