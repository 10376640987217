import React,{useState,useEffect} from "react";

import "./style.css";
import "moment/locale/fr";


function Confidentialite() {
    return (
        <div className="container">
            <div className="whiteContainer">
                <div>
                <h1>Politique de confidentialité VIGNITUS</h1>
                <div style={{whiteSpace:"pre-line"}}>
                    <span className="color">CONDITIONS GÉNÉRALES DE VENTE DES VENDEURS PROFESSIONNELS VINTED</span> - En
                    date du 01/01/2022


                    Chez VIGNITUS, dès lors qu’il s’agit de vos données à caractère personnel, la sécurité et la
                    transparence sont des priorités absolues. Afin de vous aider à comprendre quelles sont les
                    informations que nous collectons à votre sujet, comment nous les utilisons et quels sont vos droits,
                    nous avons rédigé la présente Politique de confidentialité détaillée.

                    <span className="color">1. Généralités</span>
                    <span className="color">2.  Pourquoi et comment collections nous toutes vos données à caractère personnel ?</span>
                    <span className="color">3. Bénéficiaires des données à caractère personnel</span>
                    <span className="color">4 Ce qu’on doit savoir quand nous interagissons avec un vendeur pro</span>
                    <span className="color">5. Utilisation des Cookies</span>
                    <span className="color">6. Droit d’amendement</span>
                    <span
                        className="color">7. Vos droits statutaires concernant vos données à caractère personnel</span>
                    <span className="color">8. Nos coordonnées</span>

                    <h2>1. Généralités</h2>

                    La présente Politique de confidentialité s’applique à la plateforme en ligne de VIGNITUS (ci-après
                    le « Site Web ») et à l’application associée (ci-après « l’Application ») (la « Plateforme » fait
                    conjointement référence au Site Web et à l’Application).
                    La Plateforme est exploitée par SAS VIGNITUS, 10 rue du château, 34540 Balaruc le vieux FRANCE. Les
                    termes « Nous » ou « VIGNITUS» font référence à l’exploitant. Pour de plus amples informations sur
                    la façon de nous contacter, veuillez consulter la section 8 de la présente Politique de
                    confidentialité.
                    Nous prenons le respect de votre vie privée très au sérieux. Toutes les données à caractère
                    personnel seront collectées, stockées et utilisées par nos soins, conformément au Règlement général
                    sur la protection des données n° 2016/679 de l’Union européenne (« RGPD »), et/ou à tout autre
                    règlement statutaire en vigueur.
                    Les services que nous offrons via le Site Web et/ou l’Application peuvent uniquement fonctionner si
                    nous collectons, stockons, transférons, supprimons et/ou utilisons autrement (« collecte et
                    utilisation ») des données spécifiques qui vous concernent (ci-après les « données à caractère
                    personnel » ou les « données »). Les données à caractère personnel désignent toutes les informations
                    relatives à une personne physique identifiée ou identifiable, telles que votre nom, votre date de
                    naissance, votre adresse postale ou votre adresse e-mail.
                    La présente Politique de confidentialité décrit les données que nous collectons à votre sujet, et
                    les fins auxquelles nous les collectons et les utilisons lorsque vous utilisez les services que nous
                    offrons sur la Plateforme, y compris le service VIGNITUS Pro pour les vendeurs professionnels («
                    Vendeurs Pro »). La présente Politique de confidentialité contient également des informations
                    importantes sur la protection de vos données, en particulier les droits statutaires qui y sont
                    rattachés.
                    Certains services de notre Plateforme sont offerts par des fournisseurs tiers. Lorsque vous utilisez
                    ces services, les règlements de protection des données des fournisseurs tiers s’appliqueront alors,
                    en plus de la présente déclaration de protection des données. Avant que vous n’utilisiez lesdits
                    services, les fournisseurs tiers sont susceptibles de vous inviter à leur fournir une autorisation,
                    dans le cadre de la loi sur la protection des données.
                    En vertu des lois sur la protection des données en vigueur, VIGNITUS est tenue de vous informer du
                    traitement des données et satisfait à cette obligation dans le cadre de la présente Politique de
                    confidentialité. La présente Politique de confidentialité et toute partie de celle-ci ne sont pas
                    considérées comme des clauses contractuelles et ne constituent pas les Conditions Générales (« CG »)
                    en tant que contrat conclu avec les utilisateurs enregistrés. Conformément aux lois sur la
                    protection des données en vigueur, VIGNITUS peut traiter les données nécessaires pour conclure un
                    contrat avec vous ou pour prendre des mesures, à votre demande, avant de conclure un contrat (art. 6
                    (1) (b) du RGPD). Les références aux CG doivent en tout temps être considérées comme des
                    informations sur le traitement des données (art. 13 et 14 du RGPD) et jamais comme clauses qui
                    constituent les CG. En utilisant la Plateforme et nos services, vous concluez un contrat
                    juridiquement contraignant avec VIGNITUS, dont les conditions sont décrites dans les CG.

                    <h2>2. Pourquoi et comment collectons-nous et utilisons-nous vos données à caractère personnel
                        ?</h2>

                    2.1. Afin de vous permettre d’utiliser la Plateforme, nous permettre de fournir nos services et
                    appliquer nos CG

                    Nous collectons et utilisons vos données à caractère personnel pour vous permettre d’utiliser notre
                    Plateforme, fournir nos services et conclure un contrat (CG) avec vous, et tout particulièrement
                    pour effectuer des transactions commerciales via la Plateforme, utiliser le système de paiement
                    électronique ou laisser des avis et communiquer avec d’autres membres. Afin d'utiliser ces services,
                    vous devez avoir un compte VIGNITUS. À cette fin, vous devez vous inscrire en tant que membre sur le
                    site Web ou l’Application.
                    Nous avons besoin de la plupart de vos données à caractère personnel pour conclure un contrat (CG)
                    avec vous. Dans le cas où vous ne nous fournissez pas ces données à caractère personnel, nous ne
                    serons pas en mesure de conclure et d’exécuter un contrat (CG) avec vous. Nous avons besoin d’une
                    partie de vos données afin de pouvoir satisfaire à nos obligations légales lorsque vous êtes membre
                    de notre Plateforme. Dans le cas où vous ne nous fournissez pas ces données à caractère personnel,
                    nous ne serons pas en mesure de nous conformer aux exigences légales et de fournir nos services.
                    Ces données sont également utilisées pour améliorer la Plateforme afin que nous puissions offrir une
                    meilleure expérience à nos membres (voir 2.2.12).
                    Nous collectons et utilisons vos données à caractère personnel à ces fins jusqu’à la désactivation
                    de votre compte VIGNITUS ou pendant 5 ans à partir du moment où votre compte devient inactif.
                    2.1.1. Afin de vous permettre de vous inscrire sur la Plateforme
                    2.1.2. Afin de vous authentifier via Google, Facebook ou Apple ID
                    2.1.3. Afin de vous permettre de configurer les informations de votre profil
                    2.1.4. Afin d'afficher des informations pertinentes concernant votre activité sur la Plateforme
                    auprès des autres membres
                    2.1.5. Afin de vous permettre de mettre vos articles en vente
                    2.1.6. Afin d’activer vos notifications sur la Plateforme
                    2.1.7. Afin de vous permettre de communiquer avec d’autres membres
                    2.1.8. Afin de vous permettre de laisser des avis à d’autres membres sur la Plateforme
                    2.1.9. Afin de recevoir les avis d’autres membres
                    2.1.10. Afin de vous permettre de publier dans le forum et de discuter avec notre communauté
                    2.1.11. Afin de pouvoir vous envoyer des renseignements importants concernant la Plateforme
                    2.1.12. Afin de déterminer si vous être un vendeur professionnel et vous en informer
                    2.1.13. Pour pouvoir vous envoyer des offres via le système de messagerie de la Plateforme
                    2.1.14. Afin de vous fournir un service d’assistance client
                    2.1.15. Afin de résoudre les différends liés à tout achat entre les membres
                    2.1.16. Afin de conserver temporairement votre compte désactivé

                    2.2. Afin d'améliorer votre expérience lors de l’utilisation de la Plateforme

                    Nous collectons et utilisons vos données à caractère personnel afin d’améliorer votre expérience
                    lors de l’utilisation de la Plateforme, en vous permettant de personnaliser votre fil d’actualité et
                    vos résultats de recherche, en vous fournissant des suggestions pertinentes et en conservant vos
                    recherches précédentes, en vous envoyant des notifications et, autrement, en rendant l’utilisation
                    de la Plateforme plus agréable.
                    La base juridique spécifique applicable pour la collecte et l’utilisation de vos données est décrite
                    dans chacune des sections ci-dessous.
                    2.2.1. Afin d'activer vos préférences en ce qui concerne votre fil d'actualité et vos résultats de
                    recherche
                    2.2.2. Afin de personnaliser votre fil d’actualité et vos résultats de recherche
                    2.2.3. Afin de donner la priorité aux articles avec un bon rapport qualité-prix de la part de
                    vendeurs fiables
                    2.2.4. Afin de vous proposer des articles pertinents
                    2.2.5. Afin d'améliorer les résultats de recherche sur la Plateforme
                    2.2.6. Afin d'enregistrer vos recherches récentes
                    2.2.7. Afin de vous aider à attirer plus d’attention sur vos annonces
                    2.2.8. Afin de suggérer un prix lorsqu’une annonce est créée pour un article
                    2.2.9. Afin d'activer les notifications relatives à vos articles favoris
                    2.2.10. Afin de notifier les vendeurs lorsque vous marquez leurs articles comme favoris
                    2.2.11. Afin de vous permettre de suivre d’autres membres
                    2.2.12. Afin d'améliorer notre Plateforme
                    2.2.13. Afin de mener à bien des enquêtes
                    2.2.14. Afin de mener à bien des entretiens
                    2.2.15. Afin de vous permettre de partager votre parcours utilisateur
                    2.2.16. Afin de mener à bien la traduction automatique du contenu

                    2.3. Afin de veiller à la sécurité de votre compte et de la Plateforme

                    VIGNITUS s’efforce de veiller à ce que les comptes de nos membres et de la Plateforme elle-même
                    soient sécurisés et protégés des cyberattaques, des accès non autorisés et d’autres risques
                    connexes.
                    2.3.1. Afin de suivre le nombre de visites sur la Plateforme à des fins de sécurité
                    2.3.2. Afin de vous aider à ne pas utiliser un mot de passe compromis
                    2.3.3. Afin de vous permettre de réinitialiser votre mot de passe
                    2.3.4. Afin de vérifier votre compte en cas d’actions suspectes liées à celui-ci
                    2.3.5. Afin d'effectuer une vérification du numéro de téléphone et une vérification en 2 étapes
                    2.3.6. Afin d'effectuer une vérification de sécurité de la source de paiement
                    2.3.7. Afin d'effectuer une vérification de sécurité du compte PayPal
                    2.3.8. Afin de nous assurer que des annonces sont conformes à notre Politique d’Authenticité
                    2.3.9. Afin de mener à bien une vérification relative à la propriété du compte
                    2.3.10. Afin de vous permettre de signaler un comportement ou un contenu inapproprié
                    2.3.11. Afin de signaler les publications à caractère suicidaire

                    2.4. Afin de superviser le respect et l’application des CG

                    VIGNITUS supervise activement le respect et l’application des CG dans le but d’assurer la sécurité
                    de votre compte et de la Plateforme.
                    2.4.1. Afin de calculer le score de confiance
                    2.4.2. Afin de faire respecter le filtrage des courriers indésirables (spam)
                    2.4.3. Afin de modérer votre activité sur la Plateforme
                    2.4.4. Afin d'émettre et faire respecter des avertissements
                    2.4.5. Afin de supprimer ou de masquer des articles qui violent les CG de VIGNITUS
                    2.4.6. Afin de détecter et de verrouiller les comptes compromis
                    2.4.7. Afin de suspendre des membres
                    2.4.8. Afin de faire respecter les interdictions
                    2.4.9. Afin de faire respecter les adresses IP bloquées

                    2.5. Afin de vous permettre de payer des articles

                    Les paiements sur la Plateforme sont effectués par l’intermédiaire de prestataires de services de
                    paiement qui fournissent des services de traitement des paiements et d’entiercement.
                    Nous avons besoin de la plupart de vos données à caractère personnel pour conclure un contrat (CG)
                    avec vous. Dans le cas où vous ne nous fournissez pas ces données à caractère personnel, nous ne
                    serons pas en mesure de conclure et d’exécuter un contrat (CG) avec vous (art. 6 (1) (b) du RGPD).
                    Une partie de vos données est nécessaire afin de satisfaire aux obligations légales de nos
                    prestataires de services de paiement lorsque vous êtes membre de notre Plateforme (art. 6 (1) (c) du
                    RGPD). Dans le cas où vous ne nous fournissez pas ces données à caractère personnel, nous ou nos
                    prestataires de services de paiement ne serons pas en mesure de nous conformer aux exigences légales
                    et nous ne serons pas en mesure de fournir nos services.
                    Ces données sont également utilisées pour améliorer la Plateforme afin que nous puissions offrir une
                    meilleure expérience à nos membres (voir 2.2.12).
                    2.5.1. Afin de vous permettre d’effectuer un achat ou d’ajouter une carte bancaire à des fins de
                    paiement
                    2.5.2. Afin de vous permettre d’ajouter un compte bancaire à des fins de retrait
                    2.5.3. Afin de vous permettre de payer et de recevoir des paiements sur notre Plateforme
                    2.5.4. Afin d’activer les vérifications « Know Your Customer » (KYC) sur notre Plateforme
                    2.5.5. Afin de mener à bien les vérifications de personnes politiquement exposées (PPE) sur notre
                    Plateforme
                    2.5.6. Afin d'effectuer des remboursements
                    2.5.7. Afin de transférer vos informations vers un Utilisateur Pro
                    2.5.8. Afin de tenir des registres financiers

                    2.6. Afin de permettre la livraison d’articles achetés sur la Plateforme

                    VIGNITUS s’efforce de rendre la livraison des articles achetés sur la Plateforme aussi facile et
                    pratique que possible, en proposant différentes méthodes de livraison sur la Plateforme.
                    Nous avons besoin de la plupart de vos données à caractère personnel pour conclure un contrat (CG)
                    avec vous. Dans le cas où vous ne nous fournissez pas ces données à caractère personnel, nous ne
                    serons pas en mesure de conclure et d’exécuter un contrat (CG) avec vous.
                    Ces données sont également utilisées pour améliorer la Plateforme afin que nous puissions offrir une
                    meilleure expérience à nos membres (voir 2.2.12).
                    2.6.1. Afin de vous permettre d’expédier ou de recevoir des articles
                    2.6.2. Afin de suivre votre colis

                    2.7. Afin de mener à bien des activités marketing

                    VIGNITUS cherche à impliquer ses membres dans des campagnes marketing avantageuses pour ces
                    derniers. De même, nous souhaitons vous présenter du matériel marketing à la fois pertinent et
                    attrayant.
                    2.7.1. Afin de pouvoir vous envoyer des e-mails marketing
                    2.7.2. Afin de personnaliser les messages marketing
                    2.7.3. Afin de vous contacter pour des opportunités publicitaires et/ou en matière de revenus
                    2.7.4. Afin de mener des campagnes publicitaires vous impliquant
                    2.7.5. Afin de nous permettre de publier votre contenu sur nos comptes de réseaux sociaux
                    2.7.6. Afin de vous permettre de voir des publicités personnalisées
                    2.7.7. Afin d'évaluer l’efficacité des campagnes promotionnelles
                    2.7.8. Afin de gérer nos profils de réseaux sociaux
                    2.7.9. Afin de vous permettre de participer au programme de parrainage de VIGNITUS

                    2.8. À des fins légales

                    2.8.1. Afin de traiter vos demandes liées aux données à caractère personnel
                    2.8.2. Afin de fournir des informations à la police et à d’autres institutions de l’État
                    2.8.3. Afin de défendre nos droits par rapport aux rétro facturations
                    2.8.4. Afin de se conformer à la loi française pour une République numérique
                    2.8.5. Afin de défendre les droits et les intérêts de VIGNITUS

                    <h2>3. Bénéficiaires des données à caractère personnel</h2>

                    VIGNITUS transfère ou partage des données à caractère personnel avec des prestataires de services
                    uniquement dans la mesure où cela est nécessaire et autorisé conformément à la législation en
                    vigueur. Les prestataires de services vers lesquels vos données à caractère personnel sont
                    transférées ou avec lesquels elles sont partagées à des fins spécifiques sont décrits à la section 2
                    ci-dessus. De plus, nous désignons les prestataires de services suivants qui, par conséquent,
                    reçoivent des données à caractère personnel en tant que destinataires de données.
                    Nous menons et améliorons constamment la maintenance technique de la Plateforme afin de protéger la
                    sécurité et la confidentialité des données à caractère personnel que nous traitons et d’effectuer
                    certaines fonctions liées à l’entreprise qui contribuent à rendre nos services disponibles et
                    fonctionnels. Pour cette raison, nous transférons vos données de profil aux prestataires de services
                    qui fournissent les services de cloud et d’hébergement, la sécurité informatique, la maintenance et
                    les services techniques, et les services de communication :
                    Les prestataires de services suivants étant établis en dehors de l’Espace économique européen, vos
                    données sont par conséquent susceptibles d’être transférées en dehors de l’Espace économique
                    européen. Dans ces cas précis, les données à caractère personnel sont protégées par les prestataires
                    de services qui s’engagent à respecter les clauses contractuelles de l’UE pour transférer les
                    données, telles qu’approuvées par la Commission européenne :
                    Amazon Web Services, Inc. (États-Unis) ;
                    Google LLC (États-Unis) ;
                    Apple Inc. (États-Unis) ;
                    Microsoft Corporation (États-Unis) ;
                    Slack Technologies, Inc. (États-Unis).
                    BlackLine Systems, Inc. (États-Unis) ;
                    Chronicle, LLC (États-Unis) ;
                    True Partners Consulting, LLC (États-Unis) ;
                    Arcaris, Inc. (États-Unis) ;
                    Havas Edge, Inc. (États-Unis).
                    Nous transférons des données à caractère personnel aux avocats, assistants d’avocats, notaires,
                    huissiers de justice, commissaires aux comptes, comptables, agents de recouvrement, consultants,
                    agences de traduction, prestataires de services informatiques, compagnies d’assurance et services
                    d’archivage qui fournissent des services à VIGNITUS.
                    Par ailleurs, nous partageons des données au sein du groupe VIGNITUS. Les données traitées au sein
                    du groupe VIGNITUS sont transférées à VIGNITUS, SAS VIGNITUS France dans la mesure nécessaire à la
                    gestion du groupe VIGNITUS.
                    VIGNITUS est légalement tenue de fournir des données à caractère personnel et/ou d’utilisation aux
                    autorités en charge d'une enquête, de poursuites pénales ou de surveillance, si et dans la mesure où
                    cela est nécessaire pour éviter les risques envers le public et pour la poursuite d’actes criminels.
                    VIGNITUS peut partager vos données avec des tiers lors du transfert des obligations et des droits
                    relatifs à la relation contractuelle entre vous et VIGNITUS à ces tiers, conformément aux CG en
                    particulier dans le cas du transfert d’un secteur d’activité, d'une fusion par constitution d’une
                    nouvelle société, d'une fusion par absorption, d'une scission ou de tout changement de contrôle qui
                    concerne VIGNITUS. Avant un tel événement, VIGNITUS vous informera séparément sur les informations
                    relatives au partage de vos données et obtiendra votre consentement lorsque cela est légalement
                    nécessaire.

                    <h2>4. Ce que vous devez savoir lorsque vous interagissez avec un Utilisateur Pro</h2>

                    Contrôle conjoint de VIGNITUS et de l’Utilisateur Pro – Base de l’accord
                    Dans le cadre des sections 2.1.6, 2.1.7, 2.1.8, 2.1.9, 2.1.14, 2.5.3, 2.5.7, 2.6.1 et 2.8.1, vos
                    données à caractère personnel à cette fin seront traitées conjointement par VIGNITUS et
                    l’Utilisateur Pro et vous êtes informé(e) du traitement des données par VIGNITUS et les opérateurs
                    de comptes commerciaux (« Utilisateurs Pro VIGNITUS »), appelés les « responsables conjoints du
                    traitement ». Cela signifie que VIGNITUS et les utilisateurs VIGNITUS Pro sont tous les deux
                    responsables du respect de certaines obligations légales lorsqu’ils traitent des données à caractère
                    personnel en tant que responsables conjoints du traitement. Il n’y a pas de contrôle conjoint pour
                    tous les traitements de données, mais uniquement pour certains fins et certaines étapes de
                    traitement, qui sont expliquées plus en détail dans chaque section de la présente Politique de
                    confidentialité (voir 2.1.6, 2.1.7, 2.1.8, 2.1.9, 2.1.14, 2.5.3, 2.5.7, 2.6.1 et 2.8.1).
                    Dans le cadre de la présente section, nous souhaitons vous informer de la base de l’accord sur les
                    responsabilités conjointes auxquelles nous nous sommes engagés avec les utilisateurs VIGNITUS Pro,
                    conformément à la phrase 2 de l’Art. 26 (2) du RGPD. Dans la mesure où il existe deux responsables
                    conjoints du traitement, nous souhaitons vous expliquer qui est responsable de quoi.
                    En général, VIGNITUS et les utilisateurs VIGNITUS Pro sont tous les deux responsables du respect des
                    obligations légales du RGPD. Bien que VIGNITUS et les utilisateurs VIGNITUS Pro conviennent de
                    certaines responsabilités qui s’appliquent lorsque nous agissons en tant que responsables conjoints
                    du traitement, VIGNITUS est responsable de :
                    vous servir de point de contact (phrase 3 de l’Art. 26 (1) du RGPD ;
                    gérer la communication centralisée avec vous en ce qui concerne les droits relatifs à vos données
                    (Art. 15, 16, 17, 18 et 20 du RGPD) ;
                    vous fournir des informations sur le traitement des données (Art. 13 et 14 du RGPD) ;
                    vous informer de la base de notre accord (phrase 2 de l’Art. 26 (2) du RGPD), ce que nous faisons
                    dans la présente section ;
                    vous informer des violations de données associées aux risques élevés relatifs à vos droits et
                    libertés (Art. 34 (1) du RGPD), dans le cas où une telle violation de données se produit.
                    Toutes les autres responsabilités énoncées par le RGPD sont respectées par VIGNITUS et par les
                    utilisateurs VIGNITUS Pro. Ces autres responsabilités ne vous concernent généralement pas
                    directement en tant que sujet des données. Il s’agit plutôt des obligations générales des
                    responsables conjoints du traitement, telles que le maintien de mesures de sécurité et la
                    conservation d’un dossier des activités de traitement. En ce qui concerne le traitement de données
                    en dehors du champ d’application du contrôle conjoint, VIGNITUS et les utilisateurs VIGNITUS Pro
                    sont indépendamment et séparément responsables et les informations fournies dans la présente section
                    ne sont pas applicables.

                    <h2>5. Utilisation de cookies</h2>

                    VIGNITUS utilise des cookies et des technologies similaires sur la Plateforme. Pour en savoir plus,
                    consultez notre POLITIQUE D’UTILISATION DES COOKIES

                    <h2>6. Droit d’amendement</h2>

                    Dans la mesure où nous développons constamment nos services, nous nous réservons le droit de
                    modifier la présente Politique de confidentialité à tout moment, sous réserve des règlements
                    applicables. Toute modification sera publiée sur cette page dans les meilleurs délais.
                    Indépendamment de ce qui précède, vous devrez consulter cette page régulièrement pour toute mise à
                    jour.

                    <h2>7. Vos droits statutaires concernant vos données à caractère personnel</h2>

                    7.1. Quels sont vos droits ?

                    Sous réserve des conditions, des restrictions et des exceptions établies par les dispositions
                    légales en matière de protection des données, vous avez le droit à tout moment :
                    d’être informé(e) des données que nous collectons et utilisons et de demander l’accès aux données
                    concernées ou d’en exiger une copie (droit d’être informé(e)). Vous pouvez également accéder à
                    toutes les données que vous nous avez directement fournies sur la Plateforme (consulter les sections
                    1, 2.5 et 2.6), à tout moment, sur votre compte VIGNITUS ;
                    d’exiger la correction de données inexactes et, sous réserve de la nature de la collecte et de
                    l’utilisation, le renseignement de données incomplètes (droit de rectification). Vous pouvez
                    également vous-même modifier l’ensemble des données que vous nous avez directement fournies sur la
                    Plateforme (2.1, 2.5 et 2.6), à tout moment, sur votre compte VIH (à l’exception des messages
                    envoyés et des publications ou avis sur un forum) ;
                    sous réserve d’une cause juste, d’exiger la suppression de vos données (droit de suppression);
                    d’exiger la restriction de la collecte et de l’utilisation de vos données, à condition que les
                    critères statutaires soient respectés (droit de restreindre le traitement);
                    sous réserve des critères statutaires respectés, de recevoir les données que vous avez fournies sous
                    une forme structurée, actuelle et lisible par une machine, et de transférer lesdites données à un
                    autre processeur ou, lorsque cela est faisable d'un point de vue technique, de les faire transférer
                    par VIGNITUS (droit à la portabilité des données);
                    de s’opposer à la collecte et à l’utilisation des données, uniquement lorsque lesdites collecte et
                    utilisation sont fondées sur une tâche effectuée dans l’intérêt public ou dans l’exercice de
                    l’autorité officielle conférée (art. 6 (1) (e) du RGPD) ou d’un intérêt légitime (art. 6 (1)f) du
                    RGPD), notamment le profilage, selon les mêmes motifs de collecte et d’utilisation de données que
                    ceux expliqués dans d’autres sections de la présente déclaration (droit de contester). Vous avez
                    également le droit de vous opposer, à tout moment, à la collecte de vos données à caractère
                    personnel à des fins de marketing direct ;
                    de retirer à tout moment toute autorisation que vous nous avez fournie. Ledit retrait n’affectera
                    pas la légalité de la collecte et de l’utilisation effectuées avant le retrait et sur la base de
                    l’autorisation accordée. Vous pouvez retirer votre autorisation concernant l’envoi de notre
                    newsletter en réglant les paramètres de votre compte VIGNITUS afin de bloquer l’envoi de tout autre
                    e-mail marketing. Alternativement, vous pouvez cliquer sur « Se désinscrire » à la fin de l’e-mail
                    marketing ;
                    de ne pas être soumis(e) à un traitement discriminatoire pendant l’exercice de vos droits.
                    Pour exercer un quelconque droit spécifié dans la présente section, vous pouvez contacter VIGNITUS
                    et soumettre votre demande en utilisant les coordonnées (consulter la section 8 ci-dessous).
                    Indépendamment de tout autre recours juridique, vous avez également le droit à tout moment de
                    soumettre une plainte auprès des autorités de surveillance.

                    7.2. Comment vérifierons-nous votre demande ?

                    Votre demande doit fournir suffisamment d’informations qui nous permettent de vérifier
                    raisonnablement que vous êtes la personne ou le représentant autorisé de la personne dont nous avons
                    collecté les données à caractère personnel (prénom, nom de famille, URL de votre profil public,
                    adresse e-mail, autres informations que nous pouvons demander à des fins de vérification), décrire
                    votre demande avec suffisamment d'informations qui nous permettent de bien la comprendre, l’évaluer
                    et d'y répondre, et confirmer, sous peine de sanctions, que vous êtes la personne dont les données à
                    caractère personnel font l’objet de la demande.
                    Si votre demande est soumise par un représentant autorisé, une autorisation écrite et des
                    informations qui vérifient l’identité du représentant doivent être incluses dans la demande.
                    Nous ne pouvons pas vous fournir les informations ou faire respecter tout autre droit que vous avez
                    si nous ne pouvons pas vérifier votre identité ou votre droit de demande et de confirmation des
                    informations qui vous concernent.
                    Afin de vérifier votre identité, nous pouvons vous demander de fournir des informations
                    supplémentaires vous concernant. Nous utiliserons uniquement ces informations et celles fournies
                    dans la demande pour vérifier votre identité ou votre droit de demande.

                    <h2>8. Nos coordonnées</h2>

                    Si vous avez des questions concernant la collecte et l’utilisation de vos données dans le cadre de
                    votre utilisation de la Plateforme, ou concernant vos droits, veuillez contacter notre responsable
                    de la protection des données aux adresses suivantes : legal@vignitus.fr
                    Responsable de la protection des données VIGNITUS :
                    SAS VIGNITUS
                    10 rue du château
                    34540 Balaruc le vieux
                    FRANCE
                    -

                </div>
                </div>
            </div>
        </div>)
}
export default Confidentialite;
