export function formatPrice(number) {
    if (number - Math.floor(number) !== 0) {
        return Number(Math.round(number + "e" + 2) + "e-" + 2).toFixed(2);
    }
    return number.toFixed();
}
export const storage = {
    getToken: () => window.localStorage.getItem("token"),
    setToken: (token) =>
        window.localStorage.setItem("token", token),
    clearToken: () => window.localStorage.removeItem("token")
};
