import React from "react";
import { Navbar, Footer } from "./../../Components";
import Detail from "./../../Containers/CheckOut/Detail";
import Banner from "../../Containers/Protection/Banner";
import {default as ProtectionContainer}  from "../../Containers/Protection/Protection";

function Protection() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
          <Banner/>
          <ProtectionContainer/>
      </div>
      <Footer />
    </div>
  );
}

export default Protection;
