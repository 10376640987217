import Manage from "../../ManageSales/Manage";
import React,{useState,useEffect} from "react";
import {useAuth} from "../../../Services/auth";
import {useQuery} from "react-query";
import api from "../../../Services/api";
import ItemHistoriqueWallet from "../../../Components/ItemHistoriqueWallet"
import ValidationAccountModal from "../../../Components/Modal/ValidationAccountModal"
import TransferWalletModal from "../../../Components/Modal/TransferWalletModal"
import "./style.css";
import moment from "moment";
import "moment/locale/fr";
const valueArray = [
    { value: 5, label: "5%" },
    { value: 10, label: "10%" },
    { value: 15, label: "15%" },
    { value: 20, label: "20%" },
    { value: 25, label: "25%" },
    { value: 30, label: "30%" },
    { value: 35, label: "35%" },
    { value: 40, label: "40%" },
    { value: 45, label: "45%" },
    { value: 50, label: "50%" },
];

function Wallet() {
    const [walletActivation, setWalletActivation] = useState(false);
    const [walletPendigOrRefused, setWalletPendigOrRefused] = useState(false);
    const [arrayTransact, setArrayTransact] = useState(null);
    const [modalParametre, setModalParametre] = useState(false);
    const [modalTranferWallet, setModalTranferWallet]=useState(false);
    const [modalToActivate, setModalToActivate] = useState(false);
    const [msgInfo, setMsgInfo]= useState(null)

    const { isLoading,
        isError,
        error,
        data:wallets,
        isFetching,
        isPreviousData, } = useQuery(['repoWallet'], ()=> api.getWallets(),{ keepPreviousData : true }
    )
    const { isLoading:isLoadingKycs,
        isError:isErrorKycs,
        error:errorKycs,
        data:arrayKycs,
        isFetching:isFetchingKycs,
        isPreviousData:isPreviousDataKycs } = useQuery(['repoKycs'], ()=> api.getKycs(),{ keepPreviousData : true }
    )
    const { isLoading:isLoadingTransactions,
        isError:isErrorTransactions,
        error:errorTransactions,
        data:arrayTransactions,
        isFetching:isFetchingTransactions,
        isPreviousData:isPreviousDataTransactions } = useQuery(['repoTransactions'], ()=> api.getWalletTransaction(),{ keepPreviousData : true }
    )
    useEffect(() => {
        // console.log(arrayKycs);
        const hasValidated = arrayKycs?.data?.some(function(kyc) {
            return kyc.Status === 'VALIDATED';
        })
        console.log(hasValidated)
        // console.log(arrayKycs);
        if (arrayKycs?.data?.length && hasValidated) {
            setWalletActivation(true);
        }else if(arrayKycs?.data?.length){
            setWalletPendigOrRefused(true)
        }
    }, [arrayKycs]);

    useEffect(()=>{
        console.log(arrayTransact)
    },[arrayTransact])
    useEffect(() => {
        if (arrayTransactions) {
            sortTransaction();
        }
    }, [arrayTransactions]);

    function sortTransaction() {
        let ArrayToSort = [...arrayTransactions?.data].reverse();
        let monthPresent = [];
        let finalArray = [];

        for (const item of ArrayToSort) {
            if (item.Status == "SUCCEEDED") {
                let time = new Date(item.ExecutionDate * 1000);
                if (!monthPresent.some(entry => entry.m === time.getMonth() + 1 && entry.y === time.getFullYear())) {
                    monthPresent.push({m:time.getMonth() + 1 , y:time.getFullYear()});
                }
            }
        }
        for (const month of monthPresent) {
            let objectOfThisMonth = { title: monthText(month.m)+" "+month.y, data: [] };
            for (const item of ArrayToSort) {
                if (item.Status == "SUCCEEDED") {
                    let time = new Date(item.ExecutionDate * 1000);

                    if (time.getMonth() + 1 == month.m && time.getFullYear() == month.y) {
                        objectOfThisMonth.data.push({
                            ...item,
                            Date: moment(time).format("LL"),
                        });
                    }
                }
            }
            finalArray.push(objectOfThisMonth);
        }
        setArrayTransact(finalArray);
    }
    function monthText(monthNumber) {
        if (monthNumber == 1) return "Janvier";
        if (monthNumber == 2) return "Février";
        if (monthNumber == 3) return "Mars";
        if (monthNumber == 4) return "Avril";
        if (monthNumber == 5) return "Mai";
        if (monthNumber == 6) return "Juin";
        if (monthNumber == 7) return "Juillet";
        if (monthNumber == 8) return "Août";
        if (monthNumber == 9) return "Septembre";
        if (monthNumber == 10) return "Octobre";
        if (monthNumber == 11) return "Novembre";
        if (monthNumber == 12) return "Décebmre";
    }
    return (
        <div className="container">
            <div className="whiteContainer">
                {msgInfo?(
                    <div className="alert alert-success" role="alert">
                        {msgInfo}
                    </div>

                ):null}
                <div style={{borderBottom:"solid 1px", borderBottomColor:"lightgray",paddingBottom:10}}>
                    <div
                        style={{
                            display:"flex",
                            alignItems: "center",
                            flexDirection: "row",
                            marginVertical: 10,
                            justifyContent: "space-between",
                            //width: "100%",
                        }}
                    >
                        <div
                            className="section-title"
                        >
                            Montant en attente
                        </div>
                        <div
                            style={{
                                fontSize:"16px",
                                fontWeight:800
                            }}
                        >
                            {wallets?.data
                                ? (wallets?.data?.waitingWallet?.Balance?.Amount / 100).toFixed(2)
                                : 0.0}{" "}
                            €
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        style={{
                            display:"flex",
                            alignItems: "center",
                            flexDirection: "row",
                            marginVertical: 10,
                            justifyContent: "space-between",
                            borderBottom:"solid 1px", borderBottomColor:"lightgray",paddingBottom:10, marginBottom:30
                        }}
                    >
                        <div
                            className="section-title"
                            style={{
                                marginTop: 8,
                            }}
                        >
                            Montant disponible
                        </div>
                        <div
                            style={{
                                fontSize:"16px",
                                fontWeight:800
                            }}
                        >
                            {wallets?.data ? (wallets?.data?.wallet?.Balance?.Amount / 100).toFixed(2) : 0.0}
                            {" €"}
                        </div>

                    </div>
                    <div
                        onClick={()=>{
                            if (walletActivation) {
                                setModalTranferWallet(true)
                                //setModalToActivate(true);
                                console.log("modal Transfer")
                            } else {
                                setModalToActivate(true);
                                console.log("modal Activate")
                            }

                        }}
                        className="customBtn"
                        style={{
                            marginTop: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin:"0 auto"
                        }}
                    >
                        {walletActivation
                            ? "Transférer vers un compte bancaire"
                            : "Activer le porte-monnaie"}
                    </div>
                    <div style={{
                        marginTop: 10,
                        textAlign: "center",
                    }}>
                        {walletActivation?("Ton porte-monnaie est activé"):walletPendigOrRefused?("Ton porte-monnaie est en cours de validation ou à été refusé  par Mangopay vérifie tes notifications"):("Active ton porte-monnaie pour suivre tes gains et dépenses sur Vignitus")}
                    </div>

                </div>
                <div>
                    {arrayTransact?.map((v, i) => {
                        return(
                            <div style={{borderBottom:"solid 1px", borderBottomColor:"lightgray",paddingBottom:10}} key={v.title}>
                                <div className="section-title" style={{
                                    paddingHorizontal: 20,
                                    paddingTop: 14,
                                    paddingBottom: 4,
                                    fontStyle: "italic",
                                }}>{v.title}</div>
                                <div>
                                    {v?.data?.map((d,z)=>{
                                        return(
                                            <ItemHistoriqueWallet key={"itemHistoriqueWallet"+z} data={d}/>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
            {modalToActivate?(
                <ValidationAccountModal open={modalToActivate} setModalToActivate={setModalToActivate} setMsgInfo={setMsgInfo} />
            ):null}
            <TransferWalletModal open={modalTranferWallet} setModalTranferWallet={setModalTranferWallet} setMsgInfo={setMsgInfo} />
        </div>)
}
export default Wallet;
