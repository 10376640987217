import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import React, {useEffect, useState} from "react";

import "./style.css";
import {Alert} from "react-bootstrap";

const ModifyProd = ({files,setFiles,customError}) => {
  const [imageSrc, setImageSrc] = useState(undefined);

  useEffect(()=>{
    console.log(files)
  },[files])
  const updateFiles = (incommingFiles) => {
    console.log("incomming files", incommingFiles);
    setFiles(incommingFiles);
  };
  const onDelete = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };
  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };
  const handleClean = (files) => {
    console.log("list cleaned", files);
  };
  return (
    <div className="modifySectionParent container mb-5">
      <section className="modifySection">
        <h1 className="modifyTitle">Mise en vente de ta bouteille</h1>
        <Alert show={customError!=null} variant="danger">
          <p>
            {customError}
          </p>
        </Alert>
        <p className="modifyDescrition">Ajoute jusqu’à 20 photos gratuitement</p>
        <Dropzone
          style={{ minWidth: "550px" }}
          onChange={updateFiles}
          minHeight="195px"
          onClean={handleClean}
          value={files}
          maxFiles={20}
          maxFileSize={2998000}
          localization={"FR-fr"}
          accept=".png,image/*"
          disableScroll
        >
          {files.map((file,i) => (
            <FileItem
              {...file}
              key={"file-"+i}
              onDelete={onDelete}
              onSee={(imageSource)=>{
                if(file.imageUrl){
                  handleSee(file.imageUrl)

                }else{
                  handleSee(imageSource)

                }
              }}
              //localization={"ES-es"}
              resultOnTooltip
              preview
              info
              hd
            />
          ))}
          <FullScreenPreview
            imgSource={imageSrc}
            openImage={imageSrc}
            onClose={(e) => handleSee(undefined)}
          />
        </Dropzone>
      </section>
    </div>
  );
};

export default ModifyProd;
