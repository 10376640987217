import LoginComponent from "../Login";

const ItemHistoriqueWallet = ({data}) => {
    function returnTagSplit() {
        let tagSplit = data?.Tag?.split("|");
        if(tagSplit){
            return tagSplit[0]
        }
        return "Retrait";
    }

    return (
        <div style={{}}>
            <div
                style={{
                    paddingHorizontal: 20,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingVertical: 12,
                    display:"flex"
                }}
                onClick={()=>{
                    //GO to CHAT returnTagSplit()[2]

                }}
            >
                <div style={{paddingLeft:10}}>
                    <div
                        style={{
                            fontWeight:800
                            //fontFamily: Typography.H3.font,
                            //fontSize: Typography.H3.size,
                            //color: Colors.NOIR1,
                        }}
                    >
                        {data?.Type == "PAYIN" ? "VENTE" : null}
                    </div>
                    <div
                        style={{
                            //fontFamily: Typography.DESCRIPTION_MEDIUM.font,
                            //fontSize: Typography.DESCRIPTION_MEDIUM.size,
                            //color: Colors.GRIS5,
                        }}
                    >
                        {returnTagSplit()} - {data?.Date}
                    </div>
                </div>
                <div
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                           // fontFamily: Typography.DESCRIPTION_BIG.font,
                           // fontSize: Typography.DESCRIPTION_BIG.size,
                           // color: Colors.NOIR1,
                            marginRight: 12,
                        }}
                    >
                        {data?.DebitedFunds?.Amount / 100 + " €"}
                    </div>
                </div>
            </div>
        </div>
    );

}


export default ItemHistoriqueWallet;