import React, { useEffect, useState,useRef } from "react";
import { ProductCard } from "./../../../Components";
import "./formember.css";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../Services/auth";
import {UrlBaseServer} from "../../../Services/storageServer";
import {formatPrice} from "../../../Services/tools";
import cross from "./../../../Assets/cross.png";
import LotChooseModal from "../../../Components/Modal/lotChooseModal";
import api from "../../../Services/api"


function ForMembers({userD,products, reloadProducts}) {
    const navigate = useNavigate();
    const {user} = useAuth()
    const [lot, setLot]=useState(false);
    const [showLotModal, setShowLotModal]=useState(false);
    const [productSelected, setProductSelected] = useState([]);
    const [discountAvalaible, setDiscountAvalaible] = useState([]);


    useEffect(() => {
        if (userD) {
            if (userD.lotReduction) {
                setDiscountAvalaible([
                    { nbItem: 2, discount: userD.lotReduction2 },
                    { nbItem: 3, discount: userD.lotReduction3 },
                    { nbItem: 5, discount: userD.lotReduction5 },
                ]);
            }
        }
    }, [userD]);
    function selectedProduct(id) {
        let present = productSelected.every((element) => {
            if (element.id == id) {
                return false;
            }
            return true;
        });
        return !present;
    }

    function returnOnePrice() {
        let classicPrice = 0;
        for (const item of productSelected) {
            classicPrice = classicPrice + item.price;
        }
        let nbSelected = productSelected.length;
        let discountToApply = 0;

        for (const item2 of discountAvalaible) {
            if (nbSelected >= item2.nbItem) {
                discountToApply = item2.discount;
            }
        }
        if (discountToApply > 0) {
            let discountPrice = classicPrice - classicPrice * (discountToApply / 100);
            return formatPrice(discountPrice) + " €";
        } else {
            return formatPrice(classicPrice) + " €";
        }
    }

    function returnPrice() {
        let classicPrice = 0;
        for (const item of productSelected) {
            classicPrice = classicPrice + item.price;
        }
        let nbSelected = productSelected.length;
        let discountToApply = 0;

        for (const item2 of discountAvalaible) {
            if (nbSelected >= item2.nbItem) {
                discountToApply = item2.discount;
            }
        }

        if (discountToApply > 0) {
            let discountPrice = classicPrice - classicPrice * (discountToApply / 100);
            return (
                <div
                    style={{
                        alignItems: "center",
                        display:"flex"
                    }}
                >
                    <div
                        style={{
                            color: "#000",
                            fontWeight: "700",
                            marginLeft: 20,
                            marginRight: 8,
                        }}
                    >
                        {formatPrice(discountPrice) + " €"}
                    </div>
                    <div
                        style={{
                            color: "#dedede",
                            // fontWeight: "300",
                            textDecorationLine: "line-through",
                            marginLeft: 20,
                            marginRight: 8,
                        }}
                    >
                        {formatPrice(classicPrice) + " €"}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        color: "#000",
                        fontWeight: "700",
                        marginLeft: 20,
                        marginRight: 8,
                    }}
                >
                    {formatPrice(classicPrice) + " €"}
                </div>
            );
        }
    }
    function myOffer(valueOffre) {
        let ids = [];
        for (const item of productSelected) {
            ids.push(item.id);
        }
        if (!valueOffre?.trim()) {
            return alert("Vous devez indiquer une offre");
        }
        if (valueOffre.includes(",") && valueOffre.split(",")[1].length > 2) {
            return alert("Votre offre n'a pas le format conforme");
        }

        api
            .postOffer(valueOffre.replace(",", "."), ids)
            .then((data) => {
                navigate("/messaging")
            })
            .catch((error) => {
                alert(
                    "Une erreur est survenue, merci de véfier le format de votre offre et réessayer"
                );
            });
    }

    function doOffer() {
        let classicPrice = 0;
        let ids = [];
        for (const item of productSelected) {
            classicPrice = classicPrice + item.price;
            ids.push(item.id);
        }
        let nbSelected = productSelected.length;
        let discountToApply = 0;

        for (const item2 of discountAvalaible) {
            if (nbSelected >= item2.nbItem) {
                discountToApply = item2.discount;
            }
        }
        let discountPrice = classicPrice - classicPrice * (discountToApply / 100);

        api
            .postOffer(discountPrice, ids)
            .then((data) => {
                navigate("/messaging")
                //setModalAchatEnLot(false);
                //navigation &&
                //navigation.navigate("Chat", {
                //    userChat: userD,
                //    //product: productSelected[0],
                //});
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    }

    return (
    <div className="container profile-prducts">
      <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
        <h1 className="sectionTitle">La Cave de {userD?.firstName} {userD?.lastName}</h1>
          {userD?.id == user?.id &&
          <button onClick={()=>{
              navigate("/edit-bottle")
          }} className="btn btn-danger outline-purple-btn"><i className="fa-solid fa-plus" style={{marginRight:5}}/>Ajouter une bouteille</button>}
      </div>
        {
            user?.id != userD?.id?(
                <>
                    {lot == false ?(
                    <div onClick={()=>{
                        setLot(true)
                    }} style={{marginTop:5, cursor:"pointer"}} className="profile-card d-flex align-items-center text-center flex-column">

                        <div>
                            <h5>Achète en lot</h5>
                            <span style={{color:"#6b0019"}}>Jusqu'à 20% de réduction</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{marginTop:10,width:125, height:40, backgroundColor:"#6b0019", color:"white", borderRadius:5}}>
                            Acheter
                        </div>
                    </div>):(
                        <div onClick={()=>{
                        }} style={{marginTop:5, cursor:"pointer"}} className="profile-card d-flex align-items-center text-center flex-column">
                            <div style={{position:"relative", width:"100%"}}>
                                <img onClick={()=>{
                                    setLot(false)
                                }} style={{position:"absolute", right:30}} width={30} height={30} src={cross}/>
                                <h5>Crée ton lot !</h5>
                                <div style={{color:"#6b0019"}}>Économise sur les frais de port, achète des lots.</div>
                                <div>Créer un lot à partir des articles de{" "}
                                    {userD?.firstName + " " + userD?.lastName}</div>
                            </div>
                            <div
                                style={{
                                    alignItems: "center",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    display:"flex"
                                }}
                            >
                                {returnPrice()}
                                {productSelected?.slice(0, 3).map((element, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                marginHorizontal: 6,
                                                marginLeft:6,
                                                marginRight:6,
                                                marginBottom: 6,
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                    resizeMode: "cover",
                                                    borderRadius: 6,
                                                }}
                                                src={UrlBaseServer + element.Product_images[0].image}

                                            />
                                        </div>
                                    );
                                })}

                                {productSelected.length > 3 && (
                                    <div
                                        style={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: 6,
                                            marginHorizontal: 6,
                                            backgroundColor: "#6b0019",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            {"+" + (productSelected.length - 3).toString()}
                                        </div>
                                    </div>
                                )}

                            </div>
                            {productSelected.length?(
                            <div onClick={()=>{
                                setShowLotModal(true);
                            }} className="d-flex align-items-center justify-content-center" style={{marginTop:10,width:125, height:40, backgroundColor:"#6b0019", color:"white", borderRadius:5}}>
                                Créer un lot
                            </div>):null}
                            </div>

                    )}
                </>):null}
      <section>
        <div className="cardParent mt-4 mb-4">
          <div className="row">
            {products?.map((v, i) => {
              return (
                <div key={"userProduct"+i} className="col-sm-8 col-md-5 col-lg-3 prodCard">
                  <ProductCard item={v} reloadProducts={reloadProducts} lot={lot} selected={selectedProduct(v.id)} onPressSelection={() => {
                      if (selectedProduct(v.id)) {
                          //si l'id est déjà présent alors on le retire
                          setProductSelected(
                              productSelected.filter((e) => e.id !== v.id)
                          );
                      } else {
                          //sinon on le rajoute
                          setProductSelected([...productSelected, v]);
                      }
                  }} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
        <LotChooseModal  setShowLotModal={setShowLotModal} showLotModal={showLotModal} price={returnOnePrice()} doOffer={doOffer} myOffer={myOffer} />
    </div>
  );
}
export default ForMembers;