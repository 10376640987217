import React, {useEffect} from "react";
import { ProductCard } from "./../../../Components";
import "./formember.css";
import {
    useQuery,
    useMutation,
    useQueryClient,
    useInfiniteQuery,
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import api from '../../../Services/api'
function ForWish() {
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);


    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        isFetchingPreviousPage,
        fetchNextPage,
        fetchPreviousPage,
        hasNextPage,
        hasPreviousPage,
    } = useInfiniteQuery(
        'wishList',
        async ({ pageParam = 0 }) => {
            const res =  await api.getWish(pageParam,rowsPerPage)
            return res.data
        },
        {
            getPreviousPageParam: firstPage =>firstPage.previousId ?? undefined,
            getNextPageParam: lastPage => {
                let nextp=undefined
                if(lastPage.currentPage+1 < lastPage.totalPages){
                    nextp= lastPage.currentPage+1
                }
                return nextp
            }
        }
    )
    return (
        <div className="container">
            <div>
                <h1 className="sectionTitle">Vos favoris</h1>
            </div>
            <section>
                {data?.pages.length?(
                <div className="cardParent mt-4 mb-4">
                    <div className="row">
                        {data?.pages.map(page => (
                            <React.Fragment key={page.currentPage}>
                                {page?.data?.map((v, i) => {
                                    return (
                                        <div key={v.id} className="col-sm-8 col-md-5 col-lg-3 prodCard">
                                            <ProductCard item={v} />
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </div>
                    <div
                        style={{
                            margin:"0 auto",
                            border:"1px solid",
                            cursor:"pointer",
                            marginTop: 4,
                            width:250,
                            //borderWidth: 1,
                            alignSelf: "center",
                            borderRadius: 8,
                            paddingHorizontal: 24,
                            paddingVertical: 8,
                            borderColor: "#7E7D7D",
                            marginBottom: 40,
                            display:"flex",
                            justifyContent:"center",
                        }}
                        onClick={()=> {
                            fetchNextPage()
                        }}
                    >
                        <div
                            style={{
                                fontSize: 16,
                                color: "#7E7D7D",
                                fontWeight: "bold",
                                display:"flex",
                                justifyContent:"center"
                            }}
                        >
                            {isFetchingNextPage
                                ? 'Chargement...'
                                : hasNextPage
                                    ? '+ Voir plus'
                                    : 'Vous avez tout chargé'}

                        </div>
                    </div>
                </div>):(
                    <div>
                        Pas de résultat
                    </div>
                )}
            </section>
        </div>
    );
}

export default ForWish;
