import React,{useState,useEffect} from "react";

import "./style.css";
import "moment/locale/fr";


function Tarifs() {
    return (
        <div className="container">
            <div className="whiteContainer">
                <div>
                <h1>LISTE DES TARIFS VIGNITUS au 01/01/2022</h1>
                <div style={{whiteSpace:'pre-line'}}>
                    <h2>1. Services relatifs à la Protection des Acheteurs</h2>
                    Les Services relatifs à la Protection Acheteur, qui incluent la Protection Acheteur et la Protection
                    Acheteur Pro, sont des Services Complémentaires proposés par VIGNITUS afin de faciliter et de
                    sécuriser les Transactions lorsque des Articles sont achetés sur le site à l’aide des services de
                    Paiement intégré de VIGNITUS (veuillez consulter nos Conditions Générales et les Conditions
                    Générales de Vente pour professionnel pour des informations complètes). La Protection Acheteur et
                    Acheteur Pro (conjointement les « Services relatifs à la Protection Acheteur ») font partie
                    intégrante des Services de Paiement sont obligatoires.
                    Afin de bénéficier des Services relatifs à la Protection Acheteur, l’un des frais suivants sera
                    facturé à l’Acheteur, en plus du prix de l’article, en fonction du type de Vendeur auprès duquel
                    l’achat est effectué :
                    Un frais au titre de la Protection Acheteur sera automatiquement facturé lorsque l’achat est
                    effectué auprès :
                    d’un Vendeur particulier et ou pro

                    Un frais au titre de la Protection Acheteur sera automatiquement facturé lorsque l’Acheteur est un
                    consommateur et achète un Article auprès d’un Vendeur Pro.
                    Ces frais seront indiqués à l’Acheteur sur le Site avant la confirmation de la commande et sont
                    calculés comme suit :
                    - un montant fixe de 1€ et
                    - 8 % du prix de l’article et de l’envoi convenu entre l’Acheteur et le Vendeur.
                    *Remarque : Lorsque l’Acheteur est un consommateur, la TVA est incluse dans les frais au titre de la
                    Protection Acheteur, ainsi que dans les frais au titre de la Protection Acheteur Pro. Si l’Acheteur
                    est un Utilisateur Pro, la TVA n’est pas incluse et doit être déclarée et payée par l’Utilisateur
                    Pro lui-même. Il en va de même pour tous les autres Services Complémentaires payants décrits
                    ci-après (tels que le « Boost », le « Dressing en Vitrine », etc.).
                    <h2>2. Transport</h2>
                    Dans le cas où le colis est perdu par le prestataire de transport :
                    Le montant des frais de port et le prestataire de transport seront choisis par le Vendeur, qui sera
                    pleinement responsable en cas de perte, de détérioration du colis ou dans le cas où l’Acheteur ne
                    reçoit pas le colis. En ce qui concerne les frais d’envoi personnalisé, VIGNITUS rembourse
                    uniquement l’Acheteur et ne fournit aucune assistance en cas de litige entre l’Acheteur et le
                    Vendeur.
                    <h2>3. Système de promotion des articles</h2>
                    Vous pouvez améliorer la visibilité de vos articles sur VIGNITUS en optant pour les services
                    suivants :

                    <span className="color">3.1 Articles Boostés</span>
                    Les articles proposés sur VIGNITUS peuvent bénéficier d’une meilleure visibilité dans le flux
                    d’articles/le fil d’actualités et dans les résultats de recherche du catalogue des autres membres.
                    Ce placement a lieu lorsque le membre achète des services de « Boost » et est répété une fois par
                    jour pendant un nombre déterminé de jours ou jusqu’à ce que l’article soit vendu, selon le cas.
                    La fonction « Boost » dure :
                    - 3 jours consécutifs pour 2€ ou jusqu’à ce que l’article Boosté soit vendu.
                    Les frais applicables et la durée du Boost seront affichés au moment du règlement. Vous recevrez par
                    ailleurs un e-mail avec une confirmation de commande sur l’adresse e-mail que vous avez indiquée.
                    Veuillez noter que le Boost est un service automatisé à usage unique et n’est pas transférable d’un
                    article à un autre ou entre Utilisateurs.
                    VIGNITUS vous fournira des statistiques sur le nombre de personnes qui ont vu et interagi avec votre
                    article boosté.
                    Veuillez noter que si une annonce boostée est supprimée au motif qu’elle n’est pas conforme aux
                    Règles du Catalogue, elle ne sera pas remboursée.
                    <span className="color">3.2 Ma Cave mise en avant</span>
                    Les articles proposés sur VIGNITUS peuvent faire l’objet d’une promotion dans une section distincte,
                    dans le flux d’articles/le fil d’actualités et dans les résultats de recherche du catalogue des
                    autres membres, sur la base des préférences des membres, des filtres de leur catalogue et des
                    articles les plus souvent ajoutés à leurs favoris. Veuillez noter que vous ne pouvez pas choisir les
                    articles qui sont visibles dans votre Cave mise en avant. Cette fonctionnalité est uniquement
                    disponible si vous avez téléchargé au moins 5 articles disponibles à la vente pendant la période de
                    7 jours de mise en avant de votre Cave. La Mise en avant de votre cave est valable pendant 7 jours
                    consécutifs, et la période de 7 jours se poursuit même si 5 articles ne sont pas disponibles à la
                    vente pendant cette période. La mise en avant de votre cave se termine à la fin de la période de 7
                    jours. Veuillez noter que la mise en avant de votre cave est un service automatisé à usage unique et
                    n’est pas transférable entre membres.
                    VIGNITUS vous fournira chaque semaine des statistiques sur le nombre de personnes qui ont vu et
                    interagi avec les articles dans votre Dressing.
                    Cette fonction vous permet de générer du trafic sur votre espace « Ma Cave » et donc vous fais
                    gagner des abonnés et des ventes supplémentaires.
                    La fonctionnalité Dressing en Vitrine coûte 5€ pour 7 jours.
                    Les frais applicables et la durée restante de mise en avant de votre espace « Ma Cave »
                    Veuillez noter que si des articles de votre Cave sont supprimés au motif qu’ils ne sont pas
                    conformes aux Règles du Catalogue et/ou à nos Condition Générales, ce qui porte en conséquence le
                    nombre d’articles dans votre espace « Ma cave » à moins de 5, la mise en avant prendra fin et vous
                    ne serez pas remboursé(e). De plus, si votre compte est bloqué en raison d’une violation de nos
                    Conditions Générales ou des Règles de notre Catalogue, tous tes articles disparaîtront du catalogue
                    et vous ne serez pas en mesure d’obtenir un remboursement.

                </div>
                </div>
            </div>
        </div>)
}
export default Tarifs;
