import Navbar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer/index";
import Manage from "./../../Containers/ManageSales/Manage";
const ManageSales = () => {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
        <Manage />
      </div>
      <Footer />
    </div>
  );
};

export default ManageSales;
