import React, {useEffect, useState} from "react";
import WineBottle from "../../Assets/wineBottle.jpg";
import {UrlBaseServer} from "../../Services/storageServer"
import {formatPrice} from "../../Services/tools"
import {useAuth} from '../../Services/auth'

import "./productCard.css";
import {useNavigate} from "react-router-dom";
import api from "../../Services/api";
import BoostModal from "../Modal/BoostModal";
function ProductCard({item, reloadProducts, lot,selected,onPressSelection}) {
  const {user} = useAuth()
  const [liked, setLiked] = useState(false);
  const navigate = useNavigate();
  const [showBoostModal, setShowBoostModal] = useState(false);
  useEffect(()=>{
    if(user){
      api.wasWish(item.id).then((data) => {
        setLiked(data.data.wish);
      });
    }
  },[])

  return (
    <div>
      <section className="cardBox">
        <div style={{cursor:"pointer"}} onClick={()=>{
          navigate("/product-detail/"+item?.id,{
            state: { product: item },
          })
        }} className="cardImg">
          <img src={UrlBaseServer+item?.Product_images[0]?.image} alt="" />
          {user &&(
              <i
              className={`fa-solid fa-heart fa-xl heart-icon ${
                  liked ? "heart-liked" : "heart"
              }`}
              onClick={(e) => {
                e.stopPropagation()
                if (liked) {
                  api.unWish( item?.id).then(() => {
                    setLiked(false);
                  });
                } else {
                  api.addWish( item?.id).then(() => {
                    setLiked(true);
                  });
                }
              }}
          ></i>
          )}

        </div>
        <div className="cardContent">
          <h5 style={{cursor:"pointer"}} onClick={()=>{
            navigate("/product-detail/"+item?.id,{
              state: { product: item },
            })
          }}>
            {item?.name}
          </h5>

          <p>{item?.year} {item?.Region?.name} {item?.Appelation?.name}</p>
          <h3>{formatPrice(item?.price)} &euro;</h3>
          <p>Vendu par <span style={{cursor:"pointer", fontWeight:800}} onClick={()=>{
            navigate("/user-profile/"+item?.User.id,{
              state: { user: item?.User },
            })
          }}>{item?.User?.firstName} {item?.User.lastName}</span></p>
          {/*<p>*/}
          {/*  <i className="fa-solid fa-star"></i> 4.84 &#123;85&#125;*/}
          {/*</p>*/}
          {item?.User?.id ==user?.id && item?.boosted == 0 && (
              <div
                  style={{
                    marginTop: 12,
                    alignItems: "center",
                    borderWidth: 1,
                    border:"solid 1px #6b0019",
                    borderColor: "#6b0019",
                    paddingVertical: 4,
                    borderRadius: 20,
                    marginBottom: 4,
                    padding:4,
                    cursor:"pointer"
                  }}
                  onClick={() =>{
                    setShowBoostModal(true)
                  }
                      //navigation.navigate("PaiementBoost", {
                      //  products: [data],
                      //})
                  }
              >
                <div
                    style={{
                      textAlign:"center",
                     // fontFamily: Typography.BUTTON_MEDIUM.font,
                    //  fontSize: Typography.BUTTON_MEDIUM.size,
                      color: "#6b0019",
                    }}
                >
                  Booster
                </div>
              </div>
          )}
          {item?.User?.id == user?.id && item?.boosted == 1 && (
              <div
                  style={{
                    marginTop: 12,
                    alignItems: "center",
                    borderWidth: 1,
                    border:"solid 1px #6b0019",
                    borderColor: "#6b0019",
                    paddingVertical: 4,
                    borderRadius: 20,
                    marginBottom: 4,
                    padding:4,
                    backgroundColor: "#6b0019",
                    cursor:"pointer"
                  }}
                  onClick={() =>{}
                  }
              >
                <div
                    style={{
                      textAlign:"center",
                      color: "#fff",

                    }}
                >
                  Déjà boosté
                </div>
              </div>
          )}
              {(lot) && (
                  <div
                      style={{
                        marginTop: 12,
                        alignItems: "center",
                        borderWidth: 1,
                        border:"solid #6b0019 1px",
                        borderColor: "#6b0019",
                        paddingVertical: 4,
                        borderRadius: 20,
                        marginBottom: 4,
                        textAlign:"center",
                        backgroundColor: selected ? "#6b0019" : "#fff",
                        cursor:"pointer"
                      }}
                      onClick={onPressSelection}
                  >
                    <div
                        style={{
                          color: selected ? "#fff" : "#6b0019",
                        }}
                    >
                      {selected ? "Retirer" : "Ajouter"}
                    </div>
                  </div>
              )}
        </div>
      </section>
      {user?(<BoostModal key={"modal"+item.id} setShowModalBoost={setShowBoostModal} showModalBoost={showBoostModal} product={item} reloadProducts={reloadProducts}  />):null}

    </div>
  );
}

export default ProductCard;
