import React,{useState,useEffect} from "react";

import "./style.css";
import "moment/locale/fr";
import {useNavigate} from "react-router-dom";


function Contact() {
    const navigate = useNavigate();

    return (
        <div className="container">
            <div className="whiteContainer">
                <h3 style={{textAlign:"center"}}>Des questions ? </h3>
                <h4 style={{textAlign:"center"}}>Contactez-nous</h4>
                <div className='d-flex justify-content-between mt-5'>
                    <div onClick={()=>{
                        navigate("/chat/4")
                    }} style={{textAlign:"center",cursor:"pointer"}}><i style={{fontSize:60 ,color:"#8b0000"}} className="fa-solid fa-message"></i><h5 className="mb-3">Via le chat dans l'application</h5></div>
                    <div onClick={()=>{
                        window.open("mailto:support@vignitus.fr", "_blank")
                    }} style={{textAlign:"center", cursor:"pointer"}}><i style={{fontSize:60, color:"#8b0000"}} className="fa-solid fa-envelope"></i><h5 className="mb-3">Via email</h5></div>
                    <div style={{textAlign:"center"}}><div className="d-flex justify-content-around"><i onClick={() => {
                        window.open("https://www.facebook.com/vignitus", "_blank")
                    }} style={{fontSize:60, cursor:"pointer", color:"#3b5998"}} className="fa-brands fa-facebook"></i>
                        <i onClick={() => {
                            window.open("https://www.instagram.com/vignitus_app/", "_blank")
                        }} style={{fontSize:60, cursor:"pointer", color:"#C32AA3"}} className="fa-brands fa-instagram-square"></i>
                    </div><h5 className="mb-3">Via les reseau sociaux</h5></div>
                </div>

            </div>
        </div>)
}
export default Contact;
