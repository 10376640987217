import React from "react";
import { Navbar, Footer } from "./../../Components";
import Banner from "./../../Containers/FavorisList/Banner";
import ForWish from "../../Containers/FavorisList/ForWish";
import {useLocation} from "react-router-dom";
import {useInfiniteQuery} from "react-query";
import api from "../../Services/api";

function WishList() {
    const params = useLocation();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);


    return (
    <div>
      <Navbar />
      <div className="content-wraper">
        <Banner />
        <ForWish/>
      </div>
      <Footer />
    </div>
  );
}

export default WishList;
