import React, {useEffect, useState} from "react";
import WineBottle from "../../../Assets/grandWine.jpg";
import EditIcon from "./../../../Assets/edit-icon.png";
import RadioImg from "./../../../Assets/radio.PNG";
import Lottie from "lottie-react";
import "./detail.css";
import {useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import api from "../../../Services/api";
import {useAuth} from "../../../Services/auth";
import {useQuery} from "react-query";
import checkedCircle from "../../../Assets/checkedCircle.png";
import checkCircle from "../../../Assets/checkCircle.png";
import visaCard from "../../../Assets/visaLogo.png";
import InputMask from "react-input-mask-format";
import {formatPrice} from "../../../Services/tools"
import {UrlBaseServer} from "../../../Services/storageServer"
import SuccessModal from "../../../Components/Modal/successModal";
import BoostVitrineModal from "../../../Components/Modal/BoostVitrineModal";
import FailModal from "../../../Components/Modal/failModal";
function Detail() {
  const {user} = useAuth()
  const params = useLocation();
  const nameuri =params.pathname.split("/").pop()
  const [CBChoosed, setCBChoosed] = useState(0);

  const [displayUpdateAddress, setDisplayUpdateAddress]=useState(false)
  const [successAddress,setSuccessAddress]=useState(null)
  const [errAddress,setErrAddress]=useState(null)
  const { register:registerAddress, handleSubmit:handleSubmitAddress, watch:watchAddress, formState: { errorsAddress } } = useForm({defaultValues:{number:user?.Addresses[0]?.number, address:user?.Addresses[0]?.address,additional_address:user?.Addresses[0]?.additional_address,city:user?.Addresses[0]?.city, postal_code:user?.Addresses[0]?.postal_code,country:user?.Addresses[0]?.country}});
  const [displayUpdateCB, setDisplayUpdateCB]=useState(false)
  const { register:registerCB, handleSubmit:handleSubmitCB, watch:watchCB, formState: { errorsCB } } = useForm({defaultValues:{nameCard:null, numCard:null, expirationDate:null, codeSecu:null}});
  const [errCB,setErrCB]=useState(null);
  const [successCB,setSuccessCB]=useState(null)
  const [products, setProducts] = useState(params.state?.products);
  const [offer, setOffer] = useState(params.state?.offer);
  const [delivChoosed, setDelivChoosed] = useState(null);
  const [delivMode, setDeliverMode] = useState([]);
  const [paiementId, setPaiementId] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  console.log(params.state)

  const { isLoading,
    isError,
    error,
    data:dataCard,
    refetch:refetchCard,
    isFetching,
    isPreviousData, } = useQuery(['repoCard'], ()=> api.getCards(),{ keepPreviousData : true }
  )
  console.log(nameuri)
  useEffect(()=>{
    if(nameuri.includes("pr")){
      let id=nameuri.split("pr")[1]
      api.getProductById(id).then((data) => {
        setProducts([data.data]);
      });
    }else{
      if(nameuri.includes("off")){
        let id=nameuri.split("off")[1];
      }
    }


  },[nameuri])

  useEffect(()=>{
    if(products.length==1){
      api.getDeliverMode(products[0].id).then((data) => {
        setDeliverMode(data.data);
        setDelivChoosed(data.data[0])
      })


    }
    if(offer){
      if(offer?.Delivery_mode){
        setDeliverMode([offer?.Delivery_mode])
        setDelivChoosed(offer?.Delivery_mode[0])

      }else{
        api.getDeliverMode(products[0].id).then((data) => {
          setDeliverMode(data.data);
          setDelivChoosed(data.data[0])
        });
      }
    }else if(products) {
      api.getDeliverMode(products[0].id).then((data) => {
        setDeliverMode(data.data);
        setDelivChoosed(data.data[0])
      });
    }
  },[products, offer])

  async function findIndexOnArray() {
    // let cardId = await AsyncStorage.getItem("cardID");
    let cardId = user.selectedCardId;
    let cardIndex = dataCard?.data?.findIndex((object) => {
      return object.Id === cardId;
    });

    if (cardIndex == -1) {
      cardIndex = 0;
    }
    setCBChoosed(cardIndex);
  }
  const onSubmitCB = async (data,e)=>{
    let error=false
    console.log(data)
    if (!data.nameCard?.trim()) {
      error=true
      setErrCB("Vous devez rentrer le nom figurant sur la carte ");
    }
    if (data.numCard?.replaceAll(" ", "").length != 16) {
      error=true
      setErrCB("Vous devez rentrer les 16 numéro figurant sur la carte");
    }
    if (data.expirationDate?.length != 5) {
      error=true
      setErrCB("Vous devez rentrer la date d'expiration");
    }
    if (data.codeSecu?.length != 3) {
      error=true
      setErrCB("Vous devez rentrer les 3 chiffres de sécurité");
    }
    if(error==false){
      api.createCardsToken().then((response) => {
        let cardToken = response.data;

        var details = {
          data: cardToken.PreregistrationData,
          accessKeyRef: cardToken.AccessKey,
          returnURL: "",
          cardNumber: data.numCard?.replaceAll(" ", ""),
          cardExpirationDate: data.expirationDate.replace("/", ""),
          cardCvx: data.codeSecu,
        };

        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        fetch(response.data.CardRegistrationURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: formBody,
        })
            .then((response) => response.text())
            .then((response) => {
              api
                  .updateCardToken({
                    id: cardToken.Id,
                    RegistrationData: response,
                  })
                  .then((responseUpdateCard) => {
                    console.log(responseUpdateCard);
                    setSuccessCB(true)
                    refetchCard()
                    setDisplayUpdateCB(false)

                    // setVisible(false);
                  });
            });
      });
    }
  }
  function getProductPrice(){
    let price=0;
    if(offer){
      price=offer.price;
    }else{
      price=products[0].price
    }
    return price
  }
  function getProtectionPrice(){
    let price = 0;
    if(offer){
      let pourcentPrice = offer.price * 0.08
      price= pourcentPrice;
    }else{
      console.log(products[0].price)
      let pourcentPrice=products[0].price * 0.08
      price=pourcentPrice
    }

      let pourcentD = delivChoosed?.price*0.08
      price = price+pourcentD

    price=price + 1
    let priceRounded = Math.round(price * 100) / 100;
    return formatPrice(priceRounded);
  }
  function totalPrice() {
    let price = 0;
    if(offer){
      let pourcentPrice = offer.price * 0.08
      price= price+offer.price+pourcentPrice;
    }else{
      let pourcentPrice=products[0].price * 0.08
      price=price+products[0].price+pourcentPrice
    }

      let pourcentD = delivChoosed?.price*0.08
      price = price+delivChoosed?.price+pourcentD

    price=price + 1
    let priceRounded = Math.round(price * 100) / 100;
    return formatPrice(priceRounded);
  }

  const onSubmitAddress = async (data, e) => {
    try {
      if(user?.Addresses[0].id){
        await api.putUpdateAddress(user?.Addresses[0].id,
            user.firstName,
            user.firstName,
            data.number,
            data.address,
            data.additional_address,
            data.postal_code,
            data.city,
            data.country)
        setSuccessAddress("Votre adresse a été modifié avec succès")

      }else{
        await api.postAddress(
            user.firstName,
            user.firstName,
            data.number,
            data.address,
            data.additional_address,
            data.postal_code,
            data.city,
            data.country,
            true,
            true)
        setSuccessAddress("Votre adresse a été créé avec succès")
      }
    } catch (error) {
      setErrAddress(error.response.data.error)
    }
    e.preventDefault();
  }

  const fetchPaymentSheetParams = async (offer_id) => {
    console.log('la')
    let productids=[]
    for(let prod of products){
      productids.push(prod.id)
    }
    console.log(CBChoosed)
    const response = await fetch(
        UrlBaseServer+"api/offer/payed",
        {
          method: "PUT",
          body: JSON.stringify({
            id: offer_id,
            product_ids: productids,
            card_id: dataCard.data[CBChoosed].Id,
            delivery_mode_id: delivChoosed.id,
            address_delivery_id: user.Addresses[0].id,
            address_billing_id: user.Addresses[0].id,
          }),
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": "application/json",
          },
        }
    );
    let dataPayment = await response.json();
    console.log(dataPayment)
    setPaiementId(dataPayment.data.Id);

    if (dataPayment.data.Status == "SUCCEEDED") {
      setShowSuccessModal(true)
    }else if(dataPayment.data.Status == "FAILED"){
      setShowFailModal(true)
    }else if(dataPayment.data.SecureModeNeeded){
      window.open(dataPayment.data.SecureModeRedirectURL);
      testTransaction(dataPayment.data.Id,dataCard.data[CBChoosed])
    }
  };
  async function testTransaction(pI, cbChoosed){
    console.log("test Transaction", pI, cbChoosed.Id)
    let transac = await api.getTransaction(
        pI,
        cbChoosed.Id
    );
    console.log(transac);

    if(transac.data.Status!="CREATED"){
      if (transac.data.Status == "FAILED") {
        //modalError
        setShowFailModal(true)
      }
      if (transac.data.Status == "SUCCEEDED") {
        //setSuccessBoosted("Votre cave a bien été boosté")

        setShowSuccessModal(true)
      }
    }else{
      testTransaction(pI, cbChoosed)
    }
  }
  function checkActivePay(){
    let valid =false
    if(CBChoosed != null && delivChoosed != null && user.Addresses[0]!=null && products[0].status==1 && offer==null) {
      valid=true
    }
    if(offer?.status==1 && CBChoosed != null && delivChoosed != null && user.Addresses[0]!=null){
      valid=true
    }
    return valid
  }

  function pay() {

    //cardSelected({ token: user.token, selectedCardId: cards[CBChoosed].Id });
    if (offer) {
      console.log(offer)
      fetchPaymentSheetParams(offer.id);
    } else {
      fetchPaymentSheetParams(null);
    }
  }

  return (
    <div className="container">
      <br />
      <div className="row">
        <div className="col-sm-12 col-md-8 col-lg-8">
          <div className="checkout-product-detail">
            <h1 className="title">Commande</h1>
            {products.length==1?(
            <div>
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-2 col-lg-2">
                  <img
                    src={UrlBaseServer+""+products[0]?.Product_images[0]?.image}
                    className="checkout-product-img"
                    alt=""
                  />
                </div>

                <div className="col-sm-12 col-md-8 col-lg-8">
                  <p className="name">{products[0].name}</p>
                  <p className="address">{products[0]?.year} {products[0]?.Region?(<span>- {products[0]?.Region?.name}</span>):(null)} {products[0]?.Appelation?(<span> - {products[0]?.Appelation?.name}</span>):null}</p>
                </div>
                <div className="col-sm-12 col-md-2 col-lg-2">
                  {offer?(
                      <p className="price">{offer.price} €</p>
                  ):(
                      <p className="price">{products[0].price} €</p>
                  )}

                </div>
              </div>
            </div>):null}
          </div>
          <br />
          <div className="checkout-product-detail">
            <h1 className="title">Adresse de livraison</h1>
            <div>
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-10 col-lg-10">
                  <p className="name"> {user.Addresses[0].firstName + " " + user.Addresses[0].lastName}</p>
                  <p className="address">
                    {user.Addresses[0].number +
                    " " +
                    user.Addresses[0].address +
                    " " +
                    user.Addresses[0].additional_address} {user.Addresses[0].postal_code +
                  " " +
                  user.Addresses[0].city +
                  " " +
                  user.Addresses[0].country}
                  </p>
                </div>
                <div style={{cursor:"pointer"}} className="col-sm-12 col-md-2 col-lg-2 d-flex justify-content-end">
                  {/* <p className="price">29.90 €</p> */}
                  <img onClick={()=>{
                    setDisplayUpdateAddress(!displayUpdateAddress)
                  }} src={EditIcon} alt="" />
                </div>
                {displayUpdateAddress?(
                    <form onSubmit={handleSubmitAddress(onSubmitAddress)} noValidate>
                      {successAddress?(
                          <div className="alert alert-success">
                            {successAddress}
                          </div>
                      ):null}
                      <div className="d-flex flex-lg-row flex-column flex-nowrap">
                        <div className="col-2 col-md-2 col-lg-2">
                          <div className="text-field-box">
                            <label className="input-label">Numéro</label>
                            <input
                                type="text"
                                className="input"
                                {...registerAddress("number",{ required: true })}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                          <div className="text-field-box">
                            <label className="input-label">Rue</label>
                            <input
                                type="text"
                                className="input"
                                {...registerAddress("address",{ required: true })}
                            />
                          </div>
                        </div>
                        <div className="col-10  col-md-4 col-lg-4">
                          <div className="text-field-box">
                            <label className="input-label">Complément d’adresse</label>
                            <input
                                type="text"
                                className="input"
                                {...registerAddress("additional_address",{ })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-lg-row flex-column flex-nowrap">

                        <div className="col-12  col-md-4 col-lg-4">
                          <div className="text-field-box">
                            <label className="input-label">Ville</label>
                            <input type="text" className="input"
                                   {...registerAddress("city",{ required: true })}
                            />
                          </div>
                        </div>
                        <div className="col-12  col-md-4 col-lg-4">
                          <div className="text-field-box">
                            <label className="input-label">Code postal</label>
                            <input type="text" className="input"
                                   {...registerAddress("postal_code",{ required: true })}
                            />
                          </div>
                        </div>
                        <div className="col-12  col-md-4 col-lg-46">
                          <div className="text-field-box">
                            <label className="input-label">Pays</label>
                            <input type="text" className="input"
                                   {...registerAddress("country",{ required: true })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button>Modifier mon adresse</button>
                      </div>
                    </form>):null}
              </div>
            </div>
          </div>
          <br />
          <section className="bottomBox">
            <div className="bottomContent mb-4">
              <p>Options de livraison proposé par le vendeur</p>
            </div>
            {products.length==1?(
            <>
            {delivMode.map((elt,i)=>{
              return(
                  <section onClick={()=>{
                    setDelivChoosed(elt)
                  }} className="radioBox">
                    <div>
                      <label style={{margin:0}}>
                        <input type="radio" name="test" value="large" />
                        <img style={{width:25,marginRight:5}} className={delivChoosed?.id == elt?.id?("circleChecked"):("circleUncheck")} src={delivChoosed?.id == elt?.id
                            ? checkedCircle
                            : checkCircle}/>
                      </label>
                    </div>
                    <div className="d-flex prizeCont">
                      <div className="radioContent">
                        {elt?.Delivery_mode_model?(<h5>{elt?.Delivery_mode_model?.name}</h5>):(<h5>{elt?.name}</h5>)}
                        {/*<p>Livraison domicile contre signature via colissimo</p>*/}
                      </div>
                      <div className="mr-3">{elt?.price}&#8364;</div>
                    </div>
                  </section>
              )
            })}
            </>):null}
          </section>
          <br />
          <div className="checkout-product-detail">
            <h1 className="title">Methode de paiement</h1>
            <div>
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-10 col-lg-10">
                  <p className="name" style={{display:"flex", justifyContent:"space-between"}}> Carte bancaire <i onClick={()=>{
                    setDisplayUpdateCB(!displayUpdateCB)
                    //setDisplayUpdateAddress(!displayUpdateAddress)
                  }}  className="fa-solid fa-pen-to-square" style={{marginRight:5, cursor:"pointer", fontSize:24}}/></p>
                  <p className="address">
                    Les informations liées à ta carte bancaire ne seront pas
                    partagées avec le vendeur. Vignitus n'enregistre pas tes
                    informations bancaires sans ton autorisation.{" "}
                  </p>
                  <div>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                      {dataCard?.data?.length ? (
                          <div>
                            {dataCard.data.map((v,i)=>{
                              return(
                                  <React.Fragment key={"dataCard"+i}>
                                    {dataCard.data[i].Validity!="INVALID"?(
                                        <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              cursor:"pointer"
                                            }}
                                            onClick={()=>{
                                              api.cardSelected(dataCard.data[i].id)
                                              setCBChoosed(i)
                                            }}
                                        >
                                          <div>
                                            <label>
                                              <img style={{width:25,marginRight:5}} className={CBChoosed == i?("circleChecked"):("circleUncheck")} src={CBChoosed == i
                                                  ? checkedCircle
                                                  : checkCircle}/>
                                            </label>
                                          </div>
                                          <img
                                              style={{
                                                width: 50,
                                                height: 35,
                                                resizeMode: "contain",
                                              }}
                                              src={visaCard}
                                          />
                                          <div
                                              style={{
                                                marginLeft: 16,
                                                flex: 1,
                                              }}
                                          >
                                            <div
                                                style={{
                                                  fontWeight: "300",
                                                }}
                                            >
                                              {dataCard?.data[i]?.CardProvider + " se terminant par "}
                                              <div
                                                  style={{
                                                    fontWeight: "700",
                                                  }}
                                              >
                                                {dataCard?.data[i]?.Alias.substr(
                                                    dataCard?.data[i]?.Alias?.length - 4
                                                )}
                                              </div>
                                            </div>
                                            <div
                                                style={{
                                                  fontWeight: "300",
                                                }}
                                            >
                                              Date d’expiration :{" "}
                                              {dataCard?.data[i]?.ExpirationDate[0] +
                                              "" +
                                              dataCard?.data[i]?.ExpirationDate[1]}
                                              /
                                              {dataCard?.data[i]?.ExpirationDate.substr(
                                                  dataCard?.data[i]?.ExpirationDate?.length - 2
                                              )}
                                            </div>
                                          </div>
                                        </div>):null}</React.Fragment>)})}
                          </div>
                      ) : (
                          <div>Pas de carte enregistré</div>
                      )}

                    </div>
                    {displayUpdateCB?(
                        <form onSubmit={handleSubmitCB(onSubmitCB)} noValidate>
                          {successCB?(
                              <div className="alert alert-success">
                                Ajout de votre carte réussi
                              </div>
                          ):null}
                          {errCB?(
                              <div className="alert alert-danger">
                                {errCB}
                              </div>
                          ):null}
                          <div className="d-flex flex-lg-row flex-column flex-nowrap">
                            <div className="col-12 col-md-12 col-lg-12">
                              <div className="text-field-box">
                                <label className="input-label">Nom sur la carte</label>
                                <input
                                    type="text"
                                    className="input"
                                    {...registerCB("nameCard",{ required: true })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-lg-row flex-column flex-nowrap">
                            <div className="col-12 col-md-12 col-lg-12">
                              <div className="text-field-box">
                                <label className="input-label">Numéro de la carte</label>
                                <InputMask mask={"9999 9999 9999 9999"} className="input" {...registerCB("numCard",{ required: true })} />

                                {/*<input*/}
                                {/*    type="text"*/}
                                {/*    className="input"*/}
                                {/*    {...registerCB("numCard",{ required: true })}*/}
                                {/*/>*/}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-lg-row flex-column flex-nowrap">
                            <div className="col-6 col-md-6 col-lg-6">
                              <div className="text-field-box">
                                <label className="input-label">Date expiration</label>
                                <InputMask
                                    mask={"99/99"}
                                    className="input"
                                    {...registerCB("expirationDate",{ required: true })}
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-6 col-lg-6">
                              <div className="text-field-box">
                                <label className="input-label">Code secret</label>
                                <InputMask
                                    mask={"999"}
                                    type="text"
                                    className="input"
                                    {...registerCB("codeSecu",{ required: true })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center">
                            <button>Ajouter la carte</button>
                          </div>
                        </form>
                    ):null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-2 col-lg-2"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="checkout-product-detail">
            <h1 className="title">Récapitulatif de commande</h1>
            <div className="row align-items-center justify-content-between mb-3">
              <div className="col-sm-6">
                <p className="side-box-text">Commande</p>
              </div>
              <div className="col-sm-6">
                <p className="side-box-text text-md-right">{getProductPrice()} €</p>
              </div>
            </div>

            <div className="row align-items-center justify-content-between mb-3">
              <div className="col-sm-6">
                <p className="side-box-text">Frais de port</p>
              </div>
              <div className="col-sm-6">
                <p className="side-box-text text-md-right">{delivChoosed?.price} €</p>
              </div>
            </div>

            <div className="row align-items-center justify-content-between mb-3">
              <div className="col-sm-6">
                <p className="side-box-text">Frais de service</p>
              </div>
              <div className="col-sm-6">
                <p className="side-box-text text-md-right">{getProtectionPrice()} €</p>
              </div>
            </div>

            <br />
            <div className="row align-items-center justiify-space-between">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <p className="name">Total</p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <p className="name text-md-right">{totalPrice()} €</p>
              </div>
            </div>
            <br />
            <button onClick={()=>{
              if(checkActivePay()){
                pay()
              }

            }} className={checkActivePay()?"pay-now":"pay-disable"}>Payer maintenant</button>
          </div>
        </div>
      </div>
      <SuccessModal setShowSuccessModal={setShowSuccessModal} showSuccessModal={showSuccessModal}/>
      <FailModal setShowFailModal={setShowFailModal} showFailModal={showFailModal}/>
    </div>
  );
}

export default Detail;
