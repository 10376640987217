import Manage from "../../ManageSales/Manage";
import React, {useRef, useState,useEffect} from "react";
import "./style.css";
import {useAuth} from "../../../Services/auth";
import api from "../../../Services/api"
import {useNavigate} from "react-router-dom";


const valueArray = [
    { value: 5, label: "5%" },
    { value: 10, label: "10%" },
    { value: 15, label: "15%" },
    { value: 20, label: "20%" },
    { value: 25, label: "25%" },
    { value: 30, label: "30%" },
    { value: 35, label: "35%" },
    { value: 40, label: "40%" },
    { value: 45, label: "45%" },
    { value: 50, label: "50%" },
];

function Reduction() {
    const {user} = useAuth()
    const navigate = useNavigate();

    const [swithEnabled, setSwitchEnabled] = useState(false);
    const [twoArticle, setTwoArticle] = useState("");
    const [threeArticle, setThreeArticle] = useState("");
    const [fiveArticle, setFiveArticle] = useState("");
    const [firstEnter, setFirstEnter] = useState(false);

    useEffect(() => {
        if(user.lotReduction==null){
            setSwitchEnabled(false);
        }else{
            setSwitchEnabled(user.lotReduction);
        }
        if(user.lotReduction2==null){
            setTwoArticle("");
        }else{
            setTwoArticle(user.lotReduction2);
        }
        if(user.lotReduction3==null){
            setThreeArticle("");
        }else{
            setThreeArticle(user.lotReduction3);
        }
        if(user.lotReduction5==null){
            setFiveArticle("");
        }else{
            setFiveArticle(user.lotReduction5);
        }
        setFirstEnter(true);
    }, []);

    useEffect(() => {
        if (firstEnter == true) {
            let var1=twoArticle
            let var2=threeArticle
            let var3=fiveArticle
            if(var1=="Sélectionner"|| var1==""){
                var1=null
            }if(var2=="Sélectionner"|| var2==""){
                var2=null
            }if(var3=="Sélectionner"|| var3==""){
                var3=null
            }
            api.putLotReduction({
                lotReduction: swithEnabled,
                lotReduction2: var1,
                lotReduction3: var2,
                lotReduction5: var3,
            })
        }
    }, [swithEnabled, twoArticle, threeArticle, fiveArticle]);


    return (
        <div className="container">
            <div className="whiteContainer">
                <div style={{display:"flex", alignItems: "center"}}>
                    <div>Choisis tes réductions</div>
                    <input style={{marginLeft:10}} type="checkbox" checked={swithEnabled} onChange={e => setSwitchEnabled(!swithEnabled)} id="defaultCheck1"/>
               </div>
                <div>
                    <div>REDUCTION POUR :</div>
                    <div style={{display:"flex", alignItems:"center", marginTop:10}}>
                        <div>2 articles</div>
                        <div style={{marginLeft:20}}>
                            <select value={twoArticle} onChange={e => setTwoArticle(e.target.value)} className="filter-dropdown">
                                <option>Sélectionner</option>
                                {valueArray?.map((v,i)=>{
                                    return(
                                        <option key={"twoItem"+v?.value} value={v?.value}>{v?.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div style={{display:"flex", alignItems:"center", marginTop:10}}>
                        <div>3 articles</div>
                        <div style={{marginLeft:20}}>
                            <select value={threeArticle} onChange={e => setThreeArticle(e.target.value)} className="filter-dropdown">
                                <option>Sélectionner</option>
                                {valueArray?.map((v,i)=>{
                                    return(
                                        <option key={"threeItem"+v?.value} value={v?.value}>{v?.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div style={{display:"flex", alignItems:"center", marginTop:10}}>
                        <div>5 articles</div>
                        <div style={{marginLeft:20}}>
                            <select value={fiveArticle} onChange={e => setFiveArticle(e.target.value)} className="filter-dropdown">
                                <option>Sélectionner</option>
                                {valueArray?.map((v,i)=>{
                                    return(
                                        <option key={"fiveItem"+v?.value} value={v?.value}>{v?.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        style={{
                            fontWeight: "300",
                            marginHorizontal: 20,
                            marginTop: 8,
                        }}
                    >
                        Tu peux accorder des réductions croissantes en commençant par le nombre
                        d'articles que tu veux. Plus tes réductions sont intéressantes, plus tu
                        vendras. Pour plus d'informations, consulte notre{" "}
                        <span
                            onClick={() => {
                                navigate("/faq/",{
                                })
                            }}
                            style={{
                                fontWeight: "400",
                                cursor:"pointer"
                            }}
                        >
                            {" FAQ"}
                        </span>
                        .
                    </div>
                </div>
            </div>
        </div>)
}
export default Reduction;
