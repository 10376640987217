import "./style.css";
import { useForm } from "react-hook-form";
import {useAuth} from "../../Services/auth"
import React, {useEffect,useState} from "react";
import {useNavigate} from "react-router-dom";
import GoogleLetter from "../../Assets/googleLetter.png";
import FacebookLetter from "../../Assets/facebookLetter.png";

import { signInWithGoogle,signInWithFacebook } from '../../Services/firebase';
import InputMask from "react-input-mask-format";

const LoginComponent = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { register:registerSignup, handleSubmit:handleSubmitSignUp, watch:watchSignUp, formState: { errors:errorsSignup } } = useForm();
    const {isLoggingIn,user,login, error, register:regsterAuth, isRegistering} = useAuth()
    const navigate = useNavigate();
    const [err,setErr]=useState(null)
    const [pageMode,setPageMode]=useState(0)
    const [isPro,setIsPro]=useState(false)

    useEffect(()=>{
        console.log("ISLOGGINGIN")
        console.log(isLoggingIn)
        if(user){
            if(window.location.pathname=="/login"){
                navigate(`/`, {
                         state: 'activity',
                         replace: true,
                       })
            }
            //console.log('la')
        }
    },[isLoggingIn,isRegistering])

    const handleEmailValidation = email => {
        console.log("ValidateEmail was called with", email);

        const isValid = isValidEmail(email);

        const validityChanged =
            (errors.email && isValid) || (!errors.email && !isValid);
        if (validityChanged) {
            console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
        }

        return isValid;
    };

    const onSubmitRegister =async(data,e)=>{
        let dateFormat = data.birthday.split("/");
        let newDate = dateFormat[1] + "/" + dateFormat[0] + "/" + dateFormat[2];

        try {
            //await login({email:data.email, password:data.password})
            await regsterAuth({email:data.email,
                phone:data.phone,
                lastName:data.lastName,
                firstName:data.firstName,
                birthday:newDate,
                number_siret:data.number_siret,
                password:data.password})
        } catch (error) {
            setErr(error.response.data.error)
            console.log(error.response.data.error)
        }
        e.preventDefault();
    }

    const onSubmit = async (data, e) => {
        try {
            await login({email:data.email, password:data.password})
           // await login(data.email, data.password)
            // reset()
            // if (isMountedRef.current) {
            //   navigate(`${t('routes.activity')}`, {
            //     state: 'activity',
            //     replace: true,
            //   })
            // }
        } catch (error) {
            setErr(error.response.data.error)
            console.log(error.response.data.error)
            // if (isMountedRef.current) {
            //   enqueueSnackbar(t(`alert.login.${err.message}`), {
            //     variant: 'error',
            //     anchorOrigin: {
            //       vertical: 'top',
            //       horizontal: 'center',
            //     },
            //     TransitionComponent: Zoom,
            //   })
            // }
        }
        e.preventDefault();

    }
    const isValidEmail = email =>
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        );
    function handleChange(event) {
        console.log(event.target.checked);
        setIsPro(event.target.checked);
        if(!isPro){

        }
    }

    return (
        <div className="justify-content-center">
        <div className="offset-md-3 col-6 col-md-6 col-lg-6">
            {pageMode==0?(
            <div style={{background:"#fff"}} className="p-5 mt-5">
                <h3>Se connecter</h3>
            <form className="login" onSubmit={handleSubmit(onSubmit)}  noValidate>
                <label htmlFor="email">Email</label>
                <input {...register("email",{ required: true, validate: handleEmailValidation })} id="email" placeholder="E-mail" className="filter-dropdown m-3" name="email" autoFocus type="email" required/>
                {errors?.email?.type== "validate" ?( <div className="alert alert-danger">L'email n'est pas valide</div>):errors?.email?.type =="required"?(<div className="alert alert-danger">L'email est requis</div>):null}

                <label htmlFor="email">Mot de passe</label>
                <input  {...register("password",{ required: true})} placeholder="Mot de passe" className="filter-dropdown m-3" name="password" type="password" required/>
                {errors?.email?.type =="required"?(<div className="alert alert-danger">Le mot de passe est requis</div>):null}
                {err?(
                    <div className="alert alert-danger">
                        {err}
                    </div>
                ):null}
                <button>Se connecter</button>
            </form>
                <div style={{cursor:"pointer", color:"grey", textAlign:"center"}} onClick={()=>{
                    setPageMode(1)
                }}>Pas encore de compte ?</div>
            </div>):(
                <div style={{background:"#fff"}} className="p-5 mt-5">
                    <h3>S'inscrire</h3>
                    <form className="signUp" onSubmit={handleSubmitSignUp(onSubmitRegister)}  noValidate>
                        <label htmlFor="email">Email</label>
                        <input {...registerSignup("email",{ required: true, validate: handleEmailValidation })} id="email" placeholder="E-mail" className="filter-dropdown m-3" name="email" autoFocus type="email" required/>
                        {errorsSignup?.email?.type== "validate" ?( <div className="alert alert-danger">L'email n'est pas valide</div>):errors?.email?.type =="required"?(<div className="alert alert-danger">L'email est requis</div>):null}

                        <label htmlFor="phone">Téléphone</label>
                        <InputMask mask={"09 99 99 99 99"} name="phone" className="filter-dropdown m-3" {...registerSignup("phone",{ required: true})} />
                        {errorsSignup?.phone?.type =="required"?(<div className="alert alert-danger">Le numéro de téléphone est requis</div>):null}

                        <label htmlFor="lastName">Nom</label>
                        <input  {...registerSignup("lastName",{ required: true})} placeholder="Nom" className="filter-dropdown m-3" name="lastName" type="text" required/>
                        {errorsSignup?.lastName?.type =="required"?(<div className="alert alert-danger">Le nom est requis</div>):null}
                        <label htmlFor="firstName">Prénom</label>
                        <input  {...registerSignup("firstName",{ required: true})} placeholder="Prénom" className="filter-dropdown m-3" name="firstName" type="text" required/>
                        {errorsSignup?.firstName?.type =="required"?(<div className="alert alert-danger">Le prénom est requis</div>):null}
                        <label htmlFor="birthday">Date de naissance</label>
                        <InputMask mask={"99/99/9999"} className="filter-dropdown m-3" name="birthday" {...registerSignup("birthday",{ required: true})} />

                        {errorsSignup?.birthday?.type =="required"?(<div className="alert alert-danger">La date de naissance est requise</div>):null}
                        <div>
                            <label  htmlFor="isPro">Vous êtes un Professionel ?</label>

                            <input style={{marginBottom:"0.5rem", marginLeft:"0.5rem"}} checked={isPro} type="checkbox"  onChange={handleChange} id="isPro"/>
                        </div>
                        {isPro?(
                            <>
                                <label htmlFor="firstName">Numéro de siret</label>
                                <InputMask mask={"999 999 999 99999"} name="siretNumber" className="filter-dropdown m-3" {...registerSignup("siretNumber",{ required: isPro})} />

                                {errorsSignup?.siretNumber?.type =="required"?(<div className="alert alert-danger">Le numéro de siret est requis</div>):null}
                            </>
                        ):null}

                        <label htmlFor="password">Mot de passe</label>
                        <input  {...registerSignup("password",{ required: true})} placeholder="Mot de passe" className="filter-dropdown m-3" name="password" type="password" required/>
                        {errorsSignup?.password?.type =="required"?(<div className="alert alert-danger">Le mot de passe est requis</div>):null}
                        <label htmlFor="newPassword">Confirmation de mot de passe</label>
                        <input  {...registerSignup("newPassword",{
                            required: true,
                            validate: (val) => {
                                if (watchSignUp('password') != val) {
                                    return "Les mot de passe ne correspondent pas";
                                }}
                        })} placeholder="Confirmation de mot de passe" className="filter-dropdown m-3" name="newPassword" type="password" required/>
                        {errorsSignup?.newPassword?.type =="required"?(<div className="alert alert-danger">Le mot de passe est requis</div>):errorsSignup.newPassword?(
                            <div className="alert alert-danger">{errorsSignup.newPassword.message}</div>
                        ):null}


                        {err?(
                            <div className="alert alert-danger">
                                {err}
                            </div>
                        ):null}
                        <button>S'inscrire</button>
                    </form>
                    <div style={{cursor:"pointer", color:"grey", textAlign:"center"}} onClick={()=>{
                        setPageMode(0)
                    }}>Déjà un compte ?</div>
                </div>
            )}
        </div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <div
                style={{
                    flexDirection: "row",
                    backgroundColor: "#fff",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "8px 0",
                    borderRadius: 20,
                    border:"solid 1px #000",
                    width:225,
                    display:"flex",
                    cursor:"pointer",
                    marginTop:20
                }}
                onClick={() => {
                    signInWithGoogle()
                    //connectSocial(1);
                    //setSocialChoosed(1);
                    //setModalNameSocial(true);
                }}
            >
                <img
                    style={{
                        resizeMode: "contain",
                        height: 20,
                        width: 20,
                        margin:"0 10px"
                    }}
                    src={GoogleLetter}
                />
                <div
                    style={{
                        color: "#000",
                    }}
                >
                    Continuer avec Google
                </div>
            </div>
                <div
                    style={{
                        flexDirection: "row",
                        backgroundColor: "#fff",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "8px 0",
                        borderRadius: 20,
                        border:"solid 1px #000",
                        width:225,
                        display:"flex",
                        cursor:"pointer",
                        marginTop:20
                    }}
                    onClick={() => {
                        signInWithFacebook();
                       // connectSocial(0);
                       // setSocialChoosed(0);
                        //setModalNameSocial(true);
                    }}
                >
                    <img
                        style={{
                            resizeMode: "contain",
                            height: 24,
                            width: 12,
                            margin:"0 10px"

                        }}
                        src={FacebookLetter}
                    />
                    <div
                        style={{
                            color: "#000",
                        }}
                    >
                        Continuer avec Facebook
                    </div>
                </div>
            </div>
        </div>

    );
};

export default LoginComponent;