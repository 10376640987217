import React from "react";
import { Navbar, Footer } from "./../../Components";
import Detail from "./../../Containers/CheckOut/Detail";
import Banner from "../../Containers/Confidentialite/Banner";
import {default as CatalogueContainer}  from "../../Containers/Catalogue/Catalogue";

function Catalogue() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
          <Banner/>
          <CatalogueContainer/>
      </div>
      <Footer />
    </div>
  );
}

export default Catalogue;
