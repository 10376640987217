import axios from "axios";

import { UrlBaseServer } from "./storageServer";
import { storage } from "./tools";

const instance = axios.create({
  baseURL: UrlBaseServer,
});

instance.interceptors.request.use(async (config) => {
  if(storage.getToken()){
    config.headers.Authorization = `Bearer ${storage.getToken()}`
  }
  return config
})


export default {
  getUser: () => {
    return instance({
      method: "get",
      url: "/api/users/current/",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getUserById: (idUser) => {
    return instance({
      method: "get",
      url: "/api/users/" + idUser,
    });
  },
  getProductById: (idProduct) => {
    return instance({
      method: "get",
      url: "/api/products/" + idProduct,
    });
  },
  checkSellerStatus: () => {
    return instance({
      method: "get",
      url: "/api/users/checkSellerStatus",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  loginUser: (email, password) => {
    return instance({
      method: "post",
      url: "/api/users/authenticate/",
      data: {
        email,
        password,
      },
    });
  },
  payout: () => {
    return instance({
      method: "post",
      url: "/api/users/payout/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {},
    });
  },
  getWalletTransaction: () => {
    return instance({
      method: "get",
      url: "/api/users/wallet/transactions",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  firebase: (email, firebase_id,firstName, lastName) => {
    return instance({
      method: "post",
      url: "/api/users/firebase/",
      data: {
        email,
        firebase_id,
        firstName,
        lastName,
      },
    });
  },
  notificationToken: ( notificationToken) => {
    return instance({
      method: "post",
      url: "/api/users/notificationToken/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        notificationToken,
      },
    });
  },
  cardSelected: ( selectedCardId) => {
    return instance({
      method: "post",
      url: "/api/users/cardSelected/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        selectedCardId,
      },
    });
  },
  setNotificationToken: ( notificationToken) => {
    return instance({
      method: "post",
      url: "/api/users/notificationToken/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        notificationToken,
      },
    });
  },

  registerUser: (
    data
  ) => {
    return instance({
      method: "post",
      url: "/api/users/register/",
      data: data,
    });
  },

  getProducts: (
    page,
    size,
    name = null,
    color_id = null,
    year = null,
    priceMin = null,
    priceMax = null,
    country_id = null,
    region_id = null,
    appelation_id = null
  ) => {
    let params = "";
    if (page != null) {
      params = params + "?page=" + page;
    }
    if (size != null) {
      params = params + "&size=" + size;
    }
    if (name != null) {
      params = params + "&name=" + name;
    }
    if (color_id != null) {
      params = params + "&color_id=" + color_id;
    }
    if (year != null) {
      params = params + "&year=" + year;
    }
    if (priceMin != null) {
      params = params + "&priceMin=" + priceMin;
    }
    if (priceMax != null) {
      params = params + "&priceMax=" + priceMax;
    }
    if (country_id != null) {
      params = params + "&country_id=" + country_id;
    }
    if (region_id != null) {
      params = params + "&region_id=" + region_id;
    }
    if (appelation_id != null) {
      params = params + "&appelation_id=" + appelation_id;
    }
    return instance({
      method: "get",
      url: "/api/products" + params,
    });
  },
  getPepites: (page, size) => {
    let params = "";
    if (page != null) {
      params = params + "?page=" + page;
    }
    if (size != null) {
      params = params + "&size=" + size;
    }
    return instance({
      method: "get",
      url: "/api/products/pepites" + params,
    });
  },
  getGrandCru: (page, size) => {
    let params = "";
    if (page != null) {
      params = params + "?page=" + page;
    }
    if (size != null) {
      params = params + "&size=" + size;
    }
    return instance({
      method: "get",
      url: "/api/products/grandCru" + params,
    });
  },

  getProductsFromUser: (
    userId,
    page,
    size,
    color_id = null,
    year = null,
    priceMin = null,
    priceMax = null,
    country_id = null,
    region_id = null,
    appelation_id = null
  ) => {
    let params = "?";
    if (page != null) {
      params = "?page=" + page;
    }
    if (size != null) {
      params = params + "&size=" + size;
    }
    if (color_id != null) {
      params = params + "&color_id=" + color_id;
    }
    if (year != null) {
      params = params + "&year=" + year;
    }
    if (priceMin != null) {
      params = params + "&priceMin=" + priceMin;
    }
    if (priceMax != null) {
      params = params + "&priceMax=" + priceMax;
    }
    if (country_id != null) {
      params = params + "&country_id=" + country_id;
    }
    if (region_id != null) {
      params = params + "&region_id=" + region_id;
    }
    if (appelation_id != null) {
      params = params + "&appelation_id=" + appelation_id;
    }
    return instance({
      method: "get",
      url: "/api/products/user/" + userId + params,
    });
  },

  postCreateProduct: (
    name,
    country_id,
    region_id,
    appelation,
    color_id,
    year,
    description,
    price,
    delivery,
    images,
    quantity,
    viticulture,
    typeCave,
    coffret,
    pourcentage,
    cru,
    CRD
  ) => {
    return instance({
      method: "post",
      url: "/api/products/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        name,
        country_id,
        region_id,
        appelation_id: appelation,
        color_id,
        year,
        description,
        price,
        delivery,
        images,
        quantity,
        viticulture,
        typeCave,
        coffret,
        pourcentage,
        cru,
        CRD,
      },
    });
  },

  putUpdateProduct: (
    idProduct,
    name,
    country_id,
    region_id,
    appelation,
    color_id,
    year,
    description,
    price,
    delivery,
    images,
    quantity,
    viticulture,
    typeCave,
    coffret,
    pourcentage,
    cru,
    CRD
  ) => {
    return instance({
      method: "put",
      url: "/api/products/" + idProduct,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        name,
        country_id,
        region_id,
        appelation_id: appelation,
        color_id,
        year,
        description,
        price,
        delivery,
        images,
        quantity,
        viticulture,
        typeCave,
        coffret,
        pourcentage,
        cru,
        CRD,
      },
    });
  },

  getColor: () => {
    return instance({
      method: "get",
      url: "/api/color/",
    });
  },

  getBoostModel: () => {
    return instance({
      method: "get",
      url: "/api/boostModel/?type=0",
    });
  },
  getVitrineModel: () => {
    return instance({
      method: "get",
      url: "/api/boostModel/?type=1",
    });
  },
  getArrayVitrine: () => {
    return instance({
      method: "get",
      url: "/api/boost/users",
    });
  },
  getDeliverMode: (id) => {
    return instance({
      method: "get",
      url: "/api/deliver/" + id,
    });
  },
  getImages: (id) => {
    return instance({
      method: "get",
      url: "/api/products/images/" + id,
    });
  },
  getCounter: (userId) => {
    return instance({
      method: "get",
      url: "/api/users/counter/" + userId,
    });
  },
  postColor: (name) => {
    return instance({
      method: "post",
      url: "/api/color/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        name,
      },
    });
  },
  askCodeRecovery: (email) => {
    return instance({
      method: "post",
      url: "/api/users/askCodePasswordRecovery/",
      data: {
        email,
      },
    });
  },

  checkCodePasswordRecovery: (code, password, email) => {
    return instance({
      method: "post",
      url: "/api/users/checkCodePasswordRecovery/",
      data: {
        code,
        password,
        email,
      },
    });
  },

  getConversations: (page, size) => {
    let params = "";
    if (page != null) {
      params = params + "?page=" + page;
    }
    if (size != null) {
      params = params + "&size=" + size;
    }
    return instance({
      method: "get",
      url: "/api/messages"+params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getWallets: () => {
    return instance({
      method: "get",
      url: "/api/users/wallet",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getConversation: (userId,productId, page, size) => {
    let params = "";
    if (page != null) {
      params = params + "?page=" + page;
    }
    if (size != null) {
      params = params + "&size=" + size;
    }
    if (productId != null) {
      params = params + "&product_id=" + productId;
    }
    return instance({
      method: "get",
      url: "/api/messages/" + userId + params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  readMessage: (userId) => {
    return instance({
      method: "put",
      url: "/api/messages/read/" + userId,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  putDeliveryMode: (id, deliv) => {
    return instance({
      method: "put",
      url: "/api/offer/offerAddDeliveryMode/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: id,
        deliv: deliv,
      },
    });
  },

  sendMessage: (userId, message,productId) => {
    return instance({
      method: "post",
      url: "/api/messages/" + userId,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        contain: message,
        product_id: productId,
      },
    });
  },

  getRatesById: (userId) => {
    return instance({
      method: "get",
      url: "/api/rates/" + userId,
    });
  },

  sendRate: ( userId, message, rate) => {
    return instance({
      method: "post",
      url: "/api/rates/" + userId,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        message,
        rate,
      },
    });
  },

  postOffer: (
    price,
    product_ids,
    address_delivery_id = null,
    address_billing_id = null,
    delivery_mode_id = null
  ) => {
    return instance({
      method: "post",
      url: "/api/offer/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        price,
        product_ids,
        address_delivery_id,
        address_billing_id,
        delivery_mode_id,
      },
    });
  },

  buyerCancel: ( idProduct) => {
    return instance({
      method: "put",
      url: "/api/offer/buyerCancel/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: idProduct,
      },
    });
  },

  sellerAccept: (idOffer) => {
    return instance({
      method: "put",
      url: "/api/offer/sellerAccept/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: idOffer,
      },
    });
  },
  sellerCancel: (idOffer) => {
    return instance({
      method: "put",
      url: "/api/offer/sellerCancel/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: idOffer,
      },
    });
  },
  payedOffer: (
    id,
    product_id,
    address_delivery_id,
    address_billing_id,
    delivery_mode_id
  ) => {
    return instance({
      method: "put",
      url: "/api/offer/payed/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id,
        product_id,
        address_delivery_id,
        address_billing_id,
        delivery_mode_id,
      },
    });
  },
  sendOrder: (idOffer, deliveryCode = null) => {
    return instance({
      method: "put",
      url: "/api/offer/sendOrder/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: idOffer,
        deliveryCode: deliveryCode,
      },
    });
  },
  receiveOrder: (idOffer) => {
    return instance({
      method: "put",
      url: "/api/offer/receiveOrder/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: idOffer,
      },
    });
  },
  problemOrder: (idOffer) => {
    return instance({
      method: "put",
      url: "/api/offer/problemOrder/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: idOffer,
      },
    });
  },
  refundOrder: (idOffer) => {
    return instance({
      method: "put",
      url: "/api/offer/refundOrder/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: idOffer,
      },
    });
  },
  rateOrder: (idOffer, message, rate) => {
    return instance({
      method: "put",
      url: "/api/offer/rateOrder/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: idOffer,
        message: message,
        rate: rate,
      },
    });
  },

  getOfferSelling: (page, size) => {
    let params = "";
    if (page != null) {
      params = params + "?page=" + page;
    }
    if (size != null) {
      params = params + "&size=" + size;
    }
    return instance({
      method: "get",
      url: "/api/offer/sell" + params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getOfferBuyer: (page, size) => {
    let params = "";
    if (page != null) {
      params = params + "?page=" + page;
    }
    if (size != null) {
      params = params + "&size=" + size;
    }
    return instance({
      method: "get",
      url: "/api/offer/buy" + params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getFAQ: () => {
    return instance({
      method: "get",
      url: "/api/faq/",
    });
  },
  getDeliveryModel: () => {
    return instance({
      method: "get",
      url: "/api/deliverModel/",
    });
  },

  postFAQ: (title, text) => {
    return instance({
      method: "post",
      url: "/api/faq/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        title,
        text,
      },
    });
  },
  postBoost: (products, boosterModelId, card_id) => {
    return instance({
      method: "post",
      url: "/api/boost/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        products,
        boosterModelId,
        card_id,
      },
    });
  },
  changePassword: (
      password,
      newPassword,
  ) => {
    return instance({
      method: "put",
      url: "/api/users/password",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        password,
        newPassword
      },
    });
  },
  putUpdateUser: (
    firstName,
    lastName,
    email,
    password,
    base64,
    number_siret,
    phone,
    description
  ) => {
    return instance({
      method: "put",
      url: "/api/users/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        firstName,
        lastName,
        email,
        password,
        base64,
        number_siret,
        phone,
        description,
      },
    });
  },

  putUpdateAddress: (
    idAddress,
    firstName,
    lastName,
    number,
    address,
    additional_address,
    postal_code,
    city,
    country
  ) => {
    return instance({
      method: "put",
      url: "/api/address/" + idAddress,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        firstName,
        lastName,
        number,
        address,
        additional_address,
        postal_code,
        city,
        country,
      },
    });
  },

  postAddress: (
    firstName,
    lastName,
    number,
    address,
    additional_address,
    postal_code,
    city,
    country,
    default_delivery,
    default_billing
  ) => {
    return instance({
      method: "post",
      url: "/api/address/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        firstName,
        lastName,
        number,
        address,
        additional_address,
        postal_code,
        city,
        country,
        default_delivery,
        default_billing,
      },
    });
  },

  getNotifications: () => {
    return instance({
      method: "get",
      url: "/api/notifications/",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  readNotifications: (token) => {
    return instance({
      method: "put",
      url: "/api/notifications/read/",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  addFollow: (userId) => {
    return instance({
      method: "post",
      url: "/api/users/follow/" + userId,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  unFollow: (userId) => {
    return instance({
      method: "delete",
      url: "/api/users/follow/" + userId,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getFollow: (id) => {
    return instance({
      method: "get",
      url: "/api/users/follower/" + id,
    });
  },
  getFollowed: (id) => {
    return instance({
      method: "get",
      url: "/api/users/followed/" + id,
    });
  },

  wasFollow: (userId) => {
    return instance({
      method: "get",
      url: "/api/users/follow/" + userId,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  addWish: (idProduct) => {
    return instance({
      method: "post",
      url: "/api/users/wish/" + idProduct,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  unWish: (idProduct) => {
    return instance({
      method: "delete",
      url: "/api/users/wish/" + idProduct,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  deleteProduct: (idProduct) => {
    return instance({
      method: "delete",
      url: "/api/products/" + idProduct,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getWish: (
    page,
    size,
    color_id = null,
    year = null,
    priceMin = null,
    priceMax = null,
    country_id = null,
    region_id = null,
    appelation_id = null
  ) => {
    let params = "";
    if (page != null) {
      params = "?page=" + page;
    }
    if (size != null) {
      params = params + "&size=" + size;
    }
    if (color_id != null) {
      params = params + "&color_id=" + color_id;
    }
    if (year != null) {
      params = params + "&year=" + year;
    }
    if (priceMin != null) {
      params = params + "&priceMin=" + priceMin;
    }
    if (priceMax != null) {
      params = params + "&priceMax=" + priceMax;
    }
    if (country_id != null) {
      params = params + "&country_id=" + country_id;
    }
    if (region_id != null) {
      params = params + "&region_id=" + region_id;
    }
    if (appelation_id != null) {
      params = params + "&appelation_id=" + appelation_id;
    }
    return instance({
      method: "get",
      url: "/api/users/wish/" + params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  wasWish: (idProduct) => {
    return instance({
      method: "get",
      url: "/api/users/wish/" + idProduct,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  postCountry: (name) => {
    return instance({
      method: "post",
      url: "/api/country/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        name,
      },
    });
  },

  postAppelation: (name) => {
    return instance({
      method: "post",
      url: "/api/appelation/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        name,
      },
    });
  },

  getAllAppelation: () => {
    return instance({
      method: "get",
      url: "/api/appelation/",
    });
  },

  postRegion: (name) => {
    return instance({
      method: "post",
      url: "/api/region/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        name,
      },
    });
  },
  getCountry: () => {
    return instance({
      method: "get",
      url: "/api/country/",
      //url: "https://restcountries.com/v3.1/all?fields=name,ccn3",
    });
  },
  getRegion: () => {
    return instance({
      method: "get",
      // url: "https://geo.api.gouv.fr/regions",
      url: "/api/region/",
    });
  },
  getTransaction: (id, cardId) => {
    return instance({
      method: "get",
      url: "/api/users/transaction/" + id + "?cardId=" + cardId,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCards: () => {
    return instance({
      method: "get",
      url: "/api/users/cards",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createCardsToken: () => {
    return instance({
      method: "post",
      url: "/api/users/createTokenCard",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateCardToken: ( data) => {
    return instance({
      method: "put",
      url: "/api/users/updateCardToken",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  homologation: (url, data) => {
    return instance({
      method: "post",
      url: url,
      data: data,
      headers: {
        "content-type": "application/x-www-form-urlencoded;",
      },
    });
  },
  postKyc: ( data) => {
    return instance({
      method: "post",
      url: "/api/users/kyc",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getKycs: () => {
    return instance({
      method: "get",
      url: "/api/users/kycs",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getIbans: () => {
    return instance({
      method: "get",
      url: "/api/users/ibans",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  postIban: (data) => {
    return instance({
      method: "post",
      url: "/api/users/iban",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  putLotReduction: (body) => {
    return instance({
      method: "put",
      url: "/api/users/setLotReduction",
      data: body,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
