import React, { useEffect, useState } from "react";
import HorizontalCard from "./../../../Components/HorizontalCard";
import OrderInfoModal from "../../../Components/Modal/OrderInfoModal"
import "./manage.css";
import {useInfiniteQuery} from "react-query";
import api from "../../../Services/api";
import {ProductCard} from "../../../Components";
function Manage() {
  const [isSale, setIsSale] = useState(true);
  const [rowsPerPageSelling, setRowsPerPageSelling] = useState(null);
  const [rowsPerPageBuying, setRowsPerPageBuying] = useState(null);
  const [timer, setTimer] = useState(null);
  const [selectedData,setSelectedData]=useState(null)
  useEffect(()=>{
    console.log(selectedData)
  },[selectedData])
  const {
    status:statusSelling,
    data:dataSelling,
    error:errorSelling,
    isFetching:isFetchingSelling,
    isFetchingNextPage:isFetchingNextPageSelling,
    isFetchingPreviousPage:isFetchingPreviousPageSelling,
    fetchNextPage:fetchNextPageSelling,
    fetchPreviousPage:fetchPreviousPageSelling,
    hasNextPage:hasNextPageSelling,
    hasPreviousPage:hasPreviousPageSelling,
  } = useInfiniteQuery(
      ['ventes',rowsPerPageBuying],
      async ({ pageParam = 0 }) => {
        const res =  await api.getOfferSelling(pageParam,rowsPerPageSelling)
        return res.data
      },
      {
        getPreviousPageParam: firstPage =>firstPage.previousId ?? undefined,
        getNextPageParam: lastPage => {
          let nextp=undefined
          if(lastPage.currentPage+1 < lastPage.totalPages){
            nextp= lastPage.currentPage+1
          }
          return nextp
        }
      }
  )
  const {
    status:statusBuying,
    data:dataBuying,
    error:errorBuying,
    isFetching:isFetchingBuying,
    isFetchingNextPage:isFetchingNextPageBuying,
    isFetchingPreviousPage:isFetchingPreviousPageBuying,
    fetchNextPage:fetchNextPageBuying,
    fetchPreviousPage:fetchPreviousPageBuying,
    hasNextPage:hasNextPageBuying,
    hasPreviousPage:hasPreviousPageBuying,
  } = useInfiniteQuery(
      ['achats',rowsPerPageBuying],
      async ({ pageParam = 0 }) => {
        const res =  await api.getOfferBuyer(pageParam,rowsPerPageBuying)
        return res.data
      },
      {
        getPreviousPageParam: firstPage =>firstPage.previousId ?? undefined,
        getNextPageParam: lastPage => {
          let nextp=undefined
          if(lastPage.currentPage+1 < lastPage.totalPages){
            nextp= lastPage.currentPage+1
          }
          return nextp
        }
      }
  )
  return (
    <div className="container">
      <div className="manage-tab">
        <h1
          className={`${isSale ? "active" : ""}`}
          onClick={() => setIsSale(true)}
        >
          Ventes
        </h1>
        <h1
          className={`${!isSale ? "active" : ""}`}
          onClick={() => setIsSale(false)}
        >
          Commandes
        </h1>
      </div>
      {isSale ? (
        <div className="manage-cards">
          {dataSelling?.pages[0]?.totalItems>0?(
              <>
                {dataSelling?.pages.map(page => (
                    <React.Fragment key={page.currentPage}>

                      {page?.data?.map((v, i) => {
                        return <HorizontalCard key={"sell"+i} data={v} purchase={false} setSelectedData={setSelectedData} />;
                      })}
                    </React.Fragment>
                ))}
              </> ):(
              <div style={{margin:"20px auto"}}>
                Pas de ventes
              </div>
          )}
          <div
              style={{
                margin:"0 auto",
                border:"1px solid",
                cursor:"pointer",
                marginTop: 10,
                width:250,
                //borderWidth: 1,
                alignSelf: "center",
                borderRadius: 8,
                paddingHorizontal: 24,
                paddingVertical: 8,
                borderColor: "#7E7D7D",
                marginBottom: 40,
                display:"flex",
                justifyContent:"center",
              }}
              onClick={()=> {
                console.log('la')
                fetchNextPageSelling()
              }}
          >
            <div
                style={{
                  fontSize: 16,
                  color: "#7E7D7D",
                  fontWeight: "bold",
                  display:"flex",
                  justifyContent:"center"
                }}
            >
              {isFetchingNextPageSelling
                  ? 'Chargement...'
                  : hasNextPageSelling
                      ? '+ Voir plus'
                      : 'Vous avez tous chargé'}

            </div>
          </div>
        </div>
      ) : (
          <div className="manage-cards">
            {dataBuying?.pages[0]?.totalItems>0?(
                <>
                  {dataBuying?.pages.map(page => (
                      <React.Fragment key={page.currentPage}>

                        {page?.data?.map((v, i) => {
                          return <HorizontalCard key={"sell"+i} data={v} setSelectedData={setSelectedData} purchase={true} />;
                        })}
                      </React.Fragment>
                  ))}
                </> ):(
                <div style={{margin:"20px auto"}}>
                  Pas d'achat
                </div>
            )}
            <div
                style={{
                  margin:"0 auto",
                  border:"1px solid",
                  cursor:"pointer",
                  marginTop: 10,
                  width:250,
                  //borderWidth: 1,
                  alignSelf: "center",
                  borderRadius: 8,
                  paddingHorizontal: 24,
                  paddingVertical: 8,
                  borderColor: "#7E7D7D",
                  marginBottom: 40,
                  display:"flex",
                  justifyContent:"center",
                }}
                onClick={()=> {
                  fetchNextPageBuying()
                }}
            >
              <div
                  style={{
                    fontSize: 16,
                    color: "#7E7D7D",
                    fontWeight: "bold",
                    display:"flex",
                    justifyContent:"center"
                  }}
              >
                {isFetchingNextPageBuying
                    ? 'Chargement...'
                    : hasNextPageBuying
                        ? '+ Voir plus'
                        : 'Vous avez tous chargé'}

              </div>
            </div>
          </div>
      )}
      {selectedData!=null?(
          <OrderInfoModal data={selectedData} setSelectedData={setSelectedData} purchase={!isSale}/>
      ):null}
    </div>
  );
}

export default Manage;
