import React, { useEffect, useState,useRef } from "react";
import Profile from "../../../Assets/defaultAvatar.png";
import FeedBackModal from "../../../Components/Modal/FeedBackModal";
import PayedModal from "../../../Components/Modal/PayedModal";
import SendModal from "../../../Components/Modal/SendModal";
import CancelModal from "../../../Components/Modal/CancelModal";
import AcceptModal from "../../../Components/Modal/AcceptModal";
import ProblemModal from "../../../Components/Modal/ProblemModal";
// import GeneralModal from "../../../Components/Modal/GeneralModal";
import RefuseModal from "../../../Components/Modal/RefuseModal";
import ReviewModal from "../../../Components/Modal/ReviewModal";
// import SubmitModal from "../../../Components/Modal/SubmitModal";
import CameraIcon from "../../../Assets/camera-icon.png";
import ArrowRightIcon from "../../../Assets/arrow-right.png";

import "./style.css";
import {useInfiniteQuery,useMutation,QueryClient} from "react-query";
import api from "../../../Services/api";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../../Services/auth";
import {ProductCard} from "../../../Components";
import { useInView } from 'react-intersection-observer'
import {UrlBaseServer} from "../../../Services/storageServer";
import ProfileImg from "../../../Assets/defaultAvatar.png";
import moment from "moment";
import LotChooseModal from "../../../Components/Modal/lotChooseModal";
import {formatPrice} from "../../../Services/tools";

const Message = () => {
  const { ref, inView } = useInView()
  const inputFile = useRef(null)
  const params = useLocation();
  const nameuri =params.pathname.split("/").pop()
  const {user} = useAuth()
  const [offerModal, setOfferModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [refuseModal, setRefusModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [voir, setVoir] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [userD, setUserD] = useState(null);
  const [mess, setMess] = useState("");
  const [productId, setProductId] = useState(null);
  const queryClient = new QueryClient();
  const [products, setProducts] = useState([]);
  const [showLotModal, setShowLotModal] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  useEffect(async ()=>{
    if(params?.state?.products){
      //console.log(params.state.products)
      setProducts(params.state.products)
      setVoir(true)
    }
    let newNameUri=nameuri.split("-")
    setProductId(newNameUri[1])
    let prod = await api.getProductById(newNameUri[1])
    if(prod){
      setProducts([prod.data])
    }
    var result = await api.getUserById(newNameUri[0]);
    if(result){
      setUserD(result.data)
    }
  },[])

  function returnOnePrice() {
    let classicPrice = 0;
    for (const item of products) {
      classicPrice = classicPrice + item.price;
    }
    let nbSelected = products.length;
    let discountToApply = 0;

    //for (const item2 of discountAvalaible) {
    //  if (nbSelected >= item2.nbItem) {
    //    discountToApply = item2.discount;
    //  }
   // }
    //if (discountToApply > 0) {
    //  let discountPrice = classicPrice - classicPrice * (discountToApply / 100);
    //  return formatPrice(discountPrice) + " €";
    //} else {
      return formatPrice(classicPrice) + " €";
    //}
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     //setOfferModal(true);
  //   }, 3000);
  // }, []);

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery(
      'chat_'+nameuri+"_"+user.id,
      async ({ pageParam = 0 }) => {

        const res =  await api.getConversation(userD?.id,productId,pageParam,rowsPerPage )
        return res.data
      },
      {
        getPreviousPageParam: firstPage =>firstPage.previousId ?? undefined,
        getNextPageParam: lastPage => {
          let nextp=undefined
          if(lastPage.currentPage+1 < lastPage.totalPages){
            nextp= lastPage.currentPage+1
          }
          return nextp
        },
        refetchInterval:1000
      }
  )

  const updateMutation = useMutation(value => api.sendMessage(userD.id,value,productId), {
    onSuccess: () => queryClient.invalidateQueries('chat_'+nameuri+"_"+user.id),
  })

  const sendMessage=(data)=>{
      updateMutation.mutate(data, {
        onSuccess: () => {
          setMess('')
          //setSelectedData(null)
          // setOpenDelete(false)      },
        }
      })
      // setCurrentId(null)
      // setOpenDelete(false)

  }

  function returnUri(dataMessage) {
    const message = dataMessage;
    if (message) {
      if (message.includes("[IMG]") && message.includes("[/IMG]")) {
        const urlImg = message.substring(
            message.indexOf("[IMG]") + 5,
            message.lastIndexOf("[/IMG]")
        );

        return "data:image/png;base64," + urlImg;
      } else {
        return null;
      }
    }
  }
  function uploadImage(files){
    getBase64(files[0],(result) => {
      let b64=result.split("base64,")
      sendMessage("[IMG]" + b64[1] + "[/IMG]")
          //api
          //.sendMessage(
          //    userD.id,
          //    "[IMG]" + b64[1] + "[/IMG]"
         // )
    })
  }
  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  function formatDate(){
    let addDate= new Date(products[0]?.createdAt);
    return moment(addDate).format("DD/MM/YYYY")
  }
  function myOffer(valueOffre) {
    let ids = [];
    for (const item of products) {
      ids.push(item.id);
    }
    if (!valueOffre?.trim()) {
      return alert("Vous devez indiquer une offre");
    }
    if (valueOffre.includes(",") && valueOffre.split(",")[1].length > 2) {
      return alert("Votre offre n'a pas le format conforme");
    }

    api
        .postOffer(valueOffre.replace(",", "."), ids)
        .then((data) => {
          console.log('la')
          setShowLotModal(false)
        })
        .catch((error) => {
          alert(
              "Une erreur est survenue, merci de véfier le format de votre offre et réessayer"
          );
        });
  }

  //api.getConversation(user.token, userChat.id, page, 20)

  // status 1: attente de paiement
  // status 2: attente de validation acheteur
  // status 3: annuler par l'acheteur
  // status 4: refusé par le vendeur
  // status 5: Payé
  // status 6: Envoyé
  // status 7: Recus

  return (
    <div className="container mt-5 FeedBackBox">
      <div className="row">
        <div className="col-sm-12 col-md-8 col-lg-8">
          <div className="messageParent">
            <section id="inputBox">
              <img style={{cursor:"pointer"}}  onClick={()=>{
                inputFile.current.click();
              }} src={CameraIcon} alt="" width="25px" />

              <div id="setWidth">
                <input
                    type="text"
                    id="msgInput"
                    value={mess}
                    onChange={(e)=>{setMess(e.target.value)}}
                    placeholder="Envoyer un Message"
                />
                <img
                    onClick={()=>{
                      sendMessage(mess)
                    }}
                    src={ArrowRightIcon}
                    alt=""
                    style={{ minWidth: "25px", maxHeight: "35px", cursor:"pointer" }}
                />
                <input onChange={e => {
                  uploadImage(e.target.files)
                }} accept="image/*"  type="file" id="file" ref={inputFile} style={{display:"none"}} />
              </div>
            </section>
            <main style={{width:"100%"}} className="mainBoxMessage p-3">
              {data?.pages.map(page => (
                  <React.Fragment key={page.currentPage+"page"}>
                    {page?.data?.map((v, i) => {
                      return (
                          <React.Fragment key={"message"+i}>
                          {v?.sender_id == user.id && v.Offer==null?(
                            <section className="messageBox mb-3">
                                <div className="message">
                                  {returnUri(v.contain)?(
                                    <img style={{width:"100%"}} src={returnUri(v.contain)}/>
                                  ):(
                                      <p style={{ backgroundColor: "transparent", color: "white" }}>
                                        {v.contain}
                                      </p>
                                  )}
                                </div>
                                <div className="messageImg">
                                  {user?.avatar?(
                                      <img  src={UrlBaseServer+user?.avatar} alt="" />
                                  ):(
                                      <img  src={ProfileImg} alt="" />
                                  )}
                                </div>
                            </section>
                          ):v?.sender_id != user.id && v.Offer==null?(
                              <section >
                            <div className="messageBox2 mb-3">
                              <div className="messageImg2">
                                {" "}
                                {userD?.avatar?(
                                    <img  src={UrlBaseServer+userD?.avatar} alt="" />
                                ):(
                                    <img  src={ProfileImg} alt="" />
                                )}
                              </div>
                              <div className="message2">
                                {" "}
                                {returnUri(v.contain)?(
                                    <img style={{width:"100%"}} src={returnUri(v.contain)}/>
                                ):(
                                    <p >
                                      {v.contain}
                                    </p>
                                )}
                              </div>
                            </div>
                          </section>
                          ):v.offerStatus == 1?(
                              <AcceptModal
                                  data={v}
                                  user={user}
                                  otherUser={userD}
                                  key={i+"message"}
                              />
                          ):v.offerStatus == 2?(
                              <FeedBackModal
                                  data={v}
                                  user={user}
                                  otherUser={userD}
                                  key={i+"message"}
                              />
                              ):v.offerStatus == 3?(
                            <RefuseModal
                                data={v}
                                user={user}
                                otherUser={userD}
                                key={i+"message"}
                            />
                          ):v.offerStatus == 4?(
                              <CancelModal
                                  data={v}
                                  user={user}
                                  otherUser={userD}
                                  key={i+"message"}
                              />
                          ):v.offerStatus == 5?(
                             <PayedModal data={v}
                                         user={user}
                                         key={i+"message"}
                                         otherUser={userD}/>
                          ):v.offerStatus == 6?(
                              <SendModal data={v}
                                          user={user}
                                         key={i+"message"}
                                          otherUser={userD}/>
                          ):v.offerStatus == 7?(
                              <ReviewModal data={v}
                                         user={user}
                                           key={i+"message"}
                                         otherUser={userD}/>
                          ):v.offerStatus == 10?(
                              <ProblemModal data={v} user={user} key={i+"message"} otherUser={userD}/>):  (<div>
                            <section >
                              <div className="messageBox2 mb-3">
                                <div className="messageImg2">
                                  {" "}
                                  <img src={Profile} alt="" />
                                </div>
                                <div className="message2">
                                  {" "}
                                  <p>
                                    {v.offerStatus}
                                  </p>
                                </div>
                              </div>
                            </section>
                          </div>)}</React.Fragment>
                      );
                    })}
                  </React.Fragment>
              ))}
              <div ref={ref}
              ></div>
            </main>
            {/* THIS modal will popup above message textbox  */}
            {offerModal && !refuseModal && !reviewModal && (
              <FeedBackModal
                setRefusModal={setRefusModal}
                setReviewModal={setReviewModal}
              />
            )}
            {refuseModal && <RefuseModal setRefusModal={setRefusModal} />}
            {reviewModal && (
              <ReviewModal
                setRefusModal={setRefusModal}
                setReviewModal={setReviewModal}
                setOfferModal={setOfferModal}
              />
            )}
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="ml-3 sideParent ">
            <section>
              <div className="sideBox mb-5">
                <h5>{products[0]?.name} {products.length>1?(<span>+{products.length-1}</span>):null}</h5>
                {products.length==1?(<p>{products[0].price} &euro;</p>):(null)}
                {!voir && (
                  <div className="d-flex">
                    <button
                        style={{cursor:"pointer"}}
                      onClick={() => {
                        navigate("/product-detail/"+products[0]?.id,
                            {
                              state: { product: products[0] },
                            }
                        )

                      }}
                      className="red-btn col-6"
                    >
                      Voir
                    </button>
                    <div className="col-6" />
                  </div>
                )}
                {voir && (
                  <div className="d-flex gap-2">
                    {products.length==1?(
                        <button style={{cursor:"pointer"}} onClick={()=>{
                          navigate("/checkout/pr"+products[0]?.id,
                              {
                                state: { products: products },
                              }
                          )
                        }}  className="red-btn col-6">Acheter</button>
                    ):null}
                    <button
                        style={{cursoir:"pointer"}}
                      onClick={()=>{
                        setShowLotModal(true)
                      }}
                      className="red-btn-outline col-6"
                    >
                      Faire une offre
                    </button>
                  </div>
                )}

                {voir && (
                  <div className="options" style={{ marginTop: 20 }}>
                    <div className="product-detail">
                      Envoie a la charge de l'acheteur:
                    </div>
                    {products[0]?.Delivery_modes?.map((v, i) => {
                      return (
                          <div key={"delivery"+i} className="product-detail">
                            - {v?.Delivery_mode_model?(<span>{v?.Delivery_mode_model?.name}</span>):(<span>{v?.name}</span>)}
                            <span style={{marginLeft:10}}>{v?.price} &euro;</span>
                          </div>
                      );
                    })}
                    <p style={{ marginTop: 20 }}>
                      Ajouté le: <b>{formatDate()}</b>
                    </p>
                    <p>
                      par{" "}
                      <b
                          onClick={()=>{
                            navigate("/user-profile/"+products[0]?.User.id,{
                              state: { user: products[0]?.User },
                            })
                          }}
                        style={{
                          color: "#6b0012",
                          backgroundColor: "transparent",
                          cursor:"pointer"
                        }}
                      >
                        {userD?.lastName+ " "+ userD?.firstName}
                      </b>
                    </p>
                  </div>
                )}
                {/* {submitModal && (
                  <SubmitModal
                    submitModal={submitModal}
                    setSubmitModal={setSubmitModal}
                  />
                )}{" "} */}
              </div>
            </section>
            {/*<div className="sidebox2">*/}
            {/*  <section className="sideCont">*/}
            {/*    <i className="fa-solid fa-user"></i>*/}
            {/*    <p>Blo1uer</p>*/}
            {/*  </section>*/}
            {/*  <section className="sideCont2">*/}
            {/*    <i className="fa-solid fa-envelope"></i>*/}
            {/*    <p>Supprimeer la conversation</p>*/}
            {/*  </section>*/}
            {/*  <section className="sideCont2">*/}
            {/*    <i className="fa-solid fa-user"></i>*/}
            {/*    <p>Supprimeer la conversation</p>*/}
            {/*  </section>*/}
            {/*  <section className="sideCont">*/}
            {/*    <i className="fa-solid fa-user"></i>*/}
            {/*    <p>Blo1uer</p>*/}
            {/*  </section>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <LotChooseModal  setShowLotModal={setShowLotModal} showLotModal={showLotModal} price={returnOnePrice()} doOffer={null} myOffer={myOffer} skip={true} />
    </div>
  );
};

export default Message;
