import React, { useState,useEffect } from "react";
import "./style.css";
import ReactStars from "react-rating-stars-component";
import api from "../../Services/api"
import {useNavigate} from "react-router-dom";

export default function     ReviewModal({ user, data, otherUser }) {
  const [review, setReview] = useState(false);
  const [reviewText, setReviewText] = useState(null);
  const [rate, setRate] = useState(null);
    const navigate = useNavigate();

    const ratingChanged = (newRating) => {
      setRate(newRating)
  };
  return (
    <div
      className="card_modal"
      style={{
        padding: 20,
      }}
    >
        {!review ? (
        <>
        {data.Offer.user_id != user.id ? (
            <>
                <p
                    style={{
                        background: "none",
                        marginBottom: 4,
                        fontSize: 12,
                    }}
                >
                    {otherUser?.firstName} {otherUser?.lastName} à reçu votre commande
                </p>
                {!data.Offer.rateSeller ? (
                        <input
                            type="button"
                            onClick={() => setReview(true)}
                            className="btn btn-outline-danger btn-modal mx-4 mb-2"
                            value="Laisser une évaluation"
                            style={{
                                background: "#6a0013",
                                border: "none",
                                color: "white",
                                marginTop: 20,
                                fontSize: 12,
                                fontWeight: 300,
                                padding: 10,
                            }}
                        />
                ) : (
                    <p
                        style={{
                            background: "none",
                            marginBottom: 4,
                            fontSize: 12,
                        }}
                    >
                        Merci pour votre évaluation
                    </p>
                )}
            </>
        ):(
            <>
                <p
                    style={{
                        background: "none",
                        marginBottom: 4,
                        fontSize: 12,
                    }}
                >
                    Vous avez reçu votre commande
                </p>
                {!data.Offer.rateBuyer ? (
                    <input
                        type="button"
                        onClick={() => setReview(true)}
                        className="btn btn-outline-danger btn-modal mx-4 mb-2"
                        value="Laisser une évaluation"
                        style={{
                            background: "#6a0013",
                            border: "none",
                            color: "white",
                            marginTop: 20,
                            fontSize: 12,
                            fontWeight: 300,
                            padding: 10,
                        }}
                    />
                ) : (
                    <p
                        style={{
                            background: "none",
                            marginBottom: 4,
                            fontSize: 12,
                        }}
                    >
                        Merci pour votre évaluation
                    </p>
                )}
            </>
        )}
        </>
            ):(
            <>
                <p
                    style={{
                        background: "none",
                        marginBottom: 4,
                        fontSize: 12,
                    }}
                >
                    Laisser un avis sur
                </p>
                <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={27}
                    activeColor="#ffd700"
                    color="#e5e5e5"
                />
                <div className="search">
                    {/*<i className="fa-solid fa-face-grin"></i>*/}
                    <textarea onChange={(event)=>setReviewText(event.target.value)} value={reviewText} placeholder="Message …." />
                </div>
                <input
                    type="button"
                    className="btn btn-outline-danger btn-modal mx-4 mb-2"
                    onClick={() => {
                        if (rate && reviewText) {
                            api.rateOrder(data.Offer.id, reviewText, rate).then(() => {
                                //setRateStatus(false);
                                navigate("/opinion/"+otherUser.id)
                            });
                        }
                        //setReviewModal(false);
                        //setOfferModal(false)
                    }}
                    value="Laisser une évaluation"
                    style={{
                        background: "#6a0013",
                        border: "none",
                        color: "white",
                        marginTop: 20,
                        fontSize: 12,
                        fontWeight: 300,
                        padding: 8,
                        width: 220,
                    }}
                />
            </>
        )}
    </div>
  );
}
