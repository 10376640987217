import React, {useEffect, useState} from "react";
import ProfileImg from "./../../../Assets/defaultAvatar.png";
import IconVitrine from "./../../../Assets/roomTab.png";
import "./profile.css";
import {useAuth} from "../../../Services/auth";
import api from '../../../Services/api'
import {UrlBaseServer} from "../../../Services/storageServer";
import {useNavigate} from "react-router-dom";
import BoostVitrineModal from "../../../Components/Modal/BoostVitrineModal";

function Profile({userD}) {
  const navigate = useNavigate();
  const {user} = useAuth()
  const [averageRate, setAverageRate] = useState(null);
  const [followerCount, setFollowerCount] = useState(null);
  const [followedCount, setFollowedCount] = useState(null);
  const [address, setAddress] = useState(null);
  const [wasFollow, setWasFollow] = useState(false);
  const [showBoostModal, setShowBoostModal] = useState(false);

  useEffect(()=>{
    checkDataUser()
  },[userD])

  const checkDataUser = () => {
    if (userD) {
      api.getCounter(userD.id).then((data) => {
        setAverageRate(data.data.countRate);
        setFollowerCount(data.data.countFollower);
        setFollowedCount(data.data.countFollowed);
        if(data.data.user.Addresses[0]){
          setAddress(data.data.user.Addresses[0])
        }
      });
      if (user) {
        api.wasFollow(userD.id).then((data) => {
          setWasFollow(data.data.followed);
        });
      }
    }
  };
  return (
    <div className="container">
      <div className="profile-card d-flex flex-lg-row flex-column  flex-nowrap">
        <div style={{display:"flex", alignItems:"center"}} className="col-12  col-md-2 col-lg-2 m-0">
          {userD?.avatar?(
              <img  src={UrlBaseServer+userD?.avatar} alt="" className="user-profile" />
          ):(
              <img  src={ProfileImg} alt="" className="user-profile" />
          )}
        </div>
        <div className="col-12  col-md-7 col-lg-7">
          <br />

          <div>
            <h3 className="name">{userD?.firstName} {userD?.lastName}</h3>
            {averageRate?(
                <div style={{cursor:"pointer"}} onClick={()=>{
                  navigate("/opinion/"+userD.id,{
                    state: { user: userD },
                  })
                }}><i className="fa-solid fa-star"></i> {Math.round(averageRate * 10) / 10}</div>
            ):            <p className="description">pas encore d’évaluation</p>}
          </div>
          <br />
          <div>
            <p className="location"> {address ? address?.city + ", "+address?.country : ""}</p>
            <p style={{cursor:"pointer"}} onClick={()=>{
              navigate("/subscription/"+userD.id,{
                state: { user: userD },
              })
            }}>
              <span className="info">{followedCount}</span>{" "}
              <span className="info-value"> Abonnés,</span>
              <span className="info"> {followerCount}</span>{" "}
              <span className="info-value"> Abonnements</span>
            </p>
          </div>
        </div>
        <div className="col-12  col-md-3 col-lg-3 d-flex flex-col profile-btn-box">
          {
            user?.id == userD?.id?(
                <button onClick={()=>{
                  navigate("/edit-profile")
                }} className="btn btn-danger outline-purple-btn"><i className="fa-solid fa-pen-to-square" style={{marginRight:5}}/> Modifier mon profil</button>
            ):(
                <button onClick={()=>{
                  if (wasFollow) {
                    api.unFollow(userD.id).then(() => {
                      checkDataUser();
                    });
                  } else {
                    api.addFollow(userD.id).then(() => {
                      checkDataUser();
                    });
                  }
                }} className={wasFollow?("btn btn-outline-danger purple-btn-full"):("btn btn-outline-danger purple-btn")}>{wasFollow?("Ne plus suivre"):("Suivre")}</button>
            )
          }
        </div>
      </div>
      {
        user?.id == userD?.id?(
            <>
      <div onClick={()=>{
        setShowBoostModal(true)
      }} style={{marginTop:5, cursor:"pointer"}} className="profile-card d-flex align-items-center text-center flex-column">
        <div className="d-flex align-items-center justify-content-center" style={{width:50, height:50, backgroundColor:"#6b0019", borderRadius:30}}>
          <img style={{filter: "invert(100%) sepia(6%) saturate(0%) hue-rotate(70deg) brightness(108%) contrast(108%)"}} width={30} src={IconVitrine}/>
        </div>
        <div>
          <h5>Mettre ma Cave en avant</h5>
          <span>Ta cave sera mise en avant parmi les membres</span>
        </div>
      </div>
              {user?(<BoostVitrineModal setShowModalBoost={setShowBoostModal} showModalBoost={showBoostModal}/>):null}

      </>):null}
    </div>
  );
}

export default Profile;
