import React from "react";
import "./style.css";
import api from "../../Services/api"
import {useNavigate} from "react-router-dom";

export default function AcceptModal({user, data, otherUser}) {
  const navigate = useNavigate();

  return (
    <div className="card_modal">
        {data.Offer.user_id != user.id ? (
        <>

            <p
                style={{
                    background: "none",
                    marginBottom: 4,
                }}
            >
                Vous avez accepté l'offre
            </p>
            <h3
                style={{
                    color: "#6a0013",
                }}
            >
                {data.Offer.price} € ttc
            </h3>
        </>
        ) :  (
        <>

            <p
                style={{
                    background: "none",
                    marginBottom: 4,
                }}
            >
                Votre offre a été accepté
            </p>
            <h3
                style={{
                    color: "#6a0013",
                }}
            >
                {data.Offer.price} € ttc
            </h3>
            {data.offerStatus == data.Offer.status &&
            data.Offer.OfferProduct[0].status == 1 ? (
                    <input
                        type="button"
                        className="btn btn-outline-danger btn-modal mx-4 mb-2"
                        value="Payer"
                        style={{
                            background: "#6a0013",
                            border: "none",
                            color: "white",
                        }}
                        onClick={()=> {

                          navigate("/checkout/off"+data.Offer?.id,
                              {
                                state: { products: data.Offer.OfferProduct,offer: data.Offer },
                              }
                          )
                            //Navigation vers paiement
                            // navigation.navigate("PaiementArticle", {
                            //     data: data.Offer.OfferProduct,
                            //     offer: data.Offer,
                            //     fromPage: "Chat",
                            // });
                        }}
                    />
            ) : data.offerStatus == data.Offer.status ? (
                <p
                    style={{
                        background: "none",
                        marginBottom: 4,
                    }}
                >
                    Le produit n'est plus disponible
                </p>
            ) : (
                <p
                    style={{
                        background: "none",
                        marginBottom: 4,
                    }}
                >
                    Vous avez déjà payé
                </p>
            )}
        </>
        )}
    </div>
  );
}
