import React from "react";
import "./style.css";

export default function ProblemModal({user, data, otherUser}) {
  return (
    <div className="card_modal"
        style={{
            padding: 40
        }}
    >
        {data.Offer.user_id != user.id?(
            <div>
      <p
        style={{
          background: "none",
          marginBottom: 4,
        }}
      >
        {otherUser?.firstName} {otherUser?.lastName} a signalez un problème avec la commande
      </p>
            </div>
        ):(
            <p
                style={{
                    background: "none",
                    marginBottom: 4,
                }}
            >
                Vous avez signalez un problème avec la commande
            </p>
        )}
    </div>
  );
}
