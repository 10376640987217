import "./footer.css";
import {Link, useNavigate} from "react-router-dom";
import dispoApple from "../../Assets/dispoapple.png";
import dispoAndroid from "../../Assets/dispogoogleplay.png";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div >
      <footer className="footerBox">
        <div className="footerText">
          <p>Copyright © 2022 Vignitus.com</p>
        </div>
        <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
          <h5>Télécharger l'application:</h5>
          <div style={{display:"flex",width:'50vh',justifyContent:"space-around"}}>
            <div><img style={{cursor:"pointer"}} width="173" height="60" onClick={() => {
              window.open("https://apps.apple.com/fr/app/id1615187376", "_blank")
            }} src={dispoApple}/></div>
            <div><img style={{cursor:"pointer"}} width="173" height="60" onClick={() => {
              window.open("https://apps.apple.com/fr/app/id1615187376", "_blank")
            }} src={dispoAndroid}/></div>
          </div>
        </div>
        <div style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
          <h5>Suivez nous:</h5>
          <div style={{display:"flex",width:'50vh',justifyContent:"space-around"}}>
            <div><i onClick={() => {
              window.open("https://www.facebook.com/vignitus", "_blank")
            }} style={{fontSize:50, cursor:"pointer", color:"#3b5998"}} className="fa-brands fa-facebook"></i></div>
            <div><i onClick={() => {
              window.open("https://www.instagram.com/vignitus_app/", "_blank")
            }} style={{fontSize:50, cursor:"pointer", color:"#C32AA3"}} className="fa-brands fa-instagram-square"></i></div>
          </div>
        </div>
        <div className="footerText">
          <p>
            <span className="cgv-border"><Link to="/condition-general-utilisation">Mention Legales</Link></span>
            <span className="cgv-border"><Link to="/condition-general-utilisation">CGU</Link></span>
            <span className="cgv-border"><Link to="/condition-general-vente">CGV</Link></span>
            <span><Link to="/contact">Contact</Link></span>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
