import "./navbar.css";
import Logo from "../../Assets/logo.PNG";
import Reac,{useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../Services/auth"

const Navbar = ({searchData=""}) => {

  const {user,logout} = useAuth()
  const navigate = useNavigate();

  const [search,setSearch]=useState(searchData)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
      console.log('do validate')
    }
  }

  const handleSearch=()=>{
    navigate("/product-list",{
      state: {search},
    })
  }

  return (
    <div className="navParent ">
      <nav className="navBar">
        <ul>
          <li><Link to="/">Acheter du vin</Link></li>
          <li><Link to="/user-profile">Vendre du vin</Link></li>
          <li><Link to="/faq">FAQ</Link></li>
        </ul>
        <ul>
          {user?(
          <li>
             <span style={{cursor:"pointer",    textDecoration: "none",
               color: "#000",
               margin: "0 20px",
               fontSize: "1rem",
               fontFamily: "LatoBold" }} className="dropdown-toggle" data-toggle="dropdown"><i className="fa-solid fa-user"></i>Mon compte</span>
            <div className="dropdown-menu">
              <Link className="nav-link " to="/manage-sales">
               Mes ventes et commandes
              </Link>
              <Link className="nav-link"  to="/reduction">
                Mes réductions
              </Link>
              <Link className="nav-link" to={"/opinion/"+user.id}>
                Mes évaluations
              </Link>
              <Link className="nav-link" to="/porte-monnaie">
                Mon porte-monnaie
              </Link>
              <Link className="nav-link" to="/edit-profile">
                Mon profil
              </Link>
              <div className="dropdown-divider"></div>
              <div onClick={()=>{
                logout()
              }} style={{textAlign:"center", cursor:"pointer", textDecoration: "none",
                color: "#000",
                margin: "0 20px",
                fontSize: "1rem",
                fontFamily: "LatoBold"}}>Se déconnecter</div>
            </div>
          </li>):(
              <li>
                <Link to="/login">
                  <i className="fa-solid fa-user"></i>Connexion
                </Link>
              </li>
          )}
          {user?(

                  <li>
            <Link to="/messaging">
              <i className="fa-solid fa-envelope"></i>Mes messages
            </Link>
          </li>):null}
          {user?(

                  <li>
                    <Link to="/favoris">
                    <i className="fa-solid fa-heart"></i>Favoris
                    </Link>
          </li>):null}
        </ul>
      </nav>
      <section className="bottomNav mt-3">
        <div className="logoBox">
          <img src={Logo} alt="" />
        </div>

        <div className="input-group mb-3 inputBox">
          <input
            type="text"
            className="form-control"
            placeholder="Rechercher parmi 120 320 bouteilles"
            aria-label="Rechercher parmi 120 320 bouteilles"
            aria-describedby="basic-addon2"
            value={search}
            name="search"
            onKeyDown={handleKeyDown}
            onChange={e => setSearch(e.target.value)}
          />
          <div className="input-group-append">
            <button onClick={()=>{
              handleSearch()
            }} className="btn btn-outline-secondary" type="button">
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Navbar;
