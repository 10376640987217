import React,{useState,useRef} from "react";
import "./style.css";
import {useNavigate} from "react-router-dom";
import {Modal, Button} from "react-bootstrap"
import {UrlBaseServer} from "../../../Services/storageServer";
import {useAuth} from "../../../Services/auth";
import {useQuery} from "react-query";
import api from "../../../Services/api"
import visaCard from "./../../../Assets/visaLogo.png"

import data from "bootstrap/js/src/dom/data";
const Index = () => {

    const {user} = useAuth()
    const { isLoading,
        isError,
        error,
        data:dataFAQ,
        isFetching,
        isPreviousData, } = useQuery(['repoFaq'], ()=> api.getFAQ(),{ keepPreviousData : true }
    )
    console.log(dataFAQ?.data)
    return (
        <div className="faqPage" id="accordion">
        {dataFAQ?.data?.map((v, i) => {
            return(
                <div key={"faq"+i} className="card">
                    <div className="card-header" id={"heading"+i}>
                        <h5 className="mb-0">
                            <button className="btn btn-link" data-toggle="collapse" data-target={"#collapse"+i}
                                    aria-expanded="false" aria-controls={"collapse"+i}>
                                {v.title}
                            </button>
                        </h5>
                    </div>

                    <div id={"collapse"+i} className={`collapse ${i==0 ? 'show' : ''}`} aria-labelledby={"heading"+i}
                         data-parent="#accordion">
                        <div className="card-body">
                            {v.text}

                        </div>
                    </div>
                </div>
            )
        })}
    </div>
  );
};
export default Index;
