import React from "react";
import { Navbar, Footer } from "./../../Components";
import Banner from "./../../Containers/ProductList/Banner";
import Resutls from "./../../Containers/ProductList/Resutls";
import {useLocation} from "react-router-dom";
import {useInfiniteQuery} from "react-query";
import api from "../../Services/api";

function ProductList() {
    const params = useLocation();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [color, setColor] = React.useState(null);
    const [year, setYear] = React.useState(null);
    const [region, setRegion] = React.useState(null);
    const [priceMin, setPriceMin] = React.useState(null);
    const [priceMax, setPriceMax] = React.useState(null);


    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        isFetchingPreviousPage,
        fetchNextPage,
        fetchPreviousPage,
        hasNextPage,
        hasPreviousPage,
    } = useInfiniteQuery(
        ['searchProducts',params.state.search,color, year, priceMin, priceMax, region],
        async ({ pageParam = 0 }) => {
            const res =  await api.getProducts(pageParam,rowsPerPage, params.state.search,color, year,priceMin,priceMax,null,region,null)
            return res.data
        },
        {
            getPreviousPageParam: firstPage =>firstPage.previousId ?? undefined,
            getNextPageParam: lastPage => {
                let nextp=undefined
                if(lastPage.currentPage+1 < lastPage.totalPages){
                    nextp= lastPage.currentPage+1
                }
                return nextp
            }
        }
    )
    return (
    <div>
      <Navbar searchData={params.state.search} />
      <div className="content-wraper">
        <Banner />
        <Resutls data={data} fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} hasNextPage={hasNextPage} color={color} setColor={setColor} setRegion={setRegion} region={region} year={year} setYear={setYear} priceMin={priceMin} priceMax={priceMax} setPrixMin={setPriceMin} setPrixMax={setPriceMax}  />
      </div>
      <Footer />
    </div>
  );
}

export default ProductList;
