import React, { useState,useEffect } from 'react'

import Navbar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer/index";
import RelatedProducts from "./../../Containers/UserProfile/RelatedProducts";
import Profile from "./../../Containers/UserProfile/Profile";
import { useLocation } from "react-router-dom";
import api from '../../Services/api'
import {useAuth} from "../../Services/auth";
import {useQuery} from "react-query";
import {isMobile} from "react-device-detect";

const UserProfile = () => {
    const params = useLocation();
    let [userD, setUserD]=useState(null)
    const [discountAvalaible, setDiscountAvalaible] = useState([]);
    const [userProducts, setUserProducts] = useState(null);
    const {user} = useAuth()

    const nameuri =params.pathname.split("/").pop()
    useEffect(async ()=>{
        if (params.state?.user && params.state) {
            setUserD(params.state?.user)
        }else if(nameuri=="user-profile"){
            console.log(user)
            setUserD(user)
        }else{
            var result = await api.getUserById(nameuri);
            if(result){
                setUserD(result.data)
            }
        }
    },[])

    useEffect(() => {
        if (userD) {
            //checkDataUser();
            loadProducts();
            if (userD.lotReduction) {
                setDiscountAvalaible([
                    { nbItem: 2, discount: userD.lotReduction2 },
                    { nbItem: 3, discount: userD.lotReduction3 },
                    { nbItem: 5, discount: userD.lotReduction5 },
                ]);
            }
            if(isMobile){
                window.location.replace("vignitus://profilPublic/"+userD.id);
            }
        }
    }, [userD]);
    async function loadProducts(){
        api.getProductsFromUser(userD.id).then((data) => {
            setUserProducts(data.data.data);
        });
    }
    return (
    <div>
      <Navbar />
      <div className="content-wraper">
        <Profile userD={userD} />
        <RelatedProducts userD={userD} products={userProducts} reloadProducts={loadProducts} />
      </div>
      <Footer />
    </div>
  );
};

export default UserProfile;
