import React,{useState,useEffect} from "react";
import "./style.css";
import GrandWine from "../../../Assets/grandWine.jpg";
import label from "../../../Assets/wineLabel.PNG";
import wineGlass from "../../../Assets/wine.PNG";
import SmallCard from "./../../../Components/SmallCards";
import {useAuth} from "../../../Services/auth";
import {useLocation, useNavigate} from "react-router-dom";
import FbImageLibrary from "../../../Components/react-fb-image-grid"
import {UrlBaseServer} from "../../../Services/storageServer"
import api from '../../../Services/api'
import BoostModal from "../../../Components/Modal/BoostModal"
import {isMobile} from "react-device-detect";

const Top = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const nameuri =params.pathname.split("/").pop()
  const {user} = useAuth()
  const [product, setProduct] = useState(params.state?.product);
  const [productImages, setProductImages] = useState([]);
  const [userProducts, setUserProducts] = useState([]);
  const [showBoostModal, setShowBoostModal] = useState(false);

  useEffect(()=>{
    api.getProductById(nameuri).then((data) => {
      setProduct(data.data);
    });
  },[nameuri])

  useEffect(()=>{
    if(product){
      setProductImages(product.Product_images)
      api.getProductsFromUser(product.User.id,0,8).then((data) => {
        setUserProducts(data.data.data);
      });
      if(isMobile){
        window.location.replace("vignitus://detailsProduct/"+product.id);
      }
    }
  },[product])

  function arrayOfImage() {
    let newArray = [];

    productImages.forEach((element) => {
      console.log(UrlBaseServer + element.image)
      newArray.push(UrlBaseServer + element.image);
    });
    return newArray;
  }
  function deleteProduct() {
    api
        .deleteProduct(nameuri)
        .then((response) => {
          //setLoader(false);
          alert("Suppresion réussie");
          navigate("/user-profile/"+user.id)
        })
        .catch((error) => {
          console.log(error.response.data);
          //setLoader(false);
          alert("Une erreur a eu lieu");
        });
  }

  return (
    <div className="topParent container">
      <br />
      <br />
      <div className="row">
        <div className="col-12 col-md-6 col-lg-8">
          <div className="product-gallery">
            <div className="product-img-container">
              <FbImageLibrary hideOverlay={true} images={arrayOfImage()} countFrom={4}/>
              {/*<div style={{ flex: 5 }}>*/}
              {/*  <img src={GrandWine} alt="" className="main-image" />*/}
              {/*</div>*/}
              {/*<div*/}
              {/*  style={{*/}
              {/*    flex: 2,*/}
              {/*    display: "flex",*/}
              {/*    flexDirection: "column",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div style={{ flex: 1, padding: '5px' }}>*/}
              {/*    <img src={label} alt="" className="product-small-img" />*/}
              {/*  </div>*/}
              {/*  <div style={{ flex: 1, padding: '5px' }}>*/}
              {/*    <img src={label} alt="" className="product-small-img" />*/}
              {/*  </div>*/}
              {/*  <div style={{ flex: 1, padding: '5px' }}>*/}
              {/*    <img src={wineGlass} alt="" className="product-small-img" />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <br />
            {product?.description?(
                <div className="product-description">
                  {product?.description}
                </div>
            ):null}

          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <div className="product-detal-content">
            <h5 className="prodcut-name mb-3">
              {product?.name}
            </h5>
            {product?.year != null && (
                <div className="product-detail">
                  <span className="detial-property">Millésime:</span>{product?.year}
                </div>            )}

            {product?.Color?.name != null && (
                <div className="product-detail">
                  <span className="detial-property">Couleur:</span> {product?.Color.name}
                </div>
            )}
            {product?.quantity != null && (
              <div className="product-detail">
              <span className="detial-property">Quantité:</span>{product?.quantity} bouteilles
              </div>
            )}

            {product?.viticulture != null && (
              <div className="product-detail">
              <span className="detial-property">Viticulture:</span>{product.viticulture}
              </div>
            )}

            <div className="product-detail">
              <span className="detial-property">type de cave:</span> cave
              naturelle enterree
            </div>
            <div className="product-detail">
              <span className="detial-property">
                Caisse bois/ Coffret d'origine:
              </span>
              {product?.coffret ? (
                  <span>Oui</span>
              ) : (
                  <span>Non</span>
              )}

            </div>
            <div className="product-detail">
              <span className="detial-property">
                Caspule representative de droit (CRD)
              </span>
              {product?.CRD ? (
                  <span>Oui</span>
              ) : (
                  <span>Non</span>
              )}

            </div>
            {product?.pourcentage != null && product?.pourcentage != "" && (
              <div className="product-detail">
              <span className="detial-property">Pourcentage alcool:</span>
              {product?.pourcentage}%
              </div>
            )}


            {product?.Region != null && product?.Region != "" && (
                <div className="product-detail">
                  <span className="detial-property">Région:</span>{product?.Region?.name}
                </div>
            )}
            {product?.Appelation != null && product?.Appelation.name != "" && (
              <div className="product-detail">
              <span className="detial-property">Appellation / Vin:</span>
              {product?.Appelation.name}
              </div>
            )}
            {product?.cru != null && product?.cru != "" && (
                <div className="product-detail">
                  <span className="detial-property">Classement:</span>{product.cru}
                </div>
            )}

            <br />
            <div className="product-detail">
              Ajouté le :<span className="detial-property">{product?.createdAt[8]+product?.createdAt[9]+"/"+product?.createdAt[5]+product?.createdAt[6]+"/"+product?.createdAt[0]+product?.createdAt[1]+product?.createdAt[2]+product?.createdAt[3]}</span>
            </div>
            <div style={{cursor:"pointer"}} onClick={()=>{
              navigate("/user-profile/"+product?.User.id,{
                state: { user: product?.User },
              })
            }} className="product-detail">
              par
              {user?.id== product?.User?.id?(
                  <span className="detial-property brown">Vous</span>
              ):(
                  <span className="detial-property brown">{product?.User?.firstName} {product?.User?.lastName}</span>
              )}
            </div>
            <br />
            <div className="product-detail">
              Envoie a la charge de l'acheteur:
            </div>
            {product?.Delivery_modes?.map((v, i) => {
              return (
                  <div key={"delivery"+i} className="product-detail">
                    {v?.Delivery_mode_model?(<span>{v?.Delivery_mode_model?.name}</span>):(<span>{v?.name}</span>)}
                    {v?.price} &euro;
                  </div>
              );
            })}

            <h1 className="product-price">{product?.price} &euro;</h1>
            {product?.User.id == user?.id ?(
                <div>
            <div className="btn-box">
              <button style={{cursor:"pointer"}} onClick={()=>{
                navigate("/edit-bottle/"+product?.id,{
                  state: { product: product },
                })
              }} className="btnGrey">Modifier</button>
              <button style={{cursor:"pointer"}} onClick={()=>{
                setShowBoostModal(true)
              }} className="btnMaroon">Booster</button>

            </div>
                  <div className="btn-box">
                    <button style={{cursor: "pointer"}} onClick={() => {
                      deleteProduct();
                    }} className="btnMaroon col-12 mt-2">Supprimer
                    </button>
                  </div>
                </div>) : (
                <div className="btn-box">
                  <button style={{cursor: "pointer", fontWeight: 800}} onClick={()=>{
                    navigate("/chat/"+product.user_id+"-"+product?.id,
                        {
                          state: { products: [product] },
                        }
                    )
                  }} className="btnGrey">Faire une offre</button>
                  <button style={{cursor:"pointer"}} onClick={()=>{
                    navigate("/checkout/pr"+product?.id,
                        {
                          state: { products: [product] },
                        }
                    )
                  }} style={{fontWeight:800, color:"#fff", cursor:"pointer"}} className="btnMaroon">Acheter</button>
                </div>
            )
            }
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-12 col-md-6 col-lg-8">
          <div className="related-items">
            <div className="row">
              {userProducts.map((v, i) => {
                return (
                  <div key={"smallProduct"+i} className="col-sm-8 col-md-5 col-lg-3 prodCard">
                    <SmallCard product={v}/>
                  </div>
                );
              })}
            </div>
            {user?.id == product?.User?.id ?(
                <h1 style={{cursor:"pointer"}} onClick={()=>{
                  navigate("/user-profile/"+product?.User.id,{
                    state: { user: product?.User },
                  })
                }} className="title">Voir toute votre cave</h1>
            ):(
                <h1 style={{cursor:"pointer"}} onClick={()=>{
                  navigate("/user-profile/"+product?.User.id,{
                    state: { user: product?.User },
                  })
                }} className="title">Voir la cave de {product?.User?.firstName} {product?.User?.lastName}</h1>
            )}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4"></div>
      </div>
      {user?(<BoostModal setShowModalBoost={setShowBoostModal} showModalBoost={showBoostModal} product={product}  />):null}

    </div>
  );
};
export default Top;
