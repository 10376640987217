import React, { useEffect, useState } from "react";
import MessageCard from "./../../../Components/MessageCard";
import "./manage.css";
import {useInfiniteQuery, useQuery} from "react-query";
import api from "../../../Services/api";
import {ProductCard} from "../../../Components";
import {useAuth} from "../../../Services/auth";
import {useLocation} from "react-router-dom";
function Manage() {
  const params = useLocation();
  const [isSale, setIsSale] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [countUnreadMessage,setCountUnreadMessage] = useState(0)
  const [countUnreadNotification,setCountUnreadNotification] = useState(0)
  const {user} = useAuth()

  const { isLoading:isLoadingConversation,
    isError:isErrorConversation,
    error:errorConversation,
    data:dataConversation,
    isFetching:isFetchingConversation,
    isPreviousData:isPreviousDataConversation, } = useQuery(['conversation'], ()=> api.getConversations(),{ keepPreviousData : true }
  )

  const { isLoading:isLoadingNotification,
    isError:isErrorNotification,
    error:errorNotification,
    data:dataNotification,
    isFetching:isFetchingNotification,
    isPreviousData:isPreviousDataNotification, } = useQuery(['notification'], ()=> api.getNotifications(),{ keepPreviousData : true }
  )

  useEffect(()=>{
    checkUnreadMessage()
  },[dataConversation])
  useEffect(()=>{
    checkUnreadNotification()
  },[dataNotification])

  function checkUnreadNotification() {
    let unreadNotif = 0;
    if (dataNotification?.data?.length) {
      for (let notif of dataNotification.data) {
        if (!notif.isRead) {
          unreadNotif = unreadNotif + 1;
        }
      }
    }
    setCountUnreadNotification(unreadNotif);
  }

  function checkUnreadMessage() {
    let unreadMsg = 0;
    if (dataConversation?.length) {
      for (let con of dataConversation) {
        if (con.messageReader?.length && con.messageSender?.length) {
          let dateMessageReader = new Date(
              con.messageReader[0].updatedAt.replace(" ", "T")
          );
          let dateMessageSender = new Date(
              con.messageSender[0].updatedAt.replace(" ", "T")
          );
          if (dateMessageReader > dateMessageSender) {
            if (
                con.messageReader?.length &&
                !con.messageReader[0].isRead &&
                con.messageReader[0].reader_id == user.id
            ) {
              unreadMsg = unreadMsg + 1;
            }
          } else {
            if (
                con.messageSender?.length &&
                !con.messageSender[0].isRead &&
                con.messageSender[0].reader_id == user.id
            ) {
              unreadMsg = unreadMsg + 1;
            }
          }
        }
      }
    }
    setCountUnreadMessage(unreadMsg);
  }

  return (
    <div className="container">
      <div className="message-tab">
        <div className="message-btn-box" onClick={() => setIsSale(true)}>
          <h1 className={`${isSale ? "btn-text active" : "btn-text"}`}>Messages</h1>
          {countUnreadMessage?(
          <h1
            className={`${
              isSale ? "notification active-noti" : "notification "
            }`}
          >
            {countUnreadMessage}
          </h1>):null}
        </div>
        <div className="message-btn-box" onClick={() => setIsSale(false)}>
          <h1 className={`${!isSale ? "btn-text active" : "btn-text"}`}>Notifications</h1>
          {countUnreadNotification?(
          <h1
            className={`${
              !isSale ? "notification active-noti" : "notification "
            }`}
          >
            {countUnreadNotification}
          </h1>):null}
        </div>
      </div>
      {isSale ? (
        <div className="manage-cards">
          {dataConversation?.data?.length>0?(
              <>
                      {dataConversation?.data?.map((v, i) => {
                        return (
                            <MessageCard key={"messages"+i} data={v} view={"messagerie"} />
                        );
                      })}
              </> ):(
              <div style={{margin:"20px auto"}}>
                Pas de message
              </div>
          )}
        </div>
      ) : (
        <div className="manage-cards">
          {dataNotification?.data?.data?.length>0?(
              <>
                {dataNotification?.data?.data?.map((v, i) => {
                  return (
                      <MessageCard key={"notification"+i} data={v} view={"notification"} />
                  );
                })}
              </> ):(
              <div style={{margin:"20px auto"}}>
                Pas de notification
              </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Manage;
