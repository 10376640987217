import React from "react";
import "./banner.css";
import {useNavigate} from "react-router-dom";
function Banner() {
  const navigate = useNavigate();

  return (
    <section className="parent">
      <main className="bannerBox">
        <div className="card cardCustom">
          <h3>Vente de vins entre particuliers et professionnels</h3>
          {/*<p>lorem ipsum lorem ipsum lorem ipsum lorem ipsum</p>*/}
          {/*<p>lorem ipsum lorem ipsum lorem ipsum lorem ipsum</p>*/}
          <button style={{cursor:"pointer"}} onClick={()=>{
            navigate("/edit-bottle")
          }}>je veux vendre maintenent</button>
          <a style={{cursor:"pointer"}} onClick={()=>{
            navigate("/faq")
          }}>Découvrir comment ça marche</a>
        </div>
      </main>
    </section>
  );
}

export default Banner;
