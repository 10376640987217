import React from "react";
import { ProductCard } from "./../../../Components";
import "./formember.css";
import {useQuery,useMutation,useQueryClient} from 'react-query'
import api from "../../../Services/api";

function ForMembers({data,fetchNextPage,isFetchingNextPage,hasNextPage,color, setColor,region , setRegion, year, setYear, setPrixMax , setPrixMin, prixMax, prixMin }) {
  const queryClient = useQueryClient()

  const { isLoading,
    isError,
    error,
    data:dataColor,
    isFetching,
    isPreviousData, } = useQuery(['repoColor'], ()=> api.getColor(),{ keepPreviousData : true }
  )
  const {
    data:dataRegion
  } = useQuery(['repoRegion'], ()=> api.getRegion(),{ keepPreviousData : true }
  )

  const arrayYear = () => {
    let array = [];
    let currentYear = new Date().getFullYear();
    for (let index = currentYear; index >= 1600; index--) {
      array.push(index.toString())
    }

    return array;
  };

  return (
    <div className="container  product-list">
      <br />
      <div className="row">
        <div className="col-sm-8 col-md-5 col-lg-3">
          <select value={region} onChange={e => setRegion(e.target.value)} className="filter-dropdown">
            <option value="">Région</option>
            {dataRegion?.data?.map((v,i)=>{
              return(
                  <option key={"region"+v?.id} value={v?.id}>{v.name}</option>
              )
            })}
          </select>
        </div>
        <div className="col-sm-8 col-md-5 col-lg-6">
          <div className="row">
            <div className="col-sm-8 col-md-5 col-lg-4">
              <select value={color} onChange={e => setColor(e.target.value)} className="filter-dropdown">
                <option value="">Couleur</option>
                {dataColor?.data?.map((v,i)=>{
                  return(
                      <option key={"color"+v?.id} value={v?.id}>{v.name}</option>
                  )
                })}
              </select>
            </div>
            <div className="col-sm-8 col-md-5 col-lg-4">
              <select value={year} onChange={e => setYear(e.target.value)}  className="filter-dropdown">
                <option value="">Millésime</option>
                {arrayYear().map((v,i)=>{
                  return(
                      <option key={"year"+v} value={v}>{v}</option>
                  )
                })}
              </select>
            </div>
            <div className="col-sm-8 col-md-5 col-lg-4">
              <input value={prixMin} onChange={e => setPrixMin(e.target.value)}  className="filter-dropdown" placeholder={"Prix Minimum"}/>
              {/*<select className="filter-dropdown">*/}
              {/*  <option>Prix Minimum</option>*/}
              {/*</select>*/}
            </div>
          </div>
        </div>
        <div className="col-sm-8 col-md-5 col-lg-3">
          <input value={prixMax} onChange={e => {
            console.log(prixMax)
            setPrixMax(e.target.value)
          }} className="filter-dropdown" placeholder={"Prix Maximum"}/>

          {/*<select className="filter-dropdown">*/}
          {/*  <option>Prix Maximum</option>*/}
          {/*</select>*/}
        </div>
        {/*<div className="col-sm-8 col-md-5 col-lg-3">*/}
        {/*  <select className="filter-dropdown">*/}
        {/*    <option>Style de vin</option>*/}
        {/*  </select>*/}
        {/*</div>*/}
      </div>

      <div>
        {data?.pages?.length>0?(
                <h1 className="section-title">{data.pages[0].totalItems} résultats</h1>
        ):(
            <h1 className="section-title">Pas de résultats</h1>
        )}

      </div>
      <section>
        <div className="cardParent mt-4 mb-4">
          <div className="row">

            {data?.pages[0]?.totalItems>0?(
                <>
                  {data?.pages.map(page => (
                      <React.Fragment key={page.currentPage}>

                        {page?.data?.map((v, i) => {
                          return (
                              <div key={"search"+v.id} className="col-sm-8 col-md-5 col-lg-3 prodCard">
                                <ProductCard item={v} />
                              </div>
                          );
                        })}
                      </React.Fragment>
                  ))}
                </> ):(
                <div style={{margin:"20px auto"}}>
                  Pas de résultat
                </div>
            )}
          </div>
          <div
              style={{
                margin:"0 auto",
                border:"1px solid",
                cursor:"pointer",
                marginTop: 10,
                width:250,
                //borderWidth: 1,
                alignSelf: "center",
                borderRadius: 8,
                paddingHorizontal: 24,
                paddingVertical: 8,
                borderColor: "#7E7D7D",
                marginBottom: 40,
                display:"flex",
                justifyContent:"center",
              }}
              onClick={()=> {
                console.log('la')
                fetchNextPage()
              }}
          >
            <div
                style={{
                  fontSize: 16,
                  color: "#7E7D7D",
                  fontWeight: "bold",
                  display:"flex",
                  justifyContent:"center"
                }}
            >
              {isFetchingNextPage
                  ? 'Chargement...'
                  : hasNextPage
                      ? '+ Voir plus'
                      : 'Vous avez tous chargé'}

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ForMembers;
