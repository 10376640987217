import React,{useState,useEffect} from "react";

import "./style.css";
import "moment/locale/fr";


function Cgv() {
    return (
        <div className="container">
            <div className="whiteContainer">
                <div>
                <h1>Termes et Conditions d'utilisation de la plateforme VIGNITUS</h1>
                <div style={{whiteSpace:"pre-line"}}>
                    <span className="color">CONDITIONS GÉNÉRALES DE VENTE DES VENDEURS PROFESSIONNELS VINTED</span> -
                    version applicable à partir du 1 janvier 2022
                    Les présentes Conditions Générales de Vente des Vendeurs Professionnels (les « Conditions Générales
                    de Vente ») s'appliquent aux ventes effectuées par des Vendeurs Professionnels par l’intermédiaire
                    du Site. Le Site est géré par la société SAS VIGNITUS (« VIGNITUS »), située à 10 rue du château
                    34540 Balaruc le vieux, France, immatriculée sous le numéro 907696744. au Registre des Sociétés de
                    la République Française.
                    Avant d'acheter un Article à un Vendeur Professionnel sur le Site, vous devez lire les présentes
                    Conditions Générales de Vente et les Conditions Additionnelles du Vendeur Professionnel, les
                    accepter et vous engager à les respecter.
                    Elles forment un contrat ayant force obligatoire entre vous (ci-après dénommé « l'Acheteur » et
                    désigné par les pronoms « vous », « votre » ou « vos ») et le Vendeur Professionnel ainsi que,
                    lorsque cela est pertinent, entre vous et VINTED.
                    <h2>1. DÉFINITIONS</h2>
                    Sauf indication contraire dans les présentes Conditions Générales de Vente, les termes commençant
                    par une majuscule ont la même signification que celle qui leur est attribuée dans les Conditions
                    Générales de Vente VIGNITUS ;
                    Acheteur - signifie vous, l'Utilisateur qui achète ou souhaite acheter sur le Site un ou plusieurs
                    Articles auprès d'un Vendeur Professionnel. Vous êtes soit un consommateur, soit un professionnel au
                    sens de la Directive 2011/83/UE relative aux droits des consommateurs et des lois nationales en
                    matière de droit de la consommation qui vous sont applicables. Si vous êtes un professionnel, il est
                    possible que vous ne jouissiez pas de tous les droits décrits dans les présentes Conditions
                    Générales de Vente ;
                    Article - désigne les produits/articles dont la vente n'est pas interdite par la législation
                    nationale applicable et qui sont ajoutés par le Vendeur Professionnel dans le Catalogue du Site afin
                    de les vendre ;
                    Conditions de Vente Additionnelles - désigne les conditions de vente supplémentaires du Vendeur
                    Professionnel, qui s'appliquent en plus des présentes Conditions Générales de Vente
                    Protection Acheteur - désigne le Service défini dans les Conditions Générales VIGNITUS
                    Protection Pro Acheteur - désigne le Service défini à l'Article 4 ci-dessous ;
                    Services de Protection Acheteur - désigne les Services de Protection Acheteur et de Protection Pro
                    Acheteur proposés par VIGNITUS afin de faciliter et de sécuriser les Ventes réalisées sur le Site.
                    Les Services de Protection Acheteur ne sont pas des services d'assurance ou des services de garantie
                    de protection juridique ;
                    Utilisateur/Vendeur Professionnel - désigne tout utilisateur qui ajoute un ou plusieurs Articles
                    dans le catalogue du Site dans le cadre de son activité professionnelle. Les Vendeurs Professionnels
                    sont identifiés sur le Site par une icône « Pro » affichée à côté de leur nom. Cette icône n’est
                    toutefois pas visible dans le flux des membres, mais uniquement sur la page de l'Article et dans le
                    profil ;
                    Vente - désigne la transaction et le contrat de vente conclus directement entre un Acheteur et un
                    Vendeur Professionnel.
                    <h2>2. OBJET</h2>
                    Les présentes Conditions Générales de Vente régissent :
                    - Les conditions générales applicables aux Ventes effectuées par les Vendeurs Professionnels par
                    l'intermédiaire du Site, qui sont applicables directement entre le Vendeur Professionnel et
                    l'Acheteur ;
                    - Les Services de Protection Acheteur qui vous sont proposés par VIGNITUS.
                    <h2>3. CONDITIONS DE VENTE AVEC LE VENDEUR PROFESSIONNEL</h2>
                    <span className="color">3.1 Étapes à suivre pour passer votre commande</span>
                    <span className="color">3.1.1</span> Le Site et les services ne sont pas destinés aux mineurs. Pour
                    les mineurs, un parent ou un tuteur (majeur) doit s’enregistrer pour l’utilisation des Services et
                    superviser l’utilisation des Services par le mineur.
                    <span className="color">3.1.2</span> L'Utilisateur Professionnel qui a ajouté un Article peut le
                    retirer ou modifier son prix à tout moment, avant d'entrer en contact avec un Acheteur pour la vente
                    de l'Article en question. La mise en vente d'un Article du Catalogue constitue une offre de vente
                    par l'Utilisateur Professionnel, qui peut être acceptée par un Acheteur. Cependant, un Acheteur peut
                    annuler sa commande jusqu'à ce que l'Article ait été expédié. Dans tous les cas, des commentaires
                    négatifs peuvent être envoyés aux acheteurs si, par exemple, ils annulent leur commande pour la
                    deuxième fois.
                    <span className="color">3.1.3</span> Afin d'acheter un Article, vous devez :
                    - Accéder au Site et créer un compte sur VIGNITUS, tel que décrit dans les Conditions Générales
                    VIGNITUS et, si vous êtes un Utilisateur Professionnel, tel que décrit dans les Conditions Générales
                    de vente applicables aux vendeurs professionnels ;
                    - Parcourez le Catalogue et cliquez sur la page de l'Article pour afficher sa description et ses
                    photos, telles que fournies par le Vendeur Professionnel ;
                    - Sélectionnez l'Article que vous souhaitez acheter ;
                    - Suivez les instructions du Site, notamment en remplissant le formulaire de commande et en
                    choisissant votre mode d'expédition préféré ;
                    - Vérifiez le contenu de votre commande et, si nécessaire, corrigez toute erreur ;
                    - Validez la commande et le prix à payer, qui comprend le prix de l’Article, les frais correspondant
                    aux Services de Protection Acheteur, les frais d'expédition et les taxes éventuelles (le « Prix ») ;
                    - Lisez les présentes Conditions Générales de Vente et les Conditions Additionnelles du Vendeur
                    Professionnel, et acceptez-les (en cliquant sur le bouton « Payer maintenant » et en confirmant la
                    commande) ;
                    - Choisissez votre mode de paiement et suivez les instructions du module de paiement en ligne pour
                    payer le Prix.
                    <span className="color">3.1.4</span> En cliquant sur le bouton « Payer maintenant », vous déclarez
                    comprendre et accepter que :
                    - vous concluez un contrat de vente avec un Vendeur Professionnel ;
                    - vous vous engagez à remplir l'obligation de paiement et que tout manquement à cette obligation
                    peut vous obliger à rembourser les pertes subies par le Vendeur Professionnel ;
                    - vous déclarez que vous avez fourni des informations correctes et à jour et que vous comprenez que
                    vous êtes entièrement responsable des achats effectués ;
                    - vous confirmez que vous avez lu et que vous acceptez les présentes Conditions Générales de Vente,
                    les Conditions Additionnelles du Vendeur Professionnel, les conditions de MANGOPAY SA et toute autre
                    documentation contractuelle mentionnée dans les présentes Conditions Générales de Vente.
                    <span className="color">3.1.5</span> Une fois ces étapes accomplies, vous recevrez, par courrier
                    électronique, une confirmation de l'acceptation du paiement de votre commande ainsi qu'un accusé de
                    réception confirmant votre commande.
                    <span className="color">33.2 Paiement du Prix</span>
                    <span className="color">3.2.1</span> Le Prix est affiché au moment de la validation de votre
                    commande. Il est exprimé en euros et comprend le prix de l'Article ainsi que tous les frais
                    applicables (frais du Service de Protection Acheteur, frais d'expédition et taxes applicables).
                    <span className="color">3.2.2</span> Paiement. Vous devez utiliser le Service de Paiement Intégré
                    sécurisé de VIGNITUS, qui vous est fourni dans le cadre des Services de Protection Acheteur. Vous
                    pouvez payer le Prix avec une carte de crédit ou de débit ou tout autre moyen de paiement qui
                    pourrait être ajouté par VIGNITUS sur l'interface de paiement intégrée au Site. Vous pouvez
                    également utiliser les fonds disponibles sur votre Solde VIGNITUS(c'est-à-dire votre porte-monnaie
                    électronique disponible sur VIGNITUS), le cas échéant. Si vous n'avez pas assez de fonds dans votre
                    Solde VIGNITUS pour couvrir le prix du ou des Articles, vous pouvez couvrir la partie manquante en
                    utilisant d'autres méthodes de paiement.
                    Le Prix sera débité sur votre carte, votre compte bancaire et/ou votre Solde VIGNITUS une fois que
                    vous aurez cliqué sur le bouton « Payer maintenant ». Le paiement est traité par MANGOPAY SA, qui
                    stocke également les informations concernant votre carte de crédit ou de débit ou toute autre
                    méthode de paiement. Vous êtes seul responsable de la transmission des informations associées à la
                    carte de crédit ou de débit. Le Vendeur Professionnel et VIGNITUS déclinent toute responsabilité à
                    cet égard, dans toute la mesure permise par la législation applicable.
                    <span className="color">3.2.3</span> Compte séquestre. Le Prix de votre commande sera conservé sur
                    un compte séquestre géré par MANGOPAY SA jusqu'à la finalisation de la Vente. La Vente est réputée
                    finalisée à l'expiration de la période de validité de la Protection Pro Acheteur ou la résolution de
                    tout litige, conformément à l'Article 4 ci-dessous. Une fois la Vente réputée réalisée, VIGNITUS
                    donnera instruction à MANGOPAY SA de verser le prix de l'Article au Vendeur Professionnel, après
                    déduction des frais correspondant à la Protection Pro Acheteur et des frais de port qui restent
                    acquis à VIGNITUS.
                    <span className="color">3.2.4</span> Le Vendeur Professionnel est seul responsable de l'émission des
                    factures des Articles qu'il vend sur le Site ainsi que des services d'expédition revendus à
                    l'Acheteur sur le Site. VIGNITUS ne peut, en aucun cas, être responsable de l'émission d'une facture
                    au nom du Vendeur Professionnel.
                    <span className="color">33.3 Livraison de votre commande</span>
                    <span className="color">3.3.1</span> Service d'Expédition. Vous devez tout comme les vendeurs
                    particuliers indiquer les tarifs et prestataires de livraison que vous souhaitez utiliser afin que
                    l’acheteur puisse choisir celui qui lui convient. Ces services d'expédition, de transport et de
                    livraison sont fournis par des prestataires de services tiers.
                    Vous déclarez avoir lu les conditions générales des fournisseurs de services d'expédition ci-dessous
                    et vous acceptez que les Articles soient expédiés par les fournisseurs de services d'expédition
                    conformément auxdites conditions générales, qui sont contraignantes.
                    VIGNITUS peut modifier cette liste à tout moment. Les frais d'expédition sont indiqués lors du
                    paiement et sont payés par vous en plus du prix de l'Article, des frais applicables (notamment les
                    frais des Services de Protection Acheteur) et des taxes.
                    <span className="color">3.3.2</span> Délai de livraison. Le Vendeur Professionnel doit vous livrer
                    l'Article à la date, ou dans le délai, précisé dans la confirmation de votre commande ou, si aucune
                    date n'est indiquée, au plus tard dans les 30 jours.
                    Si vous êtes un consommateur et que le Vendeur Professionnel ne vous livre pas l'Article à temps, la
                    Vente ne sera pas automatiquement annulée. Vous pouvez bénéficier de l'assistance de VIGNITUS en
                    activant la Protection Pro Acheteur comme décrit dans l'Article 4 ci-dessous. Vous pouvez également
                    annuler la Vente dans les conditions prévues par la loi, après avoir mis le Vendeur Professionnel en
                    demeure de vous livrer l'Article dans un délai supplémentaire raisonnable, et si le Vendeur
                    Professionnel ne s'est toujours pas exécuté dans ce délai, après avoir formellement informé le
                    Vendeur Professionnel que vous souhaitez annuler la Vente.
                    <span className="color">33.4 Droit de rétractation</span>
                    <span className="color">3.4.1</span> Portée. Cette disposition n'est applicable que si vous êtes un
                    consommateur au sens de la Directive 2011/83/UE relative aux droits des consommateurs (la «
                    Directive ») et des lois nationales relatives au droit de la consommation qui vous sont applicables,
                    que vous achetez un Article à un Vendeur Professionnel et que vous disposez d'un droit de
                    rétractation en vertu de la Directive et desdites lois nationales.
                    Veuillez noter que vous ne disposez pas d'un droit de rétractation si l'Article que vous avez acheté
                    relève de l'une des exceptions légales.
                    Le Vendeur Professionnel est seul responsable en vertu des dispositions du présent article. VIGNITUS
                    décline toute responsabilité à cet égard.
                    <span className="color">3.4.2</span> Conditions d'exercice du droit de rétractation. Vous pouvez
                    exercer votre droit de rétractation dans les 14 jours calendaires à compter du jour suivant la date
                    de livraison de l'Article. Si ce délai expire un samedi, un dimanche ou un jour férié, celui-ci est
                    prolongé jusqu'au premier jour ouvrable suivant.
                    Vous acceptez que VIGNITUS calcule ce délai sur la base des informations fournies par le prestataire
                    de services d'expédition que vous avez choisi et ne soit pas responsable si des informations
                    incorrectes sont fournies par ledit prestataire.
                    Afin d'exercer votre droit de rétractation, vous pouvez, entre autres, cliquer sur le bouton « J'ai
                    un problème » dans le chat avec le Vendeur Professionnel (puis choisir un motif et, dans certains
                    cas, fournir une preuve, par exemple si vous estimez que l’article est une contrefaçon), envoyer le
                    formulaire de rétractation joint en Annexe 1 aux présentes Conditions Générales de Vente et qui vous
                    sera également fourni par le Vendeur Professionnel dans le colis avec le(s) Article(s), ou faire
                    toute autre déclaration non ambiguë exprimant votre souhait d'exercer un droit de rétractation.
                    Si vous ne souhaitez pas exercer votre Droit de Rétractation par le biais de VIGNITUS, vous pouvez
                    exercer votre Droit de Rétractation en fournissant au Vendeur Professionnel les informations
                    suivantes :
                    Je (*) notifie par la présente que je (*) me rétracte de mon (*) contrat de vente des Articles
                    suivants (*)
                    Commandé le (*) / Reçu le (*),
                    Nom de l'Acheteur (Consommateur),
                    Adresse de l'Acheteur (Consommateur),
                    Signature de l'Acheteur (Consommateur) (uniquement si ce formulaire est notifié sur papier),
                    Date
                    Portugal : Pour la validité de l’exercice de votre droit de rétractation, il suffit que vous
                    exprimiez cette décision avant l’expiration du délai légal de rétractation susmentionné.
                    Vous devez alors renvoyer le(s) Article(s) au Vendeur Professionnel ou à une personne désignée par
                    lui sans retard injustifié et, au plus tard, dans les 14 jours à compter du jour où vous avez
                    communiqué votre décision de vous rétracter conformément au paragraphe ci-dessus. Vous supporterez
                    les frais de retour du ou des Articles, sauf indication contraire dans les Conditions Additionnelles
                    du Vendeur Professionnel. En outre, lorsque vous retournez un Article, le colis doit avoir un numéro
                    de suivi.
                    <span className="color">3.4.3</span> Remboursement du Prix. Vous serez remboursé de l'intégralité du
                    Prix de votre commande, y compris les frais d'expédition initiaux et les frais correspondant à la
                    Protection Pro Acheteur sans retard excessif une fois que vous aurez fourni la preuve de
                    l'expédition du ou des Articles ou jusqu'à ce que le Vendeur Professionnel ait reçu l'Article, la
                    première de ces dates étant retenue.
                    Vous serez remboursé via le même moyen de paiement que celui que vous avez utilisé pour la Vente.
                    <span className="color">3.4.4</span> Modèle de formulaire de rétractation (remplissez et renvoyez ce
                    formulaire au Vendeur Professionnel uniquement si vous êtes un consommateur et que vous souhaitez
                    vous rétracter). Ce document est joint en Annexe 1 aux présentes Conditions Générales de Vente.
                    Comme prévu ci-dessus, ce formulaire doit également vous être remis par le Vendeur Professionnel
                    dans le colis avec le ou les Articles.
                    <span className="color">3.5 Garanties légales</span>
                    <span className="color">3.5.1</span> Le Vendeur Professionnel est exclusivement responsable en vertu
                    des dispositions du présent article. En tant que simple intermédiaire, VIGNITUS décline toute
                    responsabilité à cet égard. Par conséquent, le cas échéant, vous devez exercer ces garanties
                    directement auprès du Vendeur Professionnel.
                    <span className="color">3.5.2</span> Conformément aux lois nationales qui vous sont applicables, le
                    Vendeur Professionnel est responsable envers vous des défauts de conformité du bien au contrat (si
                    vous êtes un consommateur) et des vices cachés de l'Article vendu (que vous soyez ou non un
                    consommateur).
                    <span className="color">3.5.3</span> Si vous effectuez un achat depuis la France, le Vendeur
                    Professionnel est responsable à votre égard, conformément au droit français, des défauts de
                    conformité du bien au contrat dans les conditions prévues aux Articles L. 217-4 et suivants du Code
                    de la consommation (si vous êtes un consommateur) ainsi que des vices cachés de l'Article vendu dans
                    les conditions prévues aux Articles 1641 et suivants du Code civil (que vous soyez ou non un
                    consommateur).
                    Si vous êtes un consommateur agissant sur la base d'une garantie légale de conformité, vous :
                    - bénéficierez d'un délai de deux ans à compter de la livraison du bien pour agir ;
                    - pourrez choisir entre la réparation ou le remplacement du bien dans les conditions de coût prévues
                    à l'Article L. 217-9 du Code de la consommation ;
                    - ne serez pas tenu de fournir la preuve de l'existence du défaut de conformité du bien pendant les
                    24 mois suivant la livraison du bien, sauf pour les biens d'occasion pour lesquels ce délai est de
                    six mois.
                    La garantie de conformité s'applique indépendamment de toute garantie commerciale accordée par le
                    Vendeur Professionnel.
                    Vous pouvez également décider de mettre en œuvre la garantie contre les vices cachés de l'Article
                    vendu au sens de l'article 1641 du Code civil et, dans ce cas, choisir entre l’annulation de la
                    vente ou une réduction du prix de vente conformément à l'article 1644 du Code civil.
                    <span className="color">3.5.4</span> Si vous effectuez un achat depuis l’Italie, le Vendeur
                    Professionnel est responsable envers vous, conformément à la loi italienne, des éventuels défauts de
                    conformité du bien au contrat dans les conditions prévues aux Articles 128 et suivants du Code
                    italien de la consommation (si vous êtes un consommateur) et des vices cachés de l'Article vendu
                    dans les conditions prévues à l’Article 1490 du Code civil italien (que vous soyez ou non un
                    consommateur).
                    <span className="color">3.5.5</span> Si vous effectuez un achat depuis la Belgique, le Vendeur
                    Professionnel est responsable envers vous, conformément au droit belge si vous êtes un consommateur,
                    des défauts de conformité du bien au contrat dans les conditions prévues aux Articles 1604 et 1649
                    du Code civil belge et des vices cachés de l'Article vendu dans les conditions prévues à l'Article
                    1641 du Code civil belge.
                    <span className="color">3.5.6</span> Si vous effectuez un achat depuis l'Espagne, le Vendeur
                    Professionnel est responsable envers vous, conformément à la loi espagnole si vous êtes un
                    consommateur, des défauts de conformité du bien au contrat dans les conditions prévues aux Articles
                    114 et suivants de la Loi espagnole sur la protection du consommateur et des vices cachés de
                    l'Article vendu dans les conditions prévues aux Articles 1484 et suivants du Code civil espagnol.
                    <span className="color">3.5.7</span> Si vous effectuez un achat depuis les Pays-Bas, le Vendeur
                    Professionnel est responsable envers vous, conformément à la Loi néerlandaise si vous êtes un
                    consommateur, des défauts de conformité du bien au contrat dans les conditions prévues à l'Article
                    7:17 du Code civil néerlandais.
                    <span className="color">3.5.8</span> Si vous effectuez un achat à partir du Luxembourg, le Vendeur
                    Professionnel est responsable envers vous, en vertu du droit luxembourgeois, si vous êtes un
                    consommateur, des défauts de conformité du bien au contrat dans les conditions prévues à L. 212-3 du
                    Code de la consommation luxembourgeois et pour les vices cachés de l'Article vendu dans les
                    conditions prévues à Article 1641 du Code civil luxembourgeois.
                    <span className="color">3.5.9</span> Si vous effectuez un achat depuis le Portugal, le Vendeur Pro
                    est responsable envers vous, conformément au droit portugais, pour tout défaut de conformité des
                    marchandises faisant l’objet du contrat, aux conditions prévues aux Articles 2 et suivants du
                    Décret-loi n° 67/2003 du 8 avril (si vous êtes un consommateur), et pour les vices cachés de
                    l’Article vendu, aux conditions prévues aux Articles 911 et suivants du Code civil portugais (que
                    vous soyez ou non un consommateur).
                    <h2>4. SERVICES DE PROTECTION ACHETEUR</h2>
                    En tant que simple intermédiaire, VIGNITUS n'est pas partie au contrat de Vente entre vous et le
                    Vendeur. En aucun cas, VIGNITUS n'achètera ou ne vendra les Articles présentés sur le Site, ni ne
                    prétendra le faire mais une rubrique réservé aux rachats de cave entière est proposé pour les
                    personnes souhaitant vendre la globalité d’une cave sans passer par la plateforme.
                    Cependant, VIGNITUS propose des Services de Protection Acheteur qui sécurisent et facilitent la
                    Vente. Ces Services de Protection Acheteur sont obligatoires et s'appliquent automatiquement lorsque
                    l'Acheteur paie sa commande avec le Service de Paiement Intégré.
                    Si vous effectuez un achat dans le cadre de votre activité professionnelle, vous ne bénéficiez pas
                    de la Protection Pro Acheteur, mais vous pouvez bénéficier de la Protection Acheteur décrite dans
                    les Conditions Générales Vignitus. Vous pouvez alors avoir droit à un remboursement du Prix de votre
                    commande si vous activez la Protection Acheteur : (i) dans les deux jours suivant la date de
                    livraison de votre commande, en nous indiquant que l'Article que vous recevez est endommagé ou
                    substantiellement non conforme à sa description sur le Site, (ii) ou dans les deux jours suivant la
                    date de livraison prévue indiquée par le Vendeur au moment de votre Commande, si vous n'avez
                    toujours pas reçu l'Article.
                    Si vous êtes un consommateur, vous bénéficiez automatiquement de la Protection Pro Acheteur décrite
                    aux Articles 4.1 à 4.3 ci-dessous. VIGNITUS vous aidera si vous ne recevez pas l'Article, s'il est
                    livré en retard ou si vous souhaitez exercer votre droit légal de rétractation.
                    La Protection Pro Acheteur et la Protection Acheteur sont des Services proposés par VIGNITUS qui ne
                    remplacent pas les droits légaux de l'Acheteur (tels que le droit légal de rétractation) ou toute
                    autre garantie légale dont l'Acheteur dispose en vertu des lois applicables. Ces droits et garanties
                    peuvent être exercés indépendamment de la Protection Pro Acheteur et de la Protection Acheteur.
                    <span className="color">4.1 Période de validité de la Protection Pro Acheteur</span>
                    <span className="color">4.1.1</span> Vous pouvez activer la Protection Pro Acheteur en cliquant sur
                    le bouton disponible à cet effet (ainsi qu'en choisissant un motif et, dans certains cas, en
                    fournissant une preuve, par exemple si l’Article est une contrefaçon) disponible dans le chat avec
                    le Vendeur Professionnel :
                    -dans un délai de 14 jours calendaires à compter de la livraison de l'Article commandé ;
                    -en cas de perte de l'Article, dans un délai de 14 jours calendaires à compter de la réception de la
                    notification qui vous sera adressée par VIGNITUS. En général, VIGNITUS vous enverra cette
                    notification entre quatre et cinq semaines après votre commande.
                    Il s'agit de la « Période de validité » de la Protection Pro Acheteur.
                    <span className="color">4.1.2</span> Vous ne pourrez pas activer la Protection Pro Acheteur et
                    VIGNITUS ne donnera pas suite à votre réclamation :
                    -Si vous êtes un professionnel au sens de la Directive 2011/83/UE relative aux droits des
                    consommateurs ;
                    -Si la Période de validité décrite à l'Article 4.1.1 ci-dessus a expiré à la date de votre
                    réclamation, même si les Conditions Additionnelles du Vendeur Professionnel autorisent les retours
                    ou les remboursements au-delà de cette période ;
                    -Si vous exercez votre droit de rétractation ou notifiez votre réclamation sans utiliser le bouton «
                    J'ai un problème » disponible dans le chat avec le Vendeur Professionnel ; ou
                    -Si votre réclamation n'est pas conforme à la procédure décrite dans les présentes Conditions
                    Générales de Vente.
                    <span className="color">4.1.3</span> Si vous êtes un consommateur et que vous n'êtes pas éligible à
                    la Protection Pro Acheteur, vous devez contacter directement le Vendeur Professionnel pour tout
                    problème lié à votre commande.
                    4.2 Champ d'application de la Protection Pro Acheteur
                    La Protection Pro Acheteur comprend les services énumérés ci-dessous. Pour tout problème qui n'est
                    pas couvert par la Protection Pro Acheteur, vous devez contacter le Vendeur Professionnel.
                    <span className="color">4.2.1</span> Protection du Prix de la commande et facilitation du processus
                    de rétractation : VIGNITUS vous aidera à exercer votre droit légal de rétractation et à obtenir le
                    remboursement du Prix de votre commande si vous remplissez les conditions légales applicables.
                    Le Prix de votre commande est conservé sur un compte séquestre géré par MANGOPAY SA jusqu'à la
                    finalisation de la Vente. Seul VIGNITUS peut donner instruction à MANGOPAY SA de verser le Prix de
                    votre commande au Vendeur, et ne le fera pas : (i) tant que la Période de Validité de la Protection
                    Pro Acheteur n’aura pas expiré ; ou (ii) si vous avez soumis une réclamation à VIGNITUS pendant
                    cette Période de Validité, jusqu'à ce que VIGNITUS ait déterminé si vous devez être remboursé :
                    - Si vous avez dûment exercé votre droit de rétractation en cliquant sur le bouton prévu à cet effet
                    dans le chat avec le Vendeur Professionnel et que vous remplissez les conditions légales
                    applicables, VIGNITUS informera le Vendeur Professionnel qu'il est dans l'obligation légale de vous
                    rembourser une fois qu’il aura reçu le ou les Articles retournés ou que vous aurez fourni une preuve
                    de leur expédition.
                    - Si le Vendeur Professionnel accepte ou confirme le retour, il en informera VIGNITUS, qui donnera
                    l'ordre à MANGOPAY SA de libérer les fonds détenus en séquestre pour versement à l'Acheteur.
                    - Si le Vendeur Professionnel ne confirme pas qu'il a reçu l'Article retourné dans les 14 jours
                    suivant l'exercice de votre droit de rétractation et si vous avez fourni une preuve de l’expédition
                    du ou des Articles ou une preuve que le Vendeur Professionnel a reçu le ou les Articles retournés,
                    VIGNITUS recevra automatiquement une alerte et examinera le problème. Vous pouvez également
                    contacter VIGNITUS pendant cette période de 14 jours si vous ne parvenez pas à trouver un accord
                    avec le Vendeur Professionnel. La Vente sera suspendue et le Prix de votre commande restera placé
                    sous séquestre jusqu'à ce que VIGNITUS ait déterminé si vous devez être remboursé.
                    Si la Vente n'est pas finalisée et que ni l'Acheteur ni le Vendeur ne contactent VIGNITUS dans les
                    14 jours suivant l'exercice de votre droit de rétractation, soit pour nous informer qu'un accord a
                    été conclu, soit pour confirmer la réception de l'Article retourné par le Vendeur, soit pour nous
                    fournir la preuve de l’expédition ou de la réception effective de l'Article retourné, VIGNITUS se
                    rapprochera à nouveau de l'Acheteur pour déterminer si ce dernier renonce ou non à son droit de
                    rétractation. L'Acheteur recevra un préavis de 2 jours pour réagir. Si l'Acheteur répond, VIGNITUS
                    vérifiera si les conditions du droit de rétractation ont été respectées, et si l'Acheteur a fourni
                    une preuve d'expédition, VIGNITUS remboursera l'Acheteur. Dans le cas contraire, les fonds seront
                    libérés au profit du Vendeur Professionnel.
                    VIGNITUS peut vous exempter de l'obligation de retourner l'Article au Vendeur Professionnel, par
                    exemple lorsque, après avoir enquêté sur la question avec l'Acheteur et le Vendeur Professionnel, il
                    est hautement probable que l'Article soit une contrefaçon.
                    - Si VIGNITUS considère que vous avez exercé votre droit de rétractation conformément aux exigences
                    légales applicables, VIGNITUS veillera à ce que vous soyez remboursé du Prix de votre commande -
                    prix de l'Article, frais d’expédition et frais correspondant à la Protection Pro Acheteur.
                    <span className="color">4.2.2</span> Perte d'Articles pendant l'expédition ou livraison tardive : si
                    vous n'avez pas reçu l'Article à la date ou dans le délai indiqué lors de la confirmation de
                    commande, ainsi que dans la confirmation de la commande, vous pouvez bénéficier de l'assistance de
                    VIGNITUS. Vous devez signaler le problème à VIGNITUS en cliquant sur le bouton « J'ai un problème »
                    dans le chat avec le Vendeur Professionnel dès que possible, mais au plus tard 14 jours après la
                    date à laquelle VIGNITUS vous a notifié que l'Article a été « livré ».
                    En ce qui concerne certains prestataires de services d'expédition (Mondial Relay et Chronopost),
                    VINTED vous enverra une notification si l'Article ne vous a pas été livré dans un délai respectif de
                    28 à 32 jours après son expédition. Vous pouvez annuler votre commande et être remboursé
                    automatiquement si vous cliquez sur le bouton « Annuler cette commande » dans les 2 jours suivant la
                    notification de VINTED. Si vous ne le faites pas, vous bénéficierez tout de même de la Protection
                    Pro Acheteur pendant les 14 jours calendaires suivants (si cette période expire un samedi, un
                    dimanche ou un jour férié, celle-ci sera prolongée jusqu'au premier jour ouvrable suivant).
                    Dans tous les cas, si la commande est marquée comme « livrée » mais que vous n'avez pas reçu
                    l'Article, vous pouvez contacter VIGNITUS pour activer la Protection Pro Acheteur (si la Période de
                    Validité n'a pas expiré) en appuyant sur le bouton « J'ai un problème » ou le Vendeur Professionnel.
                    <span className="color">4.2.3</span> Service client 24/24h et 7/7j : vous pouvez contacter nos
                    équipes 24 heures sur 24 et 7 jours sur 7 si vous avez besoin d'aide. Par exemple, VIGNITUS peut
                    intervenir si vous ou le Vendeur Professionnel enfreignez les présentes Conditions Générales de
                    Vente. VIGNITUS coopère également avec les organisations gouvernementales.
                    <span className="color">4.2.4</span> Contrôles d'identité des Vendeurs. VIGNITUS vérifie qu'au
                    moment de la création de leur compte VIGNITUS, les Vendeurs Professionnels sont des professionnels,
                    associations ou fondations existants et dûment immatriculés en France.
                    <span className="color">4.2.5</span> Normes applicables aux Vendeurs Professionnels. VIGNITUS exige
                    que les Vendeurs Professionnels s’engagent à respecter les lois applicables et vos droits, y
                    compris, le cas échéant, vos droits en tant que consommateur, et à traiter les commandes avec le
                    soin et la diligence nécessaires. Les Vendeurs Professionnels qui ne respectent pas les obligations
                    définies dans leur contrat avec VIGNITUS peuvent être empêchés de proposer des Articles à la vente.
                    <span className="color">4.3 Frais correspondant à la Protection Pro Acheteur </span>
                    Pour chaque Vente, des frais obligatoires correspondants à la Protection Pro Acheteur et aux frais
                    d'expédition vous seront facturés en plus du prix de l'Article, en contrepartie des services qui
                    vous sont fournis par VIGNITUS en vertu de l'Article 4.2 ci-dessus. Le montant total de ces frais
                    vous est indiqué sur le Site avant l'exécution de votre commande. Cette redevance se compose de :
                    - un montant fixe de 1 EUR et
                    - 8 % du prix d'achat (y compris la taxe sur la valeur ajoutée (« TVA ») applicable).
                    <h2>5. FORCE MAJEURE</h2>
                    L'exécution par le Vendeur Professionnel et VIGNITUS de leurs obligations au titre des présentes
                    Conditions Générales de Vente peut être suspendue en cas de force majeure affectant l'exécution.
                    Il y a force majeure lorsqu'un événement indépendant de la volonté de VIGNITUS et/ou du Vendeur
                    Professionnel, qui ne pouvait raisonnablement être prévu au moment de la conclusion du contrat et
                    dont les effets ne peuvent être évités par des mesures appropriées, empêche VIGNITUS et/ou le
                    Vendeur Professionnel d'exécuter ses obligations.
                    <h2>6. MODIFICATION DES PRÉSENTES CONDITIONS générales de vente</h2>
                    <span className="color">6.1</span> VIGNITUS peut, à son entière discrétion, modifier les présentes
                    Conditions Générales de Vente dans les conditions prévues par les Conditions générales de vente
                    VIGNITUS.
                    Les conditions modifiées ne s'appliqueront qu'aux Articles achetés après l'entrée en vigueur de ces
                    modifications, sauf lorsqu'elles concernent la Protection Pro Acheteur. Dans ce cas, les conditions
                    modifiées peuvent s'appliquer immédiatement si : (i) l'application immédiate est nécessaire pour se
                    conformer à la loi ou à une décision de justice ; ou (ii) les modifications concernent des
                    engagements supplémentaires de VIGNITUS ou du Vendeur Professionnel envers vous ou de nouvelles
                    fonctionnalités qui n'entraînent aucune diminution de vos droits.
                    Vous serez informé par un courriel et une notification sur le Site de ces changements.
                    <span className="color">6.2</span> Les Vendeurs Professionnels peuvent modifier leurs Conditions
                    Additionnelles, le cas échéant, selon leurs propres politiques.
                    <h2>7. RESPONSABILITÉ </h2>
                    <span className="color">7.1</span> VIGNITUS est un service d’intermédiation qui met en relation les
                    Acheteurs et les Vendeurs Professionnels. Elle agit en tant que fournisseur de services
                    d'hébergement. Toutes les Ventes effectuées par l'intermédiaire de VIGNITUS le sont directement
                    entre les Acheteurs et les Vendeurs Professionnels. VIGNITUS n'est partie à aucun contrat de Vente
                    et offre uniquement des Services de Protection Acheteur aux Acheteurs éligibles.
                    VIGNITUS NE FAIT AUCUNE DÉCLARATION CONCERNANT, N'APPROUVE PAS ET NE SERA PAS RESPONSABLE :
                    DES ACTIONS, ERREURS OU OMISSIONS DES VENDEURS PROFESSIONNELS ;
                    DE LA VÉRACITÉ, L'EXHAUSTIVITÉ OU L'EXACTITUDE DES INFORMATIONS FOURNIES PAR LES VENDEURS
                    PROFESSIONNELS ;
                    DU RESPECT PAR LES VENDEURS PROFESSIONNELS DES PRÉSENTES CONDITIONS GÉNÉRALES DE VENTE OU DES LOIS
                    APPLICABLES ;
                    DE LA QUALITÉ ET LA QUANTITÉ DES ARTICLES OU DE LEUR CONFORMITÉ AVEC LA DESCRIPTION DONNÉE SUR LA
                    PAGE DES ARTICLES ; OU
                    DES MANQUEMENTS DE L'ACHETEUR OU DU VENDEUR PROFESSIONNEL DANS L'EXÉCUTION DE LA VENTE CONFORMÉMENT
                    AUX PRÉSENTES CONDITIONS GÉNÉRALES DE VENTE OU AUX CONDITIONS ADDITIONNELLES DU VENDEUR
                    PROFESSIONNEL.
                    DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE, VINTED DÉCLINE TOUTE RESPONSABILITÉ POUR LES
                    ACTIONS, ERREURS OU OMISSIONS DES VENDEURS PROFESSIONNELS ET ACHETEURS, NOTAMMENT DANS LE CADRE DES
                    ÉVÉNEMENTS ÉNUMÉRÉS CI-DESSUS.
                    <span className="color">7.2</span> Sauf disposition contraire de la loi, VIGNITUS n'est pas
                    responsable des obligations fiscales et sociales ou des obligations déclaratives qui pourraient
                    incomber aux utilisateurs du fait de leurs activités sur le Site. Dans le cadre des Transactions
                    effectuées sur le Site, les Utilisateurs sont seuls responsables de leurs obligations fiscales et
                    sociales, le cas échéant, en vertu de la législation applicable. Par exemple, lorsque l'Acheteur est
                    un consommateur, la TVA est incluse dans les frais correspondants à la Protection Acheteur. En
                    revanche, si l'Acheteur est un Utilisateur Professionnel, la TVA n'est pas incluse dans les frais
                    correspondants à la Protection Acheteur ou à la Protection Pro Acheteur et doit être déclarée et
                    payée par l'Utilisateur Professionnel lui-même. Il en va de même pour les autres Services
                    Additionnels payants mentionnés dans la Liste des Prix (tels que les fonctions « Boost » ou «
                    Dressing en Vitrine » mentionnées dans la Liste des tarifs). Vous trouverez plus d’informations dans
                    le Guide du vendeur professionnel.
                    <h2>8. DONNÉES À CARACTÈRE PERSONNEL</h2>
                    <span className="color">8.1</span> Lorsque vous achetez un Article auprès d'un Vendeur Professionnel
                    sur le Site, certains traitements de données sont effectués par le Vendeur Professionnel et VIGNITUS
                    en qualité de responsables conjoints de traitement au sens de l'article 26(1), première phrase du
                    Règlement Général sur la Protection des Données. VIGNITUS vous informe sur les responsabilités de
                    VIGNITUS et des Vendeurs Professionnels ainsi que sur le point de contact désigné à l’article « 4.
                    Que devez-vous savoir lorsque vous interagissez avec un Vendeur Professionnel ? » Renseignez-vous
                    via : La Politique de Confidentialité
                    Pour plus d'informations, veuillez également consulter les politiques de confidentialité des
                    Vendeurs Professionnels, disponibles dans leurs profils, si les Vendeurs Professionnels ont inséré
                    leur propre politique. Les Vendeurs Professionnels sont seuls responsables du respect des lois
                    applicables en matière de protection des données et de confidentialité lorsqu'ils traitent les
                    données séparément et indépendamment.
                    <span className="color">8.3</span> Pour les traitements de données effectués uniquement par
                    VIGNITUS, plus d’informations sont fournies dans la Politique de Confidentialité.
                    <h2>9. NOTIFICATIONS ET COMMUNICATIONS</h2>
                    Toutes les notifications, demandes et autres informations seront envoyées comme indiqué dans les
                    présentes Conditions Générales de Vente :
                    - par l'Acheteur au Vendeur Professionnel : via le chat de l'Acheteur avec le Vendeur Professionnel
                    sur le Site, par email ou en utilisant les coordonnées mentionnées dans les Conditions
                    Supplémentaires du Vendeur Professionnel ;
                    - par l'Acheteur à VIGNITUS : via le formulaire contact figurant sur le Site ou en cas de litiges
                    entre l'Acheteur et le Vendeur Professionnel, dans le chat entre le Vendeur Professionnel et
                    l'Acheteur ;
                    - par le Vendeur Professionnel ou par VIGNITUS à l'Acheteur : par courriel, à l'adresse électronique
                    fournie lors de l'inscription sur le Site. Le Vendeur Professionnel peut également communiquer avec
                    l'Acheteur par le biais du chat entre l'Acheteur et le Vendeur Professionnel et/ou par courriel.
                    VIGNITUS peut également envoyer des messages privés à l'Acheteur.
                    <h2>10. CESSION</h2>
                    <span className="color">10.1</span> VIGNITUS peut, sous réserve des lois applicables en matière de
                    protection des données, transférer et/ou céder tous les droits et obligations découlant des
                    présentes Conditions Générales de Vente à tout tiers, notamment en cas de transfert d'un secteur
                    d'activité, de fusion par création d'une nouvelle société, de fusion par absorption, de scission ou
                    de tout changement de contrôle affectant VIGNITUS, à condition que cela ne diminue pas la protection
                    des droits du consommateur. Un tel transfert libère VIGNITUS de toute obligation en tant
                    qu'exploitant du Site. Les Vendeurs Professionnels et les Acheteurs ne peuvent transférer ou céder
                    tout ou partie de leurs droits ou obligations découlant des présentes Conditions Générales de Vente.
                    <span className="color">10.2</span> Si le Vendeur Professionnel ou VIGNITUS a l'intention de céder
                    cet accord à un tiers et que cette cession est susceptible d'entraîner une diminution de vos droits,
                    VIGNITUS ou le Vendeur Professionnel vous en informera en observant un délai de préavis raisonnable.
                    <h2>11. LOI APPLICABLE ; RÈGLEMENT DES DIFFÉRENDS</h2>
                    <span className="color">11.1</span> Les présentes Conditions Générales de Vente sont régies par le
                    droit national de l'Acheteur.
                    <span className="color">11.2</span> En cas de désaccord entre VIGNITUS et vous, vous pouvez utiliser
                    les options de résolution des litiges décrites dans les Conditions Générales de vente VIGNITUS.
                    <span className="color">11.3</span> En cas de désaccord entre le Vendeur Professionnel et vous,
                    veuillez-vous référer aux Conditions Additionnelles du Vendeur Professionnel.
                    <h2>12. Absence DE PARTENARIAT</h2>
                    Les présentes Conditions Générales de Vente n'ont pas pour effet de créer un partenariat entre le
                    Vendeur Professionnel et/ou VIGNITUS et vous, de relations de travail, de relations entre un agent
                    commercial et le client de ce dernier, ni une relation de franchise.
                    ANNEXE 1 MODÈLE DE FORMULAIRE DE RÉTRACTATION
                    Formulaire de retour de la commande
                    Adresse de retour du Vendeur :
                    [adresse]
                    [coordonnées de l'entreprise] Date de paiement : [Date]
                    Identifiant de la Transaction : [numéro d'identification]

                    Codes de retour
                    1 - Il semble s’agir d’un article contrefaisant
                    2 – Le niveau ne correspond pas à la description
                    3 - Je n'aime pas l'article
                    4 - Il est endommagé (taches/odeurs/trous/mauvaise couleur)
                    5 - La commande que j’ai reçue est incorrecte/incomplète
                    6 - Autres
                    Informations sur les retours
                    Pour retourner votre commande, il suffit de remplir ce formulaire et de le renvoyer au vendeur avec
                    le ou les Articles (dans leur état d'origine). Retour à la charge de l’acheteur sauf entente avec le
                    vendeur.


                </div>
                </div>
            </div>
        </div>)
}
export default Cgv;
