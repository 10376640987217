import { initReactQueryAuth } from "react-query-auth";
import api from "./api";
import { storage } from "./tools";
import firebase from "./firebase"

export async function handleUserResponse(data) {
    console.log(data)
    const jwt = data.data.token
    const user = data.data
    storage.setToken(jwt);
    return user;
}

async function loadUser() {
    let user = null;
    console.log("ici")
    if (storage.getToken()) {
        console.log('la')
       user= await api.getUser().then(data=>{
            console.log(data)
           return data.data
        }).catch(error=>{
            return null
        })
    }
    return user;
}

async function loginFn(data) {
    let response=null;
        if(data.uid==null){
            response = await api.loginUser(data.email, data.password);
        }else{
            response = await api.firebase(data.email,data.uid)
        }
        console.log(response)
        const user = await handleUserResponse(response);
        return user;


}
export async function firebaseLogin(userFirebase){
    const response = await api.firebase(userFirebase.email,userFirebase.uid)
    const user = await handleUserResponse(response);
    return user;
}

async function registerFn(data) {
    const response = await api.registerUser(data);
    const user = await handleUserResponse(response);
    return user;
}

async function logoutFn() {
    await firebase.auth().signOut();
    await storage.clearToken();

}

const authConfig = {
    loadUser,
    loginFn,
    registerFn,
    logoutFn
};

const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);

export { AuthProvider, useAuth };
