import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import {formatPrice} from "../../Services/tools";
import api from "../../Services/api";
import {useAuth} from "../../Services/auth";
import {useQuery} from "react-query";
import {useForm} from "react-hook-form";

export default function TransferWalletModal({open,setModalTranferWallet, setMsgInfo}) {
    const {user} = useAuth()
    const [nameTitulaire, setNameTitulaire] = useState(null);
    const [iban, setIban] = useState(null);
    const [bic, setBic] = useState(null);
    const [displayFormIban, setDisplayFormIban] = useState(false)
    const [successIban,setSuccessIban]=useState(null)
    const [errorIban,setErrorIban]=useState(null)
    const [err,setErr]=useState(null)


    const { isLoading,
        isError,
        error,
        data:wallets,
        isFetching,
        isPreviousData, } = useQuery(['repoWallet'], ()=> api.getWallets(),{ keepPreviousData : true }
    )
    const { isLoading:isLoadingIbans,
        isError:isErrorIbans,
        error:errorIbans,
        data:arrayIbans,
        isFetching:isFetchingIbans,
        refetch:refetchIbans,
        isPreviousData:isPreviousDataIbans } = useQuery(['repoIbans'], ()=> api.getIbans(),{ keepPreviousData : true }
    )
    const { register:registerIban, handleSubmit:handleSubmitIban, watch:watchIban, formState: { errors } } = useForm({defaultValues:{}});
    const onSubmitIban = async (data, e) => {
        try {
            let dataToSend = {
                OwnerName: data.name,
                OwnerAddress: {
                    AddressLine1:
                        data.address,
                    AddressLine2: "",
                    City: data.city,
                    Region: "",
                    PostalCode: "" + data.postal_code,
                    Country: "" + data.country,
                },
                IBAN: data.iban,
                BIC: data.bic,
                Tag: "iban user " + user.id,
            };
            api
                .postIban({ bankAccount: dataToSend })
                .then((response) => {
                    //setModalParametre(false);
                    console.log(response);
                    refetchIbans();
                    setSuccessIban('Votre Iban a été ajouté avec succès')
                    setErrorIban(null)
                })
                .catch((error) => {
                    setErrorIban("Votre Iban n'est pas valide")
                    console.log(error);
                });
            // if(img){
            //     data.base64=img
            // }
            // console.log(data)
            // await api.putUpdateUser(data.firstName,
            //     data.lastName,
            //     data.email,
            //     data.password,
            //     data.base64,
            //     data.number_siret,
            //     data.phone,
            //     data.description)
            // setSuccessIban('Votre Iban a été ajouté avec succès')
        } catch (error) {
            console.log(error)
            setErr(error.response.data.error)
        }
        e.preventDefault();

    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const sendIban = () => {

        let dataToSend = {
            OwnerName: nameTitulaire,
            OwnerAddress: {
                AddressLine1:
                    user.Addresses[0].number + " " + user.Addresses[0].address,
                AddressLine2: user.Addresses[0].additional_address,
                City: user.Addresses[0].city,
                Region: "",
                PostalCode: "" + user.Addresses[0].postal_code,
                Country: "" + user.Addresses[0].country,
            },
            IBAN: iban,
            BIC: bic,
            Tag: "iban user " + user.id,
        };
        api
            .postIban({ bankAccount: dataToSend })
            .then((response) => {
                //setModalParametre(false);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const ibanFormat = () => {
        let newIban =
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN[0] +
            "" +
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN[1] +
            "" +
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN[2] +
            "" +
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN[3] +
            "****" +
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN[
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN?.length - 4
                ] +
            "" +
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN[
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN?.length - 3
                ] +
            "" +
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN[
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN?.length - 2
                ] +
            "" +
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN[
            arrayIbans?.data[arrayIbans?.data?.length - 1].IBAN?.length - 1
                ];
        return newIban;
    };
    return(
        <div className="modal" style={{
            display: open ? 'block' : 'none'
        }}
             tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Transfert</h5>
                        <button onClick={()=>{
                            setModalTranferWallet(false)
                        }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{overflowX:"scroll", maxHeight:"80vh"}}>
                        <div style={{color:"#7E7D7D"}}>
                            Le transfert vers ton compte peut prendre jusqu'à 5 jours ouvrés.
                        </div>
                        <div
                            style={{
                                display:"flex",
                                alignItems: "center",
                                flexDirection: "row",
                                marginVertical: 10,
                                justifyContent: "space-between",
                                borderBottom:"solid 1px", borderBottomColor:"lightgray",paddingBottom:10, marginBottom:30
                            }}
                        >
                            <div
                                className="section-title"
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                Montant
                            </div>
                            <div
                                style={{fontWeight:800}}
                            >
                                {wallets?.data ? (wallets?.data?.wallet?.Balance?.Amount / 100).toFixed(2) : 0.0}
                                {" €"}
                            </div>
                        </div>
                        {err?(
                            <div className="alert alert-danger">
                                {err}
                            </div>
                        ):null}
                        {errorIban?(
                            <div className="alert alert-danger">
                                {errorIban}
                            </div>
                        ):null}
                        {successIban?(
                            <div className="alert alert-success">
                                {successIban}
                            </div>
                        ):null}
                        <div
                            style={{
                                display:"flex",
                                alignItems: "center",
                                flexDirection: "row",
                                marginVertical: 10,
                                justifyContent: "space-between",
                                borderBottom:"solid 1px", borderBottomColor:"lightgray",paddingBottom:10, marginBottom:30
                            }}
                        >

                            <div>
                            {arrayIbans?.data?.length ? (
                                <>
                                    <div
                                        style={{
                                        }}
                                    >
                                        {arrayIbans?.data[arrayIbans?.data?.length - 1].OwnerName}
                                    </div>
                                    <div
                                        style={{
                                        }}
                                    >
                                        {ibanFormat()}
                                    </div>
                                </>
                            ) : (
                                <div >Pas de compte associé</div>
                            )}</div>
                            <div style={{display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                            cursor:"pointer"}} className="btnMaroon" onClick={()=>{
                                setDisplayFormIban(true)
                            }}>
                                Ajouter un Iban
                                <i className="fa-solid fa-pen"></i>

                            </div>
                        </div>
                        {displayFormIban?(
                                <div
                                >
                                    <div
                                        className="section-title"
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Paramètres des transferts
                                    </div>
                                    <form onSubmit={handleSubmitIban(onSubmitIban)} noValidate>
                                        <div className="d-flex flex-column flex-nowrap">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="text-field-box" style={{display: "flex",
                                                    flexDirection: "column"}}>
                                                    <label style={{color:"#6b0019", fontWeight:800}} className="input-label">Prénom / Nom du titulaire du compte</label>
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        {...registerIban("name",{ required: true })}
                                                    />
                                                    {errors?.name?.type =="required"?(<div className="alert alert-danger mt-2">Le nom est requis</div>):null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="text-field-box" style={{display: "flex",
                                                    flexDirection: "column"}}>
                                                    <label style={{color:"#6b0019", fontWeight:800}} className="input-label">IBAN</label>
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        {...registerIban("iban",{ required: true })}
                                                    />

                                                    {errors?.iban?.type =="required"?(<div className="alert alert-danger mt-2">Le iban est requis</div>):null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="text-field-box" style={{display: "flex",
                                                    flexDirection: "column"}}>
                                                    <label style={{color:"#6b0019", fontWeight:800}} className="input-label">BIC</label>
                                                    <input type="text" className="input"
                                                           {...registerIban("bic",{ required: true })}
                                                    />
                                                    {errors?.bic?.type =="required"?(<div className="alert alert-danger mt-2">Le bic est requis</div>):null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="text-field-box" style={{display: "flex",
                                                    flexDirection: "column"}}>
                                                    <label style={{color:"#6b0019", fontWeight:800}} className="input-label">Adresse</label>
                                                    <input type="text" className="input"
                                                           {...registerIban("address",{ required: true })}
                                                    />
                                                    {errors?.address?.type =="required"?(<div className="alert alert-danger mt-2">L'adresse est requise</div>):null}
                                                </div>
                                                <div className="text-field-box" style={{display: "flex",
                                                    flexDirection: "column"}}>
                                                    <label style={{color:"#6b0019", fontWeight:800}} className="input-label">Ville</label>
                                                    <input type="text" className="input"
                                                           {...registerIban("city",{ required: true })}
                                                    />
                                                    {errors?.city?.type =="required"?(<div className="alert alert-danger mt-2">La ville est requise</div>):null}
                                                </div>
                                                <div className="text-field-box" style={{display: "flex",
                                                    flexDirection: "column"}}>
                                                    <label style={{color:"#6b0019", fontWeight:800}} className="input-label">Code postal</label>
                                                    <input type="text" className="input"
                                                           {...registerIban("postal_code",{ required: true })}
                                                    />
                                                    {errors?.postal_code?.type =="required"?(<div className="alert alert-danger mt-2">La code postal est requis</div>):null}
                                                </div>
                                                <div className="text-field-box" style={{display: "flex",
                                                    flexDirection: "column"}}>
                                                    <label style={{color:"#6b0019", fontWeight:800}} className="input-label">Pays</label>
                                                    <input type="text" className="input"
                                                           {...registerIban("country",{ required: true })}
                                                    />
                                                    {errors?.country?.type =="required"?(<div className="alert alert-danger mt-2">Le pays est requis</div>):null}
                                                </div>
                                            </div>
                                            <div  className="d-flex justify-content-center mt-3">
                                                <button style={{cursor:"pointer"}}>Ajouter</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                        ):null}
                    </div>
                    <div className="modal-footer" style={{justifyContent: "center"}}>
                        <button type="button" className="btnMaroon" style={{width:"auto",cursor:"pointer"}} onClick={()=>{
                            api.payout().then(()=>{

                            })
                        }}>Transférer vers un compte bancaire</button>
                    </div>
                </div>
            </div>
        </div>
    )

}