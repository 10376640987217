import React,{useState,useEffect} from "react";

import "./style.css";
import "moment/locale/fr";


function Catalogue() {
    return (
        <div className="container">
            <div className="whiteContainer">
                <div>
                <h1>Les règles du catalogue VIGNITUS</h1>
                <div style={{whiteSpace:"pre-line"}}>
                    Nous voulons veiller à ce que VIGNITUS soit un lieu sûr et convivial pour l’achat et la vente
                    d’articles de vins et spiritueux. Les membres sont donc tenus de respecter les règles du catalogue
                    ci-dessous lorsqu'ils mettent leurs articles en ligne.
                    Notons que ces règles font partie de nos TERMES ET CONDITIONS que les utilisateurs particuliers ont
                    acceptées au moment de leur inscription sur VIGNITUS, ainsi que des CGUP et des CGVP que les
                    vendeurs Pro ont acceptées au moment de leur inscription sur VIGNITUS.

                    <h2>1. POLITIQUES POUR LES VENDEURS PARTICULIERS </h2>

                    <span className="color">a. ARTICLES AUTORISÉS</span>
                    Sur VIGNITUS, tu peux vendre :
                    - des bouteilles de vin
                    - des spiritueux
                    - des pétillants
                    - des livres sur le vin ou les spiritueux
                    <span className="color">b. LES ARTICLES INTERDITS</span>
                    Voici ce que tu ne peux pas vendre sur VIGNITUS (note que cette liste n’est pas exhaustive et doit
                    être considérée uniquement comme un guide) :
                    Articles strictement interdits :
                    Les articles qui ne sont pas conformes aux normes d’hygiène ou qui présentent un risque pour la
                    santé :
                    Les articles qui ne sont pas dans la liste ci-dessus.

                    Veille à ce que tes annonces et articles soient conformes aux règles de notre catalogue. Si l’une de
                    tes annonces semble non conforme à ces règles, d’autres membres peuvent la signaler afin que
                    VIGNITUS l’examine. S’il s’avère que ton article n’est effectivement pas conforme, il pourra être
                    retiré ou masqué par notre équipe. Tu pourras également faire l’objet d’autres sanctions, telles
                    qu’une suspension ou un blocage de ton compte.
                    Nous nous réservons le droit de redéfinir les articles qui peuvent ou non être acceptés sur
                    VIGNITUS. Si nous découvrons que certains articles enfreignent nos conditions ou présentent un
                    risque quelconque pour nos membres, nous pouvons retirer ces articles même s'ils ne figurent pas
                    dans la liste ci-dessous.

                    Remarque : si tu supprimes un article qui enfreint les règles de notre catalogue et que nous devons
                    le supprimer ou le masquer, nous ne pourrons pas te rembourser le coût du Boost.

                    <span className="color">c. RÈGLES GÉNÉRALES</span>
                    1. Généralités
                    2. Conditions requises pour les photos de l’article
                    3. Politique en matière de propriété intellectuelle
                    4. Activités commerciales
                    5. PÉNALITÉS

                    <h2>2. POLITIQUES POUR LES VENDEURS PRO </h2>

                    Les utilisateurs Pro (ci-après nommés “vendeurs Pro”) sont mis en évidence sur le site avec une
                    étiquette “Pro” située à côté de leur nom d’utilisateur.

                    <span className="color">a. ARTICLES AUTORISÉS</span>
                    Sur VIGNITUS, tu peux vendre :
                    Les mêmes articles que ceux cités dans la liste des produits autorisés ci-dessus

                    <span className="color">b. LES ARTICLES INTERDITS</span>
                    Voici ce que tu ne peux pas vendre sur VIGNITUS (note que cette liste n’est pas exhaustive et doit
                    être considérée uniquement comme un guide) :
                    Articles strictement interdits :
                    Les articles qui ne sont pas conformes aux normes d’hygiène ou qui présentent un risque pour la
                    santé :
                    Les articles contrefaits
                    Veille à ce que tes annonces et articles soient conformes aux règles de notre catalogue. Si l’une de
                    tes annonces semble non conforme à ces règles, d’autres membres peuvent la signaler afin que
                    VIGNITUS l’examine. S’il s’avère que ton article n’est effectivement pas conforme, il pourra être
                    retiré ou masqué par notre équipe. Tu pourras également faire l’objet d’autres sanctions, telles
                    qu’une suspension ou un blocage de ton compte.
                    Notre équipe se réserve le droit de redéfinir quels articles sont autorisés ou interdits sur
                    VIGNITUS. Si nous constatons que certains articles enfreignent nos règles ou présentent un risque
                    pour nos membres, nous pouvons retirer ces articles même s’ils ne sont pas compris dans la liste
                    ci-dessous.

                    Remarque : si tu supprimes un article qui enfreint les règles de notre catalogue et que nous devons
                    le supprimer ou le masquer, nous ne pourrons pas te rembourser le coût du Boost.

                    <span className="color">c. RÈGLES GÉNÉRALES</span>
                    1. Généralités

                    Tu dois mettre en ligne chacun de tes articles séparément, dans la catégorie correspondante (et sous
                    le nom de marque correspondant, le cas échéant).
                    Tu ne dois avoir qu’une seule annonce par article.
                    Tous les articles doivent avoir une description correcte de l’état
                    La valeur maximale des articles autorisée sur notre plateforme est de 45 000 euros.
                    Les annonces ou publications liées à une vente aux enchères ou à un appel d’offres ne sont pas
                    autorisées. Si le prix de ton article est exceptionnellement bas/élevé, ou ne semble pas
                    correspondre à la valeur réelle de l’article, ton annonce pourrait être considérée comme une mise
                    aux enchères.
                    2. Conditions requises pour les photos de l’article

                    Les photos utilisées dans tes annonces doivent répondre aux exigences suivantes :
                    Elles doivent représenter l’article tel qu’il est : aucune modification de la photo n’est autorisée.
                    Il est également recommandé de montrer les défauts, les rayures, l’usure, etc.
                    Elles doivent être nettes et claires..
                    Elles ne peuvent pas être remplacées par des photos issues de banques d’images, des photos
                    publicitaires et des photos avec un copyright.
                    La première photo doit représenter clairement l’ensemble de l’article. Tu es libre de choisir la
                    façon dont tu souhaites mettre en valeur ton article dans les photos supplémentaires. Remarque : tu
                    peux mettre en ligne jusqu’à 20 photos par article.
                    Elles doivent mettre en évidence l’authenticité de ton article

                    <h2>3. Politique en matière de propriété intellectuelle</h2>

                    Il est strictement interdit de mettre en ligne des articles contrefaits sur notre plateforme. Les
                    contrefaçons sont généralement des copies ou des imitations de la marque d’une entreprise (par
                    exemple le nom de la marque, le nom de son produit et/ou son logo) sans son autorisation. Elles
                    peuvent également copier les caractéristiques spécifiques et notables du produit de cette entreprise
                    . Les vendeurs d’articles contrefaits sont passibles de poursuites civiles et pénales.
                    Les membres ne peuvent mettre en ligne que des articles dont ils sont certains de l’authenticité.
                    Ils ne peuvent pas ajouter à leur annonce une clause de non-responsabilité indiquant qu’ils ne sont
                    pas sûrs que l’article est authentique ou non.
                    Les membres doivent également télécharger des photos prouvant l’authenticité de leur article.
                    L’utilisation d’autres marques qui ne sont pas liées à ton article dans la catégorie de la marque,
                    du titre/description ou comme hashtag dans ton annonce n’est pas autorisée.
                    D’une manière générale, l’utilisation de termes tels que "inspiré par" suivi d’un nom de marque est
                    également interdite sur notre plateforme.
                    Si tu utilises des textes ou des photos créés/publiés par quelqu'un d'autre, tu dois avoir obtenu
                    l’autorisation de le faire.

                    <h2>4. Politiques sur les activités commerciales</h2>

                    Les vendeurs Pro sont autorisés à utiliser VIGNITUS dans le cadre de leurs activités
                    professionnelles. Toutefois, en plus des restrictions susmentionnées, les vendeurs Pro sont
                    uniquement autorisés à vendre des articles de seconde main et ne sont pas autorisés à :
                    Proposer des services de location d’articles ;
                    Proposer des articles portant des mentions telles que "peut être commandé" ; “actuellement en
                    rupture de stock” ; “bientôt disponible”, etc. ;
                    Proposer de revendre des articles achetés en gros ou généralement vendus comme articles de
                    dropshipping de faible valeur
                    <h2>5. PÉNALITÉS</h2>

                    Lorsque les membres ne respectent pas les règles de notre catalogue, nous pouvons prendre les
                    mesures suivantes :
                    Si une annonce signalée nécessite quelques modifications, nous la rendrons invisible dans l’espace «
                    Ma Cave » du membre concerné et nous informerons celui-ci des mesures à prendre par message. Si le
                    membre concerné ne prend pas ces mesures dans le délai imparti, son article restera masqué ;
                    Si une annonce signalée contient un article interdit, nous retirerons l’annonce de l’espace du
                    membre concerné et nous lui enverrons un message afin de lui expliquer raison de ce retrait ;
                    Si un membre met en ligne des articles interdits (c’est-à-dire des articles qui font partie d’une
                    liste d'articles interdits tels que décrits ci-dessus, des articles de nature apparemment
                    commerciale ou tout autre article qui enfreindrait nos Termes & Conditions et/ou les règles du
                    catalogue) de manière répétée, nous pourrons suspendre son compte (par exemple pour une période de 7
                    jours) ou encore le bloquer définitivement selon les cas. Nous enverrons un message au membre
                    concerné pour l’informer de notre décision.
                </div>
                </div>
            </div>
        </div>)
}
export default Catalogue;
