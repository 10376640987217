import Navbar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer/index";
import LoginComponent from "../../Components/Login/index";



const Login = () => {
    return (
        <div>
            <Navbar />
            <div className="content-wraper">
                <LoginComponent/>
            </div>
            <Footer />
        </div>
    );
};

export default Login;