import React from "react";
import { Navbar, Footer } from "./../../Components";
import SubscriptionContainer from "./../../Containers/Subscription/SubscriptionContainer";
function Subscription() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
        <SubscriptionContainer />
      </div>
      <Footer />
    </div>
  );
}

export default Subscription;
