import React,{useEffect,useState} from "react";
import BottleImage from "./../../Assets/wineBottle.jpg";
import "./horizontalCard.css";
import {UrlBaseServer} from "../../Services/storageServer";
import ProfileImg from "../../Assets/defaultAvatar.png";

function HorizontalCard({ data, purchase,setSelectedData }) {
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            compareDate();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    function compareDate() {
        let parseDate = data.updatedAt.replace(" ", "T");
        let dateProduct = new Date(parseDate);
        let dateActual = new Date();

        const diffTime = Math.abs(dateActual - dateProduct);
        //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let time = "";
        if (diffTime < 60000) {
            //secondes
            time = Math.ceil(diffTime / 1000) + "s";
        } else if (diffTime < 3600000) {
            //minutes
            time = Math.ceil(diffTime / 60000) + " min";
        } else if (diffTime < 86400000) {
            //heures
            time = Math.ceil(diffTime / 3600000) + " h";
        } else if (diffTime < 604800000) {
            //jours
            time = Math.ceil(diffTime / 86400000) + " jour(s)";
        } else if (diffTime < 2629800000) {
            //semaines
            time = Math.ceil(diffTime / 604800000) + " semaine(s)";
        } else if (diffTime < 31557600000) {
            //mois
            time = Math.ceil(diffTime / 2629800000) + " mois";
        } else {
            time = Math.ceil(diffTime / 31557600000) + " année(s)";
        }
        setTimer(time);
    }
    function getUser() {
        let name = "";
        if (purchase) {
            if (data?.OfferProduct[0]?.User?.firstName) {
                name = capitalize(data?.OfferProduct[0]?.User?.firstName);
            } else {
                name = "";
            }
        } else {
            if (data?.User?.firstName) {
                name = capitalize(data?.User?.firstName);
            } else {
                name = "";
            }
        }
        return name;
    }
    function getUserImage(){
        if (purchase) {
            if (data?.OfferProduct[0]?.User?.avatar) {
                return UrlBaseServer+""+data?.OfferProduct[0]?.User?.avatar
            }else {
                return ProfileImg
            }
        }else{
            if (data?.User?.avatar) {
                return UrlBaseServer+""+data?.User?.avatar
            }else {
                return ProfileImg
            }
        }
    }
    function capitalize(s) {
        return s[0].toUpperCase() + s.slice(1);
    }

    function returnStatus() {
        if (data.status == 1) {
            return {
                color: "text-warning",
                text: purchase
                    ? "Commande en attente - vous devez régler la commande."
                    : "Commande en attente - l’acheteur doit réaliser le paiement.",
            };
        }
        if (data.status == 2) {
            return {
                color: "text-warning",
                text: purchase
                    ? "Commande en attente - le vendeur doit valider votre commande."
                    : "Commande en attente - vous devez valider la commande.",
            };
        }
        if (data.status == 3) {
            return {
                color: "text-danger",
                text: purchase
                    ? "Vous avez annulé la commande."
                    : "Commande annulé par l'acheteur.",
            };
        }
        if (data.status == 4) {
            return {
                color: "text-danger",
                text: purchase
                    ? "Commande refusé par le vendeur."
                    : "Vous avez refusé la commande.",
            };
        }
        if (data.status == 5) {
            return {
                color: "text-warning",
                text: purchase
                    ? "Vous avez payer la commande, en attente de l'envoie."
                    : "L'acheteur a payé la commande, vous devez l'envoyer.",
            };
        }
        if (data.status == 6) {
            return {
                color: "text-warning",
                text: purchase
                    ? "Le vendeur a envoyé la commande, en attente de réception de votre part."
                    : "Vous avez envoyé la commande, en attente de réception de l'acheteur.",
            };
        }
        if (data.status == 7) {
            return {
                color: "text-success",
                text: purchase
                    ? "Vous avez reçu la commande."
                    : "L'acheteur a indiqué avoir reçu la commande.",
            };
        }
        if (data.status == 10) {
            return {
                color: "text-danger",
                text: purchase
                    ? "Un problème est survenue sur la commande."
                    : "Un problème est survenue sur la commande.",
            };
        }
        if (data.status == 11) {
            return {
                color: "text-success",
                text: purchase
                    ? "Le vendeur vous a fait le remboursement de la commande."
                    : "Vous avez fait un remboursement.",
            };
        }
    }
  return (
    <div onClick={()=>{
        setSelectedData(data)
    }} className="horizontal-card d-flex align-items-center flex-md-row flex-column flex-nowrap cursor-pointer">
      <div className="col-12  col-md-2 col-lg-2">
          {}
        <img src={getUserImage()} alt="" className="horizontal-card-image" />
      </div>
      <div className="col-12  col-md-8 col-lg-8">
        <div className="d-flex align-items-center m-0">
          <p className="horizontal-card-name mr-3">{getUser()}</p>
          <p className="horizontal-card-time">il y a {timer}</p>
        </div>
        <p
          className={`horizontal-card-description ${
              returnStatus()?.color
          }`}
        >
            {returnStatus()?.text}
        </p>
      </div>
      <div className="col-12  col-md-2 col-lg-2">
        <h3 className="horizontal-card-prize">{data.price}€</h3>
      </div>
    </div>


  );
}

export default HorizontalCard;
