import animationValidate from "../../Assets/animations/validation.json";
import Lottie from "lottie-react";
import React from "react";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

export default function SuccessModal({setShowSuccessModal,showSuccessModal}) {
    const navigate = useNavigate();

    return(
        <Modal show={showSuccessModal} onHide={()=>{setShowSuccessModal(false)}} animation={false}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}} className="successModal modal-dialog-centered" role="document">
                <Lottie
                    style={{ height: 400 }}
                    animationData={animationValidate}
                    autoPlay
                    loop={false}
                    onComplete={()=>{
                        navigate("/messaging"
                        )
                        setShowSuccessModal(false)}

                    }
                />
            </div>
        </Modal>
    )
}
