import React,{useState,useEffect} from "react";

import "./style.css";
import "moment/locale/fr";


function Cgu() {
    return (
        <div className="container">
            <div className="whiteContainer">
                <div>
                <h1>Termes et Conditions d'utilisation de la plateforme VIGNITUS</h1>
                <div style={{whiteSpace:"pre-line"}}>
                    <span className="color">CONDITIONS GÉNÉRALES D'UTILISATION</span> : version applicable à partir du
                    31 janvier 2022
                    La plateforme VIGNITUS (ci-après le “Site”) est gérée et hébergée par la société SAS VIGNITUS située
                    à 34540 BALARUC LE VIEUX en France, inscrite au Registre des Sociétés de la République Française
                    sous le numéro 907696744.
                    Avant de naviguer ou de s’inscrire sur le Site, chaque Utilisateur doit lire les présentes
                    Conditions Générales d’Utilisation (ci-après les “Conditions Générales”), la Politique de
                    Confidentialité (telle définie dans l’Article Définitions ci-dessous), ainsi que les autres
                    dispositions obligatoires du Site, rappelées ou non dans les présentes Conditions Générales (y
                    compris les Règles du Catalogue, les Règles du Forum et la Liste des Tarifs), les accepter et
                    s’engager à les respecter.

                    <h2>1 DÉFINITIONS</h2>
                    Acheteur - désigne tout Utilisateur qui achète ou souhaite acheter un ou plusieurs Article(s) sur le
                    Site.

                    Articles – désignent les marchandises/articles dont la commercialisation n’est pas interdite en
                    vertu du droit national applicable, et qui sont ajouté(e)s par l’Utilisateur sur le Catalogue du
                    Site correspondant, aux fins de les vendre, échanger, ou donner.

                    Catalogue - désigne le catalogue électronique dans lequel les Articles du même type sont
                    répertoriés, étant entendu que tous les Utilisateurs doivent se conformer aux règles du Catalogue
                    mises en place par VIGNITUS.

                    Compte d’Utilisateur ou Compte désigne l’inscription de l’Utilisateur sur le Site ; le compte créé
                    contient des données à caractère personnel.

                    Comptes sur les Réseaux Sociaux – désignent les comptes VIGNITUS sur Facebook, Google+, Twitter,
                    Instagram, Pinterest, YouTube et autres réseaux sociaux, sur lesquels figurent des informations
                    concernant le Site et les Services qui y sont proposés, y compris des partages des contenus publiés
                    par les Utilisateurs.

                    Conditions – désignent les présentes Conditions Générales ainsi que toutes les politiques et
                    conditions supplémentaires publiées sur le Site définissant les conditions dans lesquelles VIGNITUS
                    permet aux Utilisateurs et aux Visiteurs d’accéder aux et d’utiliser les Services de VIGNITUS

                    Confirmation de Réception – désigne la confirmation de la réception de l’Article par l’Acheteur ou
                    l’Utilisateur..
                    Liste des Tarifs – désigne le document qui indique les tarifs de VIGNITUS
                    Messages Privés – désignent la communication par échange de messages privés entre les Utilisateurs
                    au moyen du système de messagerie du Site, à laquelle seuls les Utilisateurs concernés peuvent avoir
                    accès.
                    Pépites– désignent le contenu partagé par VIGNITUS sur le Site relatif en particulier aux offres les
                    plus recherchés par les internautes sur la plateforme.
                    Politique de Confidentialité – désigne le fichier disponible sur la plateforme qui définit la
                    manière dont VIGNITUS collecte, utilise, conserve et transfère les données à caractère personnel et
                    autres informations importantes d’un Visiteur ou d’un Utilisateur.

                    Protection Acheteur - désigne le Service Complémentaire proposé par la plateforme VIGNITUS, dans le
                    cadre duquel l'Acheteur bénéficiera de protections supplémentaires en cas d’achat sur la plateforme,
                    litige d’envoi colis, casse, contrefaçon.

                    Protection Acheteur Pro - désigne le Service Complémentaire proposé par VIGNITUS pour les achats
                    effectués auprès de Vendeurs Professionnels. Le Service de Protection Acheteur Pro est identique aux
                    protections particuliers en prenant compte que le retour sous 14j est obligatoire donc doit être
                    proposé par l’ensemble des vendeurs professionnels.La prise en charge des frais de retour doivent
                    être convenus avec le vendeur.

                    Services – désignent l’ensemble des services proposés sur le Site, y compris les Services Principaux
                    et les Services Complémentaires.

                    Services Complémentaires – désignent les services complémentaires payants fournis par VIGNITUS et
                    disponibles sur le Site (comme indiqué dans la Liste des Tarifs), par exemple le service permettant
                    de booster des Articles, qui ne sont pas considérés comme des Services Principaux et qui sont
                    susceptibles de faire l’objet de conditions générales d’utilisation distinctes.

                    Service de Paiement et d'Envoi Intégrés – désigne le Service composé du Service de Paiement Intégré
                    et du Service d'Envoi Intégré, optionnel pour l’Acheteur, facilitant et sécurisant le paiement et la
                    livraison d’un Article lorsque la Transaction consiste en un contrat de vente :
                    En Belgique: le Service de Paiement est Intégré et disponible sur le territoire continental de
                    Belgique ;
                    En France: le Service de Paiement est disponible en France métropolitaine et en Corse ;
                    En Italie: le Service de Paiement est disponible sur le territoire italien, à l'exception de
                    Saint-Marin et de la Cité du Vatican ;
                    En Espagne: le Service de Paiement est disponible sur le territoire de la péninsule espagnole, à
                    Ibiza et à Majorque ;
                    Aux Pays-Bas: le Service de Paiement et d'Envoi Intégrés est disponible sur le territoire
                    continental des Pays-Bas ;
                    Au Luxembourg: le Service de Paiement et d'Envoi Intégrés est disponible sur le territoire du
                    Grand-Duché de Luxembourg.
                    Portugal: le service intégré de paiement et d'envoi est disponible sur le territoire du Portugal
                    continental et des îles portugaises

                    Service de Paiement Intégré – désigne le Service de paiement en ligne des Articles, accessible à
                    l’Acheteur sur le Site, les prestations de paiement étant exécutées par le partenaire de VIGNITUS
                    MANGOPAY SA, une société à responsabilité limitée enregistrée au Luxembourg, sous le numéro
                    d'immatriculation B173459 et dont le siège social est situé au 2, avenue Amélie, L-1125 Luxembourg.
                    Services Principaux – désignent les services offerts par VIGNITUS aux Utilisateurs et, le cas
                    échéant, aux Visiteurs, leur permettant d’ajouter des Articles sur le Catalogue correspondant,
                    d’examiner les Articles, de participer directement à l’échange ou à l’achat-vente, ou à d’autres
                    Transactions, d’utiliser les Services de Paiement intégré et de communiquer entre eux en privé et/ou
                    en public, notamment via le Forum.
                    Site – désigne le site internet VIGNITUS, ainsi que les applications mobiles de la plateforme
                    VIGNITUS téléchargées sur smartphones ou tablettes.
                    Transaction – désigne toute transaction qui transfère la propriété et/ou le droit d'utiliser un
                    Article, d’un Utilisateur à un autre, notamment mais sans s’y limiter, le contrat de vente conclu
                    directement entre l’Acheteur et le Vendeur, ou l’échange, ou toute autre transaction effectuée entre
                    les Utilisateurs.
                    Utilisateur – désigne toute personne physique qui s’est inscrite sur le Site et a accepté les
                    conditions générales de VIGNITUS. Par souci de clarté, un Utilisateur en vertu des présentes
                    Conditions Générales désigne un particulier qui est un consommateur et qui agit en tant que tel.
                    Utilisateur Professionnel/Vendeur Professionnel - désigne tout utilisateur qui ajoute un ou
                    plusieurs Articles au Catalogue correspondant du Site dans le cadre de son activité professionnelle.
                    Les Utilisateurs Professionnels et les Vendeurs Professionnels peuvent être des entreprises
                    individuelles ou des organisations à but non lucratif (associations au sens de la loi du 1er juillet
                    1901), dûment enregistrées en France, comme décrit dans le guide du vendeur professionnel disponible
                    sur la plateforme qui contient un aperçu de leurs droits et obligations légales. Les Vendeurs
                    Professionnels sont identifiés sur le Site par une mention “Pro” affichée à côté de leur nom.
                    Vendeur – désigne tout Utilisateur qui ajoute un ou plusieurs Article(s) sur le Catalogue du Site
                    correspondant.
                    Visiteur – désigne toute personne physique non inscrite sur le Site susceptible d’utiliser le Site
                    sans être inscrite, conformément aux Conditions Générales.
                    <h2>2 OBJET</h2>
                    <span className="color">2.1</span> Les présentes Conditions Générales définissent les relations
                    entre VIGNITUS et les Utilisateurs ou les Visiteurs ainsi que la relation entre les Utilisateurs,
                    les Visiteurs ou les Utilisateurs et les Visiteurs, et notamment les règles applicables à:
                    l'inscription et l'utilisation du Site par les Utilisateurs;
                    le rôle de VIGNITUS en tant qu'intermédiaire et les services qu'elle propose dans le cadre des
                    Transactions entre Utilisateurs; et
                    les Transactions portant sur des Articles ajoutés par des Vendeurs agissant en tant que
                    consommateurs, que l'Acheteur soit un Utilisateur Professionnel ou un Utilisateur non-professionnel.
                    Veuillez noter que VIGNITUS n'est partie à aucune des Transactions effectuées sur sa plateforme.
                    En utilisant ou en accédant au Site, vous acceptez d'être juridiquement lié par les présentes
                    Conditions Générales.

                    En utilisant ou en accédant au Site, vous acceptez d'être juridiquement lié par les présentes
                    Conditions Générales.
                    <span className="color">2.2</span> Les Utilisateurs Professionnels ne sont autorisés à utiliser les
                    Services qu'après avoir accepté les présentes Conditions Générales et les conditions générales pour
                    les vendeurs professionnels. En cas d'incohérence entre les présentes Conditions Générales et les
                    conditions d'utilisation de VIGNITUS applicables aux Vendeurs Professionnels, ces dernières
                    prévaudront.
                    Les conditions générales de VIGNITUS applicables aux ventes effectuées par des Vendeurs
                    Professionnels sont définies dans les conditions générales pour les vendeurs professionnels. Les
                    Utilisateurs doivent les lire et accepter avant d'acheter des Articles auprès de Vendeurs
                    Professionnels.
                    <span className="color">2.3</span> Vous pouvez contacter VIGNITUS en remplissant le formulaire
                    disponible sur la plateforme.
                    <span className="color">2.4</span> Le Site offre à l’Utilisateur la possibilité de participer à
                    l’échange et/ou la vente et/ou l’achat d’Articles, et d’utiliser les autres Services Complémentaires
                    proposés par le Site.
                    <span className="color">2.5</span> Les Services ne sont pas destinés aux mineurs.
                    <span className="color">2.6</span> Lors de son inscription sur le Site, l’Utilisateur doit indiquer
                    son nom d’utilisateur (pseudonyme), son adresse de courrier électronique (directement ou via son
                    profil Facebook, Google ou Apple), et, si l'Utilisateur ne s'inscrit pas par le biais de son profil
                    Facebook, Google ou Apple, un mot de passe, ce qui permet au Site d’identifier l’Utilisateur à
                    chaque fois qu’il accède au Site. Même si l’Utilisateur a fourni ses nom et prénom(s) au moment de
                    son inscription sur le Site, les autres Utilisateurs ne peuvent voir que le nom d’utilisateur
                    (pseudonyme) de l’Utilisateur, qui peut être différent de ses nom et prénom(s). VIGNITUS peut
                    demander à effectuer certaines confirmations concernant le Compte de l'Utilisateur à des fins de
                    sécurité. De telles confirmations peuvent notamment inclure la confirmation du numéro de téléphone
                    de l'Utilisateur ; une demande des preuves du titulaire d’une carte bancaire ou toutes autres
                    démarches que VIGNITUS pourrait mettre en place à sa seule discrétion.
                    <span className="color">2.7</span> VIGNITUS traitera les données à caractère personnel de
                    l'Utilisateur comme décrit dans la politique de Confidentialité, aux fins de l'exécution des
                    présentes Conditions Générales à titre de contrat avec l'Utilisateur et à d'autres finalités
                    légales.
                    <span className="color">2.8</span> VIGNITUS peut, à son entière discrétion, modifier les Conditions
                    Générales, notamment afin :
                    d’adapter les Conditions Générales aux dispositions législatives ou réglementaires en vigueur ou en
                    cours d’adoption ;
                    d’adapter les Conditions Générales à toute décision rendue par une juridiction, une agence de
                    consommation ou par toute autre autorité compétente et qui impacte le contenu du Site ou les
                    Services offerts sur celui-ci ;
                    de refléter des changements et des évolutions dans la manière dont VIGNITUS gère son activité ;
                    de refléter des changements dans les conditions du marché ou dans les pratiques courantes de
                    l'industrie ;
                    de modifier la Liste des Tarifs, en particulier les tarifs qui y sont indiqués.

                    Si VIGNITUS apporte l'une quelconque des modifications des Conditions Générales mentionnées
                    ci-dessus, elle en informera les Utilisateurs :
                    quinze (15) jours avant l’entrée en vigueur des modifications, ou
                    quatre (4) semaines avant l’entrée en vigueur des modifications en cas de modifications
                    substantielles (par exemple, des modifications renforçant les obligations des Utilisateurs ou qui
                    ont un impact sur les fonctionnalités principales du Site).
                    A moins que l'Utilisateur ne mette fin à sa relation avec VIGNITUS avant le jour d’entrée en vigueur
                    des modifications, les Conditions Générales modifiées sont réputées acceptées par l'Utilisateur. Si
                    l'Utilisateur s'y oppose dans les délais, il peut choisir de mettre fin à sa relation avec VIGNITUS
                    et de fermer son Compte VIGNITUS, conformément à nos conditions. Toute modification des Conditions
                    Générales publiées sur le Site s’appliquera immédiatement aux Visiteurs et aux Utilisateurs qui
                    effectuent la procédure d'inscription en ligne après la publication desdites modifications sur le
                    Site.

                    <h2>3 SERVICES</h2>
                    <span className="color">3.1</span> VIGNITUS est un service intermédiaire d'hébergement en ligne et
                    propose des Services permettant de faciliter les Transactions, un espace d’échange virtuel
                    communautaire, à savoir le Site, le Catalogue systématisé. Les Utilisateurs peuvent ajouter leurs
                    Articles au Catalogue approprié du Site afin d’échanger, d’acheter ou de vendre des Articles,
                    utiliser le Service de Paiement intégré, communiquer en envoyant des Messages Privés aux autres
                    Utilisateurs, échanger et utiliser d’autres Services Complémentaires proposés sur le Site. VIGNITUS
                    n’est partie à aucune Transaction entre les Utilisateurs. En aucun cas VIGNITUS n’achète, ne vend ou
                    n’échange des Articles présentés sur le Site ni ne prétend le faire. VIGNITUS propose des Services
                    facilitant les Transactions.
                    Une rubrique rachat de cave permet aux membres ne souhaitant pas vendre sur la plateforme de nous
                    joindre un fichier détaillé afin que nous lui proposions un rachat, cette activité permettra de
                    compléter l’offre du site et de proposer dans un second temps une future activité d’épargne vin à
                    nos membres.

                    <span className="color">3.2</span> Sous réserve des conditions indiquées sur le Site, les
                    Utilisateurs peuvent souscrire des Services Complémentaires payants sur le Site (aux tarifs indiqués
                    dans la liste des tarifs disponible ainsi qu’au moment du paiement avant de conclure la
                    Transaction).
                    <span className="color">3.3</span> Les présentes Conditions Générales, la Politique de
                    Confidentialité et les autres règles de VIGNITUS publiées sur le Site, s’appliquent aux
                    Utilisateurs, aux Utilisateurs Professionnels et aux Visiteurs des Comptes sur les Réseaux Sociaux
                    VIGNITUS, lesquels doivent s’y conformer.
                    <h2>4 AJOUT DES ARTICLES</h2>
                    <span className="color">4.1</span> Pour ajouter un Article sur le Site, l'ensemble des exigences
                    énoncées dans le présent article ainsi que les conditions suivantes doivent être respectées :
                    Seuls les produits autorisés dans les Règles du Catalogue peuvent être ajoutés sur le Site.
                    Le Vendeur doit s’assurer que ses Articles sont conformes aux règles du Catalogue. Certaines
                    catégories d’Articles ne peuvent être mises en ligne.
                    Le Vendeur doit être le propriétaire des Articles ajoutés et ne doit pas ajouter d'articles
                    généralement vendus comme des articles de dropshipping.
                    La vente, l’échange, l’utilisation ou la possession de tels Articles ne doivent en aucun cas violer
                    les droits d’un tiers, doivent respecter les droits de propriété intellectuelle et ne doivent violer
                    aucune loi ou réglementation locale, nationale ou internationale applicable.
                    L’Article doit répondre aux exigences communément appliquées sur le marché pour ce type d’Article en
                    fonction de la nature de la Transaction, sauf accord contraire entre l’Acheteur et le Vendeur.
                    <span className="color">4.2</span> Le Vendeur, lorsqu’il ajoute un Article au Catalogue, doit
                    remplir le questionnaire relatif à l’ajout de l’Article. Le Vendeur doit décrire l’Article aussi
                    précisément que possible, en signaler les défauts éventuels et indiquer le tarif de l’Article. Le
                    Vendeur, lorsqu’il dépose l’Article, déclare que ce dernier est conforme à la description qu’il
                    fournit, et que cette description est exhaustive. L’ajout des Articles sur le Catalogue du Site est
                    gratuit. Les Vendeurs peuvent décider de payer pour des Services Complémentaires, afin par exemple
                    d'améliorer la visibilité de leurs Articles.
                    <span className="color">4.3</span> L’Article ajouté au Catalogue ne doit pas seulement être décrit
                    dans le questionnaire relatif à l’ajout de l’Article, il doit également être photographié. Au moins
                    une photographie (ou plus, si nécessaire, selon l'Article) de bonne qualité doit être téléchargée
                    lors de l’ajout de l’Article (il est interdit d’utiliser des photos trouvées sur Internet et/ou des
                    photos d’un Article similaire). Elle doit refléter la qualité réelle et l’aspect extérieur de
                    l’Article, ainsi que l’éventuelle présence de défauts, vices ou retouches sur l’Article.
                    <span className="color">4.4</span> La quantité d’Articles ajoutés au Catalogue du Site est
                    illimitée. Il est interdit d’ajouter le même Article plus d’une fois au Catalogue du Site pour les
                    particuliers en simultané sauf si le millésime ou l’Etat peut être diffèrent.
                    <span className="color">4.5</span> Le Vendeur qui a ajouté l’Article peut le retirer ou modifier son
                    tarif à tout moment avant d’entrer en contact avec un Utilisateur pour la vente ou l’échange de
                    l’Article en question.
                    <span className="color">4.6</span> D'autres obligations ou restrictions s'appliquent aux Vendeurs
                    Professionnels. Pour plus d’informations à cet égard, veuillez consulter les conditions générales
                    d’utilisation pour vendeurs professionnels.


                    <h2>5 VENTE D’ARTICLES ET PRIX DES SERVICES COMPLEMENTAIRES</h2>
                    <span className="color">5.1</span> Lors d'un achat auprès d'un Vendeur non-professionnel, l'Acheteur
                    peut choisir de recevoir l'Article de trois manières différentes : (1) lors de l’achat sur le Site
                    via le Système de Paiement Intégré proposé par VIGNITUS, en cliquant le bouton “Acheter”, dans le
                    cadre duquel les frais de Protection Acheteur s'appliquent automatiquement et obligatoirement, ou
                    (2) lors d’une Transaction directe entre Utilisateurs. Dans ce dernier cas, l'Acheteur et le Vendeur
                    sont seuls responsables de la vente, de l'organisation et des conséquences de l’envoi de l'Article
                    et ne peuvent utiliser l'assistance fournie par VIGNITUS dans ce contexte.
                    Les achats auprès des Vendeurs Professionnels sont uniquement possibles via le Système de Paiement
                    Intégré en cliquant le bouton “Acheter”, où les frais de Protection Acheteur s'appliquent
                    automatiquement et obligatoirement. Pour plus d'informations, veuillez consulter les conditions
                    générales d’utilisation pour vendeurs professionnels.
                    Pour les besoins des Services Complémentaires, l'Utilisateur devra fournir ses coordonnées bancaires
                    pour que les bénéfices des ventes réalisées sur le Site puissent être transférés sur son compte
                    bancaire personnel. Si un compte ou une carte bancaire appartenant à une tierce personne sont
                    enregistrés sur le Site, l’équipe VIGNITUS peut demander des pièces justificatives d’identité́. Les
                    Services Complémentaires déclencheront le paiement par l'Utilisateur des frais dont les montants
                    sont indiqués dans la liste des tarifs sur le site et la plateforme.
                    <span className="color">5.2</span> Conformément à l'article 5.1, les Utilisateurs peuvent également
                    échanger des Articles entre eux. Les Utilisateurs peuvent indiquer qu'ils souhaitent échanger des
                    Articles en utilisant le tchat et en contactant le vendeur via le Site et en marquant l'Article
                    ajouté comme disponible pour un “échange” dans la description produit. Il appartient aux
                    Utilisateurs de décider de la manière dont ils souhaitent procéder à l'échange, ils peuvent soit (1)
                    décider de le faire via le Service de Paiement Intégrés, ou (2) convenir directement de la manière
                    d'organiser le paiement, l'envoi et toute autre opération concernant l'Article qu'ils souhaitent
                    échanger (c'est-à-dire sans utiliser le Service de Paiement).

                    <span className="color">5.3</span> L’ajout d’un Article dans le Catalogue conformément à l'article 4
                    et sa publication pour les autres Utilisateurs constitue une offre de vente dudit Article par le
                    Vendeur, qui pourra être acceptée par un Acheteur. Les conditions générales d’utilisation pour
                    vendeurs professionnels détaillent la manière dont l'Acheteur peut acheter des Articles auprès de
                    Vendeurs Professionnels.
                    <span className="color">5.4</span> Lorsque la Transaction consiste en une vente, le Service de
                    Paiement est automatiquement proposé à l'Acheteur. Si l’Acheteur choisit le Service de Paiement, les
                    frais de Protection Acheteur s'appliquent obligatoirement à la Transaction. De plus, l'Acheteur est
                    considéré comme ayant accepté l'offre du Vendeur lorsqu'il clique sur “Acheter maintenant” sur la
                    page de paiement. L’Acheteur reconnaît que son acceptation de l’offre est faite en considération de
                    la description de l’Article, objet de la vente. L'Acheteur et le Vendeur reconnaissent chacun que :
                    Un contrat juridiquement contraignant est créé entre l'Acheteur et le Vendeur pour l'achat de biens,
                    “le contrat de vente” ;
                    qu'il représente un engagement ferme de chaque partie au contrat d'exécuter ce contrat avec le
                    Vendeur aux conditions de l'offre ; et
                    Pour dissiper toute ambiguïté, VIGNITUS n'est pas partie à ce contrat de vente.
                    <span className="color">5.5</span> Le Vendeur peut décider d'acheter des Services Complémentaires
                    pour améliorer la visibilité de ses Articles. Les services de promotion des Articles existants sont
                    décrits dans la liste des tarifs disponible.
                    <h2>SERVICES DE PROTECTION ACHETEUR</h2>
                    <span className="color">6.1</span> Protection Acheteur. Pour les Articles vendus par des Vendeurs
                    agissant en tant que consommateurs à des Acheteurs, ainsi que pour les Articles vendus par des
                    Vendeurs Professionnels agissant en tant que professionnels à des Acheteurs, la Protection Acheteur
                    s'applique automatiquement et obligatoirement à la Transaction lorsque l'Acheteur utilise le Service
                    de Paiement Intégré. Ceci n'est pas applicable si l'Acheteur et le Vendeur conviennent de finaliser
                    la Transaction en dehors du Site. Si l'Acheteur décide d'utiliser le Service de Paiement Intégré, le
                    Vendeur doit s'y conformer. Le service de Paiement Intégré n’est disponible que dans certains pays
                    et/ou régions. Si le Service n'est pas disponible, le Vendeur et l'Acheteur doivent convenir de
                    l'une des méthodes pour réaliser leur Transaction, telles qu’identifiées à l’article 5.1.
                    Si l'Acheteur décide d'utiliser le Service de Paiement Intégré, il est considéré comme ayant accepté
                    l'offre du Vendeur lorsqu'il clique sur “Payer” sur la page de paiement.
                    Si l'Acheteur décide que la Transaction sera effectuée sans Services de Paiement VIGNITUS les
                    services de Protection Acheteur de VIGNITUS ne seront alors pas disponibles. Dans ce cas, l'Acheteur
                    et le Vendeur sont seuls responsables de l'organisation et des conséquences de l’envoi de l'Article
                    et ne peuvent pas avoir recours à l'assistance fournie par VIGNITUS dans ce contexte.
                    <span className="color">6.2</span> Protection Acheteur (Pro). Pour les Articles vendus par les
                    Vendeurs Professionnels à des Acheteurs agissant en tant que consommateurs, la Protection Acheteur
                    (Pro) s'appliquera automatiquement et obligatoirement à l'achat. Pour de plus amples informations
                    sur la Protection Acheteur Pro, veuillez consulter les consulter les conditions générales
                    d’utilisation pour vendeurs professionnels.
                    <span className="color">6.3</span> “Protection Acheteur” et “Protection Acheteur Pro”. Le Service de
                    Protection Acheteurs comprend différents services qui varient en fonction du fait que l'Acheteur (i)
                    achète des Articles auprès d'un Vendeur Professionnel ou non et (ii) est un consommateur ou non :
                    Si l'Acheteur est inscrit en tant qu'Utilisateur Professionnel/Vendeur Professionnel et qu'il achète
                    un Article auprès d'un Vendeur non-professionnel (c'est-à-dire un Utilisateur qui agit en tant que
                    consommateur), l'Acheteur (Utilisateur Professionnel/Vendeur Professionnel en l’espèce) bénéficiera
                    automatiquement de la “Protection Acheteur” décrite à l'article 6.6 ci-dessous.
                    Si l'Acheteur est inscrit en tant qu'Utilisateur Professionnel/Vendeur Professionnel et qu'il achète
                    un Article auprès d'un autre Vendeur Professionnel, l'Acheteur (Utilisateur Professionnel/Vendeur
                    Professionnel en l’espèce) bénéficiera automatiquement de la “Protection Acheteur” décrite à
                    l'article 6.6 ci-dessous.
                    Les Services de Protection Acheteur ne sont pas des services d'assurance ou des services de garantie
                    de protection juridique.
                    <span className="color">6.4</span> Les Acheteurs qui bénéficient des Services de Protection Acheteur
                    se verront facturer les frais correspondants, comme prévu à l'article 6.5. Les paiements seront
                    alors effectués via le Service de Paiement Intégré et la livraison via le Service de leurs choix
                    avec ou sans assurance, si ces services sont disponibles dans le pays et/ou la région de l'Acheteur.
                    <span className="color">6.5</span> Frais de Protection Acheteur. Les Services de Protection Acheteur
                    facilitent et sécurisent la Transaction. VIGNITUS perçoit donc des frais respectifs pour ces
                    services. Afin de bénéficier des Services de Protection Acheteur, pour chaque Transaction,
                    l'Acheteur devra payer des frais en plus du prix d'achat de l’Article. Le montant total de ces frais
                    est indiqué à l'Acheteur sur le Site avant la finalisation de la commande et est calculé selon la
                    Liste des tarifs. Le paiement des frais de Protection Acheteur permet également à l'Acheteur de
                    bénéficier des avantages mentionnés à l'article 6.6 ci-dessous.
                    <span className="color">6.6</span> Services inclus dans la “Protection Acheteur” :
                    <span className="color">6.6.1</span> Si l'Acheteur utilise le Service de Paiement intégré il
                    bénéficiera du Service Complémentaire qui fait partie intégrante et obligatoire des Services de
                    Paiement Intégré. Le Service de Protection Acheteur comprend un remboursement si les conditions
                    suivantes sont remplies :
                    <span className="color">6.6.2</span> Si l’Article n'est substantiellement pas conforme à la
                    description fournie par le Vendeur, ou si l’Article a été endommagé lors de l’envoi, l'Acheteur doit
                    en informer VIGNITUS dans le délai de deux (2) jours suivant la date de réception de l'Article, en
                    cliquant sur la fenêtre de la Transaction avec le Vendeur (“Période de Contestation”). Cela
                    suspendra la Transaction et VIGNITUS conservera le Prix d'Achat Total. Une fois que VIGNITUS a
                    suspendu la Transaction, le processus est le suivant :
                    Dans un premier temps, l'Acheteur et le Vendeur ont le droit et la possibilité de résoudre la
                    situation par eux-mêmes et d'informer VIGNITUS de leur décision commune. Dans ce cas, VIGNITUS
                    s'engage à se conformer à cette décision et à rembourser l'Acheteur ou le Vendeur comme convenu, du
                    montant retenu sur le compte séquestre.
                    Uniquement dans le cas où l'Acheteur et le Vendeur ne parviendraient pas directement à un accord, ou
                    ne souhaiteraient pas régler la situation directement, l'Acheteur ou le Vendeur peuvent faire
                    remonter le problème à VIGNITUS et lui fournir des preuves (par exemple, des photos, de la
                    documentation, etc.) pour appuyer leur position. Après que VIGNITUS ait examiné la situation et dans
                    le cas où VIGNITUS considérerait que l'Article n’est en effet substantiellement pas conforme à la
                    description fournie par le Vendeur, VIGNITUS annulera la Transaction en demandant au prestataire de
                    services financiers tiers gestionnaire du compte séquestre de procéder au remboursement de
                    l'Acheteur après que ledit Article ait été retourné au Vendeur. L'Acheteur sera remboursé du prix
                    d'achat total. Veuillez noter que VIGNITUS ne fournit pas de service de retour et que l’Acheteur
                    doit retourner l’Article au Vendeur dans le délai stipulé par VIGNITUS, après que le Vendeur aura
                    fourni une adresse de retour. L’Acheteur paie les frais de retour sauf accord contraire avec le
                    Vendeur. Si l’Acheteur ne renvoie pas l’Article dans le délai stipulé par VIGNITUS, l’Acheteur ne
                    pourra pas bénéficier de remboursement au titre de la Protection Acheteur.
                    S'il y a de fortes indications selon lesquelles l'Article reçu est une contrefaçon, VIGNITUS ne peut
                    pas exiger de l'Acheteur qu’il renvoie l’Article afin d'obtenir un remboursement, mais cela est
                    décidé par VIGNITUS au cas par cas, sur la base des informations fournies par l'Acheteur et le
                    Vendeur, et ces derniers s'engagent à se conformer à cette décision de VIGNITUS
                    <span className="color">6.6.3</span> Si l'Acheteur n'a pas reçu un Article, il doit en informer
                    VIGNITUS en signalant le problème en cliquant sur le bouton qui suspend la Transaction dans un délai
                    de deux (2) jours à compter de la date mentionnée à l'Acheteur à laquelle l’Article aurait dû être
                    livré, c'est-à-dire, après que l’Article a été marqué comme “livré”. En cas de perte de l'Article au
                    cours de l'envoi, l'Acheteur sera remboursé par le prestataire de services financiers tiers
                    gestionnaire du compte séquestre du prix d'achat total.
                    <span className="color">6.6.4</span> S'il y a un problème avec l'Article ou si l'Article n'a pas été
                    reçu (comme décrit aux articles 6.6.2 et 6.6.3), mais que l'Acheteur ne contacte pas VIGNITUS dans
                    le délai de deux (2) jours (c'est-à-dire, pendant la Période de Contestation) tel que stipulé aux
                    articles 6.6.2 et 6.6.3, ou qu'il confirme simplement sur le Site que l'Article reçu était
                    acceptable (pendant ce délai de 2 jours), la Transaction est clôturée (conformément à l’article 7.2)
                    et la Protection Acheteur prend fin. Dans ce cas, l'Acheteur n'aura droit à aucun remboursement.
                    <span className="color">6.6.5</span> Dans le cas où l’Article est conforme à la description fournie
                    par le Vendeur, l’Acheteur n’aura pas le droit de demander un remboursement par l’intermédiaire de
                    VIGNITUS et VIGNITUS clôturera la Transaction. Après que la Transaction est clôturée et que le
                    montant sous séquestre est transféré au Vendeur, l’Acheteur peut essayer de négocier directement
                    avec le Vendeur pour retourner l’Article (par exemple, en parvenant à un accord sur le
                    remboursement, les frais de retour, etc.). Veuillez noter que dans ce cas, le Vendeur peut également
                    refuser de coopérer avec l’Acheteur au motif que la Transaction a été honorée par le Vendeur.
                    <span className="color">6.7</span> Si l'Acheteur n'a pas utilisé le Service de Paiement Intégré et
                    n'a pas payé le Service de Protection Acheteur :
                    <span className="color">6.7.1</span> Dans ce cas, VIGNITUS ne garantit pas la sécurité des paiements
                    effectués. VIGNITUS ne fournit aucune assistance à l'Acheteur ou au Vendeur si l'Acheteur n'utilise
                    pas les Services de Protection Acheteur. L'Acheteur et le Vendeur sont seuls responsables de la
                    bonne réalisation de la Transaction et négocient l'ensemble des modalités de la Transaction
                    directement entre eux (par exemple, les conditions de paiement, d'envoi, les conditions de retour de
                    l'Article, colis perdu, etc.).

                    <span className="color">6.7.2</span> En cas de comportement fautif de l'Acheteur ou du Vendeur
                    signalé à VIGNITUS, VIGNITUS pourra fournir une assistance à la partie lésée. VIGNITUS coopérera
                    également avec les autorités locales si nécessaire.
                    <h2>SERVICE DE PAIEMENT INTÉGRÉ</h2>
                    <span className="color">7.1</span> Dans le cadre du Service de Paiement Intégré, l’Acheteur doit
                    payer à l’avance, le ou les Article(s) acheté(s) auprès du Vendeur par carte de crédit ou de débit,
                    ou tout autre moyen de paiement qui pourrait être introduit à l'avenir, à l’aide du Service de
                    Paiement Intégré disponible sur le Site.
                    Il sera possible de procéder au paiement via l’argent collecté de ventes antérieures et stockés sur
                    le portemonnaie de la plateforme.
                    <span className="color">7.2</span> Les montants versés par l’Acheteur (prix de l'Article, frais du
                    service de Protecteur Acheteur, collectivement le “Prix d'Achat Total”) seront conservés par un
                    prestataire de services financiers choisi par VIGNITUS (MANGOPAY SA) dans un portefeuille
                    électronique fonctionnant comme un compte séquestre, et ce jusqu'à ce que la Transaction soit
                    clôturée. La Transaction est clôturée :
                    dès lors que l'Acheteur confirme par le biais de la plateforme que l'Article expédié satisfait aux
                    conditions de l'offre en cliquant sur le bouton “tout est correct” ; ou
                    automatiquement après l'expiration de la Période de Contestation de 2 jours décrite à l'article
                    6.6.2 si aucun litige n'est ouvert au sujet de l'Article ; ou
                    Si un litige est ouvert au cours de la Période de Contestation, après la résolution du litige.
                    Une fois la Transaction clôturée, le prix d'achat sera immédiatement transféré sur le portefeuille
                    électronique du Vendeur. Les fonds disponibles sur le portefeuille électronique du Vendeur pourront
                    être transférés à tout moment sur son compte bancaire personnel, sans aucun frais de transfert et
                    pour n’importe quel montant. Les fonds disponibles sur le portefeuille électronique pourront être
                    utilisés pour effectuer des achats sur VIGNITUS auprès d’autres Utilisateurs.
                    <span className="color">7.3</span> VIGNITUS fait appel à MANGOPAY SA pour le traitement du paiement
                    et la conservation des informations relatives à la carte de crédit ou de débit. Pour pouvoir
                    utiliser le Service de Paiement Intégré, il peut être demandé à l’Acheteur de déclarer avoir accepté
                    les conditions générales dudit prestataire. L’Acheteur sera seul responsable de la transmission des
                    informations liées à sa carte de crédit ou de débit et VIGNITUS décline par les présentes toute
                    responsabilité, dans toute la mesure autorisée par la législation applicable.
                    <span className="color">7.4</span> Veuillez noter que le droit d'utiliser le Service de Paiement
                    Intégré n'est conféré que dans le cadre des ventes effectuées par un Utilisateur en son nom propre.
                    Les Utilisateurs ne sont pas autorisés à revendre, engager ou, de toute autre manière, autoriser des
                    tiers à utiliser le Service de Paiement Intégré pour permettre à ces tiers d'être rémunérés pour
                    leurs services. Les Utilisateurs ne sont pas autorisés à utiliser le Service de Paiement Intégré
                    pour des types de produits et services autres que ceux souscrits sur VIGNITUS. En particulier,
                    l'Utilisateur confirme qu'il n'utilisera pas le Service de Paiement Intégré pour tout type de
                    produits ou de services n'apparaissant pas dans les règles du catalogue. Si VIGNITUS découvre à tout
                    moment que les informations qu'un Utilisateur a fournies à son sujet sont fausses ou ont changé sans
                    que VIGNITUS en soit informée, ou si un Utilisateur enfreint l'une quelconque de ces conditions, les
                    Services peuvent être suspendus et/ou résiliés avec effet immédiat.
                    <span className="color">7.5</span> En cas de suspicion de fraude de quelque nature que ce soit
                    commise par un Utilisateur, VIGNITUS suspendra immédiatement le Compte de l'Utilisateur concerné
                    ainsi que les Transactions en cours, dans l'attente des résultats de l'enquête en cours.
                    <span className="color">7.6</span> En cas de problème (tel que par exemple des signes de ventes
                    illicites suspectées sur le Site, de blanchiment d'argent, de spam, ou en cas de violation des
                    conditions générales des prestataires de services de paiement) signalé par le prestataire en charge
                    du Service de Paiement Intégré à VIGNITUS, VIGNITUS peut suspendre immédiatement le Compte de
                    l'Utilisateur concerné ainsi que les Transactions en cours, dans l'attente des résultats de
                    l'enquête en cours.
                    <span className="color">7.7</span> VIGNITUS se réserve le droit de suspendre le Compte d'un
                    Utilisateur si celui-ci a initié, concernant une Transaction, une procédure de résolution d'un
                    litige par l’intermédiaire d'un prestataire externe de paiement et que, en considération de
                    l'ensemble des circonstances relatives à la procédure de résolution du litige initiée, VIGNITUS a
                    une raison légitime de suspendre le Compte de l'Utilisateur (par exemple, en cas de violation des
                    lois applicables et/ou de non-respect des présentes Conditions Générales et/ou en cas de possibilité
                    d'utilisation abusive du Site).
                    <h2>SERVICE D’ENVOI</h2>
                    <span className="color">8.1</span> Le Service d'Envoi est proposé par le vendeur, il pourra proposer
                    plusieurs possibilités de livraison et donc différents tarifs que l’acheteur pourra consulter et
                    choisir avant de valider sa commande.
                    VIGNITUS ne serra pas responsable des pertes, casses des produits envoyés par les parties car elle
                    ne propose pas de service d’envoi intégré.
                    L'envoi personnalisé signifie que le Vendeur décide d'avoir recours à un envoi individuel et
                    personnalisé, le Vendeur sera responsable du choix du prestataire de transport, de la détermination
                    des frais d’expédition et de la fourniture du bordereau. Le Vendeur est seul responsable si le colis
                    contenant l'Article est perdu ou endommagé lors de la livraison ou n'est pas livré à l'Acheteur,
                    VIGNITUS n'ayant aucune responsabilité dans ce cas. Le Vendeur doit donc choisir un mode de
                    livraison sécurisé offrant des garanties en cas de problème avec l'Acheteur et en cas de perte ou
                    d'endommagement des Articles. Il appartient au Vendeur d'estimer de manière correcte et précise les
                    frais d’expédition et de les mentionner sur l'annonce, il y sera indiqué des exemples pendant la
                    mise en vente des articles. Le Vendeur accepte les risques liés à l'expédition en cas d'Envoi
                    Personnalisé. VIGNITUS n'offrira aucune indemnisation pour les Articles perdus ou endommagés dans le
                    cadre de l'option d'Envoi Personnalisé et il appartiendra au Vendeur de contacter directement le
                    prestataire de transport pour tout problème lié à l'expédition.
                    <span className="color">8.2</span> Les coûts des Services d'Envoi Intégré disponibles ( “Frais
                    d’Envoi”) sont visibles après avoir cliqué sur un Article du Catalogue, sur la page détaillant
                    l'Article en question. Les Frais d’Envoi sont dus par l'Acheteur en plus du prix d'achat de
                    l'Article en question et des frais de Protection Acheteur.
                    <span className="color">8.3</span> Dès lors que le Prix d'Achat Total a été payé par l’Acheteur,
                    VIGNITUS confirme le paiement au Vendeur sur la plateforme et lui transmet les Instructions d 'Envoi
                    . Le Vendeur est tenu d'expédier l'Article dans les cinq (5) jours ouvrés suivant la réception des
                    informations sur l'envoi par VIGNITUS concernant la Transaction conclue, conformément aux
                    Instructions d'Envoi. Le Vendeur doit notamment noter le numéro de suivi de l’expédition indiqué sur
                    le Site.
                    <span className="color">8.4</span> En cas de non-envoi de l’Article à l’Acheteur dans les cinq (5)
                    jours ouvrés suivant la réception des informations sur l'envoi, VIGNITUS a le droit d'ordonner à
                    MANGOPAY SA de procéder au remboursement du Prix d'Achat Total conservé sous séquestre, incluant les
                    Frais d'Envoi, à l’Acheteur. Ce remboursement constituera le seul recours de l’Acheteur au titre de
                    son contrat avec le Vendeur, sans préjudice des droits reconnus à l'Acheteur en tant que
                    consommateur s'il a acheté l'Article auprès d’un Vendeur Professionnel.
                    <h2>PAIEMENT</h2>
                    <span className="color">9.1</span> VIGNITUS est autorisée à percevoir des sommes au titre des
                    Services fournis conformément aux tarifs indiqués dans la liste tarifire.
                    <span className="color">9.2</span> Tous les tarifs sont exprimés en euros (EUR), toutes taxes
                    applicables comprises.
                    <span className="color">9.3</span> Dans le cas d’une Transaction effectuée avec le Service de
                    Paiement le paiement de l’Acheteur se fait par carte de crédit, carte de débit ou carte virtuelle,
                    ou tout autre moyen de paiement approuvé pour le Service de Paiement Intégré sur le Site au moment
                    de la conclusion du Contrat de Vente en question. L'Acheteur peut également utiliser les fonds
                    disponibles sur son solde VIGNITUS (c'est-à-dire son portefeuille électronique disponible sur
                    VIGNITUS), le cas échéant. Si l'Acheteur ne dispose pas de suffisamment de fonds sur son solde
                    VIGNITUS pour couvrir le prix du ou des Articles, il peut couvrir la partie manquante en payant par
                    d'autres moyens de paiement disponibles. Si l'Acheteur décide de ne pas utiliser le Service de
                    Paiement Intégré, le paiement se fait par prélèvement automatique, par carte bancaire
                    (Visa/MasterCard) ou par carte virtuelle, ou tout autre moyen de paiement sur lequel les
                    Utilisateurs concernés se sont mis d'accord entre eux sans l’assistance de VIGNITUS.
                    <span className="color">9.4</span> Dans le cas d’une Transaction effectuée via le Service de
                    Paiement l’Acheteur est débité du montant total (prix de l’Article, Frais d’Envoi et Frais de
                    Protection Acheteur) et le Vendeur ne percevra le montant qui lui est dû qu’au moment de la
                    Confirmation de Réception. Si l’Acheteur ne reçoit pas l’Article ou si l’Article n'est
                    substantiellement pas conforme à la description fournie par le Vendeur, et dans les autres
                    conditions stipulées aux articles 6.6.2 et 6.6.3, son compte sera recrédité.
                    <span className="color">9.5</span> Dans le cas d'une Transaction effectuée sans le Service de
                    Paiement, l'Acheteur et le Vendeur doivent convenir des conditions de paiement comme décrit à
                    l'article 6.7 ci-dessus. Dans ce cas, VIGNITUS ne perçoit aucun frais lié à la Transaction.
                    <h2>INTERACTIONS ET MESSAGES SUR LE SITE</h2>
                    <span className="color">10.1</span> Messages Privés
                    L’échange de Messages Privés entre les Utilisateurs a pour finalité principale l’échange
                    d’informations entre les Utilisateurs relatives à des Articles du Catalogue. Si un Utilisateur
                    envoie des Messages Privés à un autre Utilisateur, il doit s’assurer qu’il n’envoie pas :
                    des messages ou des informations à caractère publicitaire (sauf si cela est autorisé dans les
                    conditions de l’article 10.3 ci-dessous);
                    des spams ou des contenus diffusant des virus ou des vers;
                    des messages en masse de quelque autre type que ce soit (lorsqu’un message est envoyé à plus de cinq
                    Utilisateurs ou lorsque le même message est copié et envoyé à des Utilisateurs qui n’ont pas demandé
                    à les recevoir);
                    des messages contenant des textes contraires aux bonnes mœurs et à l’ordre public, inappropriés,
                    insultants, diffamatoires ou considérés par ailleurs comme étant incompatibles avec les présentes
                    Conditions Générales et les intérêts des Utilisateurs ;
                    des messages à caractère illicite ou tentant par ailleurs de porter préjudice aux autres
                    Utilisateurs et/ou au Site ;
                    des messages pouvant être qualifiés d’harcèlement de quelque nature que ce soit ;

                    Des messages contenant des commentaires inappropriés, provocateurs ou malvenus sur l'apparence d'un
                    autre Utilisateur. Grâce à des algorithmes, VIGNITUS utilise un logiciel automatisé permettant de
                    détecter les contenus des Messages Privés contraires aux bonnes mœurs et à l’ordre public, les
                    contenus insultants communiqués par un Utilisateur via des Messages Privés. Dans l’hypothèse où un
                    Message Privé contenant de tels contenus est détecté par le logiciel automatisé, le Message Privé et
                    l’Utilisateur pourront, sans préjudice aux autres recours, être automatiquement bloqués conformément
                    à l’article 13 ci-dessous et/ou cachés de l’Utilisateur à qui il a été envoyé. L’Utilisateur
                    émetteur du Message Privé bloqué et/ou caché sera averti de son obligation de se conformer aux
                    Conditions Générales et informé que VIGNITUS peut prendre des mesures supplémentaires telles que la
                    suspension du Compte de l'Utilisateur en cas de nouvelles violations. Sauf pour les cas mentionnés
                    dans la Politique de Confidentialité VIGNITUS n’a jamais accès au contenu des Messages Privés, y
                    compris ceux qui sont bloqués et/ou cachés par le logiciel automatisé. Les outils automatisés
                    susmentionnés adoptés par VIGNITUS ne constituent pas une obligation de surveillance de la part de
                    VIGNITUS ni une obligation de recherche active d'activités et/ou de contenus illicites sur le Site
                    et, dans la mesure où la loi applicable le permet, ne génèrent aucune responsabilité de la part de
                    VIGNITUS.
                    <span className="color">10.2</span> Echange d’évaluations entre les Utilisateurs
                    Un Utilisateur a le droit de laisser des évaluations sur un autre Utilisateur uniquement si une
                    Transaction a été effectuée entre eux. Aucune contrepartie n'est accordée aux Utilisateurs ou à
                    VIGNITUS en échange de leurs évaluations en ligne.
                    Les évaluations d’un Utilisateur à l’égard d’un autre Utilisateur doivent toujours être justes et
                    honnêtes. Les mensonges et les insultes sont interdits.
                    VIGNITUS ne revoit pas les évaluations avant leur publication par les Utilisateurs.

                    Si l'évaluation reçue est déplacée ou abusive ou, en tout état de cause, n’est pas conforme aux
                    présentes Conditions Générales, les Utilisateurs sont autorisés à en informer VIGNITUS conformément
                    à la procédure de signalement décrite à l’article 11 ci-dessous. A la demande des Utilisateurs,
                    VIGNITUS est autorisée à retirer du Site toutes les évaluations qui sont en violation des présentes
                    Conditions Générales ou des droits des autres Utilisateurs, y compris les évaluations des
                    Utilisateurs qui n’étaient pas en droit de laisser des évaluations aux termes des présentes
                    Conditions Générales.
                    VIGNITUS, après avoir identifié l’Utilisateur qui n’a pas respecté les règles susmentionnées
                    applicables aux évaluations, peut utiliser son droit de bloquer en tout ou partie le Compte de
                    l’Utilisateur concerné, conformément à l’article 13 ci-dessous.
                    Les Visiteurs n’ont pas le droit de laisser des évaluations sur des Utilisateurs.
                    <h2>RESPONSABILITÉ</h2>
                    <span className="color">11.1</span> Tous les Visiteurs et Utilisateurs sont entièrement
                    responsables, en leur qualité d’éditeurs, de toutes les informations qu’ils postent sur le Site et,
                    le cas échéant, des Articles qu’ils proposent, vendent, échangent ou transfèrent à d’autres
                    Utilisateurs. En particulier, il est précisé que lorsqu’un Utilisateur met en ligne des Articles sur
                    le Catalogue correspondant, cet Utilisateur reconnaît et accepte qu’il est entièrement responsable
                    du téléchargement de l’Article sur le Catalogue correspondant, de sa description, des confirmations,
                    de l’exactitude des autres renseignements fournis et des communications avec les autres Utilisateurs
                    (y compris les Messages Privés et évaluations) et, de manière générale, des Transactions réalisées
                    avec d’autres Utilisateurs et des litiges susceptibles d’en découler (ci-après, ensemble, le
                    “Contenu”).
                    <span className="color">11.2</span> A cet égard, les Visiteurs et Utilisateurs doivent respecter
                    l'ensemble des réglementations applicables. Les Utilisateurs et les Visiteurs doivent notamment
                    s’abstenir de (i) porter atteinte aux droits des tiers, (ii) porter atteinte aux droits de propriété
                    intellectuelle ou ajouter des produits constituant des contrefaçons, (iii) inciter à des délits ou
                    crimes, à la discrimination, la haine ou la violence liée à la race, l’origine ethnique ou la
                    nationalité, (iv) communiquer des informations erronées ou des informations confidentielles, (v)
                    proférer des propos diffamatoires, (vi) commettre des actes qui pourraient mettre en danger des
                    mineurs, (vii) publier des données à caractère personnel d’autres individus ou violer les droits à
                    la vie privée ou (viii) usurper l’identité d’autrui.
                    <span className="color">11.3</span> Dans le cas où le Contenu ne respecte pas la réglementation
                    applicable conformément à l’article 11.2 ou, de manière générale, dans le cas où un Utilisateur ou
                    un Visiteur ne respecte pas les présentes Conditions Générales, tout Utilisateur ou le Visiteur
                    reconnaît et accepte qu’il est seul responsable des dommages directs et indirects qui en découlent à
                    l’égard des tiers ou de VIGNITUS. Par conséquent, tout Utilisateur ou Visiteur reconnaît et accepte
                    que, en sa capacité d'hébergeur, VIGNITUS ne vérifie en aucun cas le Contenu et, dans la mesure
                    autorisée par la loi, ne sera en aucun cas tenue responsable desdits dommages ou pertes subis par
                    des Utilisateurs et/ou par des tiers. En particulier, sans que cette liste ne soit exhaustive,
                    VIGNITUS ne saurait être tenue pour responsable : (i) des actes ou des omissions des Utilisateurs et
                    des Visiteurs , (ii) des informations postées sur le Site par les Utilisateurs et Visiteurs, leurs
                    sujets, leur exactitude, exhaustivité et/ou conformité avec la réglementation applicable ou (iii) de
                    la qualité et des quantités des Articles que les Utilisateurs vendent ou achètent via l’utilisation
                    du Site, ni de leur conformité avec la description donnée.
                    <span className="color">11.4</span> VINTED n’est pas responsable du Contenu mis en ligne par les
                    Utilisateurs. Les Utilisateurs sont responsables du Contenu qu’ils mettent en ligne. VIGNITUS
                    retirera le Contenu de l'Utilisateur qui lui a été dûment notifié conformément à la procédure
                    décrite à l’article 13 et aux lois applicables et qui enfreint les lois applicables et/ou les
                    politiques de VIGNITUS, c’est-à-dire l’ensemble des règles régissant l'utilisation et l’exploitation
                    du Site. VIGNITUS peut prendre des mesures à l'encontre de l'Utilisateur, notamment en ce qui
                    concerne l'utilisation de son Compte, telles que prévues à l’article 13 ci-dessous. Si un
                    Utilisateur ou un Visiteur découvre un Contenu faisant l'apologie de crimes contre l'humanité,
                    incitant à la haine raciale et/ou à la violence, concernant la pédopornographie ou tout autre
                    contenu illicite, il convient d’en informer VIGNITUS immédiatement:
                    En envoyant un courriel à l'adresse legal@vignitus.fr;
                    soit en adressant un courrier décrivant les faits en détail à l’adresse suivante : SAS VIGNITUS 10
                    rue du château 34540 BALARUC LE VIEUX, FRANCE
                    Si un tel Contenu est signalé, VIGNITUS suspendra immédiatement, sans préavis, le Compte de
                    l'Utilisateur et supprimera le Contenu concerné, comme prévu à l'article 13 des Conditions
                    Générales. VIGNITUS suspendra les Transactions en cours pendant la durée de l'enquête, ce qui pourra
                    notamment conduire VIGNITUS à prendre l'une des mesures prévues à l'article 13.
                    Si un Utilisateur estime qu’un Contenu est susceptible de porter atteinte aux présentes Conditions
                    Générales et/ou à ses droits et/ou aux droits d’un tiers (par exemple, contrefaçon, insulte,
                    atteinte à la vie privée), les Utilisateurs peuvent en notifier VIGNITUS :
                    En envoyant un courriel à l'adresse legal@vignitus.fr;
                    soit en adressant un courrier décrivant les faits en détail à l’adresse suivante : SAS VIGNITUS 10
                    rue du château 34540 BALARUC LE VIEUX, FRANCE
                    Si un tel Contenu est signalé, VIGNITUS pourra prendre des mesures à l'encontre de l'Utilisateur,
                    notamment en ce qui concerne l'utilisation de son Compte, comme prévu à l'article 13 ci-dessous.
                    Plus généralement, les Utilisateurs doivent s'abstenir de mettre en ligne tout type de Contenu
                    illicite, erroné ou inexact. Les Utilisateurs doivent agir et utiliser le Site en faisant preuve de
                    bonne foi.
                    <span className="color">11.5</span> VIGNITUS n'est pas responsable des obligations fiscales ou de
                    déclaration qui pourraient incomber aux Utilisateurs du fait de leurs activités sur le Site. Dans le
                    cadre des Transactions effectuées sur le Site, les Utilisateurs sont seuls responsables de toute
                    obligation de déclaration fiscale, le cas échéant, en vertu de la législation applicable.
                    <span className="color">11.6</span> Dans le cas d’une Transaction effectuée sans le Service de
                    Paiement proposé par VIGNITUS, le Vendeur est responsable de la livraison de l'Article en temps
                    utile en bonne et due forme à l’Acheteur, conformément à leur accord. En tout état de cause, si un
                    litige survient entre l’Acheteur et le Vendeur quant à la livraison de l’Article, le Vendeur doit
                    prouver que l’Article a été expédié (par exemple, par la fourniture d’un numéro de suivi, d’une
                    facture ou d’un document émanant du service de coursier).
                    <span className="color">11.7</span> Dans le cas d’une Transaction effectuée sans le système de
                    Service de Paiement proposé par VIGNITUS, l’Acheteur est responsable du paiement de l’Article en
                    temps utile, conformément aux conditions communiquées par le Vendeur ou selon les conditions
                    convenues d’un commun accord.
                    <span className="color">11.8</span> Dans le cas d’une Transaction effectuée sans le système de
                    Service de Paiement proposé par VIGNITUS, l’Utilisateur comprend et accepte que VIGNITUS n’est pas
                    responsable des litiges survenant entre les Acheteurs et les Vendeurs, ne fournit pas d'assistance
                    et ne prend aucunement part à leur règlement. Ces litiges peuvent être résolus conformément au droit
                    local applicable, à moins que les parties aient décidé, d’un commun accord, que la loi d’un autre
                    pays s’applique.
                    <span className="color">11.9</span> VIGNITUS ne peut être tenue pour responsable d'événements
                    imprévisibles tels que des cyber-attaques, des failles de sécurité dans la transmission des données
                    ou des garanties de performance quant au volume et à la rapidité des transmissions de données. Dans
                    ces conditions, il appartient aux Utilisateurs de prendre toutes les mesures appropriées de façon à
                    protéger leurs propres données et/ou logiciels, notamment de la contamination par d'éventuels virus
                    circulant sur Internet.
                    <h2>OBLIGATIONS DE TOUT UTILISATEUR</h2>
                    <span className="color">12.1</span> Tout Utilisateur s’engage :
                    lors de son inscription sur le Site, à fournir des informations sincères le concernant, notamment
                    son nom d’utilisateur, et son adresse électronique ;
                    à s’inscrire sur le Site une seule fois et à ne pas créer plus d’un Compte, sauf dans le cas où un
                    Utilisateur désirerait posséder un Compte personnel et une Compte professionnel VIGNITUS, mais dans
                    ce cas, l'Utilisateur ne peut pas s'inscrire en utilisant la même adresse électronique, et ces
                    Comptes doivent être clairement séparés, c'est-à-dire, le Compte personnel ne peut pas être utilisé
                    à des fins de ventes commerciales et vice versa ;
                    à ne pas utiliser le Site et/ou les Services en vue de réaliser des actes ou des transactions
                    illicites ou des actes frauduleux ;
                    lors de l’utilisation du Site, à fournir des informations objectives, justes, exhaustives et
                    détaillées sur l’échange et/ou la vente proposée ;
                    à veiller à ce que le prix proposé pour l’échange et/ou la vente des Articles, ainsi que les autres
                    informations relatives à l'Article, soient justes ;
                    à ne pas copier les informations figurant sur le Site et mises en ligne par VIGNITUS ou un autre
                    Utilisateur du Site, ni à en faire un usage illicite.
                    <span className="color">12.2</span> Les Utilisateurs et Visiteurs s’engagent, lors de leur
                    utilisation du Site, à se conformer aux règles suivantes :
                    ne pas utiliser (notamment partager et/ou publier) d'informations et/ou de données trompeuses ou
                    erronées ;
                    ne pas utiliser (notamment partager et/ou publier) de photographies (i) pour lesquelles
                    l'Utilisateur concerné ne détient pas les droits de propriété intellectuelle (en général, il s’agit
                    de photos trouvées sur Internet), ou (ii) qui contiennent des liens vers d’autres sites Internet ;
                    ne pas utiliser (notamment partager et/ou publier) de photographies (i) sur lesquelles d’autres
                    personnes que l'Utilisateur concerné sont visibles, à moins que les autres personnes visibles aient
                    donné leur accord pour la publication de ces photographies ; ou (ii) qui sont ou sont susceptibles
                    d'être considérées comme des photographies contraires aux bonnes mœurs et/ou à l’ordre public (par
                    exemple photographies érotiques, photographies pornographiques ou photographies montrant un contenu
                    violent) ;
                    ne pas proposer et/ou vendre et/ou acheter et/ou échanger et/ou transférer des Articles en violation
                    des droits de propriété intellectuelle de tiers, tels que des marques déposées et/ou d'autres
                    enseignes dans la mesure où ils sont protégés par la loi applicable (comme par exemple les enseignes
                    de sociétés dans certaines juridictions) et/ou des droits de brevet et/ou des droits d'auteur ;
                    ne pas violer les droits de propriété et/ou les droits personnels de tiers (y compris les droits de
                    propriété intellectuelle) ;
                    ne pas enfreindre les lois et/ou réglementations applicables ;
                    ne pas agir de manière contraire à l’ordre public et/ou aux bonnes mœurs
                    ne pas utiliser (notamment partager et/ou publier) des programmes et/ou des fichiers informatiques
                    qui contiennent des virus ou qui pourraient (i) perturber le fonctionnement normal du Site et/ou des
                    Services, (ii) infecter les ordinateurs des Utilisateurs et entraîner par conséquent des dommages
                    pour l’Utilisateur ou ses biens ou (iii) empêcher l’Utilisateur d’utiliser le Site, les Services ou
                    l’ordinateur de l’Utilisateur ;
                    ne pas proposer et/ou partager et/ou autrement promouvoir des liens vers d’autres sites Internet
                    et/ou sociétés qui fournissent des services analogues à ceux proposés sur le Site.
                    <span className="color">12.3</span> Les Utilisateurs et les Visiteurs s’engagent à ne pas collecter,
                    cumuler, transmettre à des tiers, rendre publiques, publier ou divulguer des données des
                    Utilisateurs du Site ou des données concernant les actions des Utilisateurs du Site, y compris les
                    Transactions, leur nombre, leur type, leur prix, etc., si les données deviennent disponibles de
                    manière illicite ou à la suite d’un acte illicite ou d’une omission.
                    Les Utilisateurs et les Visiteurs s’engagent en outre à ne pas collecter, cumuler, transmettre à des
                    tiers, rendre publiques, publier ou divulguer les informations figurant sur le Site, si cela est
                    susceptible de porter atteinte aux droits des autres Utilisateurs. Cette restriction ne s’applique
                    pas à la fonction “partager” qui existe sur le Site et donne la possibilité aux Utilisateurs de
                    partager les informations publiques disponibles sur le Site, sur les Comptes sur les Réseaux Sociaux
                    de VIGNITUS ou sur leurs propres comptes sur les réseaux sociaux, ainsi que d'envoyer lesdites
                    informations à eux-mêmes ou à d'autres personnes par courriel.
                    <span className="color">12.4</span> Dans la limite des lois applicables, VIGNITUS n’est pas
                    responsable du comportement d’un Utilisateur lorsque ce dernier utilise le Site ou les Services. En
                    particulier, VIGNITUS n’est pas responsable de la mauvaise exécution ou de l’inexécution des
                    Transactions par les Utilisateurs.
                    <span className="color">12.5</span> L’Utilisateur s’engage à garder confidentiel son identifiant et
                    son mot de passe à l’égard des tiers, sauf pour les personnes qui ont été autorisées par
                    l’Utilisateur à utiliser son identifiant.
                    <span className="color">12.6</span> L’Utilisateur s’engage à mettre à jour, sans délai, les
                    informations sur le Site qui ne sont plus pertinentes en raison de modifications de ses données (en
                    particulier celles fournies au moment de son inscription sur le Site), ainsi que les informations
                    qui ne sont plus pertinentes sur les Articles ajoutés au Catalogue et leur état.
                    <span className="color">12.7</span> Lors de l'utilisation du Site, l’Utilisateur ou le Visiteur
                    déclare :
                    être une personne physique âgée d'au moins 18 ans, qu’il utilise tous les Services du Site afin de
                    satisfaire ses besoins personnels, non liés à une activité professionnelle, et qu’il a l’entière
                    capacité et tous les droits pour effectuer les Transactions sur le Site ;
                    qu’il accepte et respecte les Conditions Générales et l'ensemble des autres documents contractuels,
                    tels que la Politique de Confidentialité, la Politique de Sécurité, ainsi que les autres règles du
                    Site ;
                    qu’il comprend être entièrement responsable des Transactions effectuées ;
                    qu’il comprend qu’après avoir commandé un Article, il s’engage à acheter ou échanger cet Article, et
                    que l’inexécution de cette Transaction peut l’obliger à rembourser les pertes subies par le Vendeur
                    ;
                    qu’il comprend qu’il devra payer VIGNITUS s’il décide d’utiliser les Services Complémentaires
                    payants selon les tarifs et la procédure indiqués dans la Liste des tarifs.
                    <h2>DROITS ET OBLIGATIONS DE VIGNITUS</h2>
                    <span className="color">13.1</span> VIGNITUS peut interdire aux Utilisateurs et aux Visiteurs
                    d'utiliser le Site, en tout (“Blocage Total”) ou partie (“Blocage Partiel”) tel que détaillé
                    ci-après, (en indiquant les raisons de cette interdiction ou de cette limitation), notamment en
                    supprimant le Contenu mis en ligne sur le Site, en annulant le Compte de l’Utilisateur et en
                    empêchant l’Utilisateur de se réinscrire sur le Site ou en empêchant le Visiteur de se rendre sur le
                    Site, sous réserve que VIGNITUS envoie une notification officielle préalable à cet Utilisateur ou
                    Visiteur, s’il :
                    fournit des informations incorrectes, trompeuses et/ou incomplètes selon les Conditions Générales au
                    moment de son inscription sur le Site ou de son utilisation du Site ;
                    divulgue délibérément et intentionnellement des informations erronées sur le Site, insulte d’autres
                    personnes ou agit de manière inappropriée ;
                    utilise l’identité d'un autre Utilisateur du Site ou agit par ailleurs de manière illicite ;
                    se connecte à partir de la même adresse IP ou du même ordinateur que celle ou celui qui a été
                    bloqué(e) en raison de violations ;
                    réponde aux critères d'éligibilité définis dans les Règles du catalogue et le Guide du vendeur
                    professionnel pour être considéré comme un Utilisateur Professionnel et ne s'inscrit pas en tant
                    qu'Utilisateur Professionnel ou ne convertit pas son Compte d’Utilisateur existant en Compte
                    Utilisateur Professionnel dans les 60 jours suivant la notification de VIGNITUS notifiera
                    l'Utilisateur en affichant une bannière lorsque celui-ci se connectera au Site et en envoyant un
                    message dans sa boîte de réception VIGNITUS. VIGNITUS notifiera également l'Utilisateur par courrier
                    électronique si ce dernier avait accepté ce moyen de communication dans les paramètres de son
                    Compte.
                    a reçu au moins trois avertissements de VIGNITUS.
                    Le “Blocage Partiel” signifie que l’Utilisateur ne sera pas en mesure d’ajouter des Articles au
                    Catalogue, de communiquer avec les autres Utilisateurs durant une période déterminé.
                    Le “Blocage Total” signifie que le Compte de l’Utilisateur sera bloqué et/ou annulé, et que la
                    possibilité de se rendre sur le Site à partir de l’ordinateur de l’Utilisateur sera bloquée.
                    L’Utilisateur doit savoir qu’après un “Blocage Total”, il n’aura plus le droit de se réinscrire sur
                    le Site. En cas d’annulation du Compte de l’Utilisateur, VIGNITUS peut conserver certaines
                    informations, conformément à la Politique de Confidentialité de VIGNITUS, dans la mesure où elles
                    peuvent être nécessaires pour reconstituer les circonstances dans lesquelles VIGNITUS a pris la
                    décision d’annuler le Compte de l’Utilisateur et pour transférer ces données aux institutions et
                    autorités chargées de faire appliquer les lois.
                    <span className="color">13.2</span> VIGNITUS, après en avoir été également dûment informé
                    conformément à l’article 11.4 ci-dessus ou par les Autorités compétentes, supprimera immédiatement
                    tout Contenu illicite. En effet, VIGNITUS peut, à tout moment, supprimer du Site les Articles
                    ajoutés par un Utilisateur au Catalogue ou toutes autres informations fournies par un Utilisateur si
                    ces Articles ou ces informations sont contraires aux Conditions Générales, à la réglementation
                    applicable ou portent atteinte aux bonnes mœurs ou à l’ordre public.
                    <span className="color">13.3</span> VIGNITUS peut investiguer sur toute violation des Conditions
                    Générales, et en informer les institutions et autorités compétentes.
                    <span className="color">13.4</span> VIGNITUS peut, à tout moment, réorganiser le Catalogue, les
                    espaces publicitaires ou les autres informations sur les Articles, sous réserve que ces changements
                    ne modifient pas le Contenu fourni par un Utilisateur, et ce afin de rendre le Site plus facile
                    d'utilisation. VIGNITUS peut publier à tout moment des Nouveautés contenant des descriptions, des
                    instructions, des règles ou d'autres informations liées à l'objet des Nouveautés.
                    <span className="color">13.5</span> VIGNITUS peut à tout moment, publier sur le Site des Nouveautés
                    ou d'autres communications concernant des offres à court et long terme, des concours, des jeux ou
                    des promotions conformes au droit national, pour promouvoir de nouveaux Services ou des biens ou des
                    services tiers. Des conditions particulières peuvent s'appliquer. Les informations relatives aux
                    offres, concours, jeux ou promotions conformes au droit national, seront fournies sur le Site. En
                    cas d’incohérences entre les Conditions Générales et les conditions particulières publiées sur le
                    Site applicables aux Nouveautés, ces dernières prévaudront.
                    <span className="color">13.6</span> VIGNITUS peut à tout moment mettre fin ou suspendre ou céder à
                    des tiers l’exploitation du Site, sous réserve d’en notifier les Utilisateurs du Site, en respectant
                    un préavis de trente (30) jours.
                    <h2>DONNÉES PERSONNELLES</h2>
                    <span className="color">14.1</span> La Politique de Confidentialité, disponible sur l’espace dédié
                    décrit les données à caractère personnel des Utilisateurs collectées par VIGNITUS et les finalités
                    pour lesquelles ces données seront traitées par VIGNITUS. La Politique de Confidentialité et toute
                    partie de celle-ci ne sont pas considérées comme des clauses contractuelles et ne font pas partie
                    des présentes Conditions Générales.
                    <span className="color">14.2</span> Les présentes Conditions Générales contiennent des obligations
                    de VIGNITUS réglementées. Aux fins de l'exécution de nos obligations au titre des présentes
                    Conditions Générales envers vous, le traitement de données à caractère personnel vous concernant est
                    requis et réalisé en partie sur la base de nos obligations contractuelles envers vous. Le traitement
                    des données excédant nos obligations contractuelles est toujours fondé sur une autre base légale
                    conformément à la Politique de Confidentialité.
                    <span className="color">14.3</span> Lorsque vous effectuez un traitement de données de manière
                    indépendante et distincte de VIGNITUS, il vous appartient de vous assurer de la conformité aux lois
                    applicables en matière de protection des données (en particulier, le Règlement Général sur la
                    Protection des Données) et de protection de la vie privée. Cela inclut, sans s'y limiter,
                    l’obligation d'information des personnes concernées conformément aux articles 12, 13 et 14 du
                    Règlement Général sur la Protection des Données et de disposer d'une base légale pour le traitement
                    des données à caractère personnel.
                    <span className="color">14.4</span> Vous êtes tenu de ne fournir que des données exactes à VIGNITUS.
                    Lorsque les données vous concernant changent (par exemple, votre adresse ou votre nom de famille),
                    vous devez notifier ces changements sans délai excessif en modifiant les informations de votre
                    Compte. Les dommages et la non-conformité résultant de la fourniture de données incorrectes de votre
                    part relèvent de votre responsabilité exclusive, sauf si les lois applicables en matière de
                    protection des données et de protection de la vie privée s'opposent à ce que vous soyez tenu
                    exclusivement responsable desdits dommages et de ladite non-conformité.
                    <span className="color">14.5</span> Lorsque vous décidez de devenir un Vendeur Professionnel, le
                    traitement de données à caractère personnel effectué par vous et VIGNITUS est régi par les
                    conditions d'utilisation de VIGNITUS applicables aux Vendeurs Professionnels et les annexes
                    correspondantes. Dans le cas où vous devenez un Vendeur Professionnel, VIGNITUS traite également les
                    données vous concernant conformément à la Politique de Confidentialité.
                    DROIT APPLICABLE, RESOLUTION DES LITIGES ET RESILIATION
                    <span className="color">15.1</span> Les présentes Conditions Générales sont régies par le droit
                    national de l'Utilisateur.
                    <span className="color">15.2</span> En cas de différend entre VIGNITUS et un Utilisateur (les
                    Utilisateurs Professionnels et les Vendeurs Professionnels doivent recourir à la procédure décrite
                    dans le Guide du vendeur Professionnel), VIGNITUS encourage les Utilisateurs à s'adresser d'abord à
                    VIGNITUS dans tous les cas pour trouver une solution à l'amiable. L'Utilisateur pourra:
                    Tout d'abord adresser une réclamation écrite au Service Client au moyen du formulaire disponible sur
                    la plateforme.
                    En l’absence de réponse satisfaisante de ce service, dans un délai raisonnable d’un (1) mois,
                    l'Utilisateur et VIGNITUS mettront en œuvre leurs meilleurs efforts pour tenter de régler la
                    réclamation de l'Utilisateur à l'amiable. legal@vignitus.fr
                    Si le problème n'est pas résolu ou si l'Utilisateur n'est pas satisfait du résultat, il peut
                    recourir à des modes alternatifs de résolution des différends tels que la médiation de la
                    consommation. VIGNITUS n'est pas obligée et ne s'engage pas à utiliser de tels modes alternatifs
                    pour résoudre les litiges avec les Utilisateurs. Les litiges entre Vendeurs et Acheteurs ne sont pas
                    éligibles à une telle médiation de la consommation. Les Utilisateurs peuvent recourir aux services
                    de médiation suivants :
                    En Espagne, le Bureau municipal d'information des consommateurs (“Oficina Municipal del
                    Consumidor”).
                    En France, seuls les litiges résultant des services ou fonctionnalités VIGNITUS payés par
                    l'Utilisateur peuvent faire l'objet d'une médiation (par exemple, Services de Protection Acheteur ou
                    la fonctionnalité automatisée offerte aux Vendeurs pour booster leurs Articles). En France, VIGNITUS
                    est membre de la Fédération du e-commerce et de la vente à distance (FEVAD) et de son service de
                    médiation du e-commerce (60 rue la Boétie, 75008 Paris) – https://www.mediateurfevad.fr. Pour savoir
                    comment déposer une réclamation auprès du médiateur, contactez-nous à legal@vignitus.fr
                    Pour le Luxembourg, le Service de médiateur de la consommation (Service national du Médiateur de la
                    consommation, Ancien Hôtel de la Monnaie, 6 rue du Palais de Justice L-1841 Luxembourg,
                    info@mediateurconsommation.lu).
                    Au Portugal, un membre VIGNITUS a les options suivantes à sa disposition: (i) Soumettre une plainte
                    via le cahier de plaintes en ligne des consommateurs en suivant ce lien :
                    https://www.consumidor.gov.pt/pagina-de-entrada/livro-de-reclamacoes-regras-de-utilizacao-do-logotipo-do-lre.aspx;
                    (ii) Pour les litiges liés à des services ou des fonctionnalités VIGNITUS payés par un membre
                    non-professionnel, il est possible de recourir à la médiation ou à l'arbitrage à la discrétion de
                    l'utilisateur. Dans le cas où ledit litige a une valeur économique inférieure à 5.000 €, le
                    fournisseur sera tenu d'accepter le recours à l'utilisation de centres de médiation ou d'arbitrage
                    au cas où l'utilisateur décide d'y recourir. Pour savoir comment fonctionnent les médiateurs ou les
                    centres d'arbitrage autorisés au Portugal, nous t’invitons à consulter cette page :
                    https://www.consumidor.gov.pt/consumidor_4/-conflitos-de-consumo.aspx ici.
                    La Commission européenne a mis en place une plateforme de règlement des litiges afin de recueillir
                    les éventuelles plaintes des consommateurs à la suite d'un achat en ligne et de les transmettre aux
                    médiateurs nationaux compétents. Cette plateforme est disponible ici:
                    http://ec.europa.eu/consumers/odr.
                    <span className="color">15.3</span> Un Utilisateur peut mettre fin à ses relations avec VIGNITUS à
                    tout moment, avec effet immédiat, après s’être acquitté de toutes ses obligations résultant des
                    Transactions qu'il a effectuées avant la fin de sa relation avec VIGNITUS. Un Utilisateur peut
                    mettre fin à ses relations avec VIGNITUS en supprimant son Compte ou en envoyant un courriel.
                    VIGNITUS peut mettre fin à ses relations avec un Utilisateur moyennant un préavis écrit de trente
                    (30) jours par écrit ou par voie électronique. En outre, VIGNITUS peut également mettre fin à sa
                    relation avec un Utilisateur pour les raisons suivantes (sans que cette liste soit limitative), avec
                    effet immédiat:
                    en cas de violation des articles 7.4, 7.5 et 8 des présentes Conditions Générales ;
                    en cas de violation de l'article 11.2 consistant au téléchargement d'un Contenu faisant l'apologie
                    de crimes contre l'humanité, incitant à la haine raciale et/ou à la violence ou concernant la
                    pornographie enfantine ;
                    en cas de violation des obligations d'un Utilisateur telles qu'identifiées à l'article 12.
                    en cas de violation de l'article 4.1, consistant notamment au téléchargement des photographies et/ou
                    du Contenu en violation des droits de propriété intellectuelle d'un tiers ou en représentant des
                    tiers sans avoir obtenu leur consentement préalable ;
                    en cas d'escroquerie, d'activité frauduleuse, d'abus ou d’utilisation abusive par un Utilisateur des
                    services de VIGNITUS;
                    en cas de violation de la loi ; ou
                    lorsqu'un Utilisateur s'est opposé à la modification des présentes Conditions Générales conformément
                    à l'article 2.8.
                    <span className="color">15.4</span> VIGNITUS et les sociétés de son groupe détiennent tous les
                    droits, notamment les droits de propriété intellectuelle relatifs au Site, relatifs notamment à son
                    système (le Catalogue, sa diffusion, etc.), à la mise en page et à la conception du Site, aux
                    logiciels utilisés par et pour le Site, aux marques et aux noms de domaine utilisés par et pour le
                    Site.
                    <span className="color">15.5</span> La mise en ligne d'informations ou de données, notamment de
                    photographies sur le Site, c'est-à-dire du “Contenu”, signifie que les Utilisateurs du Site ou des
                    Services concèdent par les présentes à VIGNITUS et toute société appartenant au Groupe VIGNITUS,
                    sans contrepartie, une licence non exclusive d’utilisation du Contenu dans le monde, valable pendant
                    toute la durée des droits applicables, le cas échéant (et leurs possibles prolongations). Ladite
                    licence comprend le droit d'utiliser, de copier, de reproduire, d'afficher et d'adapter du Contenu.
                    VIGNITUS peut utiliser ledit Contenu, sur tout support connu ou inconnu à ce jour et notamment à la
                    télévision, dans les journaux, sur Internet (dans les bannières et les articles, sur d’autres sites
                    Internet) et sur les réseaux sociaux (Facebook, Twitter, Instagram etc.), et ce à des fins
                    opérationnelles, commerciales, publicitaires et internes au Groupe VIGNITUS, ce que tout Utilisateur
                    accepte. Les Utilisateurs sont exclusivement responsables du Contenu et confirment détenir tout
                    droit sur ledit Contenu.
                    <span className="color">15.6</span> Toutes les notifications, demandes et autres informations
                    échangées entre tout Utilisateur et VIGNITUS seront envoyées comme indiqué dans les Conditions
                    Générales via le formulaire de contact figurant sur le Site, et/ou par/à l’Utilisateur par courriel,
                    à l’adresse de courrier électronique qu’il aura indiquée lors de son inscription sur le Site.
                    <span className="color">15.7</span> Les Conditions Générales n’ont pas pour effet de créer entre
                    VIGNITUS et les Utilisateurs un partenariat (quelle que soit sa forme juridique), une relation régie
                    par le droit du travail, une relation entre un agent commercial et son client, ni une relation de
                    franchise.
                    <span className="color">15.8</span> VIGNITUS peut, sous réserve des lois sur la protection des
                    données applicables, transférer et/ou céder l’ensemble des droits et obligations qui sont attachés
                    aux présentes Conditions Générales, à tous tiers, notamment en cas de cession de branche d’activité,
                    de fusion par constitution d’une nouvelle société, de fusion absorption, de scission ou de tout
                    changement de contrôle affectant VIGNITUS à condition que cela ne réduise pas la protection des
                    droits du consommateur. Un tel transfert/une telle cession libère VIGNITUS de toute obligation en
                    tant qu'opérateur du Site, notamment envers ses Utilisateurs et Visiteurs pour l’avenir. En cas de
                    transfert et/ou de cession de ces droits et obligations qui sont attachés aux présentes Conditions
                    Générales, à des tiers, tout Utilisateur pourra mettre fin immédiatement à ses relations avec
                    VIGNITUS et clôturer son Compte. Les Utilisateurs ne peuvent transférer ou céder tout ou partie de
                    leurs droits et obligations découlant des présentes Conditions Générales.
                    <span className="color">15.9</span> Un Utilisateur peut formuler des objections à tout moment quant
                    à l’accès, l’utilisation ou l’exploitation du Site ou des Services par le biais d’une notification.
                    Les notifications doivent être envoyées à VIGNITUS via le formulaire figurant sur le Site ou par
                    courrier à SAS VIGNITUS, 10 Rue du château 34540 Balaruc le vieux, FRANCE. Pour toute demande
                    juridique, merci de contacter VIGNITUS à cette adresse : legal@vignitus.fr .
                    <span className="color">15.10</span> Si tout ou partie des termes contenus dans les présentes
                    Conditions Générales est considéré par une autorité compétente comme étant invalide, illicite ou
                    inapplicable dans quelque mesure que ce soit, ce terme sera dans cette mesure dissocié des termes
                    restants qui continueront à être valides et applicables dans toute la mesure autorisée par la loi.
                    <span className="color">15.11</span> Les présentes Conditions Générales et tous les documents
                    auxquels elles font référence constituent l'intégralité de l'accord entre VIGNITUS et tout
                    Utilisateur et remplacent et mettent un terme à tous les accords, promesses, assurances, garanties,
                    déclarations et arrangements antérieurs entre VIGNITUS et tout Utilisateur, qu'ils soient écrits ou
                    oraux, relatifs à leur objet. VIGNITUS et chaque Utilisateur conviennent qu'en acceptant les
                    présentes Conditions Générales, ils se sont fondés sur toute affirmation, déclaration, assurance ou
                    garantie (qu'elle soit faite de manière innocente ou par négligence) qui n'est pas énoncée dans les
                    présentes Conditions Générales ou dans tout document auquel elles font référence. Ni VIGNITUS ni un
                    Utilisateur ne pourront se prévaloir d'une quelconque fausse déclaration, qu'elle soit faite de
                    manière innocente ou par négligence, basée sur l'une quelconque des déclarations figurant dans les
                    présentes Conditions Générales ou dans tout document auquel il est fait référence.
                </div>
                </div>
            </div>
        </div>)
}
export default Cgu;
