import React, {useState} from "react";
import "./style.css";
import api from "../../Services/api"
import {UrlBaseServer} from "../../Services/storageServer";
import visaCard from "../../Assets/visaLogo.png";
import {Modal} from "react-bootstrap";
import {useAuth} from "../../Services/auth";
import {useQuery} from "react-query";
import {useLocation, useNavigate} from "react-router-dom";
import checkedCircle from "../../Assets/checkedCircle.png";
import checkCircle from "../../Assets/checkCircle.png";
import {useForm} from "react-hook-form";
import InputMask from "react-input-mask-format";

export default function BoostModal({product,showModalBoost, setShowModalBoost,reloadProducts}) {
  const {user} = useAuth()
  const params = useLocation();
  const navigate = useNavigate();
  const [CBChoosed, setCBChoosed] = useState(0);
  const [paiementId, setPaiementId] = useState(null);
  const [webPaiementUrl, setWebPaiementUrl] = useState(null);
  const [webPaiementVisible, setWebPaiementVisible] = useState(null);
  const [priceChoosed, setPriceChoosed]=useState(1)
  const [displayUpdateAddress, setDisplayUpdateAddress]=useState(false)
  const [successAddress,setSuccessAddress]=useState(null)
  const [errAddress,setErrAddress]=useState(null)
  const { register:registerAddress, handleSubmit:handleSubmitAddress, watch:watchAddress, formState: { errorsAddress } } = useForm({defaultValues:{number:user?.Addresses[0]?.number, address:user?.Addresses[0]?.address,additional_address:user?.Addresses[0]?.additional_address,city:user?.Addresses[0]?.city, postal_code:user?.Addresses[0]?.postal_code,country:user?.Addresses[0]?.country}});
  const [displayUpdateCB, setDisplayUpdateCB]=useState(false)
  const { register:registerCB, handleSubmit:handleSubmitCB, watch:watchCB, formState: { errorsCB } } = useForm({defaultValues:{nameCard:null, numCard:null, expirationDate:null, codeSecu:null}});
  const [errCB,setErrCB]=useState(null);
  const [successCB,setSuccessCB]=useState(null)
  const [successBoosted,setSuccessBoosted]=useState(null)
  const [errorBoosted,setErrorBoosted]=useState(null)



  const { isLoading,
    isError,
    error,
    data:dataCard,
      refetch:refetchCard,
    isFetching,
    isPreviousData, } = useQuery(['repoCard'], ()=> api.getCards(),{ keepPreviousData : true }
  )
  const {
    data:dataBoostModel
  } = useQuery(['repoBoostModel'], ()=> api.getBoostModel(),{ keepPreviousData : true }
  )
  React.useEffect(()=>{
    if(dataBoostModel){
    setPriceChoosed({
      index: dataBoostModel.data[0].id,
      price: dataBoostModel.data[0].price,
      days: dataBoostModel.data[0].duration,
    })}
  },[dataBoostModel])
  React.useEffect(()=>{
    if(dataCard){
      findIndexOnArray()
    }
  },[dataCard])
  async function findIndexOnArray() {
    // let cardId = await AsyncStorage.getItem("cardID");
    let cardId = user.selectedCardId;
    let cardIndex = dataCard?.data?.findIndex((object) => {
      return object.Id === cardId;
    });

    if (cardIndex == -1) {
      cardIndex = 0;
    }
    setCBChoosed(cardIndex);
  }
  const onSubmitCB = async (data,e)=>{
    let error=false
    console.log(data)
    if (!data.nameCard?.trim()) {
      error=true
      setErrCB("Vous devez rentrer le nom figurant sur la carte ");
    }
    if (data.numCard?.replaceAll(" ", "").length != 16) {
      error=true
      setErrCB("Vous devez rentrer les 16 numéro figurant sur la carte");
    }
    if (data.expirationDate?.length != 5) {
      error=true
      setErrCB("Vous devez rentrer la date d'expiration");
    }
    if (data.codeSecu?.length != 3) {
      error=true
      setErrCB("Vous devez rentrer les 3 chiffres de sécurité");
    }
    if(error==false){
      api.createCardsToken().then((response) => {
        let cardToken = response.data;

        var details = {
          data: cardToken.PreregistrationData,
          accessKeyRef: cardToken.AccessKey,
          returnURL: "",
          cardNumber: data.numCard?.replaceAll(" ", ""),
          cardExpirationDate: data.expirationDate.replace("/", ""),
          cardCvx: data.codeSecu,
        };

        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        fetch(response.data.CardRegistrationURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: formBody,
        })
            .then((response) => response.text())
            .then((response) => {
              api
                  .updateCardToken({
                    id: cardToken.Id,
                    RegistrationData: response,
                  })
                  .then((responseUpdateCard) => {
                    console.log(responseUpdateCard);
                    setSuccessCB(true)
                    refetchCard()
                    setDisplayUpdateCB(false)

                   // setVisible(false);
                  });
            });
      });
    }
  }
  const onSubmitAddress = async (data, e) => {
    try {
      if(user?.Addresses[0].id){
        await api.putUpdateAddress(user?.Addresses[0].id,
            user.firstName,
            user.firstName,
            data.number,
            data.address,
            data.additional_address,
            data.postal_code,
            data.city,
            data.country)
        setSuccessAddress("Votre adresse a été modifié avec succès")

      }else{
        await api.postAddress(
            user.firstName,
            user.firstName,
            data.number,
            data.address,
            data.additional_address,
            data.postal_code,
            data.city,
            data.country,
            true,
            true)
        setSuccessAddress("Votre adresse a été créé avec succès")
      }
    } catch (error) {
      setErrAddress(error.response.data.error)
    }
    e.preventDefault();
  }

  async function payedBoost() {
    //cardSelected({ selectedCardId: cards[CBChoosed].Id });

    const boostData = await api.postBoost(
        [product],
        priceChoosed.index,
        dataCard?.data[CBChoosed].Id
    );
    console.log(boostData);
    //setPaiementId(boostData.data.data.Id);

    if (boostData.data.data.Status == "SUCCEEDED") {
      console.log(boostData.data.data)
      const nameuri =params.pathname.split("/").pop()
      console.log(nameuri)
      reloadProducts()
      setSuccessBoosted("Votre produit a bien été boosté")
      setTimeout(()=>{
        if(nameuri=="user-profile"){
          setShowModalBoost(false)
          setSuccessBoosted(null)
          // window.location.reload(false)
        }else{
          navigate("/user-profile",{})
        }
      },3000)    }else if(boostData.data.data.Status == "FAILED"){
      setErrorBoosted("Votre paiement n'a pas abouti")
    }else if(boostData.data.data.SecureModeNeeded){
      window.open(boostData.data.data.SecureModeRedirectURL);
      testTransaction(boostData.data.data.Id,dataCard?.data[CBChoosed])
    }
  }
  async function testTransaction(pI, cbChoosed){
    console.log("test Transaction", pI, cbChoosed.Id)
    let transac = await api.getTransaction(
        pI,
        cbChoosed.Id
    );
    console.log(transac);

    if(transac.data.Status!="CREATED"){
      if (transac.data.Status == "FAILED") {
        //modalError
        //setModalFailedVisible(true);
        setErrorBoosted("Votre paiement n'a pas abouti")
      }
      if (transac.data.Status == "SUCCEEDED") {
        const nameuri =params.pathname.split("/").pop()
        console.log(nameuri)
        setSuccessBoosted("Votre produit a bien été boosté")
        setTimeout(()=>{
          if(nameuri=="user-profile"){
            reloadProducts()
            setShowModalBoost(false)
            setSuccessBoosted(null)
            // window.location.reload(false)
          }else{
            navigate("/user-profile",{})
          }
        },3000)
        //setModalBoostOk(true);
        //setModalValidateVisible(true);
      }
    }else{
      testTransaction(pI, cbChoosed)
    }
  }
  return (
      <Modal show={showModalBoost} onHide={()=>{setShowModalBoost(false)}} animation={false}>
        <div className="modalBoost modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="edit-model">
              <p className="edit-modal-title">Boost</p>
              <div style={{display:"flex"}}>
                <img style={{width:100, height:100, objectFit:"cover"}} src={UrlBaseServer+product?.Product_images[0]?.image} />
                <div style={{display:"flex", marginLeft:10, flexDirection:"column", justifyContent:"space-between"}}>
                  <div style={{color:"grey"}}>
                    {product?.year}
                    {" " + product?.Region?.name + " "}
                    {product?.Appelation?.name}
                  </div>
                  <div>
                    {product?.name}
                  </div>
                  <div style={{fontWeight:800}}>
                    {product?.price} €
                  </div>
                </div>
              </div>
              <hr/>
              <div>
                {dataBoostModel?.data?.map((v,i)=>{
                  return(
                      <section key={"boost"+i} onClick={()=>{
                        setPriceChoosed({
                          index: v.id,
                          price: v.price,
                          days: v.duration,
                        })
                      }} className="radioBox">
                        <div>
                          <label>
                            <img style={{width:25,marginRight:5}} className={priceChoosed.index == v.id?("circleChecked"):("circleUncheck")} src={priceChoosed.index == v.id
                                ? checkedCircle
                                : checkCircle}/>
                          </label>
                        </div>
                        <div className="d-flex prizeCont">
                          <div className="radioContent">
                            <h5>{v.duration + " jours "}</h5>
                            <p>{"( " + Math.round((v?.price / v?.duration) * 100) / 100 + " € / jour )"}</p>
                          </div>
                          <div className="mr-3">{v?.price}&#8364;</div>
                        </div>
                      </section>
                  )
                })}
              </div>
              <div>
                Montant à payer: <span style={{fontWeight:800}}>{priceChoosed?.price} €</span>
              </div>
              <hr/>
              <div>
                <h5>Adresse</h5>
                <div style={{display:"flex", alignItems:"center"}}>
                  {user.Addresses?.length > 0 ? (
                      <div style={{ flex: 1 }}>
                        <div
                            style={{

                              fontWeight: "600",
                            }}
                        >
                          {user.Addresses[0].firstName + " " + user.Addresses[0].lastName}
                        </div>
                        <div
                            style={{

                            }}
                        >
                          {user.Addresses[0].number +
                          " " +
                          user.Addresses[0].address +
                          " " +
                          user.Addresses[0].additional_address}
                        </div>
                        <div
                            style={{
                            }}
                        >
                          {user.Addresses[0].postal_code +
                          " " +
                          user.Addresses[0].city +
                          " " +
                          user.Addresses[0].country}
                        </div>
                      </div>
                  ) : (
                      <div>Indiquez votre adresse</div>
                  )}
                  <i onClick={()=>{
                    setDisplayUpdateAddress(!displayUpdateAddress)
                  }} className="fa-solid fa-pen-to-square" style={{marginRight:5, cursor:"pointer", fontSize:24}}/>
                </div>
                {displayUpdateAddress?(
                    <form onSubmit={handleSubmitAddress(onSubmitAddress)} noValidate>
                      {successAddress?(
                          <div className="alert alert-success">
                            {successAddress}
                          </div>
                      ):null}
                      <div className="d-flex flex-lg-row flex-column flex-nowrap">
                        <div className="col-2 col-md-2 col-lg-2">
                          <div className="text-field-box">
                            <label className="input-label">Numéro</label>
                            <input
                                type="text"
                                className="input"
                                {...registerAddress("number",{ required: true })}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                          <div className="text-field-box">
                            <label className="input-label">Rue</label>
                            <input
                                type="text"
                                className="input"
                                {...registerAddress("address",{ required: true })}
                            />
                          </div>
                        </div>
                        <div className="col-10  col-md-4 col-lg-4">
                          <div className="text-field-box">
                            <label className="input-label">Complément d’adresse</label>
                            <input
                                type="text"
                                className="input"
                                {...registerAddress("additional_address",{ })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-lg-row flex-column flex-nowrap">

                        <div className="col-12  col-md-4 col-lg-4">
                          <div className="text-field-box">
                            <label className="input-label">Ville</label>
                            <input type="text" className="input"
                                   {...registerAddress("city",{ required: true })}
                            />
                          </div>
                        </div>
                        <div className="col-12  col-md-4 col-lg-4">
                          <div className="text-field-box">
                            <label className="input-label">Code postal</label>
                            <input type="text" className="input"
                                   {...registerAddress("postal_code",{ required: true })}
                            />
                          </div>
                        </div>
                        <div className="col-12  col-md-4 col-lg-46">
                          <div className="text-field-box">
                            <label className="input-label">Pays</label>
                            <input type="text" className="input"
                                   {...registerAddress("country",{ required: true })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button>Modifier mon adresse</button>
                      </div>
                    </form>):null}
              </div>
              <hr/>
              <div>
                <h5>Paiement <i onClick={()=>{
                  setDisplayUpdateCB(!displayUpdateCB)
                  //setDisplayUpdateAddress(!displayUpdateAddress)
                }}  className="fa-solid fa-pen-to-square" style={{marginRight:5, cursor:"pointer", fontSize:24}}/></h5>
                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                  {dataCard?.data?.length ? (
                      <div>
                        {dataCard.data.map((v,i)=>{
                          return(
                              <React.Fragment key={"dataCard"+i}>
                                {dataCard.data[i].Validity!="INVALID"?(
                                    <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                          setCBChoosed(i)
                                        }}
                                    >
                                      <div>
                                        <label>
                                          <img style={{width:25,marginRight:5}} className={CBChoosed == i?("circleChecked"):("circleUncheck")} src={CBChoosed == i
                                              ? checkedCircle
                                              : checkCircle}/>
                                        </label>
                                      </div>
                                      <img
                                          style={{
                                            width: 50,
                                            height: 35,
                                            resizeMode: "contain",
                                          }}
                                          src={visaCard}
                                      />
                                      <div
                                          style={{
                                            marginLeft: 16,
                                            flex: 1,
                                          }}
                                      >
                                        <div
                                            style={{
                                              fontWeight: "300",
                                            }}
                                        >
                                          {dataCard?.data[i]?.CardProvider + " se terminant par "}
                                          <div
                                              style={{
                                                fontWeight: "700",
                                              }}
                                          >
                                            {dataCard?.data[i]?.Alias.substr(
                                                dataCard?.data[i]?.Alias?.length - 4
                                            )}
                                          </div>
                                        </div>
                                        <div
                                            style={{
                                              fontWeight: "300",
                                            }}
                                        >
                                          Date d’expiration :{" "}
                                          {dataCard?.data[i]?.ExpirationDate[0] +
                                          "" +
                                          dataCard?.data[i]?.ExpirationDate[1]}
                                          /
                                          {dataCard?.data[i]?.ExpirationDate.substr(
                                              dataCard?.data[i]?.ExpirationDate?.length - 2
                                          )}
                                        </div>
                                      </div>
                                    </div>):null}</React.Fragment>)})}
                      </div>
                  ) : (
                      <div>Pas de carte enregistré</div>
                  )}

                </div>
                {displayUpdateCB?(
                    <form onSubmit={handleSubmitCB(onSubmitCB)} noValidate>
                      {successCB?(
                          <div className="alert alert-success">
                            Ajout de votre carte réussi
                          </div>
                      ):null}
                      {errCB?(
                          <div className="alert alert-danger">
                            {errCB}
                          </div>
                      ):null}
                      <div className="d-flex flex-lg-row flex-column flex-nowrap">
                        <div className="col-12 col-md-12 col-lg-12">
                          <div className="text-field-box">
                            <label className="input-label">Nom sur la carte</label>
                            <input
                                type="text"
                                className="input"
                                {...registerCB("nameCard",{ required: true })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-lg-row flex-column flex-nowrap">
                        <div className="col-12 col-md-12 col-lg-12">
                          <div className="text-field-box">
                            <label className="input-label">Numéro de la carte</label>
                            <InputMask mask={"9999 9999 9999 9999"} className="input" {...registerCB("numCard",{ required: true })} />

                            {/*<input*/}
                            {/*    type="text"*/}
                            {/*    className="input"*/}
                            {/*    {...registerCB("numCard",{ required: true })}*/}
                            {/*/>*/}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-lg-row flex-column flex-nowrap">
                        <div className="col-6 col-md-6 col-lg-6">
                          <div className="text-field-box">
                            <label className="input-label">Date expiration</label>
                            <InputMask
                                mask={"99/99"}
                                className="input"
                                {...registerCB("expirationDate",{ required: true })}
                            />
                          </div>
                        </div>
                        <div className="col-6 col-md-6 col-lg-6">
                          <div className="text-field-box">
                            <label className="input-label">Code secret</label>
                            <InputMask
                                mask={"999"}
                                type="text"
                                className="input"
                                {...registerCB("codeSecu",{ required: true })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button>Ajouter la carte</button>
                      </div>
                    </form>
                ):null}
              </div>
              {errorBoosted?(
                  <div style={{marginTop:30}} className="alert alert-danger">
                    {errorBoosted}
                  </div>
              ): null}
              <div style={{marginTop:30}} className="modal-btn-box">
                {successBoosted?(
                    <div className="alert alert-success">
                      {successBoosted}
                    </div>
                ):(
                    <button className="btn2 mb-2" onClick={()=>{
                      console.log("paiement")
                      payedBoost();
                    }} >Valider le paiement</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
  );
}
