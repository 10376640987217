import React, {useState} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useAuth} from '../Services/auth'
import {Login} from "../Pages";
import firebase from "../Services/firebase";

const Private = ({children}) => {
    const {isLoggingIn,user,login, error} = useAuth()
    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if(user){
                login(user.data)
            }
        })
    }, [])
    const location = useLocation()
    const [requestedLocation, setRequestedLocation] = useState(
        null
    )

    if (!user) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname)
        }
        return (
                <>
                    <Login />
                </>
        )
    }

    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    return <>{children}</>
}

Private.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

export default Private
