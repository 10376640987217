import React from "react";
import { Navbar, Footer } from "./../../Components";
import Banner from "./../../Containers/Faq/Banner";
import ListFAQ from "./../../Containers/Faq/ListFAQ";
//import ForWish from "../../Containers/Faq/";
import {useLocation} from "react-router-dom";
import {useInfiniteQuery} from "react-query";
import api from "../../Services/api";

function Faq() {
    const params = useLocation();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);


    return (
    <div>
      <Navbar />
      <div className="content-wraper">
        <Banner />
        <ListFAQ/>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
