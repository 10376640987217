import React from "react";
import { Navbar, Footer } from "./../../Components";
import Detail from "./../../Containers/CheckOut/Detail";
import Banner from "../../Containers/Cgu/Banner";
import {default as CguContainer}  from "../../Containers/Cgu/Cgu";

function Cgu() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
          <Banner/>
          <CguContainer/>
      </div>
      <Footer />
    </div>
  );
}

export default Cgu;
