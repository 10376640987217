import React,{useState,useRef} from "react";
import "./style.css";
import {useNavigate} from "react-router-dom";
import {Modal, Button} from "react-bootstrap"
import {UrlBaseServer} from "../../../Services/storageServer";
import {useAuth} from "../../../Services/auth";
import {useQuery} from "react-query";
import api from "../../../Services/api"
import visaCard from "./../../../Assets/visaLogo.png"

import data from "bootstrap/js/src/dom/data";
import BoostModal from "../../../Components/Modal/BoostModal";
const BottomBtn = ({product, update, showModal, setShowModal, setShowModalBoost, showModalBoost,priceChoosed}) => {
  const navigate = useNavigate();
  const handleClose = () =>  navigate("/product-detail/"+product.id,{});
  const handleShow = () => setShowModal(true);
  const handleCloseBoost = () => setShowModalBoost(false)
    const [CBChoosed, setCBChoosed] = useState(0);
    const [paiementId, setPaiementId] = useState(null);
    const [webPaiementUrl, setWebPaiementUrl] = useState(null);
    const [webPaiementVisible, setWebPaiementVisible] = useState(null);
    const {user} = useAuth()
    const { isLoading,
        isError,
        error,
        data:dataCard,
        isFetching,
        isPreviousData, } = useQuery(['repoCard'], ()=> api.getCards(),{ keepPreviousData : true }
    )
    React.useEffect(()=>{
        if(dataCard){
            findIndexOnArray()
        }
    },[dataCard])
    async function payedBoost() {
        //cardSelected({ selectedCardId: cards[CBChoosed].Id });

        const boostData = await api.postBoost(
            [product],
            priceChoosed.index,
            dataCard?.data[CBChoosed].Id
        );
        console.log(boostData);
        setPaiementId(boostData.data.data.Id);
        if (boostData.data.data.SecureModeNeeded) {
            setWebPaiementUrl(boostData.data.data.SecureModeRedirectURL);
            setWebPaiementVisible(true);
        }
        if (boostData.data.data.Status == "SUCCEEDED") {
            navigate("/user-profile",{})
            //setModalBoostOk(true);
            //setModalValidateVisible(true);
        }
    }
    async function findIndexOnArray() {
        // let cardId = await AsyncStorage.getItem("cardID");
        let cardId = user.selectedCardId;
        let cardIndex = dataCard?.data?.findIndex((object) => {
            return object.Id === cardId;
        });

        if (cardIndex == -1) {
            cardIndex = 0;
        }
        setCBChoosed(cardIndex);
    }
    return (
    <div>
      <div className="text-center mb-5">
        <button onClick={()=>window.history.back()} type="button" className="btn1 mx-4 mb-2">
          Annuler
        </button>
        {update?(<button
            type="submit"
            className="btn2 mb-2"
            //data-toggle="modal"
            //data-target="#exampleModalCenter"
        >
          Modifier
        </button>):(<button
            type="submit"
            className="btn2 mb-2"
            //data-toggle="modal"
            //data-target="#exampleModalCenter"
        >
          Ajouter
        </button>)}

      </div>

      <Modal show={showModal} onHide={handleClose} animation={false}>
        {update?(<div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="edit-model">
              <p className="edit-modal-title">Bouteille Mise à jour</p>
              <p className="edit-modal-description">
                Votre annonce a été mise à jour et sera visible par les autres membres dans quelques
                minutes. Vous avez la possibilité de booster votre annonce
                depuis la rubrique ma cave à tout moment.
              </p>
              <div className="modal-btn-box">
                <button className="btn2 mb-2" onClick={()=>{
                  navigate("/product-detail/"+product.id,{})
                }} >Voir mon annonce</button>
                <button onClick={()=>{
                  navigate("/user-profile",{})
                }} className="btn1">Voir ma cave</button>
              </div>
            </div>
          </div>
        </div>):(
            <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="edit-model">
              <p className="edit-modal-title">Bouteille Mise En Ligne</p>
              <p className="edit-modal-description">
                Votre annonce sera visible par les autres membres dans quelques
                minutes. Vous avez la possibilité de booster votre annonce
                depuis la rubrique ma cave à tout moment
              </p>
              <div className="modal-btn-box">
                <button className="btn2 mb-2" onClick={()=>{
                  navigate("/product-detail/"+product.id,{})
                }} >Voir mon annonce</button>
                <button onClick={()=>{
                  navigate("/edit-bottle",{})
                }} className="btn1">Ajouter une autre Bouteille</button>
              </div>
            </div>
          </div>
        </div>
        )}

      </Modal>
        <BoostModal setShowModalBoost={setShowModalBoost} showModalBoost={showModalBoost} product={product}  />

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="edit-model">
              <p className="edit-modal-title">Bouteille Mise En Ligne</p>
              <p className="edit-modal-description">
                Votre annonce sera visible par les autres membres dans quelques
                minutes. Vous avez la possibilité de booster votre annonce
                depuis la rubrique ma cave à tout moment
              </p>
              <div className="modal-btn-box">
                <button className="btn2 mb-2">voir mon annonce</button>
                <button className="btn1">Ajouter une autre Bouteille</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BottomBtn;
