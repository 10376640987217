import React,{useState} from "react";
import "./style.css";
import api from "../../Services/api"

export default function SendModal({setRefusModal,setReviewModal, user, data, otherUser}) {

    function displayFollow() {
        if (data.Offer.deliveryCode != null) {
            let linkFollow =
                data?.Offer?.Delivery_mode?.Delivery_mode_model?.url_follow +
                "" +
                data.Offer.deliveryCode;
            return (
                <div
                    style={{ marginBottom: 20, marginTop: 20, cursor:"pointer" }}
                    onClick={() => {
                        window.open(linkFollow, "_blank")
                        //WebBrowser.openBrowserAsync(linkFollow);
                        console.log(linkFollow);
                    }}
                >
                    <span style={{ textAlign: "center", fontWeight: "bold" }}>
                        Suivez le colis:{" "}
                    </span>
                    <span style={{ textAlign: "center", paddingHorizontal: 8 }}>
                        {linkFollow}
                    </span>
                </div>
            );
        } else {
            return null;
        }
    }

  return (
    <div className="card_modal">
        {data.Offer.user_id != user.id ? (
            <>
                <p
                    style={{
                        background: "none",
                        marginBottom: 4,
                    }}
                >
                    En attente de reception de la commande par {otherUser?.firstName} {otherUser?.lastName}
                </p>

                {displayFollow()}
            </>
        ) :  (
                <>
                    <p
                        style={{
                            background: "none",
                            marginBottom: 4,
                        }}
                    >
                        {otherUser?.firstName} {otherUser?.lastName} a envoyé votre commande
                    </p>

                    {displayFollow()}
                    {data.offerStatus == data.Offer.status && (
                        <>
                            <div style={{ display: "flex" , }}>
                                <input
                                    type="button"
                                    className="btn btn-outline-danger btn-modal mx-4 mb-2"
                                    value="J'ai recu ma commande"
                                    style={{
                                        background: "#6a0013",
                                        border: "none",
                                        color: "white",
                                    }}
                                    onClick={()=> {
                                        api
                                            .receiveOrder(data.Offer.id)
                                            .then((response) => {})
                                            .catch((error) => console.log(error.response))
                                    }}
                                />
                                <input
                                    type="button"
                                    className="btn btn-outline-danger btn-modal mx-4 mb-2"
                                    value="J'ai un problème avec ma commande"
                                    onClick={()=>{
                                        api.problemOrder(data.Offer.id);
                                        console.log("Envoyé");
                                    }}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
    </div>
  );
}
