import firebase from "firebase/compat/app";
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyArMDQGqwG5qlGEyFWqDpFZnytl1-WiLbI",
    authDomain: "vignitus-d3081.firebaseapp.com",
    projectId: "vignitus-d3081",
    storageBucket: "vignitus-d3081.appspot.com",
    messagingSenderId: "735608656294",
    appId: "1:735608656294:web:4a557850bf1aaa44c23f67",
    measurementId: "G-L3QV3GWDZT"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
provider.setCustomParameters({prompt:'select_account'});
facebookProvider.setCustomParameters({prompt:'select_account'});

export const signInWithGoogle = ()=> auth.signInWithPopup(provider);
export const signInWithFacebook = ()=> auth.signInWithPopup(facebookProvider);
export default firebase;
