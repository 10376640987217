import React, {useRef, useState} from "react";
import ProfileImg from "./../../../Assets/defaultAvatar.png";
import "./profileContainer.css";
import {useAuth} from "../../../Services/auth";
import {UrlBaseServer} from "../../../Services/storageServer";
import {useForm} from "react-hook-form";
import api from "../../../Services/api"
function Profile() {
  const {user} = useAuth()
  const { register, handleSubmit, watch, formState: { errors } } = useForm({defaultValues:{lastName:user.lastName,firstName:user.firstName,email:user?.email,phone:user?.phone}});
  const { register:registerAddress, handleSubmit:handleSubmitAddress, watch:watchAddress, formState: { errorsAddress } } = useForm({defaultValues:{number:user?.Addresses[0]?.number, address:user?.Addresses[0]?.address,additional_address:user?.Addresses[0]?.additional_address,city:user?.Addresses[0]?.city, postal_code:user?.Addresses[0]?.postal_code,country:user?.Addresses[0]?.country}});
  const { register:registerPassword, handleSubmit:handleSubmitPassword, watch:watchPassword, formState: { errorsPassword } } = useForm({defaultValues:{}});
  const [err,setErr]=useState(null)
  const [errAddress,setErrAddress]=useState(null)
  const [errPassword,setErrPassword]=useState(null)
  const [successPassword,setSuccessPassword]=useState(null)
    const [successAddress,setSuccessAddress]=useState(null)
    const [successProfil,setSuccessProfil]=useState(null)
  const [img,setImg]=useState(null)
  const inputFile = useRef(null)

  const handleEmailValidation = email => {
    console.log("ValidateEmail was called with", email);

    const isValid = isValidEmail(email);

    const validityChanged =
        (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
      console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
    }

    return isValid;
  };

  const isValidEmail = email =>
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
      );
  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  function uploadImage(files){
    getBase64(files[0],(result) => {
      setImg(result)
    })
  }

  const onSubmit = async (data, e) => {
    try {
      if(img){
        data.base64=img
      }
      console.log(data)
       await api.putUpdateUser(data.firstName,
           data.lastName,
           data.email,
           data.password,
           data.base64,
           data.number_siret,
           data.phone,
           data.description)
      setSuccessProfil('Votre profil a été modifié avec succès')
    } catch (error) {
      setErr(error.response.data.error)
    }
    e.preventDefault();

  }

  const onSubmitAddress = async (data, e) => {
    try {
      if(user?.Addresses[0].id){
        await api.putUpdateAddress(user?.Addresses[0].id,
            user.firstName,
            user.firstName,
            data.number,
            data.address,
            data.additional_address,
            data.postal_code,
            data.city,
            data.country)
        setSuccessAddress("Votre adresse a été modifié avec succès")

      }else{
        await api.postAddress(
            user.firstName,
            user.firstName,
            data.number,
            data.address,
            data.additional_address,
            data.postal_code,
            data.city,
            data.country,
            true,
            true)
        setSuccessAddress("Votre adresse a été créé avec succès")
      }
    } catch (error) {
      setErrAddress(error.response.data.error)
    }
    e.preventDefault();
  }
  const onSubmitPassword = async (data, e) => {
    try {
     await api.changePassword(data.password, data.newPassword)
      setSuccessPassword("Votre mot de passe a été changé avec succès")
    }catch (error){
      setErrPassword(error.response.data.message)
    }
  }

  return (
    <div className="container ">
      <div className="edit-profile-card">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {successProfil?(
              <div className="alert alert-success">
                {successProfil}
              </div>
          ):null}
        <div className=" d-flex flex-lg-row flex-column-reverse align-items-center  flex-nowrap">
          <div className="col-12  col-md-9 col-lg-9">
            <div className="d-flex flex-lg-row flex-column-reverse  flex-nowrap">
              <div className="col-12  col-md-6 col-lg-6">
                <div className="text-field-box">
                  <label className="input-label">Nom</label>
                  <input {...register("lastName",{ required: true })} type="text" className="input"/>
                </div>

                <div className="text-field-box">
                  <label className="input-label">Email</label>
                  <input {...register("email",{ required: true, validate: handleEmailValidation })} type="email" id="email"  name="email" className="input" />
                  {errors?.email?.type== "validate" ?( <div className="alert alert-danger">L'email n'est pas valide</div>):errors?.email?.type =="required"?(<div className="alert alert-danger">L'email est requis</div>):null}
                </div>
              </div>
              <div className="col-12  col-md-6 col-lg-6">
                <div className="text-field-box">
                  <label className="input-label">Prénom</label>
                    <input {...register("firstName",{ required: true })} type="text" className="input" />
                </div>

                <div className="text-field-box">
                  <label className="input-label">Téléphone</label>
                  <input {...register("phone",{ required: true })} type="text" className="input"/>
                </div>
              </div>
            </div>
          </div>
          <div  onClick={()=>{
            inputFile.current.click();
          }} style={{cursor:"pointer"}}>
            {img?(
                <img  src={img} alt="" className="user-edit-profile" />
            ):user?.avatar?(
                <img  src={UrlBaseServer+user?.avatar} alt="" className="user-edit-profile" />
            ):(
                <img  src={ProfileImg} alt="" className="user-edit-profile" />
            )}
            <p className="edit-profile">Modifier ma photo de profil</p>
            <input onChange={e => {
              uploadImage(e.target.files)
            }}  accept="image/*"  type="file" id="file" ref={inputFile} style={{display:"none"}} />
          </div>
        </div>
          <div className="d-flex justify-content-center">
            <button>Modifier mon profil</button>
          </div>
      </form>
        <form onSubmit={handleSubmitAddress(onSubmitAddress)} noValidate>
          {successAddress?(
              <div className="alert alert-success">
                {successAddress}
              </div>
          ):null}
          <div className="d-flex flex-lg-row flex-column flex-nowrap">
              <div className="col-2 col-md-2 col-lg-2">
                <div className="text-field-box">
                  <label className="input-label">Numéro</label>
                  <input
                    type="text"
                    className="input"
                    {...registerAddress("number",{ required: true })}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="text-field-box">
                  <label className="input-label">Rue</label>
                  <input
                    type="text"
                    className="input"
                    {...registerAddress("address",{ required: true })}
                  />
                </div>
              </div>
              <div className="col-10  col-md-4 col-lg-4">
                <div className="text-field-box">
                  <label className="input-label">Complément d’adresse</label>
                  <input
                    type="text"
                    className="input"
                    {...registerAddress("additional_address",{ })}
                  />
                </div>
              </div>
            </div>
              <div className="d-flex flex-lg-row flex-column flex-nowrap">

                <div className="col-12  col-md-4 col-lg-4">
                  <div className="text-field-box">
                    <label className="input-label">Ville</label>
                    <input type="text" className="input"
                           {...registerAddress("city",{ required: true })}
                    />
                  </div>
                </div>
                <div className="col-12  col-md-4 col-lg-4">
                  <div className="text-field-box">
                    <label className="input-label">Code postal</label>
                    <input type="text" className="input"
                           {...registerAddress("postal_code",{ required: true })}
                    />
                  </div>
                </div>
                <div className="col-12  col-md-4 col-lg-46">
                  <div className="text-field-box">
                    <label className="input-label">Pays</label>
                    <input type="text" className="input"
                           {...registerAddress("country",{ required: true })}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button>Modifier mon adresse</button>
              </div>
        </form>

        <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
        <h1 className="section-title">MODIFIER MON MOT DE PASSE</h1>
          {errPassword?(
              <div className="alert alert-danger">
                {errPassword}
              </div>
          ):null}
          {successPassword?(
              <div className="alert alert-success">
                {successPassword}
              </div>
          ):null}

        <div className="d-flex flex-lg-row flex-column flex-nowrap">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="text-field-box">
              <label className="input-label">Mot de passe actuel</label>
              <input {...registerPassword("password",{ required: true })} type="password" className="input" />
            </div>
          </div>
          <div className="col-12  col-md-6 col-lg-6">
            <div className="text-field-box">
              <label className="input-label">Nouveau mot de passe</label>
              <input {...registerPassword("newPassword",{ required: true })}  type="password" className="input" />
            </div>
          </div>
        </div>
          <div className="d-flex justify-content-center">
            <button>Modifier mon mot de passe</button>
          </div>
        </form>
        <br />

        <br />
      </div>
    </div>
  );
}

export default Profile;
