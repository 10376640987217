import React, {useEffect, useState} from "react";
import { OpinionCard } from "./../../../Components";
import "./opinionContainer.css"
import api from "../../../Services/api";
import {useQuery} from "react-query";
import {useLocation} from "react-router-dom";

function Opinion() {
    const params = useLocation();
    let [userD, setUserD]=useState(null)

    const nameuri =params.pathname.split("/").pop()
    useEffect(async ()=>{
        if (params.state?.user && params.state) {
            setUserD(params.state?.user)
        }else{
            var result = await api.getUserById(nameuri);
            if(result){
                setUserD(result.data)
            }
        }
    },[])
    //api.getRatesById(userData.id)
    const { isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData, } = useQuery(['rates',nameuri], ()=> api.getRatesById(nameuri),{ keepPreviousData : true }
    )
  return (
    <div className="container pt-5 opinion-container">
      <h1 className="title">Avis de {userD?.firstName} {userD?.lastName}</h1>
      <p className="description">{data?.data.length} avi(s)</p>
      <div className="opinion-container-box">
          {data?.data.length?(
            <>
              {data?.data?.map((v,i)=>{
                return(<OpinionCard key={i} data={v} />)
                })}
              </>
            ):(
              <div style={{textAlign:"center"}}>
                  Aucun avis
              </div>
          )}
      </div>
    </div>
  );
}

export default Opinion;
