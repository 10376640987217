import React,{useState} from "react";
import "./style.css";
import api from "../../Services/api"

export default function PayedModal({user, data, otherUser}) {
    const [deliveryCode, setDeliveryCode] = useState(null);

    return (
    <div className="card_modal">
        {data.Offer.user_id != user.id?(
            <>
            <p
                style={{
                    background: "none",
                    marginBottom: 4,
                }}
            >
                La commande a été payé par {otherUser?.firstName} {otherUser?.lastName}
            </p>

                {data.offerStatus == data.Offer.status && (
                    <>
                        {data.Offer.Delivery_mode.delivery_mode_model_id != null ? (
                            <>
                                <input style={{marginBottom:10}} onChange={(e)=>{
                                    setDeliveryCode(e.target.value)
                                }} value={deliveryCode}  placeholder="Entrée le code de suivi du colis"/>
                                <input
                                    type="button"
                                    className="btn btn-outline-danger btn-modal mx-4 mb-2"
                                    value="Commande envoyé"
                                    style={{
                                        background: "#6a0013",
                                        border: "none",
                                        color: "white",
                                    }}
                                    onClick={()=> {
                                        if (deliveryCode) {
                                            api.sendOrder(
                                                data.Offer.id,
                                                deliveryCode
                                            );
                                        }
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <input
                                    type="button"
                                    className="btn btn-outline-danger btn-modal mx-4 mb-2"
                                    value="Commande envoyé"
                                    style={{
                                        background: "#6a0013",
                                        border: "none",
                                        color: "white",
                                    }}
                                    onClick={()=> {
                                        api.sendOrder(data.Offer.id)
                                    }}
                                />
                            </>
                        )}
                    </>
                )}

            </>
        ):(
            <>
            <p
                style={{
                    background: "none",
                    marginBottom: 4,
                }}
            >
                En attente de l'envoie de la commande par {otherUser?.firstName} {otherUser?.lastName}
            </p>

            </>
        )}
    </div>
  );
}
