import Navbar from "../../Components/Navbar/index";
import Detail from "../../Containers/AboutProduct/Detail";
import Footer from "../../Components/Footer/index";
import AlsoLike from "./../../Containers/AboutProduct/AlsoLike";

const projecttwo = () => {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
        <Detail />
        {/*<AlsoLike />*/}
      </div>
      <Footer />
    </div>
  );
};

export default projecttwo;
