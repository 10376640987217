import React, {useEffect, useState} from "react";
import Navbar from "../../Components/Navbar/index";
import ModifyProd from "../../Containers/EditBottle/ModifySection/ModifyProd";
import EditBottleContent from "../../Containers/EditBottle/EditBottleContent/EditBottleContent";
import BottomSec from "../../Containers/EditBottle/BottomSection/BottomSec";
import Buttons from "../../Containers/EditBottle/BottomBtn/Buttton";
import Footer from "../../Components/Footer/index";
import {useForm} from "react-hook-form";
import api from "../../Services/api";
import {useLocation} from "react-router-dom";
import {UrlBaseServer} from "../../Services/storageServer";
import { createSyntheticFile,makeSynthticFileValidate } from "@dropzone-ui/react";
import {Alert} from "react-bootstrap"


const EditBottle = () => {
    const params = useLocation();
    const nameuri =params.pathname.split("/").pop()
    console.log(nameuri)
    const [product, setProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalBoost, setShowModalBoost] = useState(false);
    const [files, setFiles] = useState([]);
    const [coffret, setCoffret] = React.useState(false);
    const [CRD, setCRD] = React.useState(false);
    const [livraisonMode, setLivraisonMode] = useState([{
        delivery_mode_model_id: "",
        price: "",
        name: "",
    }]);
    const [priceChoosed, setPriceChoosed] = useState({
        index: 0,
        price: 3,
        days: 3,
    });
    const [year, setYear] = React.useState("");
    const [region, setRegion] = React.useState("");
    const [appelation, setAppelation] = React.useState("");
    const [country, setCountry] = React.useState(59);
    const [customError, setCustomError] = React.useState(null);

    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm({defaultValues:{quantity:1}});
    console.log(errors)
    useEffect(()=>{
        console.log(nameuri)
        if(nameuri != "edit-bottle"){
            api.getProductById(nameuri).then((data) => {
                setProduct(data.data);
                let deliv=[]
                for(let d of data.data.Delivery_modes){
                    let delivModel=d?.Delivery_mode_model?.id
                    if(d?.Delivery_mode_model==null){
                        delivModel="-1"
                    }
                    deliv.push({
                        delivery_mode_model_id: delivModel,
                        price: d?.price,
                        name: d?.name,
                    })
                }
                setLivraisonMode(deliv)
                //console.log(data.data.Delivery_modes)
                //setLivraisonMode(data.data.Delivery_modes)
                let color =""
                if(data.data.color_id){
                    color=data.data.color_id
                }
                setValue('title', data.data.name, { shouldValidate: true })
                setValue('description', data.data.description, { shouldValidate: true })
                setValue('color', color, { shouldValidate: true })
                setValue('year', year, { shouldValidate: true })
                setValue('viticulture', data.data.viticulture, { shouldValidate: true })
                setValue('quantity', data.data.quantity, { shouldValidate: true })
                setValue('typeCave', data.data.typeCave, { shouldValidate: true })
                setValue('pourcentage', data.data.pourcentage, { shouldValidate: true })
                setValue('cru', data.data.cru, { shouldValidate: true })
                setValue('price', data.data.price, { shouldValidate: true })

                if(data.data.country_id==null){
                    setCountry("")
                }else{
                    setCountry(data?.data?.country_id)
                }
                if(data.data.region_id==null){
                    setRegion("")
                }else{
                    setRegion(data?.data?.region_id)
                }
                if(data.data.appelation_id==null){
                    setAppelation("")
                }else{
                    setAppelation(data?.data?.appelation_id)
                }
                setCoffret(data?.data.coffret)
                setCRD(data?.data.CRD)
                if (data.data.Product_images.length > 0) {
                    let arrayPicture = [];
                    for (const item of data.data.Product_images) {
                        let f= createSyntheticFile(
                            item.position+".png",
                            290000000,
                            "image/png"
                        );
                        const validateFileFromWebUrl = makeSynthticFileValidate(
                            f,
                            true,
                        );
                        validateFileFromWebUrl.imageUrl=UrlBaseServer+item.image
                        arrayPicture.push(validateFileFromWebUrl)
                        //arrayPicture.push({
                        //    uri: null,
                        //    base64: null,
                        //    url: item.image,
                        //    id: item.id,
                        //});
                        //setPictureProduct(arrayPicture);
                    }
                    setFiles(arrayPicture)
                }
            });
        }
    },[nameuri])

    const clearValue=()=>{
        setCoffret(false)
        setCRD(false)
        setYear("");
        setRegion("");
        setAppelation("");
        setCountry(59);
        setCustomError(null)
        setFiles([])
        setLivraisonMode([{
            delivery_mode_model_id: "",
            price: "",
            name: "",
        }])
        setValue('title', "", { shouldValidate: true })
        setValue('description', "", { shouldValidate: true })
        setValue('color', "", { shouldValidate: true })
        setValue('year', "", { shouldValidate: true })
        setValue('viticulture', "", { shouldValidate: true })
        setValue('quantity', "", { shouldValidate: true })
        setValue('typeCave', "", { shouldValidate: true })
        setValue('pourcentage', "", { shouldValidate: true })
        setValue('cru', "", { shouldValidate: true })
        setValue('price', "", { shouldValidate: true })
        setShowModal(false)
    }

    const getBase64 = async (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise((reslove, reject) => {
            reader.onload = () => reslove(reader.result);
            reader.onerror = (error) => reject(error);
        })
    }
    const onSubmit = async (data, e) => {
        e.preventDefault();
        console.log("dans le submit")
        try {
            // ajout de la bouteille
            if(nameuri != "edit-bottle"){
                let newImages=[]
                for(let f of files){
                    console.log(f)
                    if(f.imageUrl){
                        newImages.push({url:f.imageUrl.replace(UrlBaseServer, ''),b64:null})
                    }else{
                      let b64=await getBase64(f.file,(result) => {
                        return result
                        })
                        console.log(b64)

                        newImages.push({url:null,b64:b64})
                    }

                }
                let newData=data;
                for(let z of Object.keys(data)){
                    console.log(z)
                    if(data[z]==""){
                        newData[z]=null
                    }
                }
                if(newData.quantity==null){
                    newData.quantity=1;
                }

                let localCountry=null
                let localRegion=null
                let localAppelation=null
                if(country!=""){
                    localCountry=country;
                }
                if(region!=""){
                    localRegion=region
                }
                if(appelation!=""){
                    localAppelation=appelation
                }
                console.log(nameuri)
                api.putUpdateProduct(nameuri,newData.title,localCountry,localRegion,localAppelation,newData.color,newData.year,newData.description,newData.price,livraisonMode,newImages,""+newData.quantity,newData.viticulture,newData.typeCave,coffret,newData.pourcentage,newData.cru,CRD).then(data=>{
                    console.log("modification reussi")
                    setShowModal(true)
                }).catch(error=>{
                    console.log("modification echec")
                })
            }else{
                setCustomError(null)
                let newImages=[]
                for(let f of files){
                    if(f.imageUrl){
                        newImages.push(null)
                    }else{
                        let b64=await getBase64(f.file,(result) => {
                            return result
                        })
                        newImages.push(b64)
                    }
                }
                let err=null
                if(newImages.length==0){
                    err="Veuillez ajouter une image"
                    setCustomError(err)
                }
                // delivery_mode_model_id: "",
                //         price: "",
                //         name: "",
                if(livraisonMode.length==0||livraisonMode[0].price=="" && (livraisonMode[0].delivery_mode_model_id == "" || livraisonMode[0].name=="") ){
                    err="Veuillez ajouter un moyen de livraison"
                    setCustomError(err)
                }
                let newData=data;
                if(newData.quantity==null){
                    newData.quantity=1;
                }
                for(let z of Object.keys(data)){
                    if(data[z]==""){
                        newData[z]=null
                    }
                }
                let localCountry=null
                let localRegion=null
                let localAppelation=null
                if(country!=""){
                    localCountry=country;
                }
                if(region!=""){
                    localRegion=region
                }
                if(appelation!=""){
                    localAppelation=appelation
                }
                if(err==null){
                    api.postCreateProduct(newData.title,localCountry,localRegion,localAppelation,newData.color,newData.year,newData.description,newData.price,livraisonMode,newImages,""+newData.quantity,newData.viticulture,newData.typeCave,coffret,newData.pourcentage,newData.cru,CRD).then(result=>{
                        setProduct(result.data)
                        if(priceChoosed.index=="-1"){
                            setShowModal(true)
                        }else{
                            setShowModalBoost(true)
                        }

                    }).catch(error=>{

                    })
                }
            }
            //Si gratuit afficher la modal
            //Si boost afficher la fenetre paiement boost
            console.log(data)
        } catch (error) {
            console.log(error)
            //setErr(error.response.data.error)
        }
        e.preventDefault();
    }


    return (
    <div>
      <Navbar />
      <div className="content-wraper">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <ModifyProd customError={customError} files={files} setFiles={setFiles} register={register} />
                <EditBottleContent register={register} coffret={coffret} setCoffret={setCoffret} CRD={CRD} setCRD={setCRD} setLivraisonMode={setLivraisonMode} livraisonMode={livraisonMode} year={year} setYear={setYear} region={region} setRegion={setRegion} appelation={appelation} setAppelation={setAppelation} country={country} setCountry={setCountry} />
              {nameuri == "edit-bottle"&&(<BottomSec priceChoosed={priceChoosed} setPriceChoosed={setPriceChoosed} register={register} />) }
                <Buttons showModalBoost={showModalBoost} priceChoosed={priceChoosed} setShowModalBoost={setShowModalBoost} showModal={showModal} setShowModal={setShowModal} product={product} update={nameuri != "edit-bottle"} register={register} />
          </form>
      </div>
      <Footer />
    </div>
  );
};

export default EditBottle;
