import React from "react";
import { Navbar, Footer } from "./../../Components";
import OpinionContainer from "./../../Containers/Opinion/OpinionContainer";
function Opinion() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
        <OpinionContainer />
      </div>
      <Footer />
    </div>
  );
}

export default Opinion;
