import React from "react";
import { Navbar, Footer } from "./../../Components";
import Banner from "./../../Containers/Home/Banner";
import ForMembers from "./../../Containers/Home/ForMembers";
import ForSearch from "./../../Containers/Home/ForSearch";
import ReviewSection from "./../../Containers/Home/ReviewSection";
function Home() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
        <Banner />
        <ForMembers />
        <ForSearch />
        {/*<ReviewSection />*/}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
