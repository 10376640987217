import React,{useState,useEffect} from "react";

import "./style.css";
import "moment/locale/fr";


function Securite() {
    return (
        <div className="container">
            <div className="whiteContainer">
                <div>
                <h1>POLITIQUE DE SECURITE VIGNITUS</h1>
                <div style={{whiteSpace:"pre-line"}}>

                    <span className="color">VIGNITUS</span> met un point d’honneur à assurer la sécurité personnelle de
                    ses membres. Nous œuvrons de manière proactive pour créer des outils et des politiques qui nous
                    aident à faire prospérer notre communauté, que tu sois un vendeur ou un acheteur. Retrouve
                    ci-dessous ce que tu dois savoir à propos de nos principales procédures de sécurité.
                    <h2>Protection acheteurs</h2>
                    Lorsque tu paies sur VIGNITUS, la Protection Acheteurs s’applique obligatoirement à ton achat. Cette
                    protection te permet d’avoir accès à notre politique de remboursement, à un paiement sécurisé ainsi
                    qu'à une assistance de notre service client pour ton achat, si besoin. Les frais de Protection
                    Acheteurs correspondent à un montant de 1€, plus 8 % du prix de ton article.

                    <h2>Remboursements</h2>
                    Nous te remboursons si ton article n’a pas été livré, est arrivé endommagé ou n’est pas tel que
                    décrit. Tu as 2 jours pour faire une réclamation. Sans accord commun, les frais de retour sont à la
                    charge de l’acheteur.

                    <h2>Paiements sécurisés</h2>
                    Tous les paiements effectués sur VIGNITUS sont cryptés. Ils sont gérés par Mangopay, notre
                    partenaire de confiance. Leur technologie sécurisée nous permet de nous assurer que le montant réglé
                    pour une transaction est bien envoyé et reçu par le ou la vendeur·se.

                    <h2>Outils anti-spam</h2>
                    Nous mettons en place des outils qui détectent des contenus inappropriés. Comme les filtres
                    anti-spam dans ta boîte e-mail, ces systèmes permettent de bloquer, automatiquement, des contenus
                    malveillants envoyés par message.

                    <h2>Vie privée</h2>
                    Nous sommes soucieux de respecter ta vie privée. Pour des informations détaillées sur la manière
                    dont nous gérons tes données personnelles et sur tes droits à cet égard, nous t’invitons à consulter
                    notre POLITIQUE DE CONFIDENTIALITE

                    <h2>Évaluation des risques</h2>
                    En coulisse, nous avons recours à l’analyse prédictive pour tenter de détecter des activités, des
                    annonces, ou des commandes suspectes, comme les produits illégaux ou dangereux. Ainsi, notre équipe
                    s’efforce de répondre présente très tôt avec des outils efficaces.

                    <h2>Communiquer</h2>
                    Le système de messagerie sécurisé de VIGNITUS te permet d’en savoir plus sur l’article que tu
                    souhaites acheter. Tu peux envoyer un message privé à tout membre de VIGNITUS. N’oublie pas qu’il
                    est important de rester poli et attentif durant les échanges avec un vendeur ou acheteur pour qu’un
                    climat de confiance soit instauré.

                    <h2>Évaluer</h2>
                    Les membres de VIGNITUS peuvent laisser une évaluation à un vendeur ou acheteur après avoir effectué
                    une transaction. Ainsi, les évaluations laissées sur le profil d’autres membres sont basées sur des
                    transactions vérifiées. N’oublie pas de laisser une évaluation après avoir reçu une commande afin
                    d’en recevoir une en retour.

                    <h2>Vérification de ton compte</h2>
                    Prends le temps de faire vérifier ton compte sur VIGNITUS afin de rassurer instantanément les autres
                    membres. Si tu souhaites savoir si un autre membre a procédé à cette vérification, consulte
                    simplement son profil.

                    En complétant les informations de ton profil. Cela permet de mieux se connaître entre membres, en un
                    coup d’œil. En étant proactif, tu rassures tes potentiels acheteurs et tu donnes un coup de boost à
                    tes ventes


                </div>
                </div>
            </div>
        </div>)
}
export default Securite;
