import {Navigate} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useAuth} from '../Services/auth'
import React from "react";
import firebase from "../Services/firebase";

const Public = ({children}) => {
    const {isLoggingIn,user,login, error} = useAuth()
    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if(user){
                login(user)
            }
            //firebaseLogin(user)
        })
    }, [])
    return <>{children}</>
}

Public.propTypes = {
    children: PropTypes.node,
}

export default Public