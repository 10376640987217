import React from "react";
import { Footer } from "../../Components";
import Navbar from "../../Components/Navbar/index";
import Message from "../../Containers/Chat/Chat";

const FeedBack = () => {
  return (
    <>
      <Navbar />
      <div className="content-wraper">
        <Message />
      </div>
      <Footer />
    </>
  );
};

export default FeedBack;
