import React from "react";
import { ProductCard,VitrineCard } from "./../../../Components";
import Red from "../../../Assets/red.png";
import Skin from "../../../Assets/skin.png";
import Pink from "../../../Assets/pink.png";
import "./formember.css";
import {useInfiniteQuery, useQuery, useQueryClient} from "react-query";
import api from "../../../Services/api";

function ForMembers() {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [color, setColor]= React.useState(null)
  const [year, setYear]= React.useState(null)
  const [country, setCountry]= React.useState(null)
  const [region, setRegion]= React.useState(null)

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery(
      ['products',color, year, country, region],
      async ({ pageParam = 0 }) => {
        const res =  await api.getProducts(pageParam,rowsPerPage, null,color, year,null,null,country,region,null)
        return res.data
      },
      {
        getPreviousPageParam: firstPage =>firstPage.previousId ?? undefined,
        getNextPageParam: lastPage => {
          let nextp=undefined
          if(lastPage.currentPage+1 < lastPage.totalPages){
            nextp= lastPage.currentPage+1
          }
          return nextp
        }
      }
  )
    const {
        data:dataVitrine,
         } = useQuery(['repoVitrine'], ()=> api
        .getArrayVitrine(),{ keepPreviousData : true })
    console.log(dataVitrine)

return (
    <div className="container">
      <div>
        <h1 className="sectionTitle">Suggestion pour votre recherche</h1>
      </div>

      <div className="container-fluid extratxtBox">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="mt-2 mb-3">
              <p className="suggestion-info-title">Par couleur</p>
              <div className="color-theme">
                <img className={[color==1 ? "selected":null]} onClick={()=>{
                    if(color==1){
                        setColor(null)
                    }else{
                        setColor(1)
                    }
                }} src={Red} alt="" />
                <img className={[color==2 ? "selected":null]} onClick={()=>{
                    if(color==2){
                        setColor(null)
                    }else{
                        setColor(2)
                    }
                }} src={Skin} alt="" />
                <img className={[color==3 ? "selected":null]} onClick={()=>{
                    if(color==3){
                        setColor(null)
                    }else{
                        setColor(3)
                    }
                }} src={Pink} alt="" />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="mt-2">
              <p className="suggestion-info-title">Par Millesime</p>
              <p className="color-theme">
                <span className={[year==2000 ? "selected":null]}  onClick={()=>{
                    if(year==2000){
                        setYear(null)
                    }else{
                        setYear(2000)
                    }
                }}>2000</span>
                <span className={[year==2004 ? "selected":null]} onClick={()=>{
                    setYear(2004)
                    if(year==2004){
                        setYear(null)
                    }else{
                        setYear(2004)
                    }
                }}>2004</span>
                <span className={[year==2005 ? "selected":null]} onClick={()=>{
                    if(year==2005){
                        setYear(null)
                    }else{
                        setYear(2005)
                    }
                }}>2005</span>
                <span className={[year==2008 ? "selected":null]} onClick={()=>{
                    if(year==2008){
                        setYear(null)
                    }else{
                        setYear(2008)
                    }
                }}>2008</span>
              </p>
            </div>
            <p className="color-theme">
              <span className={[year==2010 ? "selected":null]} onClick={()=>{
                  if(year==2010){
                      setYear(null)
                  }else{
                      setYear(2010)
                  }
              }}>2010</span>
              <span className={[year==2014 ? "selected":null]} onClick={()=>{
                  if(year==2014){
                      setYear(null)
                  }else{
                      setYear(2014)
                  }
              }}>2014</span>
              <span className={[year==2015 ? "selected":null]} onClick={()=>{
                  if(year==2015){
                      setYear(null)
                  }else{
                      setYear(2015)
                  }
              }}>2015</span>
              <span className={[year==2018 ? "selected":null]} onClick={()=>{
                  if(year==2018){
                      setYear(null)
                  }else{
                      setYear(2018)
                  }
              }}>2018</span>
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="mt-2 mb-3">
              <p className="suggestion-info-title">Par pays</p>
              <p className="color-theme">
                <span className={[country==59 ? "selected":null]} onClick={()=>{
                    if(country==59){
                        setCountry(null)
                    }else{
                        setCountry(59)
                    }
                }}>France</span>
                <span className={[country==84 ? "selected":null]} onClick={()=>{
                    if(country==84){
                        setCountry(null)
                    }else{
                        setCountry(84)
                    }
                }}>Italie</span>
              </p>
              <p className="color-theme">
                <span className={[country==53 ? "selected":null]} onClick={()=>{
                    if(country==53){
                        setCountry(null)
                    }else{
                        setCountry(53)
                    }
                }}>Espagne</span>
                <span className={[country==5 ? "selected":null]} onClick={()=>{
                    if(country==5){
                        setCountry(null)
                    }else{
                        setCountry(5)
                    }
                }}>Allemagne</span>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="mt-2 mb-3">
              <p className="suggestion-info-title">Par region</p>
              <p className="color-theme">
                <span className={[region==3 ? "selected":null]} onClick={()=>{
                    if(region==3){
                        setRegion(null)
                    }else{
                        setRegion(3)
                    }
                }}>Bordeaux</span>
                <span className={[region==4 ? "selected":null]} onClick={()=>{
                    if(region==4){
                        setRegion(null)
                    }else{
                        setRegion(4)
                    }
                }}>Bourgogne</span>
                <span className={[region==5 ? "selected":null]} onClick={()=>{
                    if(region==5){
                        setRegion(null)
                    }else{
                        setRegion(5)
                    }
                }}>Rhone</span>
              </p>
              <p className="color-theme">
                <span className={[region==10 ? "selected":null]} onClick={()=>{
                    if(region==10){
                        setRegion(null)
                    }else{
                        setRegion(10)
                    }
                }}>Champagne</span>
                <span className={[region==6 ? "selected":null]} onClick={()=>{
                    if(region==6){
                        setRegion(null)
                    }else{
                        setRegion(6)
                    }
                }}>Languedoc</span>
                <span className={[region==12 ? "selected":null]} onClick={()=>{
                    if(region==12){
                        setRegion(null)
                    }else{
                        setRegion(12)
                    }
                }}>Jura</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="cardParent mt-4 mb-4">
          <div className="row">
              {data?.pages[0]?.totalItems>0?(
                  <>
                    {data?.pages.map(page => (
                        <React.Fragment key={page.currentPage}>
                          {page?.data?.map((v, i) => {
                            return (
                                <>
                                {i!=0 && i%8==0 ?(
                                    <>
                                        {dataVitrine?.data?.length>0?(
                                        <div className="col-sm-12 col-md-12 col-lg-12 prodCard" key={"vitrine"+i} >
                                            <VitrineCard item={dataVitrine?.data[Math.floor(Math.random()*dataVitrine?.data?.length)]}/>
                                        </div>):null}
                                        <div key={"search"+v.id} className="col-sm-8 col-md-5 col-lg-3 prodCard">
                                        <ProductCard item={v} />
                                    </div>

                                    </>
                                ):(<div key={"search"+v.id} className="col-sm-8 col-md-5 col-lg-3 prodCard">
                                    <ProductCard item={v} />
                                </div>) }
                                </>
                            );
                          })}
                        </React.Fragment>
                    ))}
                  </> ):(
                  <div style={{margin:"20px auto"}}>
                      Pas de résultat
                  </div>
              )}
          </div>
          <div
              style={{
                margin:"0 auto",
                border:"1px solid",
                cursor:"pointer",
                marginTop: 10,
                width:250,
                //borderWidth: 1,
                alignSelf: "center",
                borderRadius: 8,
                paddingHorizontal: 24,
                paddingVertical: 8,
                borderColor: "#7E7D7D",
                marginBottom: 40,
                display:"flex",
                justifyContent:"center",
              }}
              onClick={()=> {
                console.log('la')
                fetchNextPage()
              }}
          >
            <div
                style={{
                  fontSize: 16,
                  color: "#7E7D7D",
                  fontWeight: "bold",
                  display:"flex",
                  justifyContent:"center"
                }}
            >
              {isFetchingNextPage
                  ? 'Chargement...'
                  : hasNextPage
                      ? '+ Voir plus'
                      : 'Vous avez tous chargé'}

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ForMembers;
