import animationValidate from "../../Assets/animations/validation.json";
import React, { useEffect, useState,useRef } from "react";
import {Modal} from "react-bootstrap";
import "./style.css";

export default function LotChooseModal({setShowLotModal,showLotModal, price, doOffer, myOffer,skip}) {
    const [modeOffre, setModeOffre] = useState(0);
    const [valueOffre, setValueOffre] = useState(null);
    useEffect(()=>{
        if(skip){
            setModeOffre(1);
        }
    })

    return(
        <Modal show={showLotModal} onHide={()=>{setShowLotModal(false)}} animation={false}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center", minHeight:230}} className="lotModal modal-dialog-centered" role="document">
                {modeOffre==0?(
                <div style={{display:"flex", flexDirection:"column"}}>
                    <div onClick={()=>{
                        setShowLotModal(false);
                        doOffer();
                    }} className="btn btn-outline-danger purple-btn">{"Payer " + price}</div>
                    <div  onClick={()=>{
                        setModeOffre(1);
                    }} className="btn btn-outline-danger purple-btn">{"Faire une offre"}</div>
                </div>):(

                    <div style={{ display:"flex", flexDirection:"column", marginHorizontal: 30 }}>
                        <h5
                            style={{
                                color: "#6b0019",
                                fontWeight: "300",
                                textAlign: "center",
                                marginTop:30
                            }}
                        >
                            Faire une offre !
                        </h5>
                        <div
                            style={{
                                display:"flex",
                                justifyContent: "center",
                                alignSelf: "center",
                                alignItems:"center",
                                marginTop: 12,
                            }}
                        >
                            <input type="number" value={valueOffre} onChange={(event)=>{setValueOffre(event.target.value)}} />
                            {valueOffre ? (
                                <div
                                    style={{
                                        color: "#6b0019",
                                        fontWeight: "700",
                                    }}
                                >
                                    €
                                </div>
                            ) : null}
                        </div>
                        <div
                            style={{
                                color: "grey",
                                fontWeight: "300",
                                marginTop: 12,
                                width: 500,
                                textAlign: "center",
                            }}
                        >
                            Un supplément pour l’envoie ainsi que pour les frais de
                            gestions seront appliqué à ce prix lors du paiement
                        </div>


                        <div style={{
                            marginTop: 20,
                            marginBottom: 20,
                            paddingHorizontal: 30,
                            alignSelf: "center",
                        }}  onClick={()=>{
                            if (!valueOffre?.trim()) {
                                return alert("Vous devez indiquer une offre");
                            }else{
                                myOffer(valueOffre);
                            }

                        }} className="btn btn-outline-danger purple-btn">{"Envoyer mon offre"}</div>
                    </div>

                )}

            </div>
        </Modal>
    )
}