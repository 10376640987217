import React, { useState,useEffect } from "react";
import "./style.css";
import WineBottle from "../../Assets/wineBottle.jpg";
import {UrlBaseServer} from "../../Services/storageServer";
import {useAuth} from "../../Services/auth";
import api from '../../Services/api'
import {useNavigate} from "react-router-dom";

const SmCards = ({product}) => {
    const navigate = useNavigate();
    const {user} = useAuth()
    const [liked, setLiked] = useState(false);

    useEffect(()=>{
        if(user){
            api.wasWish(product.id).then((data) => {
                setLiked(data.data.wish);
            });
        }
    },[])

  return (
    <section className="smalCardBox">
      <div onClick={()=>{
          navigate("/product-detail/"+product?.id,{
              state: { product: product },
          })
      }} style={{cursor:"pointer"}} className="cardImg">
        <img src={UrlBaseServer+product?.Product_images[0]?.image} alt="" />
          {user ?(
              <i
                  className={`fa-solid fa-heart fa-sm heart-icon ${
                      liked ? "heart-liked" : "heart"
                  }`}
                  onClick={(e) => {
                      e.stopPropagation()
                      if (liked) {
                          api.unWish(product.id).then(() => {
                              setLiked(false);
                          });
                      } else {
                          api.addWish(product.id).then(() => {
                              setLiked(true);
                          });
                      }
                  }}
              ></i>
          ):null}

      </div>
      <div className="cardContent">
        <h5 style={{cursor:"pointer"}}>{product.name}</h5>
        <p>{product?.year} {product?.Region?.name} {product?.Appelation?.name}</p>
        <h3>{product.price} &euro;</h3>
        {/* <p>Vendu par John Doe</p> */}
        {/* <p>
          <i className="fa-solid fa-star"></i> 4.84 &#123;85&#125;
        </p> */}
      </div>
    </section>
  );
};

export default SmCards;
