import React, {useEffect, useState} from "react";
import { FollowersCard, SubscriberCard } from "./../../../Components";
import "./subscriptionContainer.css";
import {useLocation} from "react-router-dom";
import api from "../../../Services/api";
import {useQuery,useMutation,useQueryClient} from 'react-query'

function Opinion() {
  const params = useLocation();
  let [userD, setUserD]=useState(null)
  //api.getFollow(userData.id).then((data) => {
    //setArrFollow(data.data);
  //});
 // api.getFollowed(userData.id).then((data) => {
    //setArrFollowed(data.data);
  //});
    const nameuri =params.pathname.split("/").pop()
    useEffect(async ()=>{
      if (params.state?.user && params.state) {
        setUserD(params.state?.user)
      }else{
        var result = await api.getUserById(nameuri);
        if(result){
          setUserD(result.data)
        }
      }
    },[])

  const queryClient = useQueryClient()

  const { isLoading:isLoadingFollow,
    isError:isErrorFollow,
    error:errorFollow,
    data:dataFollow,
    isFetching:isFetchingFollow,
    isPreviousData:isPreviousDataFollow, } = useQuery(['follow',userD?.id], ()=> api.getFollow(userD.id),{ keepPreviousData : true }
  )
  const { isLoading:isLoadingFollowed,
    isError:isErrorFollowed,
    error:errorFollowed,
    data:dataFollowed,
    isFetching:isFetchingFollowed,
    isPreviousData:isPreviousDataFollowed, } = useQuery(['getFollowed',userD?.id], ()=> api.getFollowed(userD.id),{ keepPreviousData : true }
  )
  console.log(dataFollow)
  console.log(dataFollowed)


  return (
    <div className="container pt-5 subscription-container">
      <div className="subscription-card d-flex flex-md-row flex-column  flex-nowrap">
        <div className="col-12  col-md-6 col-lg-6">
          <h1 className="title">Abonnements de {userD?.firstName} {userD?.lastName}</h1>
          <p className="description">{dataFollowed?.data?.length} abonnements</p>
          <div className="subscription-container-box">
            {dataFollowed?.data?.length ?(
            <>
            {dataFollowed?.data?.map((v,i)=>{
              return(<SubscriberCard key={"followed"+i} data={v} />)
            })}
            </>
            ):(
                <div style={{textAlign:"center"}}>
                  Aucun abonnement
                </div>
            )}
          </div>
        </div>
        <div className="col-12  col-md-6 col-lg-6">
          <h1 className="title text-md-right">Abonnés de {userD?.firstName} {userD?.lastName}</h1>
          <p className="description text-md-right">{dataFollow?.data?.length} abonnés</p>
          <div className="subscription-container-box">
            {dataFollow?.data?.length ?(
                <>
            {dataFollow?.data?.map((v,i)=>{
              return(<FollowersCard key={"follow"+i} data={v} />)
            })}
            </>
            ):(
                <div style={{textAlign:"center"}}>
                  Aucun abonnement
                </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default Opinion;
