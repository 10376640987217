import React,{useEffect,useState} from "react";
import "./style.css";
import {UrlBaseServer} from "../../Services/storageServer";
import {formatPrice} from "../../Services/tools";

export default function OrderInfoModal({data,setSelectedData, purchase}) {
    const [timer, setTimer] = useState(null);

    function getUser() {
        let name = "";
        if (purchase) {
            if (data?.OfferProduct[0]?.User?.firstName) {
                name = capitalize(data?.OfferProduct[0]?.User?.firstName);
            } else {
                name = "";
            }
        } else {
            if (data?.User?.firstName) {
                name = capitalize(data?.User?.firstName);
            } else {
                name = "";
            }
        }
        return name;
    }
    function getAvatar() {
        let avatar = null;
        if (purchase) {
            if (data?.OfferProduct[0]?.User?.avatar) {
                avatar = data?.OfferProduct[0]?.User?.avatar;
            }
        } else {
            if (data?.User?.avatar) {
                avatar = data?.User?.avatar;
            }
        }

        if (avatar) return UrlBaseServer + avatar;
    }

    function getUserFull() {
        let name = "";
        if (purchase) {
            if (data?.OfferProduct[0]?.User?.firstName) {
                name = capitalize(
                    data?.OfferProduct[0]?.User?.firstName +
                    " " +
                    data?.OfferProduct[0]?.User?.lastName
                );
            } else {
                name = "";
            }
        } else {
            if (data?.User?.firstName) {
                name = capitalize(data?.User?.firstName + " " + data?.User?.lastName);
            } else {
                name = "";
            }
        }
        return name;
    }

    function compareDate() {
        let parseDate = data.updatedAt.replace(" ", "T");
        let dateProduct = new Date(parseDate);
        let dateActual = new Date();

        const diffTime = Math.abs(dateActual - dateProduct);
        //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let time = "";
        if (diffTime < 60000) {
            //secondes
            time = Math.ceil(diffTime / 1000) + "s";
        } else if (diffTime < 3600000) {
            //minutes
            time = Math.ceil(diffTime / 60000) + " min";
        } else if (diffTime < 86400000) {
            //heures
            time = Math.ceil(diffTime / 3600000) + " h";
        } else if (diffTime < 604800000) {
            //jours
            time = Math.ceil(diffTime / 86400000) + " jour(s)";
        } else if (diffTime < 2629800000) {
            //semaines
            time = Math.ceil(diffTime / 604800000) + " semaine(s)";
        } else if (diffTime < 31557600000) {
            //mois
            time = Math.ceil(diffTime / 2629800000) + " mois";
        } else {
            time = Math.ceil(diffTime / 31557600000) + " année(s)";
        }
        setTimer(time);
    }
    function capitalize(s) {
        return s[0].toUpperCase() + s.slice(1);
    }
    function returnStatus() {
        if (data.status == 1) {
            return {
                color: "orangered",
                text: purchase
                    ? "Commande en attente - vous devez régler la commande."
                    : "Commande en attente - l’acheteur doit réaliser le paiement.",
            };
        }
        if (data.status == 2) {
            return {
                color: "orangered",
                text: purchase
                    ? "Commande en attente - le vendeur doit valider votre commande."
                    : "Commande en attente - vous devez valider la commande.",
            };
        }
        if (data.status == 3) {
            return {
                color: "red",
                text: purchase
                    ? "Vous avez annulé la commande."
                    : "Commande annulé par l'acheteur.",
            };
        }
        if (data.status == 4) {
            return {
                color: "red",
                text: purchase
                    ? "Commande refusé par le vendeur."
                    : "Vous avez refusé la commande.",
            };
        }
        if (data.status == 5) {
            return {
                color: "orangered",
                text: purchase
                    ? "Vous avez payer la commande, en attente de l'envoie."
                    : "L'acheteur a payé la commande, vous devez l'envoyer.",
            };
        }
        if (data.status == 6) {
            return {
                color: "orangered",
                text: purchase
                    ? "Le vendeur a envoyé la commande, en attente de réception de votre part."
                    : "Vous avez envoyé la commande, en attente de réception de l'acheteur.",
            };
        }
        if (data.status == 7) {
            return {
                color: "green",
                text: purchase
                    ? "Vous avez reçu la commande."
                    : "L'acheteur a indiqué avoir reçu la commande.",
            };
        }
        if (data.status == 10) {
            return {
                color: "red",
                text: purchase
                    ? "Un problème est survenue sur la commande."
                    : "Un problème est survenue sur la commande.",
            };
        }
        if (data.status == 11) {
            return {
                color: "green",
                text: purchase
                    ? "Le vendeur vous a fait le remboursement de la commande."
                    : "Vous avez fait un remboursement.",
            };
        }
    }

    function returnTotal() {
        return returnTotalArticle() + data.Delivery_mode.price + returnFrais();
    }

    function returnTotalArticle() {
        let amount;
        for (const item of data.OfferProduct) {
            amount = +item.price;
        }
        return amount;
    }

    function returnFrais() {
       let fee = 1 + (returnTotalArticle() + data.Delivery_mode.price) * 0.08;
        return fee;
    }

    function changeComa(value) {
        let newValue = value.replace(".", ",");
        return newValue;
    }
    const openInNewTab = (url) => {
        // 👇️ setting target to _blank with window.open
        window.open(url, '_blank', 'noopener,noreferrer');
    };

  return (
    <div className="modal" style={{
        display: data!=null ? 'block' : 'none'
    }}
         tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Information concernant {purchase ? "votre achat" : "votre vente"}</h5>
                    <button onClick={()=>{
                        setSelectedData(null)
                    }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div
                        style={{
                            marginTop: 12,
                            color: "black",
                            fontWeight: "bold",
                        }}
                    >
                        {purchase ? "Vendeur" : "Acheteur"}
                    </div>
                    <div
                        style={{
                            display:"flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginVertical: 8,
                        }}
                    >
                        <div
                        >
                            {getUserFull()}
                        </div>
                        <button onClick={()=>{
                            if (purchase) {
                                openInNewTab("/user-profile/"+data.OfferProduct[0].User.id)
                            } else {
                                openInNewTab("/user-profile/"+data.User.id)}
                            }
                        } className="btnMaroon">
                            Voir le profil
                        </button>
                    </div>
                    <div>
                        <div
                            style={{
                                marginTop: 8,
                                marginBottom: 4,
                                color: "black",
                                fontWeight: "bold",
                            }}
                        >
                            {purchase ? "Article acheté" : "Article vendu"}
                        </div>
                        {data.OfferProduct.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display:"flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginVertical: 8,
                                    }}
                                >
                                    <div
                                        style={{
                                            color: "black",
                                            flex: 1,
                                        }}
                                    >
                                        {item.name}
                                    </div>
                                    <button onClick={()=>{
                                        openInNewTab("/product-detail/"+data.id)
                                    }} className="btnMaroon">Voir le produit</button>
                                </div>
                            );
                        })}
                        <div
                            style={{
                                marginTop: 8,
                                marginBottom: 4,
                                color: "black",
                            }}
                        >
                            {"Total article - " +
                            changeComa(returnTotalArticle().toString()) +
                            " €"}
                        </div>
                        <div>
                            {data.Delivery_mode && (
                                <>
                                    <div
                                        style={{
                                            marginTop: 8,
                                            marginBottom: 4,
                                            color: "black",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Détails de livraison
                                    </div>
                                    <div style={{display:"flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",}}>
                                    <div
                                        style={{
                                            color: "black",
                                            marginTop: 8,
                                            fontStyle: "italic",
                                        }}
                                    >
                                        Mode de livraison :
                                    </div>
                                    <div
                                        style={{
                                            color: "black",
                                            marginTop: 8,
                                            marginLeft:10,

                                        }}
                                    >
                                        {data.Delivery_mode.delivery_mode_model_id
                                            ? data.Delivery_mode.Delivery_mode_model.name
                                            : data.Delivery_mode.name}
                                    </div>
                                    </div>
                                    <div style={{display:"flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",}}>
                                    <div
                                        style={{
                                            color: "black",
                                            marginTop: 8,
                                            fontStyle: "italic",
                                        }}
                                    >
                                        Frais de livraison :
                                    </div>
                                    <div
                                        style={{
                                            color: "black",
                                            marginTop: 8,
                                            marginLeft: 10,
                                        }}
                                    >
                                        {changeComa(data.Delivery_mode.price.toString()) + " €"}
                                    </div>
                                    </div>
                                    {data.Delivery_mode.delivery_mode_model_id && (
                                        <>
                                            <div
                                                style={{
                                                    color: "black",
                                                    marginTop: 8,
                                                    marginBottom: 4,
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                Suivi de livraison :
                                            </div>
                                            <div
                                                style={{
                                                    color: "black",
                                                    marginBottom: 8,
                                                }}
                                                //onPress={openLink}
                                            >
                                                {data.Delivery_mode.Delivery_mode_model.url_follow +
                                                data.deliveryCode}
                                            </div>
                                        </>
                                    )}
                                    <div
                                        style={{
                                            color: "black",
                                            marginTop: 8,
                                            marginBottom: 4,
                                            fontStyle: "italic",
                                        }}
                                    >
                                        Adresse de livraison :
                                    </div>
                                    <div style={{ flex: 1, marginBottom: 4 }}>
                                        <div
                                            style={{
                                                color: "black",
                                            }}
                                        >
                                            {data.Address.number +
                                            " " +
                                            data.Address.address +
                                            " " +
                                            data.Address.additional_address}
                                        </div>
                                        <div
                                            style={{
                                                color: "black",
                                            }}
                                        >
                                            {data.Address.postal_code +
                                            " " +
                                            data.Address.city +
                                            " " +
                                            data.Address.country}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: 8,
                                            marginBottom: 4,
                                            color: "black",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Autre
                                    </div>
                                    <div
                                        style={{
                                            color: "black",
                                            marginTop: 8,
                                            marginBottom: 4,
                                            fontStyle: "italic",
                                        }}
                                    >
                                        Frais de protection :
                                    </div>
                                    <div
                                        style={{
                                            color: "black",
                                            marginBottom: 8,
                                        }}
                                    >
                                        {changeComa(formatPrice(returnFrais().toString())) +
                                        " €"}
                                    </div>

                                    <div
                                        style={{
                                            marginTop: 8,
                                            marginBottom: 4,
                                            color: "black",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {"Total commande - " +
                                        changeComa(formatPrice(returnTotal().toString())) +
                                        " €"}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-modal" data-dismiss="modal" onClick={()=>{
                        setSelectedData(null)
                    }}>Fermer</button>
                </div>
            </div>
        </div>
    </div>
  );
}
