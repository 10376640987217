import React from "react";
import "./style.css";

export default function CancelModal({user, data, otherUser}) {
  return (
    <div className="card_modal"
        style={{
            padding: 40
        }}
    >
        {data.Offer.user_id != user.id?(
      <p
        style={{
          background: "none",
          marginBottom: 4,
        }}
      >
        {otherUser?.firstName} {otherUser?.lastName} a annulé l' offre
      </p>
        ):(
            <p
                style={{
                    background: "none",
                    marginBottom: 4,
                }}
            >
                Vous avez  offre annulé l'offre
            </p>
        )}
      <h3
        style={{
          color: "#6a0013",
          fontSize: 14,
          textDecoration: 'line-through'
        }}
      >
          {data.Offer.price} € ttc
      </h3>
    </div>
  );
}
