import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from 'react-query/devtools';

export const queryClient = new QueryClient();

export function ReactQueryProvider({ children }) {
    return (
        <QueryClientProvider client={queryClient}>
            {/* <ReactQueryDevtools /> */}
            {children}
        </QueryClientProvider>
    );
}
