import React,{useEffect, useState} from "react";
import AvatarImage from "./../../Assets/defaultAvatar.png";
import "./horizontalCard.css";
import {useAuth} from "../../Services/auth";
import {UrlBaseServer} from "../../Services/storageServer";
import {useNavigate} from "react-router-dom";

function HorizontalCard({data, view}) {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(null);
    const [message, setMessage] = useState(null);
    const [status, setStatus] = useState(null);
    const {user} = useAuth();


    useEffect(() => {
        if (view == "messagerie") {
            formatData();
        } else {
            setMessage(data.contain);

            if (!data.isRead) {
                setStatus(2);
            } else {
                setStatus("wasRead");
            }
        }
        const interval = setInterval(() => {
            if (view == "messagerie") {
                compareDate();
            }
            if (view == "notification") {
                compareDateNotification();
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    function formatData() {
        let message;
        if (data?.messageReader?.length && data?.messageSender?.length) {
            let dateMessageReader = new Date(
                data.messageReader[0].updatedAt.replace(" ", "T")
            );
            let dateMessageSender = new Date(
                data.messageSender[0].updatedAt.replace(" ", "T")
            );
            if (dateMessageReader > dateMessageSender) {
                if (data.messageReader[0].offer_id) {
                    message = generateMessageByStatus(data.messageReader[0].offerStatus);
                } else {
                    if (
                        data.messageReader[0].contain.includes("[IMG]") &&
                        data.messageReader[0].contain.includes("[/IMG]")
                    ) {
                        message = "Une image";
                    } else {
                        message = data.messageReader[0].contain;
                    }
                }
                if (data.messageReader[0].isRead) {
                    setStatus("read");
                } else {
                    if (data.messageSender[0].reader_id == user.id) {
                        setStatus(2);
                    } else {
                        setStatus("send");
                    }
                }
            } else {
                if (data.messageSender[0].offer_id) {
                    message = generateMessageByStatus(data.messageSender[0].offerStatus);
                } else {
                    if (
                        data.messageSender[0].contain.includes("[IMG]") &&
                        data.messageSender[0].contain.includes("[/IMG]")
                    ) {
                        message = "Une image";
                    } else {
                        message = data.messageSender[0].contain;
                    }
                }
                if (data.messageSender[0].isRead) {
                    setStatus("read");
                } else {
                    if (data.messageSender[0]?.reader_id == user.id) {
                        setStatus(2);
                    } else {
                        setStatus("send");
                    }
                }
            }
        } else {
            if (data?.messageReader?.length) {
                if (data.messageReader[0].offer_id) {
                    message = generateMessageByStatus(data.messageReader[0].offerStatus);
                } else {
                    if (
                        data.messageReader[0].contain.includes("[IMG]") &&
                        data.messageReader[0].contain.includes("[/IMG]")
                    ) {
                        message = "Une image";
                    } else {
                        message = data.messageReader[0].contain;
                    }
                }
                if (data.messageReader[0].isRead) {
                    setStatus("read");
                } else {
                    if (data.messageReader[0]?.reader_id == user.id) {
                        setStatus(2);
                    } else {
                        setStatus("send");
                    }
                }
            }
            if (data?.messageSender?.length) {
                if (data.messageSender[0]?.offer_id) {
                    message = generateMessageByStatus(data.messageSender[0].offerStatus);
                } else {
                    if (
                        data.messageSender[0].contain.includes("[IMG]") &&
                        data.messageSender[0].contain.includes("[/IMG]")
                    ) {
                        message = "Une image";
                    } else {
                        message = data.messageSender[0].contain;
                    }
                }
                if (data.messageSender[0].isRead) {
                    setStatus("read");
                } else {
                    if (data.messageReader[0]?.reader_id == user.id) {
                        setStatus(2);
                    } else {
                        setStatus("send");
                    }
                }
            }
        }
        setMessage(message);
    }
    function generateMessageByStatus(status) {
        let msg = "";
        if (status == 1) {
            msg = "En attente du paiement";
        } else if (status == 2) {
            msg = "Attente d' acception de l’offre";
        } else if (status == 3) {
            msg = "Commande annulée";
        } else if (status == 4) {
            msg = "Commande refusée";
        } else if (status == 5) {
            msg = "Commande payée";
        } else if (status == 6) {
            msg = "Commande envoyée";
        } else if (status == 7) {
            msg = "Commande reçue";
        }
        return msg;
    }
    function compareDateNotification() {
        let parseDate = data.updatedAt.replace(" ", "T");
        let dateProduct = new Date(parseDate);
        let dateActual = new Date();

        const diffTime = Math.abs(dateActual - dateProduct);
        //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let time = "";
        if (diffTime < 60000) {
            //secondes
            time = Math.ceil(diffTime / 1000) + "s";
        } else if (diffTime < 3600000) {
            //minutes
            time = Math.ceil(diffTime / 60000) + " min";
        } else if (diffTime < 86400000) {
            //heures
            time = Math.ceil(diffTime / 3600000) + " h";
        } else if (diffTime < 604800000) {
            //jours
            time = Math.ceil(diffTime / 86400000) + " jour(s)";
        } else if (diffTime < 2629800000) {
            //semaines
            time = Math.ceil(diffTime / 604800000) + " semaine(s)";
        } else if (diffTime < 31557600000) {
            //mois
            time = Math.ceil(diffTime / 2629800000) + " mois";
        } else {
            time = Math.ceil(diffTime / 31557600000) + " année(s)";
        }
        setTimer(time);
    }
    function compareDate() {
        let moreRecently;
        if (data) {
            let dateMessageReader = new Date(
                data.createdAt.replace(" ", "T")
            );
            let dateMessageSender = new Date(
                data.createdAt.replace(" ", "T")
            );
            if (dateMessageReader < dateMessageSender) {
                moreRecently = dateMessageSender;
            } else {
                moreRecently = dateMessageReader;
            }
        } else {
            if (data) {
                moreRecently = new Date(
                    data.createdAt.replace(" ", "T")
                );
            }
            if (data) {
                moreRecently = new Date(
                    data.createdAt.replace(" ", "T")
                );
            }
        }
        let dateProduct = moreRecently;
        let dateActual = new Date();

        const diffTime = Math.abs(dateActual - dateProduct);
        //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let time = "";
        if (diffTime < 60000) {
            //secondes
            time = Math.ceil(diffTime / 1000) + "s";
        } else if (diffTime < 3600000) {
            //minutes
            time = Math.ceil(diffTime / 60000) + " min";
        } else if (diffTime < 86400000) {
            //heures
            time = Math.ceil(diffTime / 3600000) + " h";
        } else if (diffTime < 604800000) {
            //jours
            time = Math.ceil(diffTime / 86400000) + " jour(s)";
        } else if (diffTime < 2629800000) {
            //semaines
            time = Math.ceil(diffTime / 604800000) + " semaine(s)";
        } else if (diffTime < 31557600000) {
            //mois
            time = Math.ceil(diffTime / 2629800000) + " mois";
        } else {
            time = Math.ceil(diffTime / 31557600000) + " année(s)";
        }
        setTimer(time);
    }
    function dataAvatar() {
        if(view != "messagerie"){
            if (data.avatar) {
                return UrlBaseServer +data.userNotificationSender.avatar
            }else{
                return AvatarImage
            }
        }else{
            if (view == "messagerie" && data.product_id==null) {
                if(data.sender_id != user.id){
                    if(data.userSender.avatar) {
                        return UrlBaseServer +data.userSender.avatar
                    }else{
                        return AvatarImage
                    }
                }else{
                    if(data.userReader.avatar){
                        return  UrlBaseServer +data.userReader.avatar
                    }else{
                        return AvatarImage
                    }
                }
            }else{
                return UrlBaseServer + data.Product.Product_images[0].image

            }
        }
    }
    function redirection() {
        if (view == "messagerie") {
            let uID=null
            if(data.sender_id != user.id){
                uID=data.sender_id
            }else{
                uID=data.reader_id
            }
            if(data.Product){
                navigate("/chat/"+uID+"-"+data.Product.id,{
                    state: { userChat: data },
                })
            }else{
                navigate("/chat/"+uID,{
                    state: { userChat: data },
                })
            }
        } else if (view == "notification") {
            if (data?.send_id) {
                navigate("/user-profile/"+data.userNotificationSender.id,{
                    state: { user: data.userNotificationSender },
                })
            }
        }
    }

  return (
    <div style={{cursor:"pointer"}} onClick={()=>{
        redirection();
    }} className="horizontal-card d-flex align-items-center flex-md-row flex-column  flex-nowrap">
      <div className="col-12  col-md-2 col-lg-2">
        <img src={dataAvatar()} alt="" className="horizontal-card-image" />
      </div>
      <div className="col-12  col-md-8 col-lg-8">
        <div className="d-flex align-items-center m-0">
          <p className="horizontal-card-name">{view == "messagerie" && data.product_id==null && data.sender_id != user.id ?
              data?.userSender?.firstName + " " + data?.userSender?.lastName + " •"
              : view == "messagerie" && data.product_id==null && data.sender_id == user.id?
                  data?.userReader?.firstName + " " + data?.userReader?.lastName + " •"
                  : data.product_id!=null && data.sender_id != user.id  && view== "messagerie"? data.Product.name+" • \n"+data?.userSender?.firstName + " " + data?.userSender?.lastName
                      :data.product_id!=null && data.sender_id == user.id && view== "messagerie"? data.Product.name+" •\n"+data?.userReader?.firstName + " " + data?.userReader?.lastName

                          :  data.userNotificationSender.firstName +
                          " " +
                          data.userNotificationSender.lastName +
                          " •"}</p>{" "}
        </div>
        <p className="horizontal-card-time">
            {message}
        </p>
      </div>
      <div className="col-12  col-md-2 col-lg-2">
        <p className="horizontal-card-time">il y a {timer}</p>
      </div>
    </div>
  );
}

export default HorizontalCard;
