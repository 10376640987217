import React,{useState,useEffect} from "react";

import "./style.css";
import "moment/locale/fr";


function Cookies() {
    return (
        <div className="container">
            <div className="whiteContainer">
                <div>
                <h1>Politique relative aux cookies sur Vignitus</h1>
                <div style={{whiteSpace:"pre-line"}}>
                    <span className="color">CONDITIONS GÉNÉRALES D'UTILISATION</span> : version applicable à partir du
                    31 janvier 2022

                    Lorsque vous utilisez le site Web ou l’Application VIGNITUS (ci-après conjointement la « Plateforme
                    »), nous et nos partenaires pouvons stocker et/ou accéder à des informations sur votre appareil via
                    des cookies ou des technologies similaires pour traiter des données à caractère personnel. La
                    présente Politique relative aux cookies vise à expliquer ce que sont les cookies, comment ils sont
                    utilisés sur notre Plateforme et comment vous pouvez les gérer.
                    Pour des informations générales sur les pratiques de VIGNITUS en matière de données, veuillez
                    consulter notre Politique de Confidentialité. Les termes en majuscules utilisés dans la présente
                    Politique relative aux cookies ont la même signification que dans la Politique de confidentialité.
                    Que sont les cookies ?
                    Pourquoi utilisons nous des cookies ?
                    Comment gérer les cookies ?
                    4. Quels cookies utilisez-vous ?
                    5. Avez-vous recours à des partenaires pour déployer des publicités sur la Plateforme ?
                    6. Transférez-vous mes données à des partenaires publicitaires en dehors de l’EEE ?
                    7. Comment puis-je refuser les trackers de personnalisation des publicités ?
                    8. Utilisation de Google Remarketing
                    9. Utilisation de Google Analytics
                    10. Utilisation de Google Adsense

                    <h2>1. Que sont les cookies ?</h2>

                    Les cookies sont de petits fichiers texte enregistrés par votre navigateur sur votre appareil (ex. :
                    ordinateur, téléphone portable, tablette) lorsque vous visitez certains sites web. D’autres
                    technologies, y compris tout moyen de stocker des données sur votre navigateur web ou sur votre
                    appareil, des identifiants associés à votre appareil et d’autres logiciels, sont utilisées à des
                    fins similaires. Celles-ci sont largement utilisées pour faire fonctionner les sites web ou pour en
                    améliorer les fonctionnalités. Dans la présente Politique relative aux cookies, nous nous référerons
                    à l’ensemble de ces technologies à travers le terme « cookies ».
                    Sauf indication contraire aux présentes, les cookies que nous utilisons sont nécessaires pour
                    garantir le bon fonctionnement et les performances de la plateforme VIGNITUS (la « Plateforme »).
                    Cela inclut les cookies qui vous permettent d’accéder aux zones protégées de notre plateforme,
                    d’acheter un article ou d’utiliser notre système électronique de paiement. Certains des cookies sont
                    supprimés de votre appareil à la fin de votre session de navigation (cookies de session). Les
                    renseignements enregistrés dans les cookies nécessaires nous serviront uniquement à vous fournir les
                    services et les fonctionnalités demandés.

                    <h2>2. Pourquoi utilisons-nous les cookies ?</h2>

                    <span className="color">Nous utilisons des cookies pour :</span>
                    faire fonctionner notre plateforme conformément à vos attentes,
                    améliorer la vitesse et la sécurité de notre plateforme,
                    vous reconnaître lorsque vous visitez de nouveau notre plateforme. Cela nous permet de personnaliser
                    notre contenu, de vous saluer par votre nom et de mémoriser vos préférences (il s’agit alors de «
                    cookies de fonctionnalité »),
                    évaluer le nombre de visiteurs de notre plateforme et leurs habitudes de navigation sur le site.
                    Cela nous permet d’améliorer les fonctionnalités de notre plateforme, ex. : en nous assurant que les
                    utilisateurs peuvent trouver ce qu’ils cherchent (il s’agit alors de « cookies d’analyse/de
                    performances »).
                    identifier votre visite sur notre plateforme, les pages que vous visitez et les liens sur lesquels
                    vous cliquez. Nous utilisons ces informations pour mieux adapter notre Plateforme, et la publicité
                    que nous vous montrons, en fonction de vos intérêts. Si vous nous autorisez à vous envoyer des
                    e-mails de marketing, nous utiliserons également ces informations pour vous envoyer des e-mails de
                    marketing personnalisés (il s’agit des « cookies de ciblage »),
                    vous permettre de partager des pages avec vos réseaux sociaux, comme Facebook ou Instagram (il
                    s’agit des « cookies de partage et de suivi du contenu des plug-ins sociaux », relevant généralement
                    de la catégorie des cookies de ciblage).

                    <h2>3. Comment gérer les cookies ?</h2>

                    Lorsque vous consultez la Plateforme pour la première fois ou que vous la consultez après une
                    période prolongée, ou que vous naviguez sur notre site Web en mode privé (incognito), vous verrez un
                    avis relatif aux cookies en bas de la fenêtre, et vous pourrez soit accepter tous les cookies, soit
                    définir des préférences en matière de cookies, en choisissant les types de cookies que vous
                    souhaitez voir placés sur votre appareil. Vous pouvez également, à tout moment, modifier les
                    paramètres des cookies dans l’Application en cliquant sur Profil ▸ Paramètres des cookies ou, si
                    vous naviguez sur notre site Web, en cliquant sur Paramètres des cookies en bas de la page.
                    Vous pouvez configurer votre navigateur pour refuser certains ou tous les cookies ou pour vous
                    demander l’autorisation avant de les accepter. Veuillez noter que la suppression de ces cookies ou
                    leur désactivation pourra vous empêcher d’accéder à certaines zones ou fonctionnalités de notre site
                    web. Pour plus d’informations sur la manière d’ajuster ou de modifier les paramètres de votre
                    navigateur, veuillez visiter les sites www.aboutcookies.org ou www.allaboutcookies.org.
                    Vous pouvez désactiver l’utilisation de certains cookies tiers en vous rendant sur la page de
                    désactivation de la Network Advertising Initiative sur le site <a
                    href="http://www.networkadvertising.org/managing/opt_out.asp">http://www.networkadvertising.org/managing/opt_out.asp</a>.
                    Vous pouvez toujours activer/désactiver l’utilisation des cookies de tiers à des fins de
                    personnalisation des publicités. Veuillez consulter ce qui suit pour de plus amples informations.
                    Si vous souhaitez désactiver les cookies utilisés par des services Google Analytics, Google AdSense
                    ou Google Remarketing, veuillez consulter ce qui suit pour de plus amples informations.
                    Si vous accédez à la plateforme depuis différents appareils (ex. : smartphone, tablette, ordinateur,
                    etc.), vous devrez vous assurer que vos préférences de cookies sont bien configurées sur le
                    navigateur de chaque appareil. Si vous ajustez les paramètres de cookies, les autres sites web que
                    vous visitez pourront également être affectés.

                    <h2>4. Quels cookies utilisez-vous ?</h2>
                    <span className="color">Cookies strictement nécessaires</span>
                    Ces cookies sont nécessaires au fonctionnement du site Web et ne peuvent pas être désactivés dans
                    nos systèmes. Ils sont généralement établis en tant que réponse à des actions que vous avez
                    effectuées et qui constituent une demande de services, telles que la définition de vos préférences
                    en matière de confidentialité, la connexion ou le remplissage de formulaires. Vous pouvez configurer
                    votre navigateur afin de bloquer ou être informé de l'existence de ces cookies, mais certaines
                    parties du site Web peuvent être affectées. Ces cookies ne stockent aucune information
                    d’identification personnelle.

                    <span className="color">Cookies de performance</span>
                    Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de
                    mesurer et d’améliorer les performances de notre site Web. Ils nous aident également à identifier
                    les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site Web.
                    Toutes les informations collectées par ces cookies sont agrégées et donc anonymisées. Si vous
                    n'acceptez pas ces cookies, nous ne serons pas informé de votre visite sur notre site.

                    <span className="color">Cookies de fonctionnalité</span>
                    Ces cookies permettent d’améliorer et de personnaliser les fonctionnalités du site Web. Ils peuvent
                    être activés par nos équipes, ou par des tiers dont les services sont utilisés sur les pages de
                    notre site Web. Si vous n'acceptez pas ces cookies, une partie ou la totalité de ces services
                    risquent de ne pas fonctionner correctement.
                    Cookies pour une publicité ciblée
                    Ces cookies peuvent être mis en place au sein de notre site Web par nos partenaires publicitaires.
                    Ils peuvent être utilisés par ces sociétés pour établir un profil de vos intérêts et vous proposer
                    des publicités pertinentes sur d'autres sites Web. Ils ne stockent pas directement des données
                    personnelles, mais sont basés sur l'identification unique de votre navigateur et de votre appareil
                    Internet. Si vous n'autorisez pas ces cookies, votre publicité sera moins ciblée.
                    Stocker et/ou accéder à des informations sur un terminal
                    Les cookies, identifiants de votre terminal ou autres informations peuvent être stockés ou consultés
                    sur votre terminal pour les finalités qui vous sont présentées.
                    Publicités et contenu personnalisés, mesure de performance des publicités et du contenu, données
                    d’audience et développement de produit
                    Les publicités et le contenu peuvent être personnalisés sur la base d’un profil. Des données
                    supplémentaires peuvent être ajoutées pour mieux personnaliser les publicités et le contenu. La
                    performance des publicités et du contenu peut être mesurée. Des informations peuvent être générées
                    sur les publics qui ont vu les publicités et le contenu. Les données peuvent être utilisées pour
                    créer ou améliorer l’expérience utilisateur, les systèmes et les logiciels.
                    Sélectionner des publicités standard
                    Les publicités peuvent vous être présentées en fonction du contenu éditorial que vous consultez, de
                    l’application que vous utilisez, de votre localisation approximative, ou de votre type de terminal
                    Créer un profil personnalisé de publicités
                    Un profil peut être créé sur vous et sur vos centres d’intérêt pour vous présenter des publicités
                    personnalisées susceptibles de vous intéresser.
                    Sélectionner des publicités personnalisées
                    Des publicités personnalisées peuvent vous être présentées sur la base d’un profil créé sur vous.
                    Créer un profil pour afficher un contenu personnalisé
                    Un profil peut être créé sur vous et sur vos centres d’intérêt afin de vous présenter du contenu
                    personnalisé susceptible de vous intéresser.
                    Sélectionner du contenu personnalisé
                    Du contenu personnalisé peut vous être présenté sur la base de votre profil utilisateur.
                    Mesurer la performance des publicités
                    La performance et l’efficacité des publicités que vous voyez ou avec lesquelles vous interagissez
                    peuvent être mesurées.
                    Mesurer la performance du contenu
                    La performance et l’efficacité du contenu que vous voyez ou avec lequel vous interagissez peuvent
                    être mesurées.
                    Exploiter des études de marché afin de générer des données d’audience
                    Les études de marché peuvent servir à en apprendre davantage sur les audiences qui visitent des
                    sites/utilisent des applications et voient des publicités.
                    Développer et améliorer les produits
                    Vos données peuvent être utilisées pour améliorer les systèmes et logiciels existants et pour
                    développer de nouveaux produits.
                    Utiliser des données de géolocalisation précises
                    Vos données de géolocalisation précises peuvent être utilisées à l’appui d’une ou de plusieurs
                    finalités. Cela signifie que votre localisation peut être précise à plusieurs mètres près.
                    Analyser activement les caractéristiques du terminal pour l’identification
                    Votre terminal peut être identifié sur la base d’une analyse de la combinaison unique de
                    caractéristiques de votre terminal.
                    Assurer la sécurité, prévenir la fraude et déboguer
                    Vos données peuvent être utilisées pour surveiller et prévenir les activités frauduleuses, et
                    s’assurer que les systèmes et processus fonctionnent correctement et en toute sécurité.
                    Diffuser techniquement les publicités ou le contenu
                    Votre terminal peut recevoir et envoyer des informations qui vous permettent de voir des publicités
                    et du contenu et d’interagir avec eux.
                    Mettre en correspondance et combiner des sources de données hors ligne
                    Les données issues de sources de données hors ligne peuvent être combinées à votre activité en ligne
                    à l’appui d’une ou de plusieurs finalités.
                    Relier différents terminaux
                    Différents terminaux peuvent être identifiés comme vous appartenant ou appartenant à votre foyer à
                    l’appui d’une ou de plusieurs finalités
                    Recevoir et utiliser des caractéristiques d’identification d’appareil envoyées automatiquement
                    Votre appareil peut être distingué d’autres appareils en fonction des informations qu’il envoie
                    automatiquement, telles que l’adresse IP ou le type de navigateur.

                    <h2>5. Avez-vous recours à des partenaires pour déployer des publicités sur la Plateforme ?</h2>

                    Afin d’adapter les contenus publicitaires, les partenaires de VIGNITUS utilisent divers cookies.
                    Nous les autorisons à afficher des publicités personnalisées en fonction de vos centres d’intérêt
                    (ainsi qu’à utiliser des cookies associés à des publicités personnalisées) après avoir reçu votre
                    consentement.
                    Les cookies publicitaires de tiers et les trackers mobiles peuvent être utilisés aux fins de
                    l’activation de la publicité contextuelle ou des campagnes de ciblage. Sur la base de votre
                    autorisation de placer des cookies sur votre appareil, nous évaluons également les profils
                    utilisateurs sous un pseudonyme. Dans ce cas, aucune identification personnelle n’est possible. Ces
                    technologies de suivi des tiers et leur utilisation ne sont pas sous notre contrôle. Nos partenaires
                    publicitaires sont soumis à des accords de confidentialité avec nous et/ou à d’autres restrictions
                    légales. Les cookies de tiers sont couverts par les politiques de confidentialité de ces derniers.
                    Nous communiquons ton accord ou désaccord concernant l’utilisation des cookies et le traitement des
                    données personnelles à des fins de publicité personnalisée à nos partenaires.
                    Veuillez noter que votre accord ou désaccord concernant la publicité personnalisée s’appliquera à
                    toutes les plateformes (android, iOS, Web, etc.).

                    <h2>6. Transférez-vous mes données à des partenaires publicitaires en dehors de l’EEE ?</h2>

                    Certains de nos partenaires publicitaires sont établis en dehors de l’Espace économique européen.
                    Lorsque vos données relatives aux cookies sont transférées à ces partenaires, nous sécurisons vos
                    informations en prenant des mesures de protection appropriées.
                    Vous trouverez ci-dessous une liste des partenaires situés en dehors de l’EEE qui diffusent des
                    publicités sur la Plateforme VIGNITUS. Veuillez noter que la liste suivante est constamment mise à
                    jour. Nous vous conseillons donc vivement de consulter régulièrement cette page afin de rester à
                    jour sur les informations les plus récentes.
                    <h2>7. Comment puis-je refuser les trackers de personnalisation des publicités ?</h2>

                    <span className="color">Le suivi sur vos appareils mobiles :</span> vous pouvez désactiver la
                    personnalisation des publicités dans l’Application VIGNITUS en cliquant sur Profil ▸ Paramètres des
                    cookies, et en modifiant les paramètres pertinents, en désélectionnant les types de cookies que vous
                    avez précédemment choisis.
                    <span className="color">Le suivi sur votre navigateur Web :</span> vous pouvez refuser tout suivi
                    supplémentaire à des fins de personnalisation des publicités en cliquant sur Paramètres des cookies,
                    en bas de la page, et en désélectionnant les types de cookies concernés choisis précédemment. Si
                    vous n'êtes pas un utilisateur inscrit, le retrait de votre consentement sera enregistré par
                    l’installation d’un cookie dit « opt-out ». Cela empêchera la capture future de vos données à des
                    fins de personnalisation des publicités lorsque vous consulterez ce site Web. Si vous êtes un
                    utilisateur inscrit, votre décision de retirer votre accord sera appliquée à toutes les plateformes
                    VIGNITUS sur tous les appareils utilisés. Veuillez noter que si vous supprimez tous les cookies sur
                    votre navigateur, le cookie opt-out, qui mémorise vos choix, sera également supprimé. Toutefois la
                    mise à jour des paramètres peut mettre du temps (jusqu’à l’actualisation de l’application) pour
                    fonctionner sur les appareils mobiles.

                    <h2>8. Utilisation de Google Remarketing</h2>

                    Cette technologie permet aux utilisateurs ayant déjà visité notre Site internet et expérimenté nos
                    services en ligne, et qui sont intéressés par l'offre, d'être atteints à nouveau par le biais d'une
                    publicité ciblée sur les pages web du réseau de partenaires Google. L'insertion de la publicité se
                    fait par l'utilisation de cookies. Les cookies peuvent être utilisés pour analyser le comportement
                    des utilisateurs lors de leur visite sur le Site internet et ensuite pour des recommandations de
                    produits ciblées et de la publicité basée sur leurs intérêts. Nous enregistrons dans Google
                    Analytics les Données obtenues par la publicité liée aux intérêts de Google Ireland Ltd. (Irlande)
                    et Google LLC (États-Unis) (« Google ») et les Données des visiteurs provenant de fournisseurs tiers
                    (par ex. âge, sexe et centres d'intérêt).
                    Si vous ne souhaitez pas recevoir de publicités basées sur vos centres d'intérêt et/ou vous opposer
                    à la collecte et à l'utilisation des Données, vous pouvez désactiver l'utilisation des cookies par
                    Google en procédant aux réglages nécessaires dans les paramètres publicitaires de Google (<a
                    href="https://adssettings.google.com">https://adssettings.google.com</a>). Veuillez noter que Google
                    peut stocker de nouveaux cookies à ces fins. Si vous supprimez tous les cookies de votre appareil et
                    que vous devrez peut-être mettre à jour vos paramètres de désactivation. Vous pouvez également
                    désactiver l'utilisation de cookies tiers en visitant la page d'exclusion de la Network Advertising
                    Initiative (<a
                    href="http://www.networkadvertising.org/managing/opt_out.asp">http://www.networkadvertising.org/managing/opt_out.asp</a>).

                    <h2>9. Utilisation de Google Analytics</h2>

                    Ce Site internet utilise Google Analytics, un service d'analyse web de Google. Google Analytics
                    utilise des cookies pour aider à analyser l'utilisation du Site internet par les utilisateurs. Les
                    informations générées par les cookies concernant votre utilisation de ce Site internet sont
                    généralement transférées à un serveur de Google aux États-Unis et y sont stockées. Nous avons activé
                    l'anonymisation de l'adresse IP sur notre plateforme afin que votre adresse IP soit raccourcie au
                    préalable par Google dans les États membres de l'Union européenne ou dans les autres États
                    signataires de l'Accord sur l'Espace économique européen. Ce n'est que dans des cas exceptionnels
                    que l'adresse IP complète sera transmise à un serveur Google aux États-Unis et y sera raccourcie.
                    Google utilisera ces informations au nom de VIGNITUS pour évaluer votre utilisation du Site
                    internet, pour compiler des rapports sur l'activité du Site internet et pour fournir à VIGNITUS
                    d'autres services liés à l'utilisation du Site internet et d'internet. L'adresse IP transmise par
                    votre navigateur dans le cadre de Google Analytics n'est pas fusionnée avec d'autres Données de
                    Google. Vous pouvez refuser l'utilisation de cookies en sélectionnant les paramètres appropriés sur
                    votre navigateur, mais veuillez noter que si vous le faites, il se peut que vous ne puissiez pas
                    utiliser toutes les fonctionnalités de ce Site internet. Vous pouvez également empêcher Google de
                    collecter les Données générées par les cookies et relatives à votre utilisation du Site internet (y
                    compris votre adresse IP) et au traitement de ces Données par Google en téléchargeant et installant
                    le plug-in de navigateur disponible au lien suivant : <a
                    href="http://tools.google.com/dlpage/gaoptout?hl=fr">http://tools.google.com/dlpage/gaoptout?hl=fr</a>.
                    Si vous visitez notre site en utilisant un navigateur qui ne vous permet pas d'installer le plug-in
                    de navigateur, vous pouvez également empêcher Google Analytics de le collecter en cliquant sur le
                    lien suivant Cela crée un cookie d'exclusion qui empêche la collecte future de vos Données lorsque
                    vous visitez ce Site internet. Veuillez noter que le cookie d'exclusion sera également supprimé si
                    vous supprimez tous les cookies stockés dans votre navigateur.
                    Pour un aperçu de la politique de confidentialité de Google, <a
                    href="https://support.google.com/analytics/answer/6004245">cliquez ici :
                    https://support.google.com/analytics/answer/6004245</a>.
                </div>
                </div>
            </div>
        </div>)
}
export default Cookies;
