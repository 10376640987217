import React from "react";
import "./followersCard.css";
import {UrlBaseServer} from "../../Services/storageServer";
import ProfileImg from "../../Assets/defaultAvatar.png";
import {useNavigate} from "react-router-dom";

function FollowersCard({data}) {
    const navigate = useNavigate();

    return (
    <div style={{cursor:"pointer"}} onClick={()=>{
        navigate("/user-profile/"+data.id,{
            state: { user: data },
        })
    }} className="followers-card d-flex align-items-center flex-md-row flex-column  flex-nowrap">
      <div className="col-12  col-md-3 col-lg-3">
          {data?.avatar?(
              <img  src={UrlBaseServer+data?.avatar} alt="" className="followers-card-image" />
          ):(
              <img  src={ProfileImg} alt="" className="followers-card-image" />
          )}
      </div>
      <div className="col-12  col-md-9 col-lg-9">
        <div className="d-flex align-items-center m-0">
          <p className="followers-card-name mr-2">{data?.firstName} {data?.lastName}</p>{" "}
        </div>
      </div>
    </div>
  );
}

export default FollowersCard;

