import animationValidate from "../../Assets/animations/failed.json";
import Lottie from "lottie-react";
import React from "react";
import {Modal} from "react-bootstrap";

export default function FailModal({setShowFailModal,showFailModal}) {

    return(
        <Modal show={showFailModal} onHide={()=>{setShowFailModal(false)}} animation={false}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}} className="successModal modal-dialog-centered" role="document">
                <Lottie
                    style={{ height: 400 }}
                    animationData={animationValidate}
                    autoPlay
                    loop={false}
                    onComplete={()=>{setShowFailModal(false)}}
                />
            </div>
        </Modal>
    )
}
