import React from "react";
import { Navbar, Footer } from "./../../Components";
import Detail from "./../../Containers/CheckOut/Detail";
import Banner from "../../Containers/Cgv/Banner";
import {default as CgvContainer}  from "../../Containers/Cgv/Cgv";

function Cgv() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
          <Banner/>
          <CgvContainer/>
      </div>
      <Footer />
    </div>
  );
}

export default Cgv;
