import React from "react";
import "./style.css";
import api from "../../Services/api"

export default function FeedBackModal({setRefusModal,setReviewModal, user, data, otherUser}) {
  return (
    <div className="card_modal">



        {data.sender_id == user.id?(
            <p
                style={{
                    background: "none",
                    marginBottom: 4,
                }}
            >
                Voici votre offre
            </p>
        ):(
            <p
                style={{
                    background: "none",
                    marginBottom: 4,
                }}
            >
                Voici l’offre de {otherUser?.firstName} {otherUser?.lastName}
            </p>
        )}

      <h3
        style={{
          color: "#6a0013",
        }}
      >
          {data.Offer.price} € ttc
      </h3>
        {data.sender_id == user.id && data.offerStatus == data.Offer.status?(
      <p
        style={{
          width: "70%",
          textAlign: "center",
          background: "none",
        }}
      >
          {otherUser?.firstName} {otherUser?.lastName} a 12 heures pour accepter votre offre avant qu’elle
          ne soit plus disponible.
      </p>):data?.offerStatus == data?.Offer.status?(
            <p
                style={{
                    width: "70%",
                    textAlign: "center",
                    background: "none",
                }}
            >
                Vous avez 12 heures pour accepter cette offre avant qu’elle
                ne soit plus disponible.
            </p>
        ):(
            <p
                style={{
                    width: "70%",
                    textAlign: "center",
                    background: "none",
                }}
            >
                Un choix à déjà était fais
            </p>
        )}
        {data.offerStatus == data.Offer.status && data.sender_id == user.id?(
      <div style={{ display: "flex" , }}>
        <input
          type="button"
          className="btn btn-outline-danger btn-modal mx-4 mb-2"
          value="Annuler"
          onClick={()=> {
              api.sellerCancel(data.Offer.id)

          }}
        />
      </div>):data.offerStatus == data.Offer.status && data.sender_id != user.id?(
            <div style={{ display: "flex" , }}>
                <input
                    type="button"
                    className="btn btn-outline-danger btn-modal mx-4 mb-2"
                    value="Accepter"
                    style={{
                        background: "#6a0013",
                        border: "none",
                        color: "white",
                    }}
                    onClick={()=> {
                        api.sellerAccept(data.Offer.id);
                    }}
                />
                <input
                    type="button"
                    className="btn btn-outline-danger btn-modal mx-4 mb-2"
                    value="Refuser"
                    onClick={()=>{
                        api.sellerCancel(data.Offer.id)
                    }}
                />
            </div>
        ):(<></>)}
    </div>
  );
}
