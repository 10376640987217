import React from "react";
import { Navbar, Footer } from "./../../Components";
import Detail from "./../../Containers/CheckOut/Detail";
import Banner from "../../Containers/Cookies/Banner";
import {default as CookiesContainer}  from "../../Containers/Cookies/Cookies";

function Cookies() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
          <Banner/>
          <CookiesContainer/>
      </div>
      <Footer />
    </div>
  );
}

export default Cookies;
