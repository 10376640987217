import Navbar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer/index";
import Wallet from "./../../Containers/Wallet/Wallet";
import Banner from "./../../Containers/Wallet/Banner";
const Reduction = () => {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
          <Banner/>
          <Wallet/>
      </div>
      <Footer />
    </div>
  );
};

export default Reduction;
