import React from "react";
import { ProductCard } from "./../../../Components";
import "./formember.css";
function ForMembers() {
  return (
    <div className="container" >
      <div>
        <h1 className="sectionTitle">
          Vous aimerez également chez nos autres membres :
        </h1>
      </div>
      <section>
        <div className="cardParent mt-4 mb-4">
          <div className="row">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((v, i) => {
              return (
                <div className="col-sm-8 col-md-5 col-lg-3 prodCard">
                  <ProductCard />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ForMembers;
