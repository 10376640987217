import React from "react";
import { Navbar, Footer } from "./../../Components";
import Detail from "./../../Containers/CheckOut/Detail";
import Banner from "../../Containers/Tarifs/Banner";
import {default as TarifsContainer}  from "../../Containers/Tarifs/Tarifs";

function Tarifs() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
          <Banner/>
          <TarifsContainer/>
      </div>
      <Footer />
    </div>
  );
}

export default Tarifs;
