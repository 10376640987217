import React, {useEffect, useState} from "react";
import "./style.css";
import {useQuery} from "react-query";
import api from "../../../Services/api";
import {UrlBaseServer} from "../../../Services/storageServer";
import {useLocation} from "react-router-dom";
import checkCircle from "./../../../Assets/checkCircle.png"
import checkedCircle from "./../../../Assets/checkedCircle.png"
import BoostModal from "../../../Components/Modal/BoostModal";

const EditBottleContent = ({register,coffret, setCoffret,CRD, setCRD,livraisonMode, setLivraisonMode,year, setYear,region, setRegion,appelation, setAppelation,country, setCountry}) => {
  const [appelationDisplay, setAppelationDisplay] = React.useState([]);

  const { isLoading,
    isError,
    error,
    data:dataColor,
    isFetching,
    isPreviousData, } = useQuery(['repoColor'], ()=> api.getColor(),{ keepPreviousData : true }
  )
  const {
    data:dataRegion
  } = useQuery(['repoRegion'], ()=> api.getRegion(),{ keepPreviousData : true }
  )
  const {
    data:dataCountries
  } = useQuery(['repoCountries'], ()=> api.getCountry(),{ keepPreviousData : true }
  )
  const {
    data:dataAppelation
  } = useQuery(['repoAppelation'], ()=> api.getAllAppelation(),{ keepPreviousData : true }
  )
  const {
    data:dataDeliveryModel
  } = useQuery(['repoDeliveryModel'], ()=> api.getDeliveryModel(),{ keepPreviousData : true }
  )

  const arrayYear = () => {
    let array = [];
    let currentYear = new Date().getFullYear();
    for (let index = currentYear; index >= 1600; index--) {
      array.push(index.toString())
    }

    return array;
  };
  useEffect(()=>{
    if(country==59){
      if(dataAppelation?.data){
        setAppelationDisplay(dataAppelation?.data)
      }
    }
  },[dataAppelation])
  useEffect(()=>{
    if(region){
      const filteredResult = dataRegion.data.find((e) => e.id == region);
      setAppelationDisplay(filteredResult.Appelations)
    }else if(country==59){
      if(dataAppelation?.data){
        setAppelationDisplay(dataAppelation?.data)
      }
    }
  },[region])

  return (
    <div className="container mb-5">
      <div className="bottleContentParent">
        <section className="bottleContent">
          <div className="bottle-content-box">
            <p className="label-light">Titre</p>
            <input
                {...register("title",{ required: true })}
              type="text"
              placeholder="Ex : Charles Joguet"
              className="bottle-content-input"
            />
          </div>
          <div className="bottle-content-box">
            <p className="label-light">Pays</p>
            <select value={country} onChange={e => setCountry(e.target.value)} className="filter-dropdown">
              <option>Pays</option>
              {dataCountries?.data?.map((v,i)=>{
                return(
                    <option key={"country"+v?.id} value={v?.id}>{v?.name}</option>
                )
              })}
            </select>
          </div>
          {country == 59 && (
              <>
          <div className="bottle-content-box">
            <p className="label-light">Région</p>

                <select value={region} onChange={e => {
              console.log(e.target.value)
              setRegion(e.target.value)
            }} className="filter-dropdown">
              <option value="">Région</option>
              {dataRegion?.data?.map((v,i)=>{
                return(
                    <option key={"region"+v?.id} value={v?.id}>{v.name}</option>
                )
              })}
            </select>
          </div>
          <div className="bottle-content-box">
            <p className="label-light">Appélation</p>
            <select value={appelation} onChange={e => {
              console.log(e.target.value)
              setAppelation(e.target.value)
            }} className="filter-dropdown">
              <option value="">Appélation</option>
              {appelationDisplay.map((v,i)=>{
              return(
                  <option key={"appelation"+v?.id} value={v?.id}>{v.name}</option>
              )
            })}
            </select>
          </div></>
            )}
          <div className="bottle-content-box">
            <p className="label-light">Couleur</p>
                <select {...register("color",{ })} className="filter-dropdown">
              <option value="">Couleur</option>
              {dataColor?.data?.map((v,i)=>{
                return(
                    <option key={"color"+v?.id} value={v?.id}>{v.name}</option>
                )
              })}
            </select>
          </div>

          <div className="bottle-content-box">
            <p className="label-light">Millésime</p>
            <select {...register("year",{ })}  className="filter-dropdown">
              <option value="">Millésime</option>
              {arrayYear().map((v,i)=>{
                return(
                    <option key={"year"+v} value={v}>{v}</option>
                )
              })}
            </select>
          </div>
          <div className="bottle-content-box">
            <textarea
                {...register("description",{})}
                placeholder="Description"
                className="bottle-content-description"
                rows={7}
            ></textarea>
          </div>
          <div className="bottle-content-box">
            <p className="label-light">Quantité</p>
            <div className="currency-wrap">
              <input
                  {...register("quantity",{ required: false })}
                  type="text"
                  placeholder="1"
                  className="bottle-content-input"
              />
            </div>
          </div>
          <div className="bottle-content-box">
            <p className="label-light">Viticulture</p>
            <div className="currency-wrap">
              <input
                  {...register("viticulture",{ })}
                  type="text"
                  placeholder="1"
                  className="bottle-content-input"
              />
            </div>
          </div>
          <div className="bottle-content-box">
            <p className="label-light">Type de cave</p>
            <div className="currency-wrap">
              <input
                  {...register("typeCave",{ })}
                  type="text"
                  placeholder="ex: Cave naturelle enterrée"
                  className="bottle-content-input"
              />
            </div>
          </div>
          <div className="bottle-content-box">
            <p className="label-light">Caisse en bois / Coffret d'origine</p>
            <div style={{display:"flex"}} className="currency-wrap">
              <div style={{cursor:"pointer", display:"flex", alignItems:"center"}}  onClick={()=>{
                setCoffret(true)
              }}>
                <img style={{width:25,marginRight:5}} className={coffret?("circleChecked"):("circleUncheck")} src={coffret
                    ? checkedCircle
                    : checkCircle}/>
                <span>Oui</span>
              </div>
              <div style={{cursor:"pointer", display:"flex", alignItems:"center", marginLeft:10}}  onClick={()=>{
                setCoffret(false)
              }}>
                <img style={{width:25, marginRight:5}} className={!coffret?("circleChecked"):("circleUncheck")} src={!coffret
                    ? checkedCircle
                    : checkCircle}/>
                <span>Non</span>
              </div>
            </div>
          </div>
          <div className="bottle-content-box">
            <p className="label-light">Pourcentage d'alacool en %</p>
            <div className="currency-wrap">
              <input
                  {...register("pourcentage",{ })}
                  type="text"
                  placeholder="ex: 12"
                  className="bottle-content-input"
              />
            </div>
          </div>
          <div className="bottle-content-box">
            <p className="label-light">Cru</p>
            <div className="currency-wrap">
              <input
                  {...register("cru",{ })}

                  type="text"
                  placeholder="ex: Grand cru"
                  className="bottle-content-input"
              />
            </div>
          </div>
          <div className="bottle-content-box">
            <p className="label-light">Capsule Représentative de Droit (CRD)</p>
            <div className="currency-wrap">
              <div style={{display:"flex"}} className="currency-wrap">
                <div style={{cursor:"pointer", display:"flex", alignItems:"center"}}  onClick={()=>{
                  setCRD(true)
                }}>
                  <img style={{width:25,marginRight:5}} className={CRD?("circleChecked"):("circleUncheck")} src={CRD
                      ? checkedCircle
                      : checkCircle}/>
                  <span>Oui</span>
                </div>
                <div style={{cursor:"pointer", display:"flex", alignItems:"center", marginLeft:10}}  onClick={()=>{
                  setCRD(false)
                }}>
                  <img style={{width:25, marginRight:5}} className={!CRD?("circleChecked"):("circleUncheck")} src={!CRD
                      ? checkedCircle
                      : checkCircle}/>
                  <span>Non</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bottle-content-box">
            <p className="label-light">Prix de vente</p>
            <div className="currency-wrap">
            <input
                {...register("price",{ required: true })}
              type="text"
              placeholder="Ex : 13.00 €"
              className="bottle-content-input"
            />
            </div>
          </div>


          <div className="bottle-content-box">
            <p className="label-light">Mode de livraison</p>
              {livraisonMode.map((element, index) => {
              return(
                  <div key={"allLivraisonMode"+index} className="bottle-content-box">

                  <div className="bottle-content-detail">
                    <select style={{width:300}} value={element.delivery_mode_model_id}   onChange={e => {
                      let newLivraisonMode = [...livraisonMode];
                      newLivraisonMode.splice(index, 1, {
                        delivery_mode_model_id: e.target.value,
                        price: livraisonMode[index].price,
                        name: livraisonMode[index].name,
                      });
                      setLivraisonMode(newLivraisonMode);
                    }}  className="filter-dropdown">
                      <option value="">Selectionner </option>
                      {dataDeliveryModel?.data?.map((v,i)=>{
                        return(
                            <option key={"livraisonMode"+v.id} value={v.id}>{v.name}</option>
                        )
                      })}
                      <option value="-1">Autre</option>
                    </select>
                    {(livraisonMode[index].delivery_mode_model_id == "-1") && (
                        <input
                            onChange={e => {
                              let newLivraisonMode = [...livraisonMode];
                              newLivraisonMode.splice(index, 1, {
                                delivery_mode_model_id: livraisonMode[index].delivery_mode_model_id,
                                price: livraisonMode[index].price,
                                name: e.target.value,
                              });
                              setLivraisonMode(newLivraisonMode);
                            }}
                            value={element.name}
                            style={{width:300}}
                            type="text"
                            placeholder="Nom du transporteur"
                            className="bottle-content-input"
                        />
                    )}
                    <input
                        onChange={e => {
                          let newLivraisonMode = [...livraisonMode];
                          newLivraisonMode.splice(index, 1, {
                            delivery_mode_model_id: livraisonMode[index].delivery_mode_model_id,
                            price: e.target.value,
                            name:livraisonMode[index].name ,
                          });
                          setLivraisonMode(newLivraisonMode);
                        }}
                        value={element.price}
                        style={{width:100}}
                        type="text"
                        placeholder="Prix"
                        className="bottle-content-input"
                    />
                  </div>
                  </div>
              )
              })}
            <div className="mt-5" style={{display:"flex", justifyContent:"center"}}>

            <button onClick={()=>{
              setLivraisonMode([
                ...livraisonMode,
                {
                  delivery_mode_model_id: "",
                  price: "",
                  name: "",
                },
              ])
            }} type="button" className="btn1 mx-4 mb-2">
              Ajouter un mode de livraison

            </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EditBottleContent;
