import React, {useRef, useState} from "react";
import "./style.css";
import {formatPrice} from "../../Services/tools";
import api from "../../Services/api";
import {useAuth} from "../../Services/auth";
import {useQuery} from "react-query";

export default function ValidationAccountModal({open,setModalToActivate, setMsgInfo}) {
    const {user} = useAuth()
    const [pieceIdentite, setPieceIdentite] = useState(null);
    const [kbis, setKbis] = useState(null);
    const [status, setStatus] = useState(null);

    function verifActivation() {
        // if (!nameTitulaire?.trim()) {
        //   return alert("Veuillez indiquer votre prénom / nom");
        // }
        // if (!birthdayDate?.trim()) {
        //   return alert("Veuillez indiquer votre date de naissance");
        // }
        if (!pieceIdentite) {
            return alert("Veuillez rajouter votre pièce d'identité");
        }
        if (user.number_siret && !kbis) {
            return alert("Veuillez rajouter votre Kbis");
        }
        if (user.number_siret && !status) {
            return alert("Veuillez rajouter votre Status");
        }

        if (user.number_siret) {
            api
                .postKyc({
                    b64: kbis.split(',')[1],
                    type: "Kbis",
                })
                .then((kycData) => {
                    // setModalToActivate(false);
                })
                .catch((error) => {
                    console.log(error);
                });

            api
                .postKyc({
                    b64: status.split(',')[1],
                    type: "Status",
                })
                .then((kycData) => {
                    // setModalToActivate(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        api
            .postKyc({
                b64: pieceIdentite.split(',')[1],
                type: "Pièce identitée",
            })
            .then((kycData) => {
                setMsgInfo(kycData.data.message)
                setModalToActivate(false);
            })
            .catch((error) => {
                console.log(error.response);
            });
        //setWalletActivation(true);
        //clearModal();
        //setModalToActivate(false);
    }
    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    return(
        <div className="modal" style={{
            display: open ? 'block' : 'none'
        }}
             tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Configuration du compte</h5>
                        <button onClick={()=>{
                            setModalToActivate(false)
                        }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlFile1">Envoyez une copie de ma pièce d'identité</label>
                                    <input onChange={e => {
                                        getBase64(e.target.files[0],(result) => {
                                            setPieceIdentite(result)
                                        })
                                       // uploadImage(e.target.files)
                                    }}  type="file" className="form-control-file" id="CIFile"/>
                                </div>
                        </div>
                        {user.number_siret && (
                            <>
                                <div
                                    style={{
                                        marginHorizontal: 20,
                                    }}
                                >

                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlFile1">Envoyez une copie de mon Kbis</label>
                                        <input onChange={e => {
                                            getBase64(e.target.files[0],(result) => {
                                                setKbis(result)
                                            })
                                            // uploadImage(e.target.files)
                                        }} type="file" className="form-control-file" id="kbisFile"/>
                                    </div>
                                </div>
                            </>
                        )}
                        {user.number_siret && (
                            <>
                                <div
                                    style={{
                                        marginHorizontal: 20,
                                    }}
                                >
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlFile1">Envoyez une copie de ses status</label>
                                        <input onChange={e => {
                                            getBase64(e.target.files[0],(result) => {
                                                setPieceIdentite(result)
                                            })
                                            // uploadImage(e.target.files)
                                        }} type="file" className="form-control-file" id="statusFile"/>
                                    </div>

                                </div>
                            </>
                        )}
                        <div>
                            <div
                                style={{
                                    marginHorizontal: 20,
                                    fontSize:12
                                }}
                            >
                                Ton porte-monnaie Vignitus est géré par <span style={{fontWeight:800}}>Mangopay SA</span>, notre
                                prestataire de services de paiement (PSP) réglementé. En
                                activant ton porte- monnaie Vignitus, tu acceptes
                                <span
                                    style={{color:"#6b0019", fontWeight:800, cursor:"pointer"}}
                                   // style={{ color }}
                                    onClick={() => {
                                        window.open("https://www.mangopay.com/fr/update-terms/", '_blank', 'noopener,noreferrer');

                                    }}
                                >
                                    {" les conditions du PSP "}
                                </span>
                                et reconnais que tes données seront transférées au PSP.{"\n\n"}
                                L'activation de ton porte-monnaie Vignitus signifie que tu peux
                                être soumis à des contrôles de vérification d'identité (KYC)
                                effectués par le PSP. Pour plus d'informations, consulte notre
                                Centre d'aide.
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer" style={{justifyContent: "center"}}>
                        <button type="button" className="btnMaroon" style={{width:"auto",cursor:"pointer"}} onClick={()=>{
                            verifActivation()
                        }}>Active ton porte-monnaie</button>
                    </div>
                </div>
            </div>
        </div>
    )

}