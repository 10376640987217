import React from "react";
import "./banner.css";
function Banner() {
  return (
    <div className="product-list-banner">
      <main className="banner-box">
        <div className="product-list-banner-card">
          <h3>Rechercher</h3>
        </div>
      </main>
    </div>
  );
}

export default Banner;
