import React, {useEffect, useState} from "react";
import "./vitrineCard.css";
import WineBottle from "../../Assets/wineBottle.jpg";
import {UrlBaseServer} from "../../Services/storageServer"
import {formatPrice} from "../../Services/tools"
import {useAuth} from '../../Services/auth'
import ProfileImg from "../../Assets/defaultAvatar.png";

import {useNavigate} from "react-router-dom";
import api from "../../Services/api";
import BoostModal from "../Modal/BoostModal";
function VitrineCard({item}) {
  const {user} = useAuth()
  const navigate = useNavigate();
  const [averageRate, setAverageRate] = useState(null);
  const [followerCount, setFollowerCount] = useState(null);
  const [followedCount, setFollowedCount] = useState(null);
  const [wasFollow, setWasFollow] = useState(false);

  React.useEffect(()=>{
    checkDataUser()
  },[item])

  console.log(item)
  function dataAvatar() {
    if (item.avatar) {
      return UrlBaseServer + item.avatar
    } else {
      return ProfileImg
    }
  }
  const checkDataUser = () => {
    if (item) {
      api.getCounter(item.id).then((data) => {
        setAverageRate(data.data.countRate);
        setFollowerCount(data.data.countFollower);
        setFollowedCount(data.data.countFollowed);
      });
      if (user) {
        api.wasFollow(item.id).then((data) => {
          setWasFollow(data.data.followed);
        });
      }
    }
  };
  return (
      <>
        {item?(
      <div className="vitrine" style={{width:"100%"}}>
        <section style={{backgroundColor: "white",
          boxShadow: "0 0 12px -10px #000", width:"100%"}}>
          <div style={{cursor:"pointer"}} onClick={()=>{
          }} className="cardImg">
          </div>
          <div className="cardContent">
            <h3 style={{margin:8}}>
              Cave en Vitrine
            </h3>
            <div  style={{display: "flex", justifyContent:"space-between"}}>
              <div style={{display: "flex", alignItems:"center"}}>
                <img style={{ width: 40,
                  height: 40,
                  borderRadius: 40,
                  margin:8
                  // resizeMode: "contain",
                }} width="80" height="80" src={dataAvatar()}/>
                <div style={{display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column"
                }}>
                  <div className="name">{item?.firstName} {item?.lastName}</div>
                  {averageRate?(
                      <div style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/opinion/"+item.id,{
                          state: { user: item },
                        })
                      }}><i className="fa-solid fa-star"></i> {Math.round(averageRate * 10) / 10}</div>
                  ):            <p className="description">pas encore d’évaluation</p>}
                </div>
              </div>

              <button onClick={()=>{
                if (wasFollow) {
                  api.unFollow(item.id).then(() => {
                    checkDataUser();
                  });
                } else {
                  api.addFollow(item.id).then(() => {
                    checkDataUser();
                  });
                }
              }} className={wasFollow?("btn btn-outline-danger purple-btn-full"):("btn btn-outline-danger purple-btn")}>{wasFollow?("Ne plus suivre"):("Suivre")}</button>
            </div>
            <div style={{display:"flex"}}>
              {item.Products.slice(0, 4).map((element, index) => (
                  <div
                      onClick={()=>{
                        navigate("/product-detail/"+element?.id,{
                          state: { product: element },
                        })
                      }}
                      style={{
                        marginLeft: 20,
                        maxWidth: "400px",
                        cursor:"pointer"
                        //width: (>.WIDTH - 64) / 2.5,
                      }}
                      key={element.id}
                      //activeOpacity={1}
                      // onPress={() =>
                      //     navigation.push("DetailsProduct", {
                      //       data: element,
                      //     })
                      // }
                  >
                    <img style={{width: "100%",
                      height: "300px",
                      objectFit: "cover"}} src={UrlBaseServer+element?.Product_images[0]?.image} />
                    <div>
                      <h5 style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/product-detail/"+element?.id,{
                          state: { product: element },
                        })
                      }}>
                        {item?.name}
                      </h5>

                      <p>{element?.year} {element?.Region?.name} {element?.Appelation?.name}</p>
                      <h3>{formatPrice(element?.price)} &euro;</h3>
                  </div>
                  </div>
              ))}
              <div onClick={()=>{
                navigate("/user-profile/"+item?.id,{
                  state: { user: item },
                })
              }} style={{
                cursor:"pointer",
                display:"flex",
                width:"25%",
                backgroundColor:"#dedede",
                borderWidth: 1,
                marginHorizontal: 20,
                borderRadius: 15,
                borderColor: "#6A011A",
                alignItems: "center",
                justifyContent: "center",
                margin: 8,}}>
                Voir les autres articles !
              </div>
            </div>

          </div>
        </section>
      </div>):null}
        </>
  );
}

export default VitrineCard;
