import React, {useEffect, useState} from "react";
import avisImage from "./../../Assets/defaultAvatar.png";
import ReactStars from "react-rating-stars-component";
import "./opinionCard.css";
import {UrlBaseServer} from "../../Services/storageServer";
import {useNavigate} from "react-router-dom";

function OpinionCard({data}) {
    const [timer, setTimer] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const interval = setInterval(() => {
            compareDate();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    function compareDate() {
        let parseDate = data.updatedAt.replace(" ", "T");
        let dateProduct = new Date(parseDate);
        let dateActual = new Date();

        const diffTime = Math.abs(dateActual - dateProduct);
        //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let time = "";
        if (diffTime < 60000) {
            //secondes
            time = Math.ceil(diffTime / 1000) + "s";
        } else if (diffTime < 3600000) {
            //minutes
            time = Math.ceil(diffTime / 60000) + " min";
        } else if (diffTime < 86400000) {
            //heures
            time = Math.ceil(diffTime / 3600000) + " h";
        } else if (diffTime < 604800000) {
            //jours
            time = Math.ceil(diffTime / 86400000) + " jour(s)";
        } else if (diffTime < 2629800000) {
            //semaines
            time = Math.ceil(diffTime / 604800000) + " semaine(s)";
        } else if (diffTime < 31557600000) {
            //mois
            time = Math.ceil(diffTime / 2629800000) + " mois";
        } else {
            time = Math.ceil(diffTime / 31557600000) + " année(s)";
        }
        setTimer(time);
    }


  return (
    <div onClick={()=>{
        navigate("/user-profile/"+data?.rateRater?.id,{
            state: { user: data?.rateRater },
        })
    }} style={{cursor:"pointer"}} className="opinion-card d-flex align-items-center flex-md-row flex-column  flex-nowrap">
      <div className="col-12  col-md-2 col-lg-2">
          {data?.rateRater?.avatar?(
              <img src={UrlBaseServer+""+data?.rateRater?.avatar} alt="" className="opinion-card-image" />
          ):(
              <img src={avisImage} alt="" className="opinion-card-image" />
              )}
      </div>
      <div className="col-12  col-md-8 col-lg-8">
        <div className="d-flex align-items-center m-0">
          <p className="opinion-card-name mr-2">{data?.rateRater?.firstName} {data?.rateRater?.lastName}</p>{" "}
          <p className="opinion-card-time">il y a {timer}</p>
        </div>
        <p className="opinion-card-description">{data?.message}</p>
      </div>
      <div className="col-12  col-md-2 col-lg-2">
        <h3 className="opinion-card-prize">
          <ReactStars
              edit={false}
            count={5}
            // onChange={ratingChanged}
            size={20}
            activeColor="#FF9017"
            value={data?.rate}
          />
        </h3>
      </div>
    </div>
  );
}

export default OpinionCard;
