import React from "react";
import { Navbar, Footer } from "./../../Components";
import Detail from "./../../Containers/CheckOut/Detail";
function CheckOut() {
  return (
    <div>
      <Navbar />
      <div className="content-wraper">
        <Detail />
      </div>
      <Footer />
    </div>
  );
}

export default CheckOut;
