import "./App.css";
import React from "react";
import {
  Home,
  Faq,
  ProductDetail,
  EditBottle,
  ManageSales,
  UserProfile,
  Messaging,
  ProductList,
  WishList,
  CheckOut,
  Chat,
  Opinion,
  Subscription,
  EditProfile,
    Login,
    SignUp,
    Reduction,
  Wallet,
  Cgu,
  Cgv,
  Confidentialite,
  Cookies,
    Protection,
  Securite,
    Tarifs,
  Catalogue,
    Contact
} from "./Pages";
import { AuthProvider,useAuth,firebaseLogin } from "./Services/auth";
import { ReactQueryProvider } from "./Services/react-query";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import Private from './Router/Private'
import Public from './Router/Public'
import ScrollToTop from "./Services/ScrollToTop";
import firebase from './Services/firebase';
import {isMobile} from 'react-device-detect';
import AskDownloadApp from "./Components/Modal/askDownloadApp";

function App() {
  const [showPopup, setShowPopup] = React.useState(false);

  React.useEffect(()=>{
    if(isMobile){
      window.location.replace("vignitus://");
      setTimeout(()=>{
        setShowPopup(true)
      },1000)
    }

  },[])

  return (
    <div>
      <ReactQueryProvider>
        <AuthProvider>
          <Router>
            <ScrollToTop />
            <Routes>
              <Route exact path="/" element={<Public><Home/></Public>} />
              <Route exact path="/product-detail/:id" element={<ProductDetail />} />
              <Route exact path="/edit-bottle" element={<Private><EditBottle /></Private>} />
              <Route exact path="/edit-bottle/:id" element={<Private><EditBottle /></Private>} />
              <Route exact path="/manage-sales" element={<Private><ManageSales /></Private>} />
              <Route exact path="/reduction" element={<Private><Reduction /></Private>} />
              <Route exact path="/porte-monnaie" element={<Private><Wallet /></Private>} />
              <Route exact path="/user-profile" element={<Private><UserProfile /></Private>} />
              <Route exact path="/user-profile/:id" element={<UserProfile />} />
              <Route exact path="/messaging" element={<Private><Messaging /></Private>} />
              <Route exact path="/product-list" element={<ProductList />} />
              <Route exact path="/favoris" element={<Private><WishList /></Private>} />
              <Route exact path="/checkout" element={<Private><CheckOut /></Private>} />
              <Route exact path="/checkout/:id" element={<Private><CheckOut /></Private>} />
              <Route exact path="/chat/:id" element={<Private><Chat /></Private>} />
              <Route exact path="/opinion/:id" element={<Public><Opinion /></Public>} />
              <Route exact path="/subscription/:id" element={<Public><Subscription /></Public>} />
              <Route exact path="/edit-profile" element={<Private><EditProfile /></Private>} />
              <Route exact path="/login" element={<Public><Login /></Public>} />
              <Route exact path="/faq" element={<Public><Faq /></Public>} />
              <Route exact path="/condition-general-utilisation" element={<Public><Cgu /></Public>} />
              <Route exact path="/condition-general-vente" element={<Public><Cgv /></Public>} />
              <Route exact path="/protection-acheteur" element={<Public><Protection /></Public>} />
              <Route exact path="/cookies" element={<Public><Cookies /></Public>} />
              <Route exact path="/guide-vendeurs-professionnels" element={<Public><Faq /></Public>} />
              <Route exact path="/regle-du-catalogue" element={<Public><Catalogue /></Public>} />
              <Route exact path="/politique-de-securite" element={<Public><Securite /></Public>} />
              <Route exact path="/politique-de-confidentialite" element={<Public><Confidentialite /></Public>} />
              <Route exact path="/regles-catalogue" element={<Public><Faq /></Public>} />
              <Route exact path="/liste-tarifs" element={<Public><Tarifs /></Public>} />
              <Route exact path="/contact" element={<Public><Contact /></Public>} />
            </Routes>
          </Router>
        </AuthProvider>
      </ReactQueryProvider>
      <AskDownloadApp setShowModal={setShowPopup} showModal={showPopup} />
    </div>
  );
}

export default App;
