import React from "react";
import { Navbar, Footer } from "./../../Components";
import ProfileContainer from "../../Containers/EditProfile/ProfileContainer";

function EditProfile() {

  return (
    <div>
      <Navbar />
      <div className="content-wraper">

      <ProfileContainer />
      </div>
      <Footer />
    </div>
  );
}

export default EditProfile;
